import React, { useState, FormEvent, useEffect } from 'react';
import { StyledH4, PrimaryLoadingButton } from 'component-library';
import { showIfOrElse, isEqual } from 'helpers/conditionals';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import {
  StyledForm,
  VerificationErrorMsg,
  AddressVerificationWrapper,
  AddressTileList,
  EditAddressButton,
  VerifyAddressHeader,
  VerifyAddressTitle
} from './styled';
import AddressTile from './AddressTile';
import { findDiff, immutableKeyDelete } from 'faunctions';
import { connect } from 'react-redux';
import { openPreviousModal as _openPreviousModal } from 'store/modal/actions';
import {
  updateCustomerAddressRequest as _updateCustomerAddressRequest,
  addCustomerAddressRequest as _addCustomerAddressRequest,
  refreshCartState as _refreshCartState
} from 'store/customer/actions';

import { Dispatch } from 'redux';
import { newrelic } from 'helpers/reporting/newrelic';
import { isFetching, LoadingState } from 'store/constants';

const ADDRESS_VERIFICATION_TIMEOUT = 'ADDRESS_VERIFICATION_TIMEOUT';

interface ShippingAddressVerificationProps {
  origAddr: CustomerDataAddress;
  suggAddr: CustomerDataAddress | void[];
  openPreviousModal: () => void;
  updateCustomerAddressRequest: (addr: CustomerDataAddress) => void;
  addCustomerAddressRequest: (addr: CustomerDataAddress, shippingMethod?: string) => void;
  shippingVerificationLoadingState: LoadingState;
  refreshCartState: () => void;
}

const isEmptyArray = (obj: unknown): obj is void[] => {
  return Array.isArray(obj) && obj.length === 0;
};

const ShippingAddressVerification = ({
  origAddr,
  suggAddr,
  openPreviousModal,
  updateCustomerAddressRequest,
  addCustomerAddressRequest,
  refreshCartState,
  shippingVerificationLoadingState
}: ShippingAddressVerificationProps) => {
  const [addrToSave, setAddrToSave] = useState(Object.keys(suggAddr).length ? suggAddr : origAddr);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // Cleanup loading state on component dismount
    return () => setIsLoading(false);
  }, []);

  return (
    <AddressVerificationWrapper>
      <VerifyAddressHeader>
        <VerifyAddressTitle secondary>Verify Your Address</VerifyAddressTitle>
        {showIfOrElse(!!suggAddr)(
          <StyledH4 secondary>
            To ensure accurate delivery, we suggest the changes highlighted below. Please choose which address you would
            like to use. If neither option is correct, edit your address.
          </StyledH4>
        )(
          // If Suggested Address isn't provided, render an error header instead
          <VerificationErrorMsg secondary>
            We were unable to verify your address. Please confirm the Original address is accurate then save to
            continue.
          </VerificationErrorMsg>
        )}
      </VerifyAddressHeader>
      <StyledForm
        onSubmit={(e: FormEvent) => {
          e.preventDefault();
          setIsLoading(true);

          if ((addrToSave as QuoteDataAddress).customer_address_id || addrToSave.id) {
            updateCustomerAddressRequest(addrToSave);
          } else {
            const address: QuoteDataAddress = immutableKeyDelete(['id'])(addrToSave);
            addCustomerAddressRequest(address);
          }

          // SetTimeout as failsafe for instances where
          // We have seen an infinite loading button in the past
          setTimeout(() => {
            // Get new state for setTimout
            refreshCartState();

            if (isFetching(shippingVerificationLoadingState)) {
              newrelic('addPageAction')(ADDRESS_VERIFICATION_TIMEOUT, { err: 'Save Address button loading timeout' });
            }
            setIsLoading(false);
          }, 5000);
        }}
      >
        <AddressTileList>
          <AddressTile
            address={origAddr}
            type="Original"
            value="original"
            handleChange={() => setAddrToSave(origAddr)}
            checked={isEqual(addrToSave)(origAddr)}
            editButton={
              <EditAddressButton type="button" onClick={openPreviousModal} disabled={isLoading}>
                Edit
              </EditAddressButton>
            }
          />
          {suggAddr && !isEmptyArray(suggAddr) ? (
            // Only render a Suggested Address if one is passed in
            <AddressTile
              address={suggAddr}
              type="Suggested"
              value="suggested"
              handleChange={() => setAddrToSave(suggAddr)}
              checked={isEqual(addrToSave)(suggAddr)}
              diff={findDiff(suggAddr)(origAddr)}
            />
          ) : null}
        </AddressTileList>
        <PrimaryLoadingButton isLoading={isLoading} width={200}>
          Save Address
        </PrimaryLoadingButton>
      </StyledForm>
    </AddressVerificationWrapper>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openPreviousModal: () => dispatch(_openPreviousModal()),
  updateCustomerAddressRequest: address => dispatch(_updateCustomerAddressRequest(address)),
  addCustomerAddressRequest: address => dispatch(_addCustomerAddressRequest(address)),
  refreshCartState: () => dispatch(_refreshCartState())
});

export default connect(null, mapDispatchToProps)(ShippingAddressVerification);
