import {
  UiState,
  PAGINATION_STATE,
  FILTER_STATE,
  SET_PRODUCTS,
  HIDE_GATEWAY_MODAL,
  OPEN_GATEWAY_MODAL,
  SET_FEATURE_FLAG
} from './constants';
import { GlobalAction, CLEAR_STORE } from '../global/constants';

const initUiState: UiState = {
  currentPaginationState: {
    categoryId: null,
    currentPage: 0,
    displayCount: null
  },
  currentFilterState: {
    filters: []
  },
  featureFlags: {},
  products: [],
  gatewayModal: {
    isVisible: false
  }
};

export default (state: UiState = initUiState, action: any | GlobalAction) => {
  switch (action.type) {
    case PAGINATION_STATE: {
      const { categoryId, currentPage, displayCount } = action.payload;

      return {
        ...state,
        currentPaginationState: {
          categoryId,
          currentPage,
          displayCount
        }
      };
    }
    case FILTER_STATE: {
      const { filters } = action.payload;

      return {
        ...state,
        currentFilterState: {
          filters
        }
      };
    }
    case SET_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products
      };
    }

    case HIDE_GATEWAY_MODAL:
      return {
        ...state,
        gatewayModal: {
          isVisible: false,
        }
      };

    case OPEN_GATEWAY_MODAL:
      return {
        ...state,
        gatewayModal: {
          isVisible: true,
          destination: action.payload.destination,
          hideable: action.payload.hideable
        }
      };
    case SET_FEATURE_FLAG: {
      const featureFlags = {
        ...state.featureFlags,
        [action.payload.name]: { value: action.payload.value, isFetching: action.payload.isFetching }
      };

      return {
        ...state,
        featureFlags
      };
    }
    case CLEAR_STORE: {
      return initUiState;
    }
    default: {
      return {
        ...state
      };
    }
  }
};
