import QuoteDataCart from 'mage-swagfaces/quote/QuoteDataCart';
import { CustomerDataAddress } from 'au-js-sdk/lib/models/magento/customer/CustomerDataAddress';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import { EMPTY_OBJECT } from 'store/constants';
import { immutableShift } from 'helpers/arrays';
import QuoteDataShippingAssignment from 'mage-swagfaces/quote/QuoteDataShippingAssignment';

export const getCustomerAddressesFromCart = (cart: QuoteDataCart): CustomerDataAddress[] =>
  cart?.customer?.addresses || [];

export const cartCustomerHasAddresses = (cart: QuoteDataCart): boolean => getCustomerAddressesFromCart(cart).length > 0;

export const cartHasItems = (cart: QuoteDataCart): boolean => (cart?.items ? cart.items.length > 0 : false);

export const cartHasShippingAddress = (cart: QuoteDataCart): boolean => !!getShippingAddressFromCart(cart).id;

export const getBillingAddressFromCart = (cart: QuoteDataCart): QuoteDataAddress =>
  cart?.billing_address ? cart.billing_address : (EMPTY_OBJECT as QuoteDataAddress);

export const getFirstShippingAssignmentFromCart = (cart: QuoteDataCart): QuoteDataShippingAssignment | false => {
  if (!cart?.extension_attributes?.shipping_assignments) {
    return false;
  }
  return immutableShift(cart.extension_attributes.shipping_assignments) || false;
};

export const getShippingAddressFromCart = (cart: QuoteDataCart): QuoteDataAddress => {
  const firstShippingAssignment = getFirstShippingAssignmentFromCart(cart);
  if (!firstShippingAssignment || !firstShippingAssignment?.shipping?.address) {
    return EMPTY_OBJECT as QuoteDataAddress;
  }
  return firstShippingAssignment.shipping.address;
};

export const getShippingMethodFromCart = (cart: QuoteDataCart): string => {
  const firstShippingAssignment = getFirstShippingAssignmentFromCart(cart);
  if (!firstShippingAssignment || !firstShippingAssignment?.shipping?.method) {
    return '';
  }
  return firstShippingAssignment.shipping.method;
};

export const getProjectIds = (cart: QuoteDataCart): Array<string> => {
  return cart?.items
    ?.reduce((orderedProjectIds, currentItem) => {
      return [...orderedProjectIds, (currentItem?.extension_attributes as any)?.au_project_id];
    }, [])
    .filter(p => p);
};
