import { P2 } from 'au-gatsby-ds/components';
import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
`;

export const AccordionContent = styled.div<{ useNewFonts: boolean }>`
  border-bottom: 1px solid #c2c2c2;
  padding-bottom: 1.25em;

  ${props =>
    props.useNewFonts &&
    `
    ${P2}
    padding-bottom: 16px;
    border-bottom: none;

    a {
      text-decoration: underline !important;
    }
  `}

  @media screen and (max-width: 768px) {
    .desktop-only {
      display: none;
    }

    .au-mobile-only {
      display: block;
    }
  }

  @media screen and (min-width: 768px) {
    .desktop-only {
      display: block;
    }

    .au-mobile-only {
      display: none;
    }
  }
`;
