import styled from 'styled-components';
import { mediaQuery } from '../Grid';

export const Card = styled.div`
  min-width: 50px;
  min-height: 50px;
  display: inline-block;
  padding: 1rem;
  background-color: white;
`;

export const CardWithShadow = styled(Card)`
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
  height: fit-content;
`;

export const CardWithDeepShadow = styled(Card)`
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: rgba(255, 255,255,0.05);
  border-radius: 2px;
  cursor: pointer;
  transition:all .3s ease-out;

  svg {
    cursor: pointer;
  }

  &:hover {
    background-color: rgba(255,255,255,0.75);
  }
`;

export const DeletePaymentMethodButton = styled(CloseButton)`
  @media only screen and (max-width: 768px) {
    width: 21rem;
  }

  @media only screen and (min-width: 768px) {
    margin-right: -1rem;
  }
`;

export const FullScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  background-color: #fff;

  ${mediaQuery.medium`
    background-color: transparent;
  `}

  img {
    max-width: 100%;
  }

  > div {
    pointer-events: none;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    z-index: 1010;
  }

  ${CardWithShadow} {
    color: #434343;
    padding: 1rem;
    margin: auto;
    position: absolute;
    height: 100%;
    pointer-events: all;
    top: 0;
    left: 0;
    width: 100vw;
    /** Ensure that modals are always closeable, even on mobile devices **/
    overflow-y: auto;
    border-radius: 25px;

    ${mediaQuery.large`
      max-width: 1100px;
    `}

    ${mediaQuery.medium`
      padding: 2rem 3rem;
      height: auto;
      width: auto;
      max-height: 96vh;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
    `}
  }

  /** a space between the component and [data-fullscreen] will break the style **/
  ${CardWithShadow}[data-fullscreen=true] {
    max-width: none;
    max-height: none;
    width: 100%;
    overflow: hidden;
    border-radius: 0;
    background-color: rgba(0,0,0,0.90);
    backdrop-filter: blur(24px);
  }

  @media screen and (max-width: 780px) {
    &.partial-overlay-mobile {
      bottom: 0;
      top: unset;
      background-color: rgba(0, 0, 0, 0.25);

      #Modal {
        top: unset;
        bottom: 0;

        ${CardWithShadow} {
          height: auto;
          top: unset;
          bottom: 0;
          border-radius: 25px 25px 0 0;
        }
      }
    }
  }

  .no-padding {
    padding: 00;
    position: absolute;
    height: 100%;
    pointer-events: all;
    border-radius: 0;

    ${CloseButton} {
      position: absolute;
      display: block;
      z-index: 99;
      bottom: 24px;
      left: calc(50% - 32px);
      width: 64px;
      height: 48px;
      padding-top: 12px;

      ${mediaQuery.medium`
        padding: 20px; 
        top: 32px;
        right: 32px;
        left: unset;
        height: 64px;
      `}
    }
  }
`;
