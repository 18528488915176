import styled from 'styled-components';

export const HeaderContent = styled.div`
  font-weight: 900;
  font-szie: 16px;
  color: #000000;
  border-bottom: 1px solid #bdbdbd;
  text-align: center;
  margin-top: -21px;
  padding-bottom: 28px;
  margin-bottom: 32px;
  flex: 0 1 auto;
`;

export const MobileProjectFilterButton = styled.div`
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #333333;
  cursor: pointer;
  text-align: center;
  margin-bottom: 12px;

  &.active {
    color: #ffffff;
    background-color: #333333;
  }
`;

export const FooterContent = styled.div`
  border-top: 1px solid #bdbdbd;
  text-align: center;
  margin-bottom: 12px;
  flex: 0 1 40px;
`;

export const FooterButton = styled.div`
  width: 235px;
  text-align: center;
  color: #ffffff;
  background-color: #333333;
  padding: 15px 62px;
  border-radius: 4px;
  margin: 12px auto;
`;

export const ModalFilterContainer = styled.div`
  flex: 1 1 auto;
`;

export const MobileFilterContainer = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
`;
