import styled, { keyframes } from 'styled-components';

type CodeCopiedProps = {
  visible: boolean;
};

export const StrikethroughContainer = styled.div`
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  color: #000000;
  line-height: 20px;
  font-size: 16px;
  display: inline;
  margin-left: 8px;
`;

export const StrikethroughPrice = styled.span`
  font-weight: 600;
  color: #000000;
`;

export const StrikethroughPromoCode = styled.span`
  .promoCodeContainer {
    display: block;
    background-color: #1c3f48;
    color: #ffffff;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    padding: 8px;
    cursor: pointer;
    margin: 8px 0 0;
    width: fit-content;
  }

  .promoCodeContainer:hover {
    opacity: 0.9;
    transition: opacity 0.2s linear;
  }

  .code {
    text-transform: uppercase;
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
`;

export const CodeCopied = styled.span<CodeCopiedProps>`
  font-size: 10px;
  display: ${props => (props.visible ? 'contents' : 'none')};
  margin-left: 185px;
  animation: ${fadeIn} 0.3s linear, ${fadeOut} 0.3s linear;
  animation-delay: 0s, 1s;
  position: absolute;

  :after {
    content: 'Code copied';
  }
`;
