import styled from 'styled-components';

export const ProjectOption = styled.a`
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  text-decoration: underline;
  text-transform: uppercase;
  opacity: 1;
  clear: both;
  font-size: 11px;
  letter-spacing: 0.15em;
  text-decoration-color: #d2d2d0 !important;
  color: #000;
  transition: color, 0.15s, ease-in;

  &:hover {
    color: #959595;
    cursor: pointer;
  }
`;

export const SelectableColumn = styled.div<{
  selectable?: boolean;
  selected?: boolean;
}>`
  &:hover {
    cursor: ${({ selectable }) => (selectable ? 'pointer;' : 'normal;')};
  }

  ${({ selectable, selected }) =>
    selectable && selected ? 'background-color: rgba(0,0,0,.05);' : ''}
`;
