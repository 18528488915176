import React from 'react';
import styled, { css } from 'styled-components';

const sizes: any = {
  large: 992,
  medium: 768,
  small: 576,
  tiny: 376
};

// Iterate through the sizes and create a media template
export const mediaQuery = Object.keys(sizes).reduce(
  (
    acc: any,
    label: string
  ) => {
    acc[label] = (...args: [any]) => css`
    @media (min-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
  }, {}
);

export const mediaQueryMax = Object.keys(sizes).reduce(
  (
    acc: any,
    label: string
  ) => {
    acc[label] = (...args: [any]) => css`
    @media (max-width: ${sizes[label] / 16}em) {
      ${css(...args)}
    }
  `;
    return acc;
  }, {}
);

export interface ColumnProps {
  size: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
  tiny?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12| 13;
  small?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12| 13;
  medium?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12| 13;
  large?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12| 13;
  xlarge?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12| 13;
}

export const Column = styled.div<ColumnProps>`
  box-sizing: border-box;
  flex: 0 0 auto;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  max-width: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
  ${({ tiny }) => (
    tiny ? mediaQuery.tiny`
         flex-basis: ${(tiny / 12) * 100}%;
         max-width: ${(tiny / 12) * 100}%;
    ` : ''
  )}
  ${({ large }) => (
    large ? mediaQuery.large`
         flex-basis: ${(large / 12) * 100}%;
         max-width: ${(large / 12) * 100}%;
    ` : ''
  )}
  ${({ medium }) => (
    medium ? mediaQuery.medium`
         flex-basis: ${(medium / 12) * 100}%;
         max-width: ${(medium / 12) * 100}%;
    ` : ''
  )}
  ${({ small }) => (
    small ? mediaQuery.small`
         flex-basis: ${(small / 12) * 100}%;
         max-width: ${(small / 12) * 100}%;
    ` : ''
  )}
  ${({ size }) => {
    const percentage = `${(size / 12) * 100}%`;
    return `
      flex-basis: ${percentage};
      max-width: ${percentage};
    `;
  }}
`;

Column.defaultProps = {
  size: 12
};

interface GridProps {
  gutter?: number;
  id?: string;
}

export const Grid = styled.div<GridProps>`
  box-sizing: border-box;
  display: flex;
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;

  ${({ gutter = 8 }) => (gutter
      ? `
          margin-top: 0px;
          margin-right: ${gutter * -1}px;
          margin-bottom: 0px;
          margin-left: ${gutter * -1}px;
          > * {
            padding-left: ${gutter}px;
            padding-right: ${gutter}px;
          }
        `
      : 'margin: 0')};
`;

Grid.defaultProps = {
  gutter: 8
};

export default Grid;
