import { RelatedProduct } from 'types/Product';

type PartialProduct = {} & { relatedProducts: RelatedProduct[] }

export function selectUpsellProducts(product: PartialProduct) {
  return product.relatedProducts
    .filter(p => p.productRelationType === 'upsell')
    .sort((rA, rB) => rA.position - rB.position)
    .map(relatedProduct => relatedProduct.product);
}
