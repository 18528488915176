import { Show } from 'components/Functional';
import Strikethrough from 'components/Strikethrough';
import { formatPrice } from 'helpers/currency';
import { getStrikethroughPrice } from 'helpers/strikethroughPricing';
import React from 'react';
import { StrikethroughValues } from 'types/StrikethroughValues';

export type PriceRule = {
  product_id: number;
  rule_id: number;
  action_operator: 'by_fixed' | 'by_percent';
  action_amount: string;
};

export type PriceObject = {
  productPrice: number;
  basePrice: number;
  productPriceAdd: number;
  envelopePrice: number;
  quantity: number;
  specialPrice: number;
  isCard: boolean;
  isConfigurable: boolean;
  isStandalone: boolean;
  catalogPriceRules: PriceRule[];
  possibleProductIds: string[];
  strikethroughPrice: number | null;
  promoCode: string | null;
  productCategory: string | null;
  productPriceTotal: number; //total price displayed on product header
};

type ProductPriceProps = {
  price: PriceObject;
  inStock: boolean;
  sku: string;
  className?: string;
  productCategory?: string;
  strikethroughValues?: StrikethroughValues;
  shouldDisplayPromoCode?: boolean;
};

type DyanmicPriceProps = {
  price: PriceObject;
  productCategory: string;
  strikethroughValues: StrikethroughValues;
  sku: string;
  shouldDisplayPromoCode?: boolean;
};

const applyPriceRules = (possibleProductIds: string[], priceRules: PriceRule[], price: number): number =>
  priceRules
    .filter(pr => possibleProductIds.includes(pr.product_id.toString()))
    .reduce(
      (rules: PriceRule[], current) =>
        rules.find(r => r.rule_id === current.rule_id) ? rules : rules.push(current) ? rules : rules,
      []
    )
    .reduce((total, currentRule) => {
      switch (currentRule.action_operator) {
        case 'by_fixed':
          return total - parseFloat(currentRule.action_amount);
        case 'by_percent': {
          const discount = total * (1 / parseFloat(currentRule.action_amount));
          return Math.ceil((total - discount) * 100) / 100;
        }
        default:
          break;
      }
      return price;
    }, price);

export const getSubtotal = (price: PriceObject, strikethroughPrice: number): string => {
  // Strikethrough price already has upcharge applied, don't apply it twice.
  const productPrice = strikethroughPrice ? strikethroughPrice : price.productPrice + price.productPriceAdd;

  const totalPrice = productPrice + price.envelopePrice;
  let specialPrice = 0;
  if (price.catalogPriceRules.length > 0) {
    specialPrice = applyPriceRules(price.possibleProductIds, price.catalogPriceRules, totalPrice);
  }
  return formatPrice((specialPrice || totalPrice) * price.quantity);
};

export const DynamicPricing = ({
  price,
  productCategory,
  strikethroughValues,
  shouldDisplayPromoCode,
  sku
}: DyanmicPriceProps): JSX.Element => {
  const totalPrice = price.productPrice + price.productPriceAdd;
  let { specialPrice } = price;

  if (price.catalogPriceRules?.length) {
    specialPrice = applyPriceRules(price.possibleProductIds, price.catalogPriceRules, totalPrice);
  }

  const displayedPrice = specialPrice ? specialPrice : totalPrice;
  const strikethroughPrice = getStrikethroughPrice(strikethroughValues, displayedPrice, sku);
  const isCardWithQuantity = price.isCard && price.quantity > 1;

  return (
    <span style={{ display: 'inline-block' }}>
      {specialPrice && !strikethroughPrice ? <span className="crossedOutPrice">{formatPrice(totalPrice)}</span> : ''}

      <span id="product-details-subtotal" style={isCardWithQuantity ? { width: '100%', display: 'flex' } : {}}>
        {isCardWithQuantity ? `${getSubtotal(price, strikethroughPrice)} subtotal` : ''}
      </span>

      <span id="product-details-price" className={specialPrice ? 'specialPrice' : ''}>
        <Show when={strikethroughPrice && (!price.isCard || isCardWithQuantity)}>
          <>
            <span className="crossedOutPrice">{formatPrice(displayedPrice)}</span>
            {isCardWithQuantity ? ' per card ' : ' '}
            <Strikethrough
              strikethroughPrice={strikethroughPrice}
              promoCode={price.promoCode}
              productCategory={productCategory}
              envelopePricing={getStrikethroughPrice(strikethroughValues, price.envelopePrice, sku)}
              shouldDisplayPromoCode={shouldDisplayPromoCode}
            />
          </>
        </Show>
        <Show when={!strikethroughPrice}>
          <span className="afterpay-price-selector">
            {price.isCard
              ? isCardWithQuantity &&
                `${formatPrice(displayedPrice)} per card ${
                  price.envelopePrice > 0 ? ` + ${formatPrice(price.envelopePrice)} per envelope ` : ''
                }`
              : formatPrice(displayedPrice)}
          </span>
        </Show>
      </span>
    </span>
  );
};

export const getDynamicPrice = (price: PriceObject): number => {
  const totalPrice = price.productPrice + price.productPriceAdd;
  let { specialPrice } = price;
  if (price.catalogPriceRules?.length) {
    specialPrice = applyPriceRules(price.possibleProductIds, price.catalogPriceRules, totalPrice);
  }

  return (specialPrice ? specialPrice : totalPrice) * (price.quantity || 1.0);
};

const ProductPrice = ({
  price,
  inStock,
  className,
  productCategory,
  strikethroughValues,
  shouldDisplayPromoCode,
  sku
}: ProductPriceProps): JSX.Element => {
  return (
    <div className={className}>
      <DynamicPricing
        price={price}
        productCategory={productCategory}
        strikethroughValues={strikethroughValues}
        shouldDisplayPromoCode={shouldDisplayPromoCode}
        sku={sku}
      />
      {!inStock && <span className="error-text">This product is currently out of stock.</span>}
    </div>
  );
};

export default ProductPrice;
