import { useStaticQuery, graphql } from 'gatsby';
import DirectoryDataCountryInformation from 'mage-swagfaces/directory/DirectoryDataCountryInformation';

export const useCountries = (): DirectoryDataCountryInformation[] => {
  const {
    allCountry: { edges: countries }
  } = useStaticQuery(graphql`
    query {
      allCountry {
        edges {
          node {
            two_letter_abbreviation
            full_name_locale
            available_regions {
              id
              code
              name
            }
          }
        }
      }
    }
  `);

  return countries.map((x: { node: DirectoryDataCountryInformation }) => x.node);
};
