import { LoadingState } from '../constants';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import { CartPaymentMethod } from 'helpers/billing';
import { MagentoErrors } from '../magento/constants';
import { AddItemToCartRequestPayload } from './actions';
import { Cart__Patched } from '../../pages/checkout/cart';

export interface CartLoadingStates {
  cart: LoadingState;
  cartItems: LoadingState;
  shippingAddress: LoadingState;
  paymentMethods: LoadingState;
  shippingRates: LoadingState;
  promoCode: LoadingState;
  giftCard: LoadingState;
  placeOrder: LoadingState;
  storeCredit: LoadingState;
}

// Union type that joins Cart__Patched with our top-level UI state
export type CustomUICartState = Cart__Patched & {
  billingAddress: QuoteDataAddress
  shippingAddress: QuoteDataAddress
  shippingMethod: string
  paymentMethods: CartPaymentMethod[]
  shippingRates: QuoteDataShippingMethod[]
}

export interface CartState {
  loadingStates: CartLoadingStates
  // @todo: this should eventually be replaced by our own UI Cart State
  entity: CustomUICartState,
  queuedAddToCartItemPayload: AddItemToCartRequestPayload
}

export enum CartActionType {
  FETCH_CART_REQUEST = 'FETCH_CART_REQUEST',
  FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS',
  FETCH_CART_FAILURE = 'FETCH_CART_FAILURE',
  FETCH_TOTALS_REQUEST = 'FETCH_TOTALS_REQUEST',
  FETCH_TOTALS_SUCCESS = 'FETCH_TOTALS_SUCCESS',
  FETCH_TOTALS_FAILURE = 'FETCH_TOTALS_FAILURE',
  ADD_TO_CART_REQUEST = 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE',
  UPDATE_CART_ITEM_QTY_REQUEST = 'UPDATE_CART_ITEM_QTY_REQUEST',
  UPDATE_CART_ITEM_QTY_SUCCESS = 'UPDATE_CART_ITEM_QTY_SUCCESS',
  UPDATE_CART_ITEM_QTY_FAILURE = 'UPDATE_CART_ITEM_QTY_FAILURE',
  REMOVE_CART_ITEM_REQUEST = 'REMOVE_CART_ITEM_REQUEST',
  REMOVE_CART_ITEM_SUCCESS = 'REMOVE_CART_ITEM_SUCCESS',
  REMOVE_CART_ITEM_FAILURE = 'REMOVE_CART_ITEM_FAILURE',
  APPLY_SHIPPING_ADDRESS_REQUEST = 'APPLY_SHIPPING_ADDRESS_REQUEST',
  APPLY_SHIPPING_ADDRESS_SUCCESS = 'APPLY_SHIPPING_ADDRESS_SUCCESS',
  APPLY_SHIPPING_ADDRESS_FAILURE = 'APPLY_SHIPPING_ADDRESS_FAILURE',
  FETCH_SHIPPING_RATES_REQUEST = 'FETCH_SHIPPING_RATES_REQUEST',
  FETCH_SHIPPING_RATES_SUCCESS = 'FETCH_SHIPPING_RATES_SUCCESS',
  FETCH_SHIPPING_RATES_FAILURE = 'FETCH_SHIPPING_RATES_FAILURE',
  APPLY_SHIPPING_METHOD_REQUEST = 'APPLY_SHIPPING_METHOD_REQUEST',
  APPLY_SHIPPING_METHOD_SUCCESS = 'APPLY_SHIPPING_METHOD_SUCCESS',
  APPLY_SHIPPING_METHOD_FAILURE = 'APPLY_SHIPPING_METHOD_FAILURE',
  SET_FREE_SHIPPING_APPLIED = 'SET_FREE_SHIPPING_APPLIED',
  ANALYTICS_TRACK_FIRST_PURCHASE = 'ANALYTICS_TRACK_FIRST_PURCHASE',
  ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED = 'ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED',
  ANALYTICS_PRODUCT_ADDED_TO_CART = 'PRODUCT_ADDED',
  APPLY_GIFT_WRAP_REQUEST = 'APPLY_GIFT_WRAP_REQUEST',
  APPLY_GIFT_WRAP_SUCCESS = 'APPLY_GIFT_WRAP_SUCCESS',
  APPLY_GIFT_WRAP_FAILURE = 'APPLY_GIFT_WRAP_FAILURE',
  APPLY_PROMO_CODE_REQUEST = 'APPLY_PROMO_CODE_REQUEST',
  APPLY_PROMO_CODE_SUCCESS = 'APPLY_PROMO_CODE_SUCCESS',
  APPLY_PROMO_CODE_FAILURE = 'APPLY_PROMO_CODE_FAILURE',
  REMOVE_PROMO_CODE_REQUEST = 'REMOVE_PROMO_CODE_REQUEST',
  REMOVE_PROMO_CODE_SUCCESS = 'REMOVE_PROMO_CODE_SUCCESS',
  REMOVE_PROMO_CODE_FAILURE = 'REMOVE_PROMO_CODE_FAILURE',
  APPLY_GIFT_CARD_REQUEST = 'APPLY_GIFT_CARD_REQUEST',
  APPLY_GIFT_CARD_SUCCESS = 'APPLY_GIFT_CARD_SUCCESS',
  APPLY_GIFT_CARD_FAILURE = 'APPLY_GIFT_CARD_FAILURE',
  REMOVE_GIFT_CARD_REQUEST = 'REMOVE_GIFT_CARD_REQUEST',
  REMOVE_GIFT_CARD_SUCCESS = 'REMOVE_GIFT_CARD_SUCCESS',
  REMOVE_GIFT_CARD_FAILURE = 'REMOVE_GIFT_CARD_FAILURE',
  BRAINTREE_VALIDATION_REQUEST = 'BRAINTREE_VALIDATION_REQUEST',
  BRAINTREE_VALIDATION_SUCCESS = 'BRAINTREE_VALIDATION_SUCCESS',
  BRAINTREE_VALIDATION_FAILURE = 'BRAINTREE_VALIDATION_FAILURE',
  SAVE_PAYMENT_METHOD_REQUEST = 'SAVE_PAYMENT_METHOD_REQUEST',
  SAVE_PAYMENT_METHOD_SUCCESS = 'SAVE_PAYMENT_METHOD_SUCCESS',
  SAVE_PAYMENT_METHOD_FAILURE = 'SAVE_PAYMENT_METHOD_FAILURE',
  PLACE_ORDER_REQUEST = 'PLACE_ORDER_REQUEST',
  PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS',
  PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE',
  PLACE_ORDER_TRANSACTION_DECLINED = 'PLACE_ORDER_TRANSACTION_DECLINED',
  APPLY_PAYMENT_METHOD = 'APPLY_PAYMENT_METHOD',
  CLEAR_PAYMENT_METHOD = 'CLEAR_PAYMENT_METHOD',
  APPLY_AMAZON_PAY_ADDRESS_REQUEST = 'APPLY_AMAZON_PAY_ADDRESS_REQUEST',
  APPLY_AMAZON_PAY_ADDRESS_SUCCESS = 'APPLY_AMAZON_PAY_ADDRESS_SUCCESS',
  APPLY_AMAZON_PAY_ADDRESS_FAILURE = 'APPLY_AMAZON_PAY_ADDRESS_FAILURE',
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_REQUEST = 'UPDATE_AMAZON_PAY_CHECKOUT_SESSION_REQUEST',
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_SUCCESS = 'UPDATE_AMAZON_PAY_CHECKOUT_SESSION_SUCCESS',
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_FAILURE = 'UPDATE_AMAZON_PAY_CHECKOUT_SESSION_FAILURE',
  PUSH_CART_TO_DIGITAL_DATA = 'PUSH_CART_TO_DIGITAL_DATA',
  APPLY_STORE_CREDIT_REQUEST = 'APPLY_STORE_CREDIT_REQUEST',
  APPLY_STORE_CREDIT_SUCCESS = 'APPLY_STORE_CREDIT_SUCCESS',
  APPLY_STORE_CREDIT_FAILURE = 'APPLY_STORE_CREDIT_FAILURE',
  REMOVE_STORE_CREDIT_REQUEST = 'REMOVE_STORE_CREDIT_REQUEST',
  REMOVE_STORE_CREDIT_SUCCESS = 'REMOVE_STORE_CREDIT_SUCCESS',
  REMOVE_STORE_CREDIT_FAILURE = 'REMOVE_STORE_CREDIT_FAILURE',
  APPLY_AFTERPAY_PAYMENT_METHOD = 'APPLY_AFTERPAY_PAYMENT_METHOD',
  APPLY_AFTERPAY_REDIRECT_URL = 'APPLY_AFTERPAY_REDIRECT_URL',
  APPLY_AFTERPAY_SESSION_ID = 'APPLY_AFTERPAY_SESSION_ID',
  APPLY_AFTERPAY_CHECKOUT_TYPE = 'APPLY_AFTERPAY_CHECKOUT_TYPE',
  APPLY_AFTERPAY_CHECKSUM = 'APPLY_AFTERPAY_CHECKSUM',
  DELETE_CART_PAYMENT_METHODS_REQUEST = 'DELETE_CART_PAYMENT_METHODS_REQUEST',
  DELETE_CART_PAYMENT_METHODS_SUCCESS = 'DELETE_CART_PAYMENT_METHODS_SUCCESS',
  DELETE_CART_PAYMENT_METHODS_FAILURE = 'DELETE_CART_PAYMENT_METHODS_FAILURE',
  QUEUE_ADD_ITEM_TO_CART_PAYLOAD = 'QUEUE_ADD_ITEM_TO_CART_PAYLOAD',
  ANALYTICS_TRACK_CHECKOUT_ERROR = 'ANALYTICS_TRACK_CHECKOUT_ERROR',
  ANALYTICS_TRACK_NEW_CARD_ADDED = 'ANALYTICS_TRACK_NEW_CARD_ADDED',
  ANALYTICS_APPLY_PROMO_CODE = 'ANALYTICS_APPLY_PROMO_CODE',
  ANALYTICS_APPLY_PROMO_CODE_ERROR = 'ANALYTICS_APPLY_PROMO_CODE_ERROR'
}

export const {
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_CART_FAILURE,
  FETCH_TOTALS_REQUEST,
  FETCH_TOTALS_SUCCESS,
  FETCH_TOTALS_FAILURE,
  ADD_TO_CART_REQUEST,
  ADD_TO_CART_SUCCESS,
  ADD_TO_CART_FAILURE,
  UPDATE_CART_ITEM_QTY_REQUEST,
  UPDATE_CART_ITEM_QTY_SUCCESS,
  UPDATE_CART_ITEM_QTY_FAILURE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  APPLY_SHIPPING_ADDRESS_REQUEST,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  FETCH_SHIPPING_RATES_REQUEST,
  FETCH_SHIPPING_RATES_SUCCESS,
  FETCH_SHIPPING_RATES_FAILURE,
  APPLY_SHIPPING_METHOD_REQUEST,
  APPLY_SHIPPING_METHOD_SUCCESS,
  SET_FREE_SHIPPING_APPLIED,
  APPLY_SHIPPING_METHOD_FAILURE,
  ANALYTICS_TRACK_FIRST_PURCHASE,
  ANALYTICS_TRACK_CHECKOUT_STEP_COMPLETED,
  ANALYTICS_PRODUCT_ADDED_TO_CART,
  APPLY_GIFT_WRAP_REQUEST,
  APPLY_GIFT_WRAP_SUCCESS,
  APPLY_GIFT_WRAP_FAILURE,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_PROMO_CODE_FAILURE,
  REMOVE_PROMO_CODE_REQUEST,
  REMOVE_PROMO_CODE_SUCCESS,
  REMOVE_PROMO_CODE_FAILURE,
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_CARD_SUCCESS,
  APPLY_GIFT_CARD_FAILURE,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  REMOVE_GIFT_CARD_FAILURE,
  BRAINTREE_VALIDATION_REQUEST,
  BRAINTREE_VALIDATION_SUCCESS,
  BRAINTREE_VALIDATION_FAILURE,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  SAVE_PAYMENT_METHOD_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_TRANSACTION_DECLINED,
  APPLY_PAYMENT_METHOD,
  CLEAR_PAYMENT_METHOD,
  APPLY_AMAZON_PAY_ADDRESS_REQUEST,
  APPLY_AMAZON_PAY_ADDRESS_SUCCESS,
  APPLY_AMAZON_PAY_ADDRESS_FAILURE,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_REQUEST,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_SUCCESS,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_FAILURE,
  PUSH_CART_TO_DIGITAL_DATA,
  APPLY_STORE_CREDIT_REQUEST,
  APPLY_STORE_CREDIT_SUCCESS,
  APPLY_STORE_CREDIT_FAILURE,
  REMOVE_STORE_CREDIT_REQUEST,
  REMOVE_STORE_CREDIT_SUCCESS,
  REMOVE_STORE_CREDIT_FAILURE,
  DELETE_CART_PAYMENT_METHODS_REQUEST,
  DELETE_CART_PAYMENT_METHODS_SUCCESS,
  DELETE_CART_PAYMENT_METHODS_FAILURE,
  QUEUE_ADD_ITEM_TO_CART_PAYLOAD,
  ANALYTICS_TRACK_CHECKOUT_ERROR,
  ANALYTICS_TRACK_NEW_CARD_ADDED,
  ANALYTICS_APPLY_PROMO_CODE,
  ANALYTICS_APPLY_PROMO_CODE_ERROR
} = CartActionType;

export interface CartAction {
  type: CartActionType | MagentoErrors;
  payload?: any;
  meta?: {
    redirect: boolean
    path: string
  };
}
