import PaymentMethodInputSelector from './PaymentMethodInput';
import { optionGet } from 'faunctions';
import React from 'react';
import { paypal, amazonpay } from 'helpers/billing';
import styled, { css } from 'styled-components';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';

import { AddressTileContent } from '../../Shipping/ShippingAddressSelection/styled';
import PaymentMethodBlock from './PaymentMethodBlock';
import { PaymentMethodTile } from './styled';
import { isPaymentMethodAfterpay } from 'features/afterpay/helpers';

interface PaymentMethodTileRadioProps {
  paymentMethod: CustomerPaymentMethod;
  onChange?: (e: any) => void;
  checked: boolean;
  dataTestId?: string;
}

const Wrapper = styled(AddressTileContent)<{ paypal?: boolean, amazonpay?: boolean, afterpay?: boolean }>`
  background: transparent !important;

  ${({ paypal, amazonpay, afterpay }) =>
  (paypal || amazonpay || afterpay) &&
  css`
      text-align: 1.75rem;
      margin-left: 10px;
    `}
`;

const PaymentMethodTileRadio = ({
  paymentMethod,
  onChange,
  checked,
  dataTestId
}: PaymentMethodTileRadioProps) => {
  const token: string = optionGet('token')(paymentMethod).getOrElse('');

  return (
    <PaymentMethodTile data-testid={dataTestId}>
      <PaymentMethodInputSelector value={token} onChange={onChange} checked={checked}>
        <Wrapper
          paypal={paymentMethod.token === paypal}
          amazonpay={paymentMethod.token === amazonpay}
          afterpay={isPaymentMethodAfterpay(paymentMethod)}
        >
          <PaymentMethodBlock 
            paymentMethod={paymentMethod} 
            isInRadio={true} 
            isPaymentSelected={checked}
          />
        </Wrapper>
      </PaymentMethodInputSelector>
    </PaymentMethodTile>
  );
};

export default PaymentMethodTileRadio;
