import React from 'react';
import { StyledRadioInput, OptionLabel, SoldOutLabel } from '../styled';
import { SwatchProps } from 'au-gatsby-ds/components/SwatchSelect';
import { StyledSwatchContainer, StyledSwatch, DisabledIconContainer, SwatchImg } from './styled';
import SvgContainer from 'au-gatsby-ds/components/Svg';

const SquareImageSwatch = ({
  id,
  name,
  title = '',
  label,
  checked,
  hoverImage,
  disabled,
  mediaUrl,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  return (
    <StyledSwatchContainer>
      <StyledSwatch
        id={`option-value-label-${title}`}
        className={`redesign ${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        htmlFor={`option-value-${id}`}
        onClick={disabled ? undefined : onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        aria-selected={checked}
        aria-label={label}
      >
        {disabled && (
          <DisabledIconContainer>
            <SvgContainer icon="close" />
          </DisabledIconContainer>
        )}
        <StyledRadioInput
          id={`option-value-${id}`}
          key={`option-${id}`}
          type="radio"
          name={name}
          value={id}
          checked={checked}
          disabled={false}
          onChange={disabled ? undefined : onChange}
          onFocus={disabled ? undefined : onClick}
          ref={ref}
        />
        <SwatchImg alt={label} src={hoverImage} />
        <OptionLabel disabled={disabled}>{label}</OptionLabel>
        {disabled && <SoldOutLabel>Sold Out</SoldOutLabel>}
      </StyledSwatch>
    </StyledSwatchContainer>
  );
};

export default SquareImageSwatch;
