// Turn on and off window scrolling 
// If we're zoomed in on an image, for example, 
// we don't want the window to scroll behind
export const setWindowScrollable = (scrollable: boolean): void => {
    if (scrollable) {
        document.body.classList.remove('no-scroll');
        // Safari wants the html tag to have the class as well
        document.getElementsByTagName('html')[0].classList.remove('no-scroll');
        return;
    }
    document.body.classList.add('no-scroll');
    // Safari wants the html tag to have the class as well
    document.getElementsByTagName('html')[0].classList.add('no-scroll');
};