import React from 'react';
import { StyledRadioInput } from '../index.styled';
import { StyledTextSwatch, TextSwatchContainer } from './index.styled';
import { SwatchProps } from '../../types/productAttribute';
import { OptionLabel, SoldOutLabel, SoldOutLabelContainer } from '../shared.styled';

export const TextSwatch = ({
  id,
  name,
  title = '',
  label,
  checked,
  disabled,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  return (
    <TextSwatchContainer>
      <StyledTextSwatch
        id={`option-value-label-${title}`}
        className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        htmlFor={`option-value-${id}`}
        onClick={disabled ? undefined : onClick}
        onMouseOver={disabled ? undefined : onMouseOver}
        onMouseOut={disabled ? undefined : onMouseOut}
        aria-checked={checked}
      >
        <StyledRadioInput
          id={`option-value-${id}`}
          type="radio"
          name={name}
          value={id}
          checked={checked}
          disabled={false}
          onChange={disabled ? undefined : onChange}
          onFocus={disabled ? undefined : onClick}
          ref={ref}
        ></StyledRadioInput>
        <OptionLabel disabled={disabled}>{label}</OptionLabel>
        {disabled && (
          <SoldOutLabelContainer>
            <SoldOutLabel>Sold Out</SoldOutLabel>
          </SoldOutLabelContainer>
        )}
      </StyledTextSwatch>
    </TextSwatchContainer>
  );
};

export default TextSwatch;
