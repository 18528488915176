import React from 'react';
import ShippingAddressFormModal from 'components/Shipping/ShippingAddressFormModal';

const EditContactModal = ({ selectedContact, flashId, flashToken, updateContactData }) => {
  return (
    <ShippingAddressFormModal
      abbreviated={true}
      sendModalData={updateContactData}
      flashId={flashId}
      flashToken={flashToken}
      skipValidation={true}
      address={selectedContact}
    />
  );
};

export default EditContactModal;
