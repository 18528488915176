import React from 'react';
import {
  DesktopOnly,
  SectionWrapper,
  StyledImage,
  SectionTitle,
  StyledText,
  Banner,
  StyledTitleContainer,
  BannerBody,
  Pair
} from './styled';

const getBadgeImageUrl = (badgeName: string) =>
  `https://cdn.artifactuprising.com/assets/images/PDP+Icons/${badgeName}.png?auto=webp&width=200`;

const craftedImg = getBadgeImageUrl('crafted-for-forever');
const thoughtfullyImg = getBadgeImageUrl('thoughtfully-sourced');
const madeToLoveImg = getBadgeImageUrl('made-to-love');
const assembledUSAImg = getBadgeImageUrl('assembled-usa');

const BreakLine = () => <DesktopOnly>__</DesktopOnly>;

const BannerSection = ({
  img,
  alt,
  title,
  copy
}: {
  img: string;
  alt: string;
  title: React.ReactNode;
  copy: React.ReactNode;
}) => (
  <SectionWrapper>
    <StyledImage src={img} alt={alt} />
    <SectionTitle>{title}</SectionTitle>
    <BreakLine />
    <StyledText>{copy}</StyledText>
  </SectionWrapper>
);

export const BuyBanner = ({ className = '' }: { className?: string }) => (
  <Banner className={className}>
    <StyledTitleContainer>Because Your Story Matters</StyledTitleContainer>

    <BannerBody>
      <Pair>
        <BannerSection
          img={craftedImg}
          alt="Image Premium Quality Products"
          title={
            <>
              Premium Quality <br /> Products
            </>
          }
          copy="Carefully crafted with time-tested materials that let your most meaningful stories live on."
        />
        <BannerSection
          img={thoughtfullyImg}
          alt="Image Thoughtfully sourced materials"
          title={
            <>
              Thoughtfully <br /> sourced materials
            </>
          }
          copy="Because the moments that are worth preserving happen in places that are worth protecting."
        />
      </Pair>
      <Pair>
        <BannerSection
          img={madeToLoveImg}
          alt="Image Made to Love Guaranteed"
          title={
            <>
              Made to Love, <br /> Guaranteed
            </>
          }
          copy="If you’re not completely happy with your order experience, we’ll make it right — human to human."
        />
        <BannerSection
          img={assembledUSAImg}
          alt="Image Assembled in the USA"
          title={
            <>
              Assembled <br /> in the USA
            </>
          }
          copy="We maintain a deep connection with the artifacts we create by crafting them close to home."
        />
      </Pair>
    </BannerBody>
  </Banner>
);
