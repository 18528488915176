/**
 * ComponentName
 *
 * Enum used for component loading time tracking. The names here will show up in
 * the 'componentLoaded' analytics event
 */
export enum ComponentName {
  PAYMENT_INFORMATION = 'Payment Information',
  SHIPPING_ADDRESS = 'Shipping Address',
  SHIPPING_OPTIONS = 'Shipping Options',
  SUMMARY = 'Summary',
  YOU_MAY_ALSO_LIKE = 'You May Also Like'
}
