import React, { useState, FormEvent } from 'react';
import { Column, Grid, PrimaryButton, StyledP, ErrorLabel } from 'component-library';
import { optionGet } from 'faunctions';
import { Checkbox } from 'component-library';
import { TextInput } from 'component-library';
import { connect } from 'react-redux';
import { GlobalState } from 'store/constants';
import { applyGiftWrapRequest } from 'store/cart/actions';
import { cartItemsSelector } from 'store/cart/selectors';
import { hideModal as _hideModal } from 'store/modal/actions';
import { showIf, any, all } from '../../helpers/conditionals';
import { Dispatch, Action } from 'redux';
import QuoteDataCart from 'mage-swagfaces/quote/QuoteDataCart';
import {
  CenteredHeading,
  SubHeading,
  VerticalLine,
  CheckboxLabel,
  GiftWrapImg,
  GiftOptionsForm,
  ButtonWrapper,
  GiftOptionsModalWrapper,
  MarginCheckbox
} from './styled';
import { isEmptyString } from 'helpers/strings';

const getGiftWrapOptions = (cartItems, id) => {
  const item = cartItems.filter(c => c.item_id === id)[0];
  const gift_wrapping = optionGet('extension_attributes.gift_wrapping')(item).getOrElse({
    wrappingId: 0
  });
  const gift_message = optionGet('extension_attributes.gift_message')(item)
    .map(x => ({
      ...x,
      gift_message_id: !!x.gift_message_id
    }))
    .getOrElse({
      gift_message_id: false,
      message: null,
      sender: null,
      recipient: null
    });
  const formatted = {
    ...item,
    extension_attributes: {
      ...item.extension_attributes,
      gift_message,
      gift_wrapping
    }
  };
  return formatted;
};

const GiftWrapModal = ({
  itemId,
  applyGiftWrap,
  hideModal,
  cartItems
}: {
  itemId: string
  applyGiftWrap: any
  hideModal: any
  cartItems: any
}) => {
  const [cartItemState, setCartItemState] = useState({
    ...getGiftWrapOptions(cartItems, itemId)
  });
  const [error, setError] = useState(false);

  /* @todo: This component is manipulating a complex QuoteDataCartItem object, where we should probably be manipulating
            simpler state variables like giftWrap and giftMessage, and then building a cart item payload onSubmit */

  const onStateChange = (property: string) => (field: string) => (value: any) => {
    setCartItemState({
      ...cartItemState,
      extension_attributes: {
        ...cartItemState.extension_attributes,
        [field]: property
          ? {
              ...cartItemState.extension_attributes[field],
              [property]: value
            }
          : value
      }
    });
  };
  const gift_messaging = cartItemState.extension_attributes.gift_message;
  const giftWrappingApplied = !!cartItemState.extension_attributes.gift_wrapping;
  const giftMessagingApplied = !!gift_messaging.gift_message_id;

  return (
    <GiftOptionsModalWrapper>
      <CenteredHeading secondary>Gift Note</CenteredHeading>
      <GiftOptionsForm>
        <Grid>
          {showIf(cartItemState.gift_message_available)(
            <Column size={12} medium={5}>
              <MarginCheckbox
                disabled={giftWrappingApplied}
                onClick={() => {
                  setError(false);
                  if (giftMessagingApplied && giftWrappingApplied) {
                    // This ensures that giftMessaging is required when giftWrapping is applied
                    setCartItemState({
                      ...cartItemState,
                      extension_attributes: {
                        gift_wrapping: 0,
                        gift_message: {
                          ...cartItemState.extension_attributes.gift_message,
                          gift_message_id: 0
                        }
                      }
                    });
                  } else {
                    onStateChange('gift_message_id')('gift_message')(giftMessagingApplied ? 0 : 1);
                  }
                }}
                tabIndex={100}
                checked={giftMessagingApplied}
              >
                <CheckboxLabel>
                  <StyledP secondary>Include gift message {<>&mdash;</>} $0</StyledP>
                </CheckboxLabel>
              </MarginCheckbox>
              <Grid>
                <Column size={12} medium={6}>
                  <TextInput
                    error={all(error, !gift_messaging.recipient) ? '(Required)' : ''}
                    label="To*"
                    value={gift_messaging.recipient || ''}
                    onChange={e => {
                      onStateChange('recipient')('gift_message')(e.currentTarget.value);
                      setError(false);
                    }}
                    maxLength={25}
                    disabled={!giftMessagingApplied}
                    tabIndex={101}
                  />
                </Column>
                <Column size={12} medium={6}>
                  <TextInput
                    error={all(error, !gift_messaging.sender) ? '(Required)' : ''}
                    label="From*"
                    value={gift_messaging.sender || ''}
                    onChange={e => {
                      onStateChange('sender')('gift_message')(e.currentTarget.value);
                      setError(false);
                    }}
                    maxLength={25}
                    disabled={!giftMessagingApplied}
                    tabIndex={102}
                  />
                </Column>
              </Grid>
              <TextInput
                error={all(error, !gift_messaging.message) ? '(Required)' : ''}
                inputType="textarea"
                label="Message*"
                value={gift_messaging.message || ''}
                onChange={e => {
                  onStateChange('message')('gift_message')(e.currentTarget.value);
                  setError(false);
                }}
                maxLength={200}
                tabIndex={103}
                disabled={!giftMessagingApplied}
              />
            </Column>
          )}
          {showIf(cartItemState.gift_wrapping_available && cartItemState.gift_message_available)(
            <Column size={12} medium={2}>
              <VerticalLine />
            </Column>
          )}
          {showIf(cartItemState.gift_wrapping_available)(
            <Column size={12} medium={5}>
              <SubHeading secondary>Gift Wrap</SubHeading>
              <Checkbox
                onClick={() => {
                  setCartItemState({
                    ...cartItemState,
                    extension_attributes: {
                      ...cartItemState.extension_attributes,
                      gift_wrapping: giftWrappingApplied ? 0 : 1,
                      gift_message: {
                        ...cartItemState.extension_attributes.gift_message,
                        gift_message_id: 1
                      }
                    }
                  });
                }}
                tabIndex={104}
                checked={giftWrappingApplied}
              >
                <CheckboxLabel>
                  <StyledP secondary>Include gift wrapping {<>&mdash;</>} $10</StyledP>
                  <StyledP secondary>(Gift message required)</StyledP>
                </CheckboxLabel>
              </Checkbox>
              <GiftWrapImg src="https://media.artifactuprising.com/media/wrapping/gift-wrap-note_2x_2_.jpg" />
            </Column>
          )}
        </Grid>
        <ButtonWrapper>
          <PrimaryButton
            onClick={(e: FormEvent) => {
              const giftMessage = optionGet('extension_attributes.gift_message')(cartItemState).getOrElse(false);

              if (giftMessage) {
                const message = optionGet('message')(giftMessage).getOrElse('');
                const recipient = optionGet('recipient')(giftMessage).getOrElse('');
                const sender = optionGet('sender')(giftMessage).getOrElse('');

                if (
                  giftMessagingApplied &&
                  any(isEmptyString(message), isEmptyString(recipient), isEmptyString(sender))
                ) {
                  setError(true);
                } else {
                  applyGiftWrap(cartItemState);
                  hideModal();
                }
              }

              e.preventDefault();
            }}
            width={200}
          >
            Update
          </PrimaryButton>
        </ButtonWrapper>
      </GiftOptionsForm>
    </GiftOptionsModalWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  cartItems: cartItemsSelector(state)
});
const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  hideModal: () => dispatch(_hideModal()),
  applyGiftWrap: (cart: QuoteDataCart) => dispatch(applyGiftWrapRequest(cart))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GiftWrapModal);
