import { useEffect } from 'react';
import { TrackingProp } from 'react-tracking';
import Itly, { FilterDeselectedProperties } from 'itly';
import { FilterDeselectedEvent } from '../analyticsWrapper';

export function useTrackFilterDeselected(
  tracking: TrackingProp<FilterDeselectedEvent>,
  attributeCode: string,
  optionDeselected: string
) {
  useEffect(() => {
    trackFilterDeselected(tracking, attributeCode, optionDeselected);
  }, [tracking, attributeCode, optionDeselected]);
}

export function trackFilterDeselected(
  tracking: TrackingProp<FilterDeselectedEvent>,
  attributeCode: string,
  optionDeselected: string,
  filterState: any
) {
  const data: FilterDeselectedProperties = {
    applied_filters: filterState?.filters,
    filter_option: attributeCode,
    filter_value: optionDeselected
  };

  Itly.filterDeselected(data);
}
