import { all, call, put, select, takeEvery } from '@redux-saga/core/effects';
import { CustomerPaymentMethod } from 'au-types/lib/magento/sales/braintree/CustomerPaymentMethod';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import {
  getCustomer,
  getPaymentMethods,
  saveNewCustomerAddress,
  updateCustomerAddress
} from 'services/magenum/customer';

import { applyShippingAddressRequest, applyPaymentMethod } from '../cart/actions';
import {
  shippingMethodSelector,
  totalSegmentsSelector,
  isPaypalEnabledSelector,
  isAmazonpayEnabledSelector
} from '../cart/selectors';
import { hideModal, openShippingAddressSelectionModal } from '../modal/actions';
import { currentModalDataSelector } from '../modal/selectors';
import { customerSelector } from 'store/customer/selectors';
import {
  addCustomerAddressFailure,
  addCustomerAddressSuccess,
  deleteCustomerAddressFailure,
  deleteCustomerAddressSuccess,
  fetchCustomerFailure,
  fetchCustomerPaymentMethodsFailure,
  fetchCustomerPaymentMethodsSuccess,
  fetchCustomerSuccess,
  updateCustomerAddressFailure,
  updateCustomerAddressSuccess
} from './actions';
import { CustomerAction, CustomerActionType, DELETE_CUSTOMER_ADDRESS_REQUEST } from './constants';
import { isNotEmpty } from 'helpers/arrays';
import { handleSagaDispatch } from '../sagas';
import { deleteCustomerAddress as _deleteCustomerAddress } from 'services/magenum/customer';
import { ApiRequestFailure, setUrlParams } from 'helpers/http';
import { getCurrentURL, redirectToPage } from 'helpers/navigation';
import { getGrandTotalFromTotalSegments } from 'store/cart/magentoSelectors';
import { CartAction } from '../cart/constants';
import { newrelic } from 'helpers/reporting/newrelic';
import { optionGet } from 'faunctions';
import { all as allTrue } from 'helpers/conditionals';
import { isAfterpayAppliedToCartSelector } from 'features/afterpay/store/selectors';
import { isNotServerSideRendering, isServerSideRendering } from 'au-gatsby-ds/helpers/env';
import { customerCustomAttributesSelector, customerEmailSelector, customerIdSelector } from './selectors';
import { getCustomAttribute } from 'helpers/mage-helpers/magento';
import { shouldGoToEditorV2 } from 'helpers/projects/redirect';

const {
  FETCH_CUSTOMER_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  ADD_CUSTOMER_ADDRESS_REQUEST,
  FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST,
  CUSTOMER_LOGIN_SUCCESS
} = CustomerActionType;

// Workers

function* fetchCustomer(action: CartAction) {
  const response = yield call(getCustomer);
  yield handleSagaDispatch<CustomerDataCustomer>(response, action)(fetchCustomerSuccess)(fetchCustomerFailure);
}

function* addCustomerAddress(action: CartAction) {
  /**
   * Address verification happens in the AddressVerification Modal,
   * if we're on the shipping page during a users first checkout, we set a flag
   * in the modalData indicating firstTimeFlow.
   *
   * If firstTimeFlow is true, prevent the modal from being hidden so that the dispatched
   * applyShippingAddressRequest can check for the flag and hide the modal and redirect
   * to the billing page, otherwise just hide the modal since it means we're already on the
   * checkout page
   */

  const modalData = yield select(currentModalDataSelector);
  if (modalData && !modalData.firstTimeFlow) {
    yield put(hideModal());
  }

  const { address: addrWithoutId } = action.payload;
  const response = yield call(saveNewCustomerAddress, addrWithoutId);

  yield response
    .map(function*(customer: CustomerDataCustomer) {
      const address = customer.addresses.pop();
      yield put(addCustomerAddressSuccess(address));

      if (!window.location.href.includes('checkout')) {
        yield put(hideModal());
        return;
      }

      const shippingMethod = yield select(shippingMethodSelector);
      yield put(applyShippingAddressRequest(address, shippingMethod));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(addCustomerAddressFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
    });
}

function* editCustomerAddress(action: CartAction) {
  const { address } = action.payload;
  const response = yield call(updateCustomerAddress, address);

  yield response
    .map(function*(customer: CustomerDataCustomer) {
      yield put(updateCustomerAddressSuccess(customer));

      if (!window.location.href.includes('checkout')) {
        yield put(hideModal());
        return;
      }

      //if we are in the checkout flow, then proceed with shipping address selection
      const updatedAddress = customer.addresses.find(x => x.id === address.id);
      const shippingMethod = yield select(shippingMethodSelector);
      yield put(applyShippingAddressRequest(updatedAddress, shippingMethod));

      const modalData = yield select(currentModalDataSelector);

      if (modalData && modalData.firstTimeFlow) {
        // If editing shipping address in firstTimeFlow
        yield put(hideModal());

        const totalSegments = yield select(totalSegmentsSelector);
        const isFree = getGrandTotalFromTotalSegments(totalSegments) === 0;

        if (isFree) {
          /* When applying a shipping address on the shipping page, if payment has been taken care of
              through store credit and/or gift cards we can skip the billing page and head straight to review */
          redirectToPage('/checkout');
        } else {
          redirectToPage('/checkout/billing');
        }
      } else {
        // Otherwise, we're on the review page
        yield put(openShippingAddressSelectionModal());
      }
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(updateCustomerAddressFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
    });
}

function* deleteCustomerAddress(action: CartAction) {
  const { address } = action.payload;

  const response = yield call(_deleteCustomerAddress, address);

  yield response
    .map(function*() {
      yield put(deleteCustomerAddressSuccess(address));
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(deleteCustomerAddressFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
    });
}

function* fetchCustomerPaymentMethods(action: CartAction) {
  // @todo Limit where this is called to not impact guest users
  const response = yield call(getPaymentMethods);

  yield response
    .map(function*(paymentMethods: CustomerPaymentMethod[]) {
      yield put(fetchCustomerPaymentMethodsSuccess(paymentMethods));

      const totalSegments = yield select(totalSegmentsSelector);
      const isFree = getGrandTotalFromTotalSegments(totalSegments) === 0;
      const isPaypalEnabled = yield select(isPaypalEnabledSelector);
      const isAmazonpayEnabled = yield select(isAmazonpayEnabledSelector);
      const isAfterpaySelected = yield select(isAfterpayAppliedToCartSelector);

      if (allTrue(!isFree, isNotEmpty(paymentMethods), !isPaypalEnabled, !isAmazonpayEnabled, !isAfterpaySelected)) {
        yield put(applyPaymentMethod(paymentMethods[0]));
      }
    })
    .getOrElseL(function*(err: ApiRequestFailure) {
      newrelic('addPageAction')(action.type, { err });

      yield put(fetchCustomerPaymentMethodsFailure(optionGet('body.message')(err).getOrElse('Something went wrong.')));
    });
}

function* customerLoginRedirect(action: CustomerAction) {
  if (isServerSideRendering()) {
    return;
  }
  // select all the pieces for the redirect

  const { editorVersion, redirectTo, hammerCategory, message, sku } = action.payload;
  const customer = yield select(customerSelector);

  if (!customer?.email) {
    const customerResponse = yield call(getCustomer);
    yield handleSagaDispatch<CustomerDataCustomer>(customerResponse, action)(fetchCustomerSuccess)(
      fetchCustomerFailure
    );
  }

  const correctedRedirectTo = redirectTo ? decodeURIComponent(redirectTo) : '/';
  const customAttributes = yield select(customerCustomAttributesSelector);
  const flashId = getCustomAttribute('flash_id')(customAttributes);
  const flashToken = getCustomAttribute('flash_auth_token')(customAttributes);
  const customerEmail = yield select(customerEmailSelector);
  const customerId = yield select(customerIdSelector);

  let nextUrl = correctedRedirectTo;

  if (nextUrl?.includes('edit') || nextUrl?.includes('build')) {
    if (flashId && flashToken) {
      nextUrl = setUrlParams(nextUrl, { flashId, flashToken });
    }
  }

  if (hammerCategory || sku) {
    try {
      const shouldRedirect = shouldGoToEditorV2(sku, hammerCategory, customerEmail, customerId, editorVersion);
      if (shouldRedirect) {
        nextUrl = nextUrl.replace(process.env.GATSBY_EDITOR_URL || '', process.env.GATSBY_EDITOR_V2_URL || '');
      }
    } catch (error) {
      console.error('Error checking Editor V2 split', error); // we should handle this better;
    }
  }

  // dispatch the redirect
  if (window.opener) {
    window.opener.postMessage({ postAuthRedirect: nextUrl, source: 'au-google-auth-popup' }, getCurrentURL());
    window.close();
  } else {
    redirectToPage(nextUrl);
  }
}

// Watchers

function* watchCustomer() {
  yield takeEvery(FETCH_CUSTOMER_REQUEST, fetchCustomer);
}

function* watchAddCustomerAddress() {
  yield takeEvery(ADD_CUSTOMER_ADDRESS_REQUEST, addCustomerAddress);
}

function* watchEditCustomerAddress() {
  yield takeEvery(UPDATE_CUSTOMER_ADDRESS_REQUEST, editCustomerAddress);
}

function* watchFetchPaymentMethods() {
  yield takeEvery(FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST, fetchCustomerPaymentMethods);
}

function* watchDeleteCustomerAddress() {
  yield takeEvery(DELETE_CUSTOMER_ADDRESS_REQUEST, deleteCustomerAddress);
}

function* watchCustomerLoginSuccess() {
  yield takeEvery(CUSTOMER_LOGIN_SUCCESS, customerLoginRedirect);
}

export default function*() {
  yield all([
    watchCustomer(),
    watchEditCustomerAddress(),
    watchAddCustomerAddress(),
    watchFetchPaymentMethods(),
    watchDeleteCustomerAddress(),
    watchCustomerLoginSuccess()
  ]);
}
