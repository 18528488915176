import styled from 'styled-components';
import SvgContainer from '../../Svg';
import { P2 } from '../..';

export const disabledInputCss = `
  &:not(.disabled) {
    :hover,
    &.checked {
      border: 1.5px solid #000;
    }

    &not(.checked) {
      border-color: transparent;
    }
  }
  &.disabled {
    cursor: not-allowed;
    opacity: 0.9;
    color: #757575;
  }
`;

export const TextSwatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 32%;
`;

export const StyledTextSwatch = styled.label`
  ${P2}

  display: flex !important;
  position: relative;
  flex-direction: column;
  box-sizing: content-box;
  justify-content: center;
  background-color: #fff;
  border: 1.5px solid #e0e0e0;
  height: 2.5rem;
  align-items: center;
  width: auto;
  height: 40px;
  padding: 0px 8px 0px 8px;
  letter-spacing: 0.01em;

  ${disabledInputCss}
`;

export const SoldOutContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  bottom: 0;
`;

export const SoldOutIcon = styled(SvgContainer)`
  width: 10px;
  height: 10px;
  margin-bottom: 3px;

  img {
    position: inherit;
  }
`;

export const SoldOutLabel = styled.span`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #757575;
  text-decoration: none;
  letter-spacing: normal;
  margin: auto;
`;

export const OptionLabel = styled.span<{ disabled: boolean; paperType: boolean; }>`
  width: 100%;
  white-space: nowrap;
  overflow: ${props => (props.paperType ? 'none' : 'hidden')};
  text-overflow: ${props => (props.paperType ? 'none' : 'ellipsis')};
  filter: ${props => (props.disabled ? 'blur(2px)' : 'none')};

  @media (max-width: 1350px) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
