import { createContext } from 'react';

interface GatewayContextProps {
  updateGateway: (gatewayId: string, children: JSX.Element) => void
  removeGateway: (gatewayId: string) => void
  addGateway: (into: string, children: JSX.Element, onSetGatewayId: (gatewayIdParam: string) => void) => void
  addContainer: (name: string, children?: JSX.Element) => void
  removeContainer: (gatewayId: string) => void
  getContainerChildren: (gatewayId: string) => JSX.Element[]
}

const defaultContext: GatewayContextProps = {
  updateGateway: () => {},
  removeGateway: () => {},
  addGateway: () => () => {},
  addContainer: () => {},
  removeContainer: () => {},
  getContainerChildren: () => []
};

const GatewayContext = createContext(defaultContext);

export default GatewayContext;
