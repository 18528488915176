import { isNotServerSideRendering, getGatsbySiteUrl } from '../../../helpers/env';
import Itly, { AssetClickedProperties } from 'itly';
import { useEffect } from 'react';
import { ensureStartsWithSlash, isPath } from '../../../helpers/strings';

export function useTrackAssetClicked(creative: string, location: string, navigationTarget: string) {
  useEffect(() => trackAssetClicked(creative, location, navigationTarget), [creative, location, navigationTarget]);
}

export function trackAssetClicked(creative: string, location: string, navigationTarget: string) {
  if (creative && location && navigationTarget && isNotServerSideRendering()) {
    const url = isPath(navigationTarget) ? `${getGatsbySiteUrl()}${ensureStartsWithSlash(navigationTarget)}` : navigationTarget;
    const data: AssetClickedProperties = { creative: creative, location, navigation_target: url };
    Itly.assetClicked(data);
  }
}
