import { GlobalState } from 'store/constants';
import { findCustomerPaymentMethodInCartPaymentMethods } from 'helpers/billing';
import { PAYMENT_METHOD_AFTERPAY } from 'au-types/lib/magento/sales/payment';
import { cartPaymentMethodsSelector } from 'store/cart/selectors';
import { optionGet } from 'faunctions';

export const isAfterpayAppliedToCartSelector = (state: GlobalState) =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === PAYMENT_METHOD_AFTERPAY;

export const afterpaySessionIdSelector = (state: GlobalState) => optionGet('cart.afterpaySessionId')(state).getOrElse(null);

export const afterpayRedirectUrlSelector = (state: GlobalState) => optionGet('cart.afterpayRedirectUrl')(state).getOrElse(null);

export const afterpayCheckoutTypeSelector = (state: GlobalState) => optionGet('cart.afterpayCheckoutType')(state).getOrElse(null);

export const afterpayChecksumSelector = (state: GlobalState) => optionGet('cart.afterpayChecksum')(state).getOrElse(null);