import SvgContainer from 'au-gatsby-ds/components/Svg';
import styled from 'styled-components';

export const StyledTooltipContainer = styled.div`
  position: absolute;
  background: white;
  right: 100%;
  top: 50%;
  min-height: 250px;
  width: 250px;
  z-index: 10000 !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
  border-radius: 4px;

  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const ImageContainer = styled.div`
  height: 250px;
  border-radius: 4px 4px 0px 0px;
`;

export const SoldOutIconWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: inherit;
`;

export const SoldOutIcon = styled(SvgContainer)<{ isWhite: boolean }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: ${({ isWhite }) => (isWhite ? 'rgba(0, 0, 0, 0.05)' : 'rgba(255, 255, 255, 0.5)')};
`;

export const StyledImg = styled.img`
  width: 100%;
  border-radius: 4px 4px 0px 0px;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
`;

export const DetailsContainer = styled.div`
  width: 100%;
  padding: 18px 23px;
`;

export const StyledTooltipLabel = styled.span<{ isSoldOut: boolean }>`
  display: block;
  font-size: 16px;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-weight: bold;
  text-transform: capitalize;
  color: #333;
  text-decoration: ${({ isSoldOut }) => (isSoldOut ? 'line-through' : 'none')};
`;

export const StyledTooltipDescription = styled.span`
  display: block;
  margin: 4px 0;
  font-size: 14px;
  color: #757575;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
`;

export const StyledTooltipPrice = styled.span`
  display: block;
  font-size: 14px;
  color: #757575;
`;
