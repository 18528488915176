import { isNumber } from 'au-js-sdk/lib/types/typeguard';
import { isEqual } from 'helpers/conditionals';
import { isNotServerSideRendering } from 'helpers/env';

// IsValidEmail :: string => boolean
export const isValidEmail = (x: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(x);

export const isEmptyString = (str: string) => isEqual('')(str);

export const toTitleCase = (str: string): string =>
  str.replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());

export const stripSnakeCase = (str: string): string => str.replace('_', ' ');

const getFailureMessage = (patterns: [RegExp, string][]) => (matches: [RegExp, string][]) => {
  const missedCharacterClasses = patterns.filter(x => !matches.includes(x)).map(([_, x]) => x);
  return missedCharacterClasses.length
    ? missedCharacterClasses.reduce(
        (accum, x, i) =>
          `${accum} ${i < missedCharacterClasses.length - 1 ? '' : 'or '}${x}${
            i < missedCharacterClasses.length - 1 ? ',' : ' character.'
          }`,
        'Must include at least one other:'
      )
    : '';
};

export const strMatchesMinimumPatterns = (...patterns: [RegExp, string][]) => (minimumMatches: number) => (
  x: string
) => {
  const matches = patterns.filter(([p]) => p.test(x));
  return [matches.length >= minimumMatches, getFailureMessage(patterns)(matches)];
};

const LOWER_CASE: [RegExp, string] = [/[a-z]/, 'lowercase'];
const UPPER_CASE: [RegExp, string] = [/[A-Z]/, 'uppercase'];
const NUMERIC: [RegExp, string] = [/\d/, 'number'];
const SPECIAL_CHARS: [RegExp, string] = [/[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/, 'special'];

export const isPasswordValid = strMatchesMinimumPatterns(LOWER_CASE, UPPER_CASE, NUMERIC, SPECIAL_CHARS)(2);

export const camelToSentenceCase = (x: string) =>
  x
    .replace(/([A-Z])/g, ' $1')
    // Uppercase the first character
    .replace(/^./, str => str.toUpperCase());

export const removeExtraQuotes = (str: string) => str.replace(/"|'/g, '');

export const replaceAssetUrlsWithCdn = (str: string) =>
  str.replace(
    /(s3-us-west-2\.amazonaws\.com\/au-assets)|(au-assets\.s3-us-west-2\.amazonaws\.com)|(assets\.artifactuprising\.com)/g,
    process.env.GATSBY_AU_CDN_HOST
  );

export const snakify = (input: string): string =>
  input
    .toString()
    .replace(/\s+/g, '_') // Replace spaces with _
    .replace(/[^\w_]+/g, '_') // Remove all non-word chars
    .replace(/__+/g, '_') // Replace multiple _ with single _
    .replace(/^_+/, '') // Trim _ from start of text
    .replace(/_+$/, ''); // Trim _ from end of text

export const slugify = (input: string): string =>
  input
    .toString()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w-]+/g, '-') // Remove all non-word chars
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text

export const adjustHiDPI = (size: number) => {
  if (isNotServerSideRendering()) {
    return Math.ceil(window.devicePixelRatio * size);
  }
};

interface StringMods {
  lowercase: string;
  noSpaces: string;
  slugified: string;
}

export const lowercaseNoSpaceSlugify = (input: string): StringMods => {
  const lowercase = input.toLowerCase();
  return {
    lowercase,
    noSpaces: lowercase.replace(/\s/g, ''),
    slugified: lowercase.replace(/\s/g, '-')
  };
};

// Will match two strings that are the same when you take out spaces OR you slugify
// MATCH `Hand-lettered` and `hand-Lettered`
// MATCH `Handlettered` and `hand-lettered`
// MATCH `Hand lettered` and `hand-lettered`
// MATCH `Custom Color` and `custom-color`
export const caseInsensitiveSlugMatch = (a, b) => {
  const aMods: StringMods = lowercaseNoSpaceSlugify(a);
  const bMods: StringMods = lowercaseNoSpaceSlugify(b);

  const _match = (x: StringMods, y: StringMods) =>
    [x.noSpaces, x.slugified, x.lowercase].includes(y.lowercase) ||
    [x.noSpaces, x.slugified, x.lowercase].includes(y.slugified) ||
    [x.noSpaces, x.slugified, x.lowercase].includes(y.noSpaces);

  return _match(aMods, bMods) || _match(bMods, aMods);
};

export const ensureStartsWithSlash = (path: string) => {
  if (!path?.startsWith('/')) {
    return `/${path}`;
  }
  return path;
};

export const isPath = (url: string) => (!url.startsWith('http') && !url.startsWith('www')) || url.startsWith('/');

// Make a plural string singular.  NOTE: This only handles cases where the plural form is an s.
// For instance, frames vs frame, but not man vs men.
export const makeSingular = (pluralString: string): string => {
  if (pluralString.charAt(pluralString.length - 1) === 's') {
    return pluralString.slice(0, -1);
  } else {
    return pluralString;
  }
};

const makeEllipsisText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.slice(0, maxLength)}...`;
  }
  return text;
};

export const transformToEllipsisText = (projectName: string, maxLength: number, isMobile: boolean) => {
  if (projectName?.length > maxLength && isMobile) {
    projectName = makeEllipsisText(projectName, maxLength);
  }
  if (projectName?.length > maxLength && !isMobile) {
    projectName = makeEllipsisText(projectName, maxLength);
  }
  return projectName;
};

//Given an array or number, return a numbered label with a 's' appended if appropriate
//ex: given ([obj, obj, obj], 'contact') return "3 contacts"
//ex: given (1, 'address') return "1 address"
export const smartPluralizer = (arrayOrNumber: any[] | number, singularLabel: string): string => {
  let count;
  if (isNumber(arrayOrNumber)) {
    count = arrayOrNumber;
  } else if (Array.isArray(arrayOrNumber)) {
    count = arrayOrNumber.length;
  } else {
    return '';
  }

  if (count > 1) {
    return `${count} ${singularLabel}s`;
  } else if (count === 1) {
    return `1 ${singularLabel}`;
  } else {
    return `0 ${singularLabel}s`;
  }
};
