import styled from 'styled-components';
import { StyledLabel } from '../../typography';

export default styled(StyledLabel)`
  color: #CB0000;
  padding: 0;
  display: inline-block;
  margin-bottom: 0;
  margin-left: 0;
  margin-top: 2px;
  text-transform: none;
  font-size: 12px;
  letter-spacing: 0;
`;
