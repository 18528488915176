export const computeInitialOffsetToBottom = (
  rect: DOMRect,
  width: number,
  height: number,
  initialZoomFactor: number
): {
  x: number;
  y: number;
} => {
  const x = -Math.abs(width * initialZoomFactor - rect.width) / 2;
  const y = -Math.abs(height * initialZoomFactor - rect.height);

  return { x, y };
};

export const computeInitialOffsetToTop = (
  rect: DOMRect,
  width: number,
  _height: number,
  initialZoomFactor: number
): {
  x: number;
  y: number;
} => {
  const x = -Math.abs(width * initialZoomFactor - rect.width) / 2;
  const y = 0;

  return { x, y };
};
