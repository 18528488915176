import React, { useEffect, useRef } from 'react';
import useLazyLoad from '../hooks/useLazyLoad';
import InnerImageZoom from 'react-inner-image-zoom';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { useWindowSize, DEVICE_SIZE_SMALL, DeviceSize } from 'hooks/useWindowSize';
import Show from 'component-library/Show';
export interface CatalogImageProps {
  className?: string;
  onLoad?: () => void;
  src: string;
  widths: {
    tiny?: number;
    small?: number;
    medium?: number;
    large?: number;
  };
  fullscreenOnMobile?: boolean;
  alt: string;
  css?: Record<string, unknown>;
  height?: number | string;
  onZoom?: (zoom: boolean) => void;
  zoom?: boolean;
  borderRadius?: string;
  lazyLoad?: boolean;
  handleProductImageGalleryOpen?: (src: string) => void;
  modalImageZoom?: boolean;
}

interface BreakPointsObject {
  large: string;
  medium: string;
  small: string;
  tiny: string;
  [key: string]: string;
}

interface InnerImageZoomSourcesProps {
  srcSet: string | undefined;
  src: string | undefined;
  media: string | undefined;
  key: string | undefined;
}

const BREAKPOINTS_BY_SIZE: BreakPointsObject = {
  large: '768',
  medium: '576',
  small: '376',
  tiny: '100'
};

const MULTIPLIERS = {
  large: 2.638297872,
  medium: 2.042553191,
  small: 1.531914894,
  tiny: 1
};

const responsiveCatalogImageUrl = ({ src, width }: { src: string; width: number | undefined }): string => {
  const queryStart = (src || '').includes('?') ? '&' : '?';
  const srcWithQueryStart = `${src}${queryStart}`;
  return width ? encodeURI(`${srcWithQueryStart}width=${width}&auto=webp`) : encodeURI(`${srcWithQueryStart}auto=webp`);
};

const getZoomCoordinates = (ev: any, innerEl: HTMLElement) => {
  const parentOffset = innerEl.offsetParent;

  const parentClientRects = parentOffset?.getClientRects().item(0);

  return {
    x: ev.clientX - (parentClientRects?.left || 0),
    y: ev.clientY - (parentClientRects?.top || 0)
  };
};

const renderImage = renderImageProps => {
  const {
    src,
    widthsWithDefaults,
    alt,
    optionalProps,
    imgRef,
    sources,
    className,
    onLoad,
    handleProductImageGalleryOpen,
    modalImageZoom
  } = renderImageProps;

  const quickPinchRef = useRef<any>();

  const { deviceSize } = useWindowSize();
  const isMobile = deviceSize === DeviceSize.SMALL;

  const onUpdate = ({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);

      if (scale > 1) {
        img.style.setProperty('min-height', '100vh');
        img.style.setProperty('min-width', '100vh');
        img.style.setProperty('cursor', 'zoom-out');
      } else {
        img.style.setProperty('min-height', 'auto');
        img.style.setProperty('cursor', 'zoom-in');
      }
    }
  };

  const handleModalImageZoom = event => {
    const { x, y } = getZoomCoordinates(event, imgRef.current);

    const isZoomedOut = quickPinchRef.current._offset?.x === 0 && quickPinchRef.current._offset.y === 0;

    quickPinchRef.current.scaleTo({
      x,
      y,
      scale: isZoomedOut ? 4 : 1,
      animated: true
    });
  };

  return modalImageZoom ? (
    <>
      <Show when={isMobile}>
        <QuickPinchZoom
          onUpdate={onUpdate}
          tapZoomFactor={4}
          maxZoom={4}
          doubleTapZoomOutOnMaxScale
          draggableUnZoomed={false}
        >
          <img ref={imgRef} src={src} alt="zoomed-modal-image" className={'gallery-modal-pinch-zoom'} />
        </QuickPinchZoom>
      </Show>
      <Show when={!isMobile}>
        <div onClick={event => handleModalImageZoom(event)}>
          <QuickPinchZoom
            ref={quickPinchRef}
            onUpdate={onUpdate}
            tapZoomFactor={isMobile ? 4 : 0}
            maxZoom={4}
            doubleTapZoomOutOnMaxScale
            draggableUnZoomed={false}
          >
            <img ref={imgRef} src={src} alt="zoomed-modal-image" className={'gallery-modal-pinch-zoom'} />
          </QuickPinchZoom>
        </div>
      </Show>
    </>
  ) : (
    <picture onClick={() => (handleProductImageGalleryOpen ? handleProductImageGalleryOpen(src) : null)}>
      {sources}
      <img
        ref={imgRef}
        className={`${className}`}
        style={{
          height: isMobile ? 'auto' : optionalProps.height ? optionalProps.height.toString() : 'auto',
          borderRadius: `${optionalProps.borderRadius ? optionalProps.borderRadius : '0'}px`
        }}
        src={responsiveCatalogImageUrl({ src, width: widthsWithDefaults.medium })}
        data-src={src}
        alt={alt}
        onLoad={onLoad}
      />
    </picture>
  );
};

const renderImageZoom = renderImageZoomProps => {
  const { src, widthsWithDefaults, alt, InnerImageZoomSources, optionalProps, onZoom } = renderImageZoomProps;

  return (
    <InnerImageZoom
      src={responsiveCatalogImageUrl({ src, width: widthsWithDefaults.medium })}
      imgAttributes={{ alt }}
      sources={InnerImageZoomSources}
      fullscreenOnMobile={optionalProps.fullscreenOnMobile ?? false}
      afterZoomIn={() => {
        if (onZoom) {
          onZoom(true);
        }
      }}
      afterZoomOut={() => {
        if (onZoom) {
          onZoom(false);
        }
      }}
    />
  );
};

const CatalogImage = ({ src, widths, alt, onZoom, ...optionalProps }: CatalogImageProps, forwardRef): JSX.Element => {
  const className = optionalProps.className || '';
  const onLoad = optionalProps.onLoad || function() {};
  const lazyLoad = typeof optionalProps.lazyLoad === 'undefined' ? true : optionalProps.lazyLoad;

  const modalImageZoom = optionalProps.modalImageZoom || false;

  const handleProductImageGalleryOpen = optionalProps.handleProductImageGalleryOpen;

  const tinyWidth = widths.tiny;

  const zoom = optionalProps.zoom || false;
  const widthsWithDefaults = {
    large: widths.large || (typeof tinyWidth !== 'undefined' ? MULTIPLIERS.large * tinyWidth : undefined),
    medium: widths.medium || (typeof tinyWidth !== 'undefined' ? MULTIPLIERS.medium * tinyWidth : undefined),
    small: widths.small || (typeof tinyWidth !== 'undefined' ? MULTIPLIERS.small * tinyWidth : undefined),
    tiny: tinyWidth
  };

  const InnerImageZoomSources: InnerImageZoomSourcesProps[] = [];

  const sources = [];
  let lastBreakSize = '';
  for (const [index, [breakSize, imageWidth]] of Object.entries(Object.entries(widthsWithDefaults))) {
    const breakpoint = BREAKPOINTS_BY_SIZE[breakSize];
    const url = responsiveCatalogImageUrl({ src, width: imageWidth });
    const key = `img-${src}-${imageWidth ? imageWidth?.toString() : ''}-${imageWidth}-${index}`;

    let media = `(min-width: ${breakpoint}px)`;
    if (lastBreakSize) {
      const lastBreakpoint = parseInt(BREAKPOINTS_BY_SIZE[lastBreakSize], 10) - 1;
      media = `((min-width: ${breakpoint}px) and (max-width: ${lastBreakpoint}px))`;
    }

    lastBreakSize = breakSize;

    InnerImageZoomSources.push({
      media,
      srcSet: url,
      src: url,
      key
    });

    sources.push(<source key={key} data-srcset={url} media={media} />);
  }
  const imgRef = useRef<HTMLImageElement>();
  useEffect(() => {
    if (imgRef && imgRef.current && imgRef.current.complete) {
      onLoad();
    }
  }, []);

  // This doesn't seem to work if the thing being viewed is in the viewport
  // https://github.com/verlok/vanilla-lazyload/issues/423
  // TODO make it work
  // const blurredSrc = responsiveCatalogImageUrl({ src, width: 10 })

  useLazyLoad();

  return zoom
    ? renderImageZoom({ src, widthsWithDefaults, alt, InnerImageZoomSources, optionalProps, onZoom })
    : renderImage({
        src,
        widthsWithDefaults,
        alt,
        optionalProps,
        imgRef,
        lazyLoad,
        className,
        handleProductImageGalleryOpen,
        modalImageZoom
      });
};

export default CatalogImage;
