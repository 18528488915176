import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProduct } from './useProduct';
import { PDPFields } from './useProductForm';
import { getProductLivePreviewUrl, getCorrectedLivePreviewAttributes, watchFields } from '../helpers';

export const useProductLivePreview = (): string => {
  const form = useFormContext<PDPFields>();
  const { product } = useProduct();
  
  // Send params to `form.watch` to only watch the needed values
  const previewValues = watchFields(form.watch, getCorrectedLivePreviewAttributes(product?.livePreviewAttributes));

  const livePreviewUrl = useMemo(
    () => getProductLivePreviewUrl(product?.sku, product?.livePreviewAttributes, previewValues),
    [product?.sku, product?.livePreviewAttributes, previewValues]
  );

  return livePreviewUrl;
};
