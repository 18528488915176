import { useMediaContext } from './useMediaContext';
import { Configuration, MediaType, UrlsType } from '../types/event';

export const useMedia = () => {
  const { configuration, media, urls } = useMediaContext();

  return {
    configuration,
    photos: Object.values(hydrateMedia(configuration, media, urls)),
    mediaUrl: urls?.media,
    urls
  };
};

const hydrateMedia = (configuration: Configuration, media: MediaType, urls?: UrlsType): MediaType => {
  // @todo: Add new fields here on demand when needed using `media`

  return {
    ...media
  };
};
