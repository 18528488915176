import styled from 'styled-components';

export const MyAccountIconStyled = styled.div`
  position: absolute;
`;

export const CartCountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledCart = styled.div`
  margin-left: 20px;
`;

export const StyledLogo = styled.span`
  width: 115px
`;

export const LoginButton = styled.button`
  text-align: right;
  margin-right: 0.25rem;
  display: inline-block;
  vertical-align: middle;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 11px;
  letter-spacing: 1.5px;
  padding: 0;
  text-transform: uppercase;
  font-family: "Lato", Lato, sans-serif;
  transition: color,.15s,ease-in;

  &:hover {
    color: #959595;
  }

  &:after {
    content: "Login";
  }
`;

export const LoginLoaderContainer = styled.div`
  margin-top: 6px;
  display: inline-block;
  vertical-align: middle;
`;