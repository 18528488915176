import { ModalType, ModalState } from './constants';
import { allButLast, immutablePop } from 'helpers/arrays';
import { CLEAR_STORE, GlobalAction } from '../global/constants';

const defaultModalState: ModalState = {
  history: [],
  current: {
    type: ModalType.HIDE_MODAL,
    hideable: true,
    fullscreen: false,
    className: '',
    paddedModal: true
  }
};

export default (state: ModalState = defaultModalState, action: any | GlobalAction) => {
  const { SET_MODAL, OPEN_PREVIOUS_MODAL, HIDE_MODAL, SEND_MODAL_DATA } = ModalType;

  switch (action.type) {
    case SET_MODAL: {
      return {
        ...state,
        history: [...state.history, state.current],
        current: action.payload
      };
    }
    case OPEN_PREVIOUS_MODAL: {
      return {
        ...state,
        history: allButLast(state.history),
        current: immutablePop(state.history)
      };
    }
    case HIDE_MODAL: {
      return {
        history: [],
        current: {
          type: HIDE_MODAL
        }
      };
    }
    case SEND_MODAL_DATA: {
      return {
        ...state,
        current: {
          ...state.current,
          data: {
            ...state.current.data,
            ...action.payload
          }
        }
      };
    }
    case CLEAR_STORE: {
      return defaultModalState;
    }
    default:
      return {
        ...state
      };
  }
};
