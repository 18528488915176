import { BuyBanner as _BuyBanner, mediaQuery } from 'component-library';
import styled from 'styled-components';

const BuyBanner = styled(_BuyBanner)`
  margin-top: 0;

  h2 {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: subpixel-antialiased;
    line-height: 34px;

    ${mediaQuery.medium`
      font-size: 30px;
    `};
  }
`;

export default BuyBanner;
