import React from 'react';
import { CardWithShadow, FullScreen, CloseButton } from '../Card';
import useLockBodyScroll from '../hooks/useBodyScrollLock';
import { Icon } from '..';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import cx from 'classix';

const WHITE_OUT = 'white-out';

const clickHandler = (e: any, onClickOutside?: () => void) => {
  if (e.target === e.currentTarget && onClickOutside) {
    e.preventDefault();
    onClickOutside();
  }
};

export const WhiteOut = FullScreen.withComponent('div');

interface GenericOverlayProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  type?: string;
  fullscreen?: boolean;
  paddedModal?: boolean;
}

export const GenericOverlay = ({
  children,
  className = '',
  onClose,
  type = '',
  fullscreen = false,
  paddedModal = true
}: GenericOverlayProps): React.ReactElement => {
  const dispatch = useDispatch();

  useLockBodyScroll();

  const handleCloseModal = () => {
    dispatch(hideModal());
  };
  return (
    <WhiteOut
      className={`${WHITE_OUT} ${className}`}
      onMouseDown={e => {
        clickHandler(e, handleCloseModal);
      }}
      role="presentation"
    >
      <div id={`${type}Modal`}>
      
        <CardWithShadow data-fullscreen={fullscreen} className={cx(className, !paddedModal && 'no-padding')}>
          {onClose ? (
            <CloseButton onClick={onClose}>
              <Icon icon="close" size="small" />
            </CloseButton>
          ) : null}
          {children}
        </CardWithShadow>
      </div>
    </WhiteOut>
  );
};

export default GenericOverlay;