import styled from 'styled-components';
import { H6 } from 'au-gatsby-ds/components';
import { TextSwatchContainer } from './TextSwatch/styled';
import { Breakpoint } from '../../helpers/Breakpoint';

export const StyledOptionContainer = styled.div`
  margin: 20px auto 40px auto;
  min-width: 300px;
`;

export const StyledRadioGroup = styled.div<{ options: number; id: string; overflowWrap?: boolean }>`
  ${(props) => {
  const display = props.id === 'envelope' ? 'grid' : 'flex';
  const columns = props.options > 7 ? 'repeat(4, 50px)' : 'repeat(7, 50px)';
  const rows = props.options > 7 ? 'repeat(2, auto)' : 'auto';
  return `
      display: ${display};
      grid-template-columns: ${columns};
      grid-template-rows: ${rows};
    `;
  }}
  justify-content: flex-start;
  overflow-x: auto;
  flex: 1;
  margin-bottom: 8px;
  position: relative;
  text-align: center;
  gap: 8px;
  flex-wrap: wrap;

  label {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
    :focus {
      outline: none;
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  @media (max-width: 780px) {
    flex-wrap: ${props => (props.overflowWrap ? 'wrap' : 'nowrap')};
    overflow-x: ${props => (props.overflowWrap ? 'auto' : 'scroll')};
  }

  &.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));

    ${TextSwatchContainer} {
      width: 100%;
    }
  }
`;

export const SwatchTitleContainer = styled.div`
  display: flex;
`;

export const StyledH3 = styled.h3<{ onClick?: () => void }>`
  ${H6}

  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

  display: block;
  text-transform: capitalize;
  margin-bottom: 20px;
  margin-top: 0;

  @media (min-width: 48em) {
    margin-bottom: 16px;
  }
`;

export const StyledAttributeValue = styled.span<{ onClick?: () => void }>`
  color: #757575;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-style: normal;
  font-weight: 300;
  margin-left: 0.25rem;
  text-decoration-line: ${props => (props.onClick ? 'underline' : '')};
  text-underline-offset: 3px;
  text-decoration-thickness: 1px;
  margin-top: -2px;
`;

export const InformationIcon = styled.span<{ visible: boolean }>`
  margin-left: 4px;

  /* always display for mobile */
  @media ${Breakpoint.medium} {
    display: ${props => (props.visible ? 'inline' : 'none')};
  }
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  display: none;
  width: 0;
  height: 0;

  :checked,
  :hover {
    color: #000;
    border: 1px solid #000;
    z-index: 1 !important;
  }
`;
