/**
 * Implement Gatsby's Browser APIs in this file.
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.css';
import wrapWithProvider from './wrap-with-provider';
import 'whatwg-fetch';
import { withErrorBoundary } from 'components/ErrorBoundary';
import { analyticsInit } from 'analytics/itly/init';
import itlyContext from 'analytics/itly/context';
import { initYotpoFirstPageLoadState } from 'components/Yotpo/useYotpo';
import { initSplit } from 'au-js-sdk/lib/services/split.io/client';
import { getSplitUserKey } from 'au-js-sdk/lib/services/split.io/cookies';
import { userLocatedInEU, getCookie, setCookie } from 'helpers/http';
import { isNotServerSideRendering } from 'helpers/env';
import { GOOGLE_SHOPPING_COOKIE } from 'components/PromoCode';
import { SEGMENT_TRACKING_PREFERENCES_COOKIE } from 'components/SegmentConsentBanner';

// Load fonts
import 'typeface-lato';
import 'typeface-crimson-text';
import { getLocalStorageCartInfo, hasAuthedCartInfo } from 'helpers/storage/cart';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';

export const wrapRootElement = wrapWithProvider;

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  // Only navigate to home page if the back button takes the user to billing from confirm
  // We current replace history state on navigation to confirm so return customers hit the cart on back
  if (
    location &&
    location.pathname &&
    location.pathname.includes('billing') &&
    prevLocation &&
    prevLocation.pathname &&
    prevLocation.pathname.includes('confirmation')
  ) {
    window.location = process.env.GATSBY_SITE_URL;
    // We have to throw here to prevent rendering of the previous page while location change is in flight
    throw new Error('');
  }
};

export const wrapPageElement = withErrorBoundary;

const SITE_ENV = process.env.GATSBY_SITE_ENV === 'prod' ? 'production' : 'development';
const ENABLE_ITLY = process.env.GATSBY_ENABLE_ITLY === 'true';

export const onClientEntry = async () => {
  const isNotSSR = isNotServerSideRendering();
  const searchUrl = isNotSSR ? window.location.search : '';
  const urlParams = new URLSearchParams(searchUrl);

  const gShop = urlParams.get('gshop');
  if (gShop) {
    // If the user has come from Google shopping, set a cookie that
    // will be used to auto-apply a promo code if one is available
    setCookie(GOOGLE_SHOPPING_COOKIE, true, null);
  }

  const cart = getLocalStorageCartInfo();
  if (cart && hasAuthedCartInfo(cart)) {
    await initSplit([SPLIT_TRAFFIC_TYPES.MAGENTO, SPLIT_TRAFFIC_TYPES.ANONYMOUS], {
      splitAnonymousUserKey: getSplitUserKey(),
      splitMagentoUserKey: cart.customerId?.toString() || '',
      splitMagentoEmail: cart.email
    });
  } else {
    await initSplit([SPLIT_TRAFFIC_TYPES.ANONYMOUS], {
      splitAnonymousUserKey: getSplitUserKey(),
      splitMagentoUserKey: '',
      splitMagentoEmail: ''
    });
  }

  let destinations;

  const segmentConsentSettingsString = getCookie(SEGMENT_TRACKING_PREFERENCES_COOKIE);

  if (segmentConsentSettingsString) {
    const settingsObject = JSON.parse(segmentConsentSettingsString);
    destinations = settingsObject?.destinations;
  } else {
    destinations = await getDefaultSegmentDestinations();
  }

  analyticsInit({
    enabled: ENABLE_ITLY,
    environment: SITE_ENV,
    context: itlyContext,
    destinations: {
      segment: {
        integrations: destinations
      }
    }
  });
  initYotpoFirstPageLoadState();
};

const getDefaultSegmentDestinations = async () => {
  const writeKey = process.env.GATSBY_SEGMENT_WRITE_ID;

  const res = await fetch(`https://cdn.segment.com/v1/projects/${writeKey}/integrations`);
  const destinations = await res.json();
  const userInEu = userLocatedInEU();

  const mappedDestinations = destinations.reduce((acc, destination) => ({ ...acc, [destination.name]: !userInEu }), {});
  const customSettings = {
    version: 1,
    mappedDestinations,
    custom: {
      marketingAndAnalytics: !userInEu,
      advertising: !userInEu,
      functional: !userInEu
    }
  };

  setCookie(SEGMENT_TRACKING_PREFERENCES_COOKIE, JSON.stringify(customSettings), 31536000);

  return mappedDestinations;
};
