import React from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import { CancelButton, ModalTitle, SubmitButton } from 'components/Projects/styled';
import {
  ActionDescription,
  ButtonContainer,
  Image,
  ImageContainer,
  StyledDeleteProjectModal,
  PromptContainer
} from './styled';

export interface DeleteProjectModalProps {
  projectTitle: string;
  imageUrl: string;
  deleteProjectHandler: () => Promise<void>;
}

export const DeleteProjectModal = (props: DeleteProjectModalProps): JSX.Element => {
  const { projectTitle, imageUrl, deleteProjectHandler } = props;

  const dispatch = useDispatch();

  return (
    <StyledDeleteProjectModal>
      <ImageContainer>
        <Image src={imageUrl} />
      </ImageContainer>
      <PromptContainer>
        <ModalTitle secondary alignment="center">
          Delete {projectTitle}?
        </ModalTitle>
        <ActionDescription>Are you sure you want to delete this project? You can’t undo this action.</ActionDescription>
        <ButtonContainer>
          <CancelButton onClick={() => dispatch(hideModal())}>CANCEL</CancelButton>
          <SubmitButton
            onClick={async () => {
              dispatch(hideModal());
              await deleteProjectHandler();
            }}
            disabled={false}
          >
            DELETE PROJECT
          </SubmitButton>
        </ButtonContainer>
      </PromptContainer>
    </StyledDeleteProjectModal>
  );
};
