import styled from 'styled-components';

export const ProjectsGrid = styled.div<{
  columnWidth?: number | string;
  columns?: number | string;
}>`
  display: grid;
  grid-auto-columns: ${({ columnWidth = 'auto' }) => `minmax(${columnWidth}, auto)`};
  grid-template-columns: ${({ columnWidth = 'auto', columns = 'auto-fill' }) =>
    `repeat(${columns}, minmax(${columnWidth}, 1fr))`};
`;

export const ProjectActions = styled.div<{
  rowHeight?: number | string;
  rows?: number | string;
}>`
  display: grid;
  grid-auto-rows: ${({ rowHeight = 'auto' }) => `minmax(${rowHeight}, auto)`};
  grid-template-rows: ${({ rowHeight = 'auto', rows = 'auto-fill' }) => `repeat(${rows}, minmax(${rowHeight}, 1fr))`};
`;

export const ProjectRow = styled(ProjectsGrid)<{ alignItems?: string }>`
  border-bottom: 1px solid #e9e9e8;
  padding: 5px 0;
  ${({ alignItems = 'none' }) => `
    align-items: ${alignItems};
    ${alignItems === 'center' ? `> p { margin: 0 }` : ''}
  `}
`;

export const ProjectsHead = styled(ProjectsGrid)<{ alignItems?: string }>`
  border-top: 1px solid #e9e9e8;
  border-bottom: 1px solid #e9e9e8;
  padding: 5px 0;

  ${({ alignItems = 'none' }) => `
    align-items: ${alignItems};
    ${alignItems === 'center' ? '> p { margin: 0.5rem 0 }' : ''}
  `}
`;
