import React, { createRef } from 'react';
import styled from 'styled-components';

const TooltipOuter = styled.div`
  position: relative;
`;

const TooltipInner = styled.div`
  background: white;
  border-radius: 1.5px;
  box-shadow: 0 1px 1px 1px rgba(0,0,0,.1);
  max-width: 100%;
  font-size: 11px;
  min-height: 1rem;
  line-height: 1rem;
  text-align: center;
  > * {
    line-height: inherit;
    margin: 0;
    padding: 0;
    padding: .5rem;
    display: inline-block;
  }
`;

interface WithTooltipProps {
  children: React.ReactNode
}

const withTooltip = (Component: React.ComponentType<any>) => class extends React.Component<WithTooltipProps, { isDisplayed: boolean }> {
    tooltipComponentRef = React.createRef<HTMLDivElement>();

    constructor(props: WithTooltipProps) {
      super(props);

      this.state = {
        isDisplayed: false
      };
      this.getElementOffset = this.getElementOffset.bind(this);
      this.setDisplayState = this.setDisplayState.bind(this);
    }

    getElementOffset() {
      if (!this.tooltipComponentRef || !this.tooltipComponentRef.current) {
        return [0, 0];
      }
      const { top, left } = this.tooltipComponentRef.current.getBoundingClientRect();
      const { offsetHeight } = this.tooltipComponentRef.current;
      return [left, top - offsetHeight - 30];
    }

    setDisplayState(isDisplayed: boolean) {
      return (e: React.SyntheticEvent) => {
        this.setState({ isDisplayed });
      };
    }

    render() {
      const { children } = this.props;
      const { isDisplayed } = this.state;
      const [left, top] = this.getElementOffset();
      return (
        <>
          <div data-testid="tooltip" style={{ display: 'inline-block' }} ref={this.tooltipComponentRef} onTouchStart={this.setDisplayState(!isDisplayed)} onMouseOver={this.setDisplayState(true)} onMouseLeave={this.setDisplayState(false)}>
            <Component />
          </div>
          {
            isDisplayed && this.tooltipComponentRef.current ? (
              <TooltipInner style={{ position: 'fixed', top: `${top}px`, left: `${left}px`, ...(left < 200 ? {} : { transform: 'translateX(calc(-50% + 1rem))' }) }}><div>{children}</div></TooltipInner>
            ) : null
          }
        </>
      );
    }
  };

export default withTooltip;
