import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { StyledH2, StyledP, Subtext } from '../typography';
import TextInput from '../Forms/TextInput';
import { PrimaryLoadingButton, PrimaryButton } from '../Button';
import { FullWidthWrapper, AuthErrorMessage } from './sharedStyles';
import { AUTH_EVENTS, AuthPageType } from '.';
import { customerPasswordReset } from '../services/magento';

const ResetPasswordBtnWrapper = styled.div`
  button {
    margin-top: 1rem;
  }
`;

const HelpLink = styled.a`
  color: #000;
  text-decoration: underline;
`;

interface ResetPasswordPageProps {
  authEndpoint: string;
  onEvent: (type: string) => void;
  setCurrPage: (pageType: AuthPageType) => void;
}

const ResetPasswordPage = ({
  authEndpoint,
  onEvent,
  setCurrPage
}: ResetPasswordPageProps) => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState('');

  const submit = useCallback(async () => {
    setError('');
    setIsLoading(true);
    try {
      await customerPasswordReset(authEndpoint)(email);
      onEvent(AUTH_EVENTS.CUSTOMER_PASSWORD_RESET);
      setEmailSent(true);
    } catch (error) {
      setError('Email not found.');
    } finally {
      setIsLoading(false);
    }
  }, [email]);

  return (
    <FullWidthWrapper>
      {emailSent ? (
        <>
          <StyledH2 secondary alignment="center">Check Your Email</StyledH2>
          <StyledP alignment="center">
            We sent an email to
            {' '}
            {email}
            {' '}
            with a link to reset your password.
          </StyledP>
          <ResetPasswordBtnWrapper>
            <PrimaryButton
              onClick={() => {
                setCurrPage(AuthPageType.LOGIN);
              }}
            >
              Sign In
            </PrimaryButton>
          </ResetPasswordBtnWrapper>
        </>
      ) : (
        <>
          <StyledH2 secondary alignment="center">Reset Password</StyledH2>
          {error && (
            <AuthErrorMessage>
              <StyledP alignment="center">
                {error}
              </StyledP>
            </AuthErrorMessage>
          )}
          <StyledP alignment="center">
            Enter the email address you used to sign up to receive a link to
            reset your password.
          </StyledP>
          <TextInput
            error={error}
            label="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <ResetPasswordBtnWrapper>
            <PrimaryLoadingButton isLoading={isLoading} onClick={submit} disabled={!email}>
              Reset Password
            </PrimaryLoadingButton>
          </ResetPasswordBtnWrapper>
        </>
      )}
      <Subtext alignment="center">
        Need more help? Contact us at
        {' '}
        <HelpLink href="mailto:help@artifactuprising.com">
          help@artifactuprising.com
        </HelpLink>
      </Subtext>
    </FullWidthWrapper>
  );
};

export default ResetPasswordPage;
