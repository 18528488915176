import { useEffect } from 'react';

const useEscapeKey = (callbackFn: Function) => {
  useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  const escFunction = (event: KeyboardEvent) => {
    if (event.keyCode === 27) {
      callbackFn();
    }
  };
};

export default useEscapeKey;
