import styled from 'styled-components';
import { SecondaryButton } from '../../Button';

export const Message = styled.div`
  padding: 0rem 0.5rem 1rem;
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: .6875rem;
  line-height: 1.2rem;
  text-align: left;
  display: none;

  @media screen and (max-width: 780px) {
    display: flex;
    width: 100%;
  }
`;

export const ButtonChildWrapper = styled.div`
  align-items: center;
  display: flex;
`;

export const BaseButton = styled(SecondaryButton)`
  line-height: normal;
  width: 100%;
  background-color: #FFFFFF;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;

  @media screen and (max-width: 780px) {
    transition: box-shadow 0.2s linear;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 -1px 1px 0 rgba(0,0,0,0.05);
    border: none;
    width: 100%;
    margin-bottom: 1rem;
  
    &:hover,
    &:focus {
      box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
      border: none;
      outline: none;
    }
  }
`;

export const BorderedButton = styled(BaseButton)`
  transition: box-shadow 0.2s linear;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 -1px 1px 0 rgba(0,0,0,0.05);
  border: none;

  &:hover,
  &:focus {
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
    border: none;
    outline: none;
  }
`;

export const UnborderedButton = styled(BaseButton)`
  border: 0;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  & {
    padding-right: 0;
    padding-left: 0;
  }

  > ${ButtonChildWrapper} {
    padding: 0;

    @media screen and (min-width: 781px) {
      width: 100%;
      justify-content: center;
    }
  }
`;

export const ButtonText = styled.div`
  ${UnborderedButton} {
    text-transform: none;
    font-family: "Crimson Text", "Georgia", serif;
    letter-spacing: 0;
    font-size: 16px;
    margin-left: 1rem;
  }
`;

export const Wrapper = styled.div`
  @media screen and (min-width: 781px) {
    height: 50px;
    margin: .5%;
    margin-top: 10px;
    width: 19%;
    margin-top: 20px;

    > ${ButtonText} {
      display: flex;
    }
  }

  @media screen and (max-width: 780px) {
    width: 100%;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`;
