import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';
import { CancelButton, ModalTitle, SubmitButton } from 'components/Projects/styled';
import { ButtonContainer, TextInput, InputLabel, StyledRenameProjectModal } from './styled';
import Itly from 'itly';
import { SmallLoader } from 'components/AULoader/SmallLoader';

export interface RenameProjectModalProps {
  projectTitle: string;
  renameProjectHandler: (newName: string) => Promise<void>;
  scrollPosition: number;
}

export const RenameProjectModal = ({
  projectTitle,
  renameProjectHandler,
  scrollPosition
}: RenameProjectModalProps): JSX.Element => {
  const dispatch = useDispatch();
  const [newName, setNewName] = useState<string>(null);
  const [isLoading, setIsLoading] = useState(false);

  const submitNewName = async () => {
    const trimmedNewName = newName?.trim();
    if (!trimmedNewName) {
      setNewName('');
      return;
    }

    const isAppleDevice = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (isAppleDevice) {
      window.scroll(0, scrollPosition);
    }

    setIsLoading(true);
    Itly.projectRenamed();
    await renameProjectHandler(trimmedNewName);
    dispatch(hideModal());
  };

  return (
    <StyledRenameProjectModal onKeyUp={e => e.key === 'Enter' && submitNewName()}>
      <ModalTitle secondary alignment="center">
        Rename project
      </ModalTitle>
      <InputLabel>Project Name</InputLabel>
      <TextInput
        autoFocus
        aria-label="rename-project-input"
        type="text"
        placeholder={projectTitle}
        value={newName ?? projectTitle}
        onFocus={e => e.currentTarget.select()}
        onChange={e => setNewName(e.target.value)}
        maxLength={50}
      />
      <ButtonContainer>
        <CancelButton onClick={() => dispatch(hideModal())} disabled={isLoading}>
          CANCEL
        </CancelButton>
        <SubmitButton onClick={submitNewName} disabled={isLoading || !newName?.length}>
          <SmallLoader isLoading={isLoading}>SAVE</SmallLoader>
        </SubmitButton>
      </ButtonContainer>
    </StyledRenameProjectModal>
  );
};
