import React, { useState } from 'react';
import styled from 'styled-components';
import { StyledLabel, ErrorLabel } from 'component-library';
import { Show } from 'components/Functional';
import { StyledInputLabel, StyledInput } from 'components/Forms/styled';

const NOOP = () => {};

const StyledInputContainer = styled.div`
  margin: 20px auto 40px auto;
  min-width: 300px;
`;

const PasswordInputWrapper = styled.div`
  position: relative;

  + p {
    position: relative;
    top: 2.8rem;
  }

  input {
    position: absolute;
  }

  ${StyledLabel} {
    position: absolute;
    right: 10px;
    color: #757575;
    cursor: pointer;
  }
`;

interface TextInputProps {
  value: string;
  id: string;
  placeholder?: string;
  inputType?: 'text' | 'textarea' | 'password';
  label?: string;
  tabIndex?: number;
  maxLength?: number;
  disabled?: boolean;
  error?: string;
  onChange: (e: any) => void;
  onBlur?: (e?: any) => void;
}

const TextInput = ({
  maxLength = 200,
  id,
  value = '',
  label,
  placeholder,
  onChange,
  inputType = 'text',
  tabIndex,
  disabled,
  error = '',
  onBlur = NOOP
}: TextInputProps) => {
  const [charlength, setCharLength] = useState(value.length);
  const [showPassword, setShowPassword] = useState(false);

  const renderTextArea = () => (
    <>
      <textarea
        onChange={e => {
          onChange(e);
          setCharLength((e.target.value && e.target.value.length) || 0);
        }}
        maxLength={maxLength}
        value={value}
        disabled={disabled}
        onBlur={onBlur}
        {...(tabIndex ? { tabIndex } : {})}
      />
      <Show when={!!maxLength}>
        <span>
          {' '}
          Character Limit:
          {maxLength - charlength} remaining
        </span>
      </Show>
    </>
  );

  const renderText = () => (
    <StyledInput
      id={id}
      onChange={onChange}
      type={inputType}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      onBlur={onBlur}
      {...(tabIndex ? { tabIndex } : {})}
    />
  );

  const renderPassword = () => (
    <PasswordInputWrapper>
      <input
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onBlur={onBlur}
        {...(tabIndex ? { tabIndex } : {})}
      />
      <StyledLabel onClick={() => setShowPassword(!showPassword)}>{showPassword ? 'Hide' : 'Show'}</StyledLabel>
    </PasswordInputWrapper>
  );

  const renderInputType = (inputType: string) => {
    switch (inputType) {
      case 'text':
        return renderText();
      case 'textarea':
        return renderTextArea();
      case 'password':
        return renderPassword();
      default:
        return null;
    }
  };

  return (
    <StyledInputContainer>
      <StyledInputLabel id={`option-title-${label}`} aria-label={label}>
        {label}
      </StyledInputLabel>
      {renderInputType(inputType)}
      <Show when={!!error}>
        <ErrorLabel>{error}</ErrorLabel>
      </Show>
    </StyledInputContainer>
  );
};

export default TextInput;
