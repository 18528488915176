import { BraintreeError } from 'braintree-web';
import { PrimaryLoadingButton } from 'component-library';
import BillingForm from 'components/Billing/BillingForm';
import ModalHeader from 'components/Modal/ModalHeader';
import { optionGet } from 'faunctions';
import { Formik } from 'formik';
import { immutablePop } from 'helpers/arrays';
import { BILLING_FORM_SUBMIT_BUTTON_ID, creditcard } from 'helpers/billing';
import { addressFormValidator, initialFormValues, isAddressFormPopulated } from 'helpers/forms';
import { buildAddressVerificationPayload } from 'helpers/shipping';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { saveNewCustomerAddress, setDefaultBillingAddress } from 'services/magenum/customer';
import {
  braintreeValidationFailure as _braintreeValidationFailure,
  braintreeValidationRequest as _braintreeValidationRequest,
  braintreeValidationSuccess as _braintreeValidationSuccess
} from 'store/cart/actions';
import {
  braintreeValidationStateSelector,
  paymentMethodLoadingStateSelector,
  isVirtualCartSelector
} from 'store/cart/selectors';
import { GlobalState, isFetching } from 'store/constants';

import { BillingFormModalWrapper } from './styled';

const BillingFormModal = ({ isValidating, braintreeValidationRequest, isVirtualCart }) => {
  const [useSameAsShipping, setUseSameAsShipping] = useState(true);
  const [allCCFieldsValid, setAllCCFieldsValid] = useState(false);
  const [updateBillingValues, setUpdateBillingValues] = useState(false);

  const applyBillingAddress = (shippingAddress, formikProps) => {
    let cardholder: string;
    let postcode: string;

    if (useSameAsShipping && shippingAddress) {
      postcode = shippingAddress?.postcode;
      cardholder = shippingAddress?.firstname?.concat(' ').concat(shippingAddress?.lastname);

      setDefaultBillingAddress(shippingAddress.customer_address_id);
    } else {
      const { firstname, lastname, postcode: zipCode } = formikProps.values;
      postcode = zipCode;
      cardholder = firstname.concat(' ').concat(lastname);

      /* If we're using a new address instead of the shipping address,
        save it and then set it as the default billing address */
      saveNewCustomerAddress(buildAddressVerificationPayload(formikProps.values).address).then(res =>
        // Successful response returns a Customer
        res.map(customer => {
          // Set the address we just saved as default billing
          optionGet('addresses')(customer)
            .map(immutablePop)
            .map(address => setDefaultBillingAddress(address.id));
        })
      );
    }

    return {
      postcode,
      cardholder
    };
  };

  useEffect(() => {
    if (isVirtualCart) {
      setUseSameAsShipping(false);
    }
  }, [isVirtualCart]);

  return (
    <Formik
      initialValues={initialFormValues()}
      onSubmit={() => {}}
      validate={addressFormValidator}
      render={formikProps => {
        const { values } = formikProps;
        const isPopulated = isAddressFormPopulated(values);
        const isDisabled = useSameAsShipping ? !allCCFieldsValid : !allCCFieldsValid || !isPopulated;

        return (
          <BillingFormModalWrapper>
            <ModalHeader title="Add New Credit Card" />
            <BillingForm
              formikProps={formikProps}
              useSameAsShipping={useSameAsShipping}
              setUseSameAsShipping={setUseSameAsShipping}
              allCCFieldsValid={allCCFieldsValid}
              setAllCCFieldsValid={setAllCCFieldsValid}
              creditCardOnly
              applyBillingAddress={applyBillingAddress}
              updateBillingValues={updateBillingValues}
            />
            <PrimaryLoadingButton
              id={BILLING_FORM_SUBMIT_BUTTON_ID}
              isLoading={isValidating}
              disabled={isDisabled}
              onClick={() => {
                setUpdateBillingValues(true);
                /**
                 * Braintree.client listens for a click event on this button which sets braintreeValidationState
                 * to FETCHING and performs braintree validation, upon successful validation, a braintreeValidationSuccess
                 * event is dispatched and triggers savePaymentMethodRequest
                 *
                 * braintreeValidationRequest => braintreeValidationSuccess => savePaymentMethodRequest => savePaymentMethodSuccessRedirect("/checkout")
                 */

                braintreeValidationRequest();
              }}
            >
              Add
            </PrimaryLoadingButton>
          </BillingFormModalWrapper>
        );
      }}
    />
  );
};

const mapStateToProps = (state: GlobalState) => ({
  isValidating:
    isFetching(paymentMethodLoadingStateSelector(state)) || isFetching(braintreeValidationStateSelector(state)),
  isVirtualCart: isVirtualCartSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  braintreeValidationRequest: () => dispatch(_braintreeValidationRequest()),
  braintreeValidationSuccess: paymentMethod => dispatch(_braintreeValidationSuccess({ paymentMethod })),
  braintreeValidationFailure: (err: BraintreeError) => dispatch(_braintreeValidationFailure({ err }))
});

export default connect(mapStateToProps, mapDispatchToProps)(BillingFormModal);
