import { optionGet } from 'faunctions';

import { GlobalState } from './../constants';
import { FilterState } from '../../types/Category';

export const paginationStateSelector = (state: GlobalState) =>
  optionGet('ui.currentPaginationState')(state).getOrElse({});
export const filterStateSelector = (state: GlobalState): FilterState =>
  optionGet('ui.currentFilterState')(state).getOrElse({});
export const gatewayModalSelector = (state: GlobalState) => optionGet('ui.gatewayModal')(state).getOrElse({});
export const featureFlagsSelector = (state: GlobalState): { [name: string]: { value: boolean; isFetching: boolean } } =>
  optionGet('ui.featureFlags')(state).getOrElse({});
