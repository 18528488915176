import { SET_FEATURE_FLAG, UiActionType } from './constants';

const { PAGINATION_STATE, FILTER_STATE, SET_PRODUCTS, HIDE_GATEWAY_MODAL, OPEN_GATEWAY_MODAL } = UiActionType;

export const updatePaginationState = (categoryId, currentPage, displayCount) => ({
  type: PAGINATION_STATE,
  payload: { categoryId, currentPage, displayCount }
});

export const updateFilterState = filterState => ({
  type: FILTER_STATE,
  payload: filterState
});

export const clearFilterState = () => ({
  type: FILTER_STATE,
  payload: {
    filters: []
  }
});

export const setProducts = (products: object[]) => ({
  type: SET_PRODUCTS,
  payload: { products }
});

// Gateway Modal
export const openGatewayModal = (destination: string, hideable = true) => ({
  type: OPEN_GATEWAY_MODAL,
  payload: { destination, hideable }
});

export const hideGatewayModal = () => ({
  type: HIDE_GATEWAY_MODAL
});

export const setFeatureFlag = (name: string, value: boolean, isFetching: boolean) => ({
  type: SET_FEATURE_FLAG,
  payload: { name, value, isFetching }
});
