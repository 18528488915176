import styled from 'styled-components';

export const StyledSvgContainer = styled.div`
  display: flex;
  justify-content: center;
  height: inherit;

  img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  }
`;
