import React from 'react';
import { Link } from 'gatsby';
import { redirectToPage, toAbsolutePath, checkIsBlocklisted } from '../helpers/navigation';

/**
 * TODO: Likely some overlap with /src/components/Header/Navigation/AULink.tsx
 *       Both this and AULink exist to serve the same purpose; conditionally
 *       render internal links with either gatsby-link's <Link /> component or a
 *       conventional HTML anchor tag depending on whether linking to a page in
 *       Gatsby or not.
 *       At some point it would be nice to normalize these approaches but as of
 *       right now, determining existence of a AUspecific Gatsby page is very
 *       context-specific making it difficult to generalize this logic.
 */

interface AUProductLinkProps {
  path: string;
  children: React.ReactNode;
  className?: string;
  linkToGatsby?: boolean;
  role?: string;
  onClick?: (e: any) => void;
  id: string;
}

export const AULink: React.FC<AUProductLinkProps> = props => {
  const { path, children, id, ...optional } = props;
  const { className, onClick } = optional;

  const attrs: { role?: string } = {};
  if (optional.role) {
    attrs.role = optional.role;
  }
  // Make sure this is a boolean
  const shouldLinkToGatsby = !!optional.linkToGatsby;

  const isNotBlocklisted = !checkIsBlocklisted(path);
  const shouldUseGatsbyLink = shouldLinkToGatsby && isNotBlocklisted;
  const navigateToPath = (
    event: React.MouseEvent<HTMLAnchorElement>,
    path: string,
    additionalEvent?: (e: any) => void
  ) => {
    event.preventDefault();

    additionalEvent?.(event);

    redirectToPage(path, { isNotBlocklisted });
  };

  if (shouldUseGatsbyLink) {
    return (
      <Link
        id={id}
        className={className}
        to={toAbsolutePath(path)}
        onClick={e => {
          navigateToPath(e, path, onClick);
        }}
        {...attrs}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        id={id}
        className={className}
        href={toAbsolutePath(path)}
        onClick={e => {
          navigateToPath(e, path, onClick);
        }}
        {...attrs}
        data-gatsby-pass-through
      >
        {children}
      </a>
    );
  }
};
