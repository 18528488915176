import React from 'react';
import Option from 'react-select/lib/components/Option';
import { StyledQuantityPicker } from './styled';

interface Option {
  label: string;
  value: string;
}

interface QuantityPickerProps {
  options: Option[];
  onChange: (opt: Option) => void;
  value: Option;
  isDisabled?: boolean;
}

const styles = {
  container: (provided: any) => ({
    ...provided,
    width: '6.5rem',
    paddingRight: '0.3rem'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  control: (provided: any) => ({
    ...provided,
    width: '4.8rem',
    border: 'none',
    background: 'transparent',
    fontSize: '1rem',
    minHeight: '10px',
    boxShadow: 'none',
    justifyContent: 'flex-start'
  }),
  valueContainer: (provided: any) => ({
    ...provided,
    cursor: 'pointer'
  }),
  dropdownIndicator: (provided: any) => ({
    ...provided,
    cursor: 'pointer'
  })
};

const QuantityPicker = ({ options, onChange, value, isDisabled }: QuantityPickerProps) => (
  <StyledQuantityPicker
    isSearchable={false}
    pattern="\d*"
    placeholder="Quantity"
    styles={styles}
    options={options}
    isDisabled={isDisabled}
    value={value}
    onChange={(opt: Option) => {
      onChange(opt);
    }}
  />
);

export default QuantityPicker;
