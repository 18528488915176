import { isNotServerSideRendering, match } from './conditionals';
import {
  UI_INSTAGRAM_ALBUM,
  UI_TEMPORARY_ALBUM,
  UI_FACEBOOK_ALBUM,
  UI_DROPBOX_ALBUM,
  UI_GOOGLE_PHOTOS_ALBUM
} from '../constants/album';
import { Video, SliderPhoto } from '../types';

// Matches off of albumName and returns the appropriate title for display.
export const albumTitle = (albumName: string): string => {
  return match(
    '',
    () => 'All Galleries',
    UI_INSTAGRAM_ALBUM,
    () => 'Instagram',
    UI_TEMPORARY_ALBUM,
    () => 'My Photos',
    UI_FACEBOOK_ALBUM,
    () => 'Facebook',
    UI_DROPBOX_ALBUM,
    () => 'Dropbox',
    UI_GOOGLE_PHOTOS_ALBUM,
    () => 'Google Photos',
    match.default,
    () => albumName
  )(albumName) as string;
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

export const getMonthName = (date: Date, short?: boolean): string => {
  const monthString = monthNames[date.getMonth()];
  return !short
    ? monthString
    : monthString
        .charAt(0)
        .concat(monthString.charAt(1))
        .concat(monthString.charAt(2));
};

export const getFormattedDate = (date: string): string => {
  const parsedDate = new Date(date);
  return `${getMonthName(
    parsedDate
  )} ${parsedDate.getDate()}, ${parsedDate.getFullYear()}`;
};

export const adjustHiDPI = (size: number): number | undefined => {
  if (isNotServerSideRendering()) {
    return Math.ceil(window.devicePixelRatio * size);
  }
  return undefined;
};

export function isVideo(media: Video | SliderPhoto): media is Video {
  const videoUrl = (media as Video).videoUrl;
  return videoUrl !== undefined && videoUrl !== null;
}
