import { PrimaryButton } from 'component-library';
import { optionFind, optionGet } from 'faunctions';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import React, { FormEvent, Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { applyShippingAddressRequest } from 'store/cart/actions';
import { shippingAddressSelector, shippingMethodSelector, shippingRatesSelector } from 'store/cart/selectors';
import { GlobalState } from 'store/constants';
import { customerAddressesSelector } from 'store/customer/selectors';
import { hideModal as _hideModal, openAddressFormModal as _openAddressFormModal } from 'store/modal/actions';

import AddressTileRadio from './AddressTileRadio';
import {
  AddNewButton,
  AddressSelectionHeader,
  AddressTileList,
  HeaderText,
  ShippingAddressSelectionWrapper,
  SpacedText
} from './styled';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';

interface ShippingAddressSelectionProps {
  shippingAddress: CustomerDataAddress;
  addresses: CustomerDataAddress[];
  shippingMethod: string;
  hideModal: () => void;
  openAddressFormModal: (addr?: CustomerDataAddress) => void;
  applyShippingAddress: (addr: CustomerDataAddress, method: string) => void;
}

const ShippingAddressSelection = ({
  hideModal,
  openAddressFormModal,
  applyShippingAddress,
  shippingAddress,
  addresses,
  shippingMethod
}: ShippingAddressSelectionProps) => {
  const initAddressId = optionGet('customer_address_id')(shippingAddress)
    .getOrElseL(() => optionGet('id')(shippingAddress).getOrElse(''))
    .toString(); // In the cart response, address id is under `customer_address_id`

  const [selectedAddrId, setSelectedAddrId] = useState(initAddressId);

  const findAddressWithId = (xs: CustomerDataAddress[]) => (id: number) =>
    optionFind(x => x.id === id, xs).getOrElse(null);

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();

    applyShippingAddress(findAddressWithId(addresses)(parseInt(selectedAddrId)), shippingMethod);
    hideModal();
  };

  const handleEdit = (addressId: number) => {
    openAddressFormModal(findAddressWithId(addresses)(addressId));
  };

  const handleChange = (event: FormEvent<HTMLInputElement>) => {
    setSelectedAddrId(event.currentTarget.value);
  };

  const handleAddNew = () => {
    openAddressFormModal();
  };

  return (
    <Fragment>
      <AddressSelectionHeader>
        <HeaderText secondary>Change Shipping Address</HeaderText>
      </AddressSelectionHeader>
      <ShippingAddressSelectionWrapper onSubmit={handleSubmit}>
        <AddressTileList>
          <AddNewButton onClick={handleAddNew}>
            <SpacedText>ADD NEW</SpacedText>
          </AddNewButton>
          {addresses.map((address: CustomerDataAddress, idx: number) => {
            const maybeAddrId = optionGet('id')(address)
              .getOrElse('')
              .toString();

            return (
              <AddressTileRadio
                key={idx}
                address={address}
                value={maybeAddrId}
                onChange={handleChange}
                checked={selectedAddrId === maybeAddrId}
                handleEdit={() => handleEdit(address.id)}
              />
            );
          })}
        </AddressTileList>
        <PrimaryButton width={300}>Update</PrimaryButton>
      </ShippingAddressSelectionWrapper>
    </Fragment>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  shippingAddress: shippingAddressSelector(state),
  shippingMethod: shippingMethodSelector(state),
  addresses: customerAddressesSelector(state),
  shippingRates: shippingRatesSelector(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideModal: () => dispatch(_hideModal()),
  openAddressFormModal: (address?) => dispatch(_openAddressFormModal({ address })),
  applyShippingAddress: (address, shippingMethod) => dispatch(applyShippingAddressRequest(address, shippingMethod))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShippingAddressSelection);
