import React from 'react';

export default () => (<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
  <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="Information_black">
      <g id="icon_warning">
        <path d="M12,21 C7.02857143,21 3,16.9714286 3,12 C3,7.02857143 7.02857143,3 12,3 C16.9714286,3 21,7.02857143 21,12 C21,16.9714286 16.9714286,21 12,21 Z M12,3.85714286 C7.5,3.85714286 3.85714286,7.5 3.85714286,12 C3.85714286,16.5 7.5,20.1428571 12,20.1428571 C16.5,20.1428571 20.1428571,16.5 20.1428571,12 C20.1428571,7.5 16.5,3.85714286 12,3.85714286 Z M12.0661018,6.48692568 C11.6593649,6.48692568 11.3292597,6.81703094 11.3292597,7.22376779 L11.3292597,7.61908358 C11.3292597,8.02582042 11.6593649,8.35592568 12.0661018,8.35592568 C12.4728386,8.35592568 12.8029439,8.02582042 12.8029439,7.61908358 L12.8029439,7.22376779 C12.8029439,6.81703094 12.473207,6.48692568 12.0661018,6.48692568 Z M11.3509789,10.1180314 L11.3509789,16.3460361 C11.3509789,16.7407839 11.671354,17.0611589 12.0661018,17.0611589 C12.4608496,17.0611589 12.7812246,16.7407839 12.7812246,16.3460361 L12.7812246,10.1180314 C12.7812246,9.72328357 12.4608496,9.40290854 12.0661018,9.40290854 C11.671354,9.40290854 11.3509789,9.72328357 11.3509789,10.1180314 Z" id="Shape" fill="#000000" fillRule="nonzero" />
        <rect id="Rectangle-8" x="0" y="0" width="24" height="24" />
      </g>
    </g>
  </g>
</svg>);
