import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import Itly from '../itly';
import { isNotServerSideRendering } from 'helpers/env';
import { newrelic } from 'helpers/reporting/newrelic';

export const analyticsIdentifyCustomer = (customer: CustomerDataCustomer): void => {
  identifyWithItly(customer);
  identifyWithHotjar(customer);
};

export const identifyWithItly = (customer: CustomerDataCustomer): void => {
  // Casting userId as a string for Itly's type signature.
  if (typeof customer.id !== 'undefined') {
    const userId = `${customer.id}`;
    Itly.identify(userId, {
      first_name: customer.firstname,
      last_name: customer.lastname,
      email: customer.email
    });
  } else {
    Itly.identify('', {
      first_name: customer.firstname,
      last_name: customer.lastname,
      email: customer.email
    });
  }
};

export const identifyWithHotjar = (customer: CustomerDataCustomer): void => {
  const hotjarUserData = setHotjarUserData(customer);
  let isHotjarReady = false;


  if (isNotServerSideRendering() && window) {
    if (window.hj) {
      isHotjarReady = true;
    } else {
      window.addEventListener('hj.ready', () => {
        isHotjarReady = true;
      });
    }
  }

  if (!isHotjarReady || !window.hj) {
    return;
  }

  window.hj('identify', hotjarUserData.flashId, {
    zenDeskUserId: hotjarUserData.zenDeskId,
    customerEmail: hotjarUserData.customerEmail
  });
};

export interface HotjarUserData {
  flashId: string,
  zenDeskId: string
  customerEmail: string
}

export const setHotjarUserData = (customer: CustomerDataCustomer): HotjarUserData => {
  let flashId;
  let zdUserId;

  if (!customer) {
    newrelic('addPageAction')('Customer is undefined ');
    flashId = undefined;
    zdUserId = undefined;
    customer.email = undefined;
  } else if (!customer.custom_attributes) {
    newrelic('addPageAction')('Customer is missing custom_attributes');
    if (!customer.email) {
      flashId = undefined;
      zdUserId = undefined;
      customer.email = undefined;
    }
  } else {
    const flashIdAttribute = customer.custom_attributes.filter(attribute => attribute.attribute_code === 'flash_id');
    const zdIdAttribute = customer.custom_attributes.filter(attribute => attribute.attribute_code === 'zd_user_id');

    if (flashIdAttribute.length === 0) {
      flashId = undefined;
      newrelic('addPageAction')('Customer flashId not defined', {customer: customer});
    } else if (zdIdAttribute.length === 0) {
      zdUserId = undefined;
      newrelic('addPageAction')('Customer zen desk id not defined', {customer: customer});
    } else {
      flashId = flashIdAttribute[0].value;
      zdUserId = zdIdAttribute[0].value;
    }
  }

  return {
    flashId: flashId,
    zenDeskId: zdUserId,
    customerEmail: customer.email
  };
};