import React, { useRef } from 'react';
import { __BREAKPOINT_SMALL__ } from '../constants/breakpoints';
import {
  MobileAvailability,
  MobileAvailabilityHeadline,
  MobileAvailabilityIcon,
  MobileAvailabilityOutputWrapper,
  AddToCartButton,
  MobileAvailabilityIconAppStore
} from './index.styled';
import {
  MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA,
  MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA,
  MOBILE_DISPLAY_SHOW_ADD_BUTTON
} from '../constants/products';
import { useEffect } from 'react';
import { useVisible } from '../hooks/useVisible';

type CTAButtonProps = {
  addToCartLabel: string;
  displayState: string;
  onClick?: () => void;
  onVisible?: () => void;
  id?: string;
};

const CTAButton = ({
  onClick,
  addToCartLabel,
  displayState,
  onVisible,
  id = 'addToCartButton'
}: CTAButtonProps): JSX.Element | null => {
  let output = null;

  const ctaButtonRef = useRef<any>();
  const ctaButtonInViewport = useVisible(ctaButtonRef, '-50px', true);

  useEffect(() => {
    if (onVisible && ctaButtonInViewport) {
      onVisible();
    }
  }, [onVisible, ctaButtonInViewport]);

  switch (displayState) {
    case MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA:
      output = (
        <a href="/account/mobile/index/">
          <MobileAvailability>
            <MobileAvailabilityHeadline>Upload Your Photos</MobileAvailabilityHeadline>
            <MobileAvailabilityIcon>
              <img src="https://assets.artifactuprising.com/assets/images/icons/upload-images-icon.svg" alt="" />
            </MobileAvailabilityIcon>
            <p>
              This product is not currently available to customize on your mobile device. However, you can get a head
              start by uploading photos from your camera roll here. Then, return to this site on your desktop or laptop
              computer to continue creating!
            </p>
          </MobileAvailability>
        </a>
      );
      break;

    case MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA:
      output = (
        <MobileAvailability>
          <p>This product is not yet available for customization within a mobile browser.</p>
          <MobileAvailabilityIcon>
            <a
              aria-label="Download on the itunes store"
              href="https://itunes.apple.com/us/app/artifact-uprising-photo-books-prints-cards/id713083894?mt=8"
            >
              <img
                className="mobile-availability__icon-app"
                src="https://assets.artifactuprising.com/assets/images/icons/au-app-icon.png"
                alt=""
              />
            </a>
          </MobileAvailabilityIcon>
          <p>Download the Artifact Uprising App to get started.</p>
          <MobileAvailabilityIcon>
            <a
              aria-label="Download on the app store"
              href="https://itunes.apple.com/us/app/artifact-uprising-photo-books-prints-cards/id713083894?mt=8"
            >
              <MobileAvailabilityIconAppStore
                src="https://assets.artifactuprising.com/assets/images/appstore.jpg"
                alt="Download on the app store"
              />
            </a>
          </MobileAvailabilityIcon>
        </MobileAvailability>
      );
      break;

    // Display everything as it is on desktop
    case MOBILE_DISPLAY_SHOW_ADD_BUTTON:
      output = (
        <AddToCartButton ref={ctaButtonRef} id={id} type="submit" onClick={onClick}>
          {addToCartLabel}
        </AddToCartButton>
      );
      break;

    default:
      return null;
  }

  return <MobileAvailabilityOutputWrapper>{output}</MobileAvailabilityOutputWrapper>;
};

export default CTAButton;
