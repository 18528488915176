import Itly, { SearchResultSelectedProperties } from 'itly';
import { useEffect } from 'react';


export function useTrackSearchResultSelected(
  resultTerm: string,
  searchTerm: string
) {
  useEffect(() => trackSearchResultSelected(searchTerm, resultTerm), [searchTerm, resultTerm]);
}


export function trackSearchResultSelected(
  searchTerm: string,
  resultTerm: string
){
  const data: SearchResultSelectedProperties = { search_term: searchTerm, result_term: resultTerm };
  Itly.searchResultSelected(data);
}