import React from 'react';
import styled from 'styled-components';
import Icon from '../icons';

export const PhotosNavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 5vh;
`;
export const NavigationWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const NavImage = styled.img`
  width: 6rem;
  height: 6rem;
  margin: 2rem;
  cursor: pointer;
  @media screen and (max-width: 780px) {
    width: 2rem;
    height: 2rem;
    margin: 1rem;
  }
`;

export const NextArrowIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  display: flex;
  margin-top: 1px;
  line-height: 24px;
  width: 4.5rem;
  height: 4.5rem;
`;

export const PreviousArrowIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  display: flex;
  margin-top: 1px;
  line-height: 24px;
  width: 4.5rem;
  height: 4.5rem;
`;
