import styled from 'styled-components';

type PhotoItemWrapperProps = {
  isLoaded?: boolean;
};
export const PhotoItemWrapper = styled.div`
  display: flex;
  margin: 0 1rem 1rem 2rem;
  width: fit-content;
  height: fit-content;
  img {
    display: flex;
    max-height: 100%;
    max-width: 150px;
    margin: 0;
  }
  @media (max-width: 780px) {
    margin: 1rem;
    img {
      max-width: 90vw;
    }
  }
`;
