import React, { useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { random } from 'lodash';
import { MagentoMenuItem, MenuItemEdge } from 'types/Navigation';
import { flattenMenuItemNodes, redirectToPage, checkIsBlocklisted } from 'helpers/navigation';
import { Icon } from 'component-library';
import './ProductSearch.css';
import { trackSearchResultSelected } from 'analytics/itly/hooks';
import { graphql, useStaticQuery } from 'gatsby';
import { ProductType } from 'features/pdp/types/event';
import { isCard } from 'features/pdp';

export interface ProductSearchProps {
  menuItems: Array<MenuItemEdge>;
  cancelSearch?: () => void;
}

const ProductSearch = ({ menuItems, cancelSearch }: ProductSearchProps): JSX.Element => {
  const {
    allProduct: { nodes: products }
  } = useStaticQuery(graphql`
    query {
      allProduct {
        nodes {
          name
          canonicalUrl
          customProductType
          reportingProductCategory
        }
      }
    }
  `);

  // Autocomplete item list
  const [items, setItems] = useState([]);

  // The current search param typed
  const [searchParam, setSearchParam] = useState('');

  const autocompleteProps = {
    style: {
      fontFamily: '"Lato", sans-serif',
      letterSpacing: '.5px',
      border: '1px solid',
      borderColor: 'rgb(209, 209, 209)',
      width: '100%',
      height: '54px',
      marginBottom: '15px',
      borderRadius: '4px',
      paddingLeft: '10px',
      boxShadow: '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)'
    },
    autoFocus: true,
    placeholder: ' Search...'
  };
  const wrapperStyle = {
    width: '100%'
  };
  const menuStyle = {
    width: '100%'
  };

  // UseState usage doesnt get on time to set the values, this is why we use a simple variable in here
  let yScrollPosition = null;

  // The individual product row
  const renderItem = item => (
    <div
      className="productSearchResult"
      style={{ zIndex: 4 }}
      key={item.name + random(1, 1000).toString()}
      onClick={() => navigateToSelection(item.name)}
      onTouchStart={event => {
        yScrollPosition = event.target?.getBoundingClientRect()?.y;
      }}
      onTouchEnd={event => {
        if (event.target?.getBoundingClientRect()?.y === yScrollPosition) {
          navigateToSelection(item?.name);
        }
      }}
    >
      <span>{item.name}</span>
      <Icon className="rightArrow" icon="arrow" />
    </div>
  );

  // Set the initial filter values, update the search param
  const filterList = event => {
    if (items.length === 0) {
      const flattenedItems = flattenMenuItemNodes(menuItems);

      const cardsItems = products.reduce((cardsItems: MagentoMenuItem[], product: ProductType) => {
        if (isCard(product) && product.canonicalUrl) {
          return [...cardsItems, { name: product.name, path: `/${product.canonicalUrl}` }];
        }
        return cardsItems;
      }, []);

      setItems([...flattenedItems, ...cardsItems]);
    }
    const queryTerm = event.currentTarget.value;
    setSearchParam(queryTerm);
  };

  // Set the default state, add the result count if we're searching
  const renderMenu = (items, value) => (
    <div className="menu autocompleteContainer">
      {value === '' ? (
        <div className="defaultText" onClick={cancelSearch}>
          <div className="quoteText">We take photos as a return ticket to a moment otherwise gone.</div>
        </div>
      ) : (
        <div>
          <div className="productCount">Showing {items.length} product results </div> {items}
        </div>
      )}
    </div>
  );

  // On selecting an item, go to that page
  const navigateToSelection = (value: string): void => {
    const foundItem = items.find(item => item.name === value);
    if (foundItem) {
      const isNotBlocklisted = !checkIsBlocklisted(foundItem.path);
      trackSearchResultSelected(searchParam, foundItem?.name);
      redirectToPage(foundItem.path, { isNotBlocklisted });
      cancelSearch();
    }
  };

  // Look for products that match the value we're searching for
  const productNameIncludesSearchParam = (item: MagentoMenuItem, value: string): boolean => {
    if (!item) {
      return false;
    }
    return item.name.toLowerCase().indexOf(value.toLowerCase()) > -1;
  };
  return (
    <div className="productSearchWrapper">
      <a onClick={cancelSearch} className="cancelLink">
        <Icon icon="close" className="productSearchClose" onClick={cancelSearch} size="medium" />
      </a>
      <Autocomplete
        menuStyle={menuStyle}
        getItemValue={item => item.name}
        shouldItemRender={productNameIncludesSearchParam}
        wrapperStyle={wrapperStyle}
        items={items}
        renderItem={renderItem}
        renderMenu={renderMenu}
        inputProps={autocompleteProps}
        value={searchParam}
        onChange={filterList}
        onSelect={navigateToSelection}
      />
    </div>
  );
};

export default ProductSearch;
