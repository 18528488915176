const LOWER_CASE: [RegExp, string] = [/[a-z]/, 'lowercase'];
const UPPER_CASE: [RegExp, string] = [/[A-Z]/, 'uppercase'];
const NUMERIC: [RegExp, string] = [/\d/, 'number'];
const SPECIAL_CHARS: [RegExp, string] = [
  /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/,
  'special'
];

const strMatchesMinimumPatterns =
  (patterns: [RegExp, string][], minMatches: number) => (x: string) => {
    const matches = patterns.filter(([p]) => p.test(x));
    return getFailureMessage(patterns, matches, minMatches)(x);
  };

const getFailureMessage =
  (
    patterns: [RegExp, string][],
    matches: [RegExp, string][],
    minMatches: number
  ) => (str: string) => {
    // @ts-ignore
    const missedCharacterClasses = patterns
      .filter((x) => !matches.includes(x))
      .map(([_, x]) => x);

    if (missedCharacterClasses.length > minMatches) {
      return missedCharacterClasses.reduce(
        (accum, x, i) => `${accum} ${i < missedCharacterClasses.length - 1 ? '' : 'or '}${x}${
            i < missedCharacterClasses.length - 1 ? ',' : ' character.'
          }`,
        'Must include at least one other:'
      );
    } else if (minLengthValidator(7)(str)) {
      return 'Please enter a password with at least 7 characters.';
    }

    return '';
  };

const isValidPassword = strMatchesMinimumPatterns(
  [LOWER_CASE, UPPER_CASE, NUMERIC, SPECIAL_CHARS],
  2
);

const isValidEmail = (x: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(x);

export const requiredValidator = (value: string) => {
  if (value === '') {
    return 'This field is required.';
  }
  return '';
};

export const minLengthValidator = (minLength: number) => (value: string) => {
  if (value.length < minLength) {
    return `Must be at least ${minLength} characters.`;
  }
  return '';
};

export const emailValidator = (value: string) => {
  if (!isValidEmail(value)) {
    return 'Please enter a valid email.';
  }
  return '';
};

export const passwordValidator = (value: string) => isValidPassword(value);

export const validate = (validators: Function[], value: string): string[] => {
  const validateErrors = validators
    .map((validator) => validator(value))
    .filter((result) => !!result);
  return validateErrors;
};
