import { analyticsApplyPromoCode } from 'store/cart/actions';
import { ApplyPromoCodeEvent } from '../analyticsWrapper';

export function mapApplyPromoCodeToEvent(
  action: ReturnType<typeof analyticsApplyPromoCode>): ApplyPromoCodeEvent {
  const { promoCode, cartId } = action.payload;
  return {
    type: 'applyPromoCode',
    data: {
      promo_code: promoCode,
      ...(cartId && { cart_id: cartId })
    }
  };
}