import { propsExist } from 'faunctions';

export enum MagentoErrors {
  // Out of stock
  ERR_OOS = 'ERR_OOS',
}

export const {
  ERR_OOS
} = MagentoErrors;

export const isMagentoErrorCode = (errorCode: string) => Object.values(MagentoErrors).includes(errorCode);

export interface MagentoJsonError {
  data: any;
  errorCode: string;
  statusCode: number;
}

export const isMagentoJsonError = (obj: any): obj is MagentoJsonError => {
  if (typeof obj === 'object') {
    return propsExist(
      'data',
      'errorCode',
      'statusCode'
    )(obj);
  }
  return false;
};
