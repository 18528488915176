import { useStaticQuery, graphql } from 'gatsby';

export const UseShippingRates = () => {
  const {
    allShippingRate: { nodes: shippingRates }
  } = useStaticQuery(graphql`
    query {
      allShippingRate {
        nodes {
          id
          max_shipping_time
          min_shipping_time
          name
          shipping_speed
        }
      }
    }
  `);

  return shippingRates;
};
