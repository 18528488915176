//This component was copied from ProductHeader with modification to be used in a split test
import React from 'react';
import { PDPPrice } from '../PDPPrice';
import { Product, Rating } from 'types/Product';
import Show from 'component-library/Show';
import { PriceObject } from '../ProductDetails/ProductPrice';
import { PDPMetatags } from '../ProductDetails/PDPMetaTags';
import YotpoStars from 'components/Yotpo/YotpoStars';
import { getYotpoApiKey, isNotServerSideRendering } from 'helpers/env';
import { formatPrice } from 'helpers/currency';
import {
  AfterpayContainer,
  Badge,
  DesktopAfterpayPlacementContainer,
  OriginalPrice,
  PriceAndPromoContainer,
  PriceSectionContainer,
  PricingContainer,
  PricingPromoContainer,
  ProductHeaderContainer,
  ProductPromoTitle,
  ProductTitle,
  ProductTitleAndStars,
  PromoCodeTitleLine,
  SecondLine,
  StrikethroughPrice,
  TitleLine,
  YotpoStarsContainer
} from './styled';
import { DeviceSize, useWindowSize } from 'hooks/useWindowSize';
import { PromoCode } from 'components/Strikethrough';
import { SKU_CURATION_SERVICES } from 'component-library/constants/products';

type ProductHeaderProps = {
  product: Product;
  productTitle: string;
  price: PriceObject;
  siteUrl: string;
  yotpoStars: Rating;
};

const ProductHeaderNewDesign = ({ product, productTitle, price, siteUrl, yotpoStars }: ProductHeaderProps): JSX.Element => {
  const isNotSSR = isNotServerSideRendering();
  const { deviceSize } = useWindowSize();

  const isMobile = deviceSize === DeviceSize.SMALL;

  // The filter on badges exists because API has a habit of providing badge
  // Values that are empty strings, these should be treated as no badge.
  const badges = Array.isArray(product.badges) ? product.badges.filter(b => b) : [];

  let shouldDisplayPromoCode = false;

  if (price.promoCode && price.promoCode.length > 0) {
    shouldDisplayPromoCode = true;
  }

  const shouldDisplayDivider = product.sku === SKU_CURATION_SERVICES;

  return (
    <ProductHeaderContainer divider={shouldDisplayDivider}>
      <PDPMetatags product={product} siteUrl={siteUrl || ''}/>
      <Show when={badges?.length > 0}>
        <Badge>{badges[0]}</Badge>
      </Show>

      {shouldDisplayPromoCode ? (
        <PromoCodeTitleLine>
          <ProductTitleAndStars>
            <ProductPromoTitle data-testid="product-title">{productTitle}</ProductPromoTitle>
            <Show when={!!yotpoStars}>
              <YotpoStarsContainer>
                <YotpoStars
                  apiKey={getYotpoApiKey()}
                  productId={product.id}
                  productName={product.name}
                  yotpoStars={yotpoStars}
                  useNewStyles={true}
                />
              </YotpoStarsContainer>
            </Show>
          </ProductTitleAndStars>
          <PriceAndPromoContainer>
            <PDPPrice price={price} skipSubtotal placementSelector=".afterpay-placement"/>
            <PricingPromoContainer strikethrough={!!price.strikethroughPrice}>
              {/* Initial price from SSR may be different from actual price due to product options, */}
              {/* so we can't display price until CSR kicks in */}
              <Show when={isNotSSR}>
                <Show when={!!price.strikethroughPrice}>
                  <StrikethroughPrice className="afterpay-price-selector">
                    {formatPrice(price.strikethroughPrice)}
                  </StrikethroughPrice>
                </Show>
                <OriginalPrice className={!price.strikethroughPrice && 'afterpay-price-selector'}>
                  {formatPrice(price.productPriceTotal)}
                </OriginalPrice>
              </Show>
              <Show when={!isMobile}>
                <DesktopAfterpayPlacementContainer>
                  <AfterpayContainer className="afterpay-placement"></AfterpayContainer>
                </DesktopAfterpayPlacementContainer>
              </Show>
            </PricingPromoContainer>
            {PromoCode(shouldDisplayPromoCode, price.promoCode)}
            <AfterpayContainer className="afterpay-placement"></AfterpayContainer>
          </PriceAndPromoContainer>
        </PromoCodeTitleLine>
      ) : (
        <TitleLine>
          <PriceSectionContainer>
            <ProductTitle data-testid="product-title">{product.name}</ProductTitle>
            <PDPPrice price={price} skipSubtotal placementSelector=".afterpay-placement"/>
            <PricingContainer strikethrough={!!price.strikethroughPrice}>
              <Show when={isNotSSR}>
                <Show when={!!price.strikethroughPrice}>
                  <StrikethroughPrice className="afterpay-price-selector">
                    {formatPrice(price.strikethroughPrice)}
                  </StrikethroughPrice>
                </Show>
                <OriginalPrice className={!price.strikethroughPrice && 'afterpay-price-selector'}>
                  {formatPrice(price.productPriceTotal)}
                </OriginalPrice>
              </Show>
              <Show when={!isMobile}>
                <DesktopAfterpayPlacementContainer>
                  <AfterpayContainer className="afterpay-placement"></AfterpayContainer>
                </DesktopAfterpayPlacementContainer>
              </Show>
            </PricingContainer>
          </PriceSectionContainer>
        </TitleLine>
      )}
      <SecondLine>
        <Show when={!!yotpoStars && !shouldDisplayPromoCode}>
          <YotpoStarsContainer>
            <YotpoStars
              apiKey={getYotpoApiKey()}
              productId={product.id}
              productName={product.name}
              yotpoStars={yotpoStars}
              useNewStyles={true}
            />
          </YotpoStarsContainer>
        </Show>
        <Show when={isMobile && !shouldDisplayPromoCode}>
          <AfterpayContainer className="afterpay-placement"></AfterpayContainer>
        </Show>
      </SecondLine>
    </ProductHeaderContainer>
  );
};

export default ProductHeaderNewDesign;
