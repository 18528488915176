export const getGatsbySiteUrl = () => process.env.GATSBY_SITE_URL;
export const getMagentoBaseUrl = () => process.env.GATSBY_MAGENTO_BASE_URL;
export const getGoogleSiteVerificationKey = () => process.env.GATSBY_GOOGLE_SITE_VERIFICATION_KEY;

export const pathToMagentoBase = (path: string) => `${getMagentoBaseUrl()}${path}`;

export const isNotServerSideRendering = () => typeof window !== 'undefined';
export const isServerSideRendering = () => typeof window === 'undefined';

export const getEnv = () => process.env.GATSBY_SITE_ENV;
export const getNodeEnv = () => process.env.NODE_ENV;
export const isProdEnv = () => getEnv() === 'prod';

const NODE_ENV_DEVELOPMENT = 'development';
export const isLocal = () => getNodeEnv() === NODE_ENV_DEVELOPMENT;

export const debugLog = (...args: any[]) => getEnv() !== 'prod' && console.debug(...args);

export const getCrudCoreBaseUrl = () => process.env.GATSBY_CRUD_CORE_BASE_URL;
export const getYotpoApiKey = () => process.env.GATSBY_YOTPO_API_KEY;

export const getAfterpayCheckoutWidgetScriptUrl = (): string => process.env.GATSBY_AFTERPAY_CHECKOUT_WIDGET_SDK_URL;

export const getV2PdfBaseUrl = () => process.env.GATSBY_V2_PDF_BASE_URL;

export const getCDNBaseURL = () => process.env.GATSBY_CDN_IMAGE_BASE_URL;
