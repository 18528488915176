import styled from 'styled-components';
import Select from 'react-select';

export const StyledQuantityPicker = styled(Select)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 500px;
  border: 1px solid #707070;
`;

export const QuantityValue = styled.select`
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font-size: 0.85rem;
  font: inherit;
`;
