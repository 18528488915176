import { MutableRefObject, useEffect, useState } from 'react';

export const useVisible = (ref: MutableRefObject<any>, rootMargin: string | undefined, fireOnce: boolean = false) => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (fireOnce) {
          if (entry.isIntersecting) {
            setState(entry.isIntersecting);
            observer.unobserve(ref.current);
          }
        } else {
          setState(entry.isIntersecting);
        }
      },
      { rootMargin }
    );

    ref.current && observer.observe(ref.current);

    return () => ref.current && observer.unobserve(ref.current);
  }, []);

  return isVisible;
};
