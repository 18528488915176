import React from 'react';
import { MagentoMenuItem } from '../../../../types/Navigation';
import { isNotEmpty } from 'helpers/arrays';
import Dropdown from './Dropdown';
import { Show } from 'components/Functional';
import { AULink } from '../../../AULink';
import { NavFieldTrackingBody, buildTrackingBodyForNavFields, trackNavLinkClicked } from 'analytics/itly/helpers/nav';

interface NavLinkProps {
  name: string;
  path: string;
  dropdownMenuItems?: Array<MagentoMenuItem>;
  leftDropdownChildren?: JSX.Element;
  rightDropdownChildren?: JSX.Element;
  isGatsbyPage?: boolean;
  children?: JSX.Element;
}

const NavLink = ({
  name,
  path,
  isGatsbyPage,
  dropdownMenuItems = [],
  leftDropdownChildren,
  rightDropdownChildren,
  children
}: NavLinkProps) => {
  const navFieldTrackingBody: NavFieldTrackingBody = buildTrackingBodyForNavFields(name);

  return (
    <div className="NavLink__Container">
      <AULink
        onClick={() => trackNavLinkClicked('header', name, path, navFieldTrackingBody)}
        path={path}
        linkToGatsby={isGatsbyPage}
        className="DesktopNav__NavLink"
        id={`DesktopNav__NavLink_${name}`}
      >
        {children ? children : name}
      </AULink>
      <Show when={isNotEmpty(dropdownMenuItems)}>
        <Dropdown
          menuItems={dropdownMenuItems}
          leftDropdownChildren={leftDropdownChildren}
          rightDropdownChildren={rightDropdownChildren}
          parentName={name}
        />
      </Show>
    </div>
  );
};

export default NavLink;
