import React from 'react';
import styled from 'styled-components';
import { CanonicalCategory } from '../../templates/CLP';
import './SeoBlock.css';

const SeoBlockContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin: 2.25rem 0;
  a:link {
    text-decoration: underline;
  }
  h2, h3 {
    font-family: "Crimson Text", "Georgia",  serif;
    font-size: 28px;
    font-weight: 300;
    letter-spacing: 0.2px;
    -webkit-font-smoothing: subpixel-antialiased;
  }
`;

interface SeoBlockProps {
  className?: string
  content?: string
  currentCanonicalCategory?: CanonicalCategory
}

const SeoBlock = ({ content, className, currentCanonicalCategory }: SeoBlockProps) => {
  const hasSEOBLock = () => {
    if(currentCanonicalCategory) {
      return currentCanonicalCategory?.meta?.SEOBlock || null;
    }
    return content;
  };

  return (
    <SeoBlockContainer className={className} dangerouslySetInnerHTML={{__html: hasSEOBLock()}} />
  );
};

export default SeoBlock;
