import { PDPFields } from '../hooks/useProductForm';
import { MediaObject } from '../types/media';

export const OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR = '|';

// GetCorrectedLivePreviewAttributes returns a set of attributes without `|` in its values
export const getCorrectedLivePreviewAttributes = (attributes: string[]): string[] =>
  attributes.reduce((attrs, attr) => [...attrs, ...attr.split(OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR)], []);

// GetCorrectedAttributesValues returns the object that contains only the needed values for live preview
export const getCorrectedAttributesValues = (attributes: string[], values: PDPFields): PDPFields => {
  return attributes.reduce((correctedValues, attr) => {
    const correctedKey = attr
      .split(OPTIONAL_LIVE_ATTRIBUTES_SEPARATOR)
      .filter(key => !!values[key])
      .pop();

    return !correctedKey
      ? correctedValues
      : {
          ...correctedValues,
          [correctedKey]: values[correctedKey]
        };
  }, {});
};
export const generateLivePreviewImage = (url: string): MediaObject => {
  return {
    caption: 'Live Preview',
    full: url,
    img: url,
    isMain: true,
    position: '-1',
    thumb: url,
    type: 'image',
    url: url,
    videoUrl: null,
    videoId: null
  };
};