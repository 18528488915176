import { getGatsbySiteUrl, isNotServerSideRendering } from '../../../helpers/env';
import Itly, { NavLinkClickedProperties } from 'itly';
import { ensureStartsWithSlash, isPath } from '../../../helpers/strings';
export interface NavFieldTrackingBody {
  nav_header: string;
  nav_section?: string;
  nav_subsection?: string;
}

export const buildTrackingBodyForNavFields = (
  headerName: string,
  sectionName?: string,
  subsectionName?: string
): NavFieldTrackingBody => ({
  nav_header: headerName,
  nav_section: sectionName,
  nav_subsection: subsectionName
});

export function trackNavLinkClicked(
  clickZone: string,
  link: string,
  navigationTarget: string,
  nav_fields: NavFieldTrackingBody
) {
  if (clickZone && link && navigationTarget && isNotServerSideRendering()) {
    const url = isPath(navigationTarget)
      ? `${getGatsbySiteUrl()}${ensureStartsWithSlash(navigationTarget)}`
      : navigationTarget;
    const data: NavLinkClickedProperties = {
      click_zone: clickZone,
      link,
      navigation_target: url,
      ...nav_fields
    };
    Itly.navLinkClicked(data);
  }
}