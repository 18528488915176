import React from 'react';
import { Provider } from 'react-redux';
import { RecoilRoot } from 'recoil';
import { GlobalStyle, GoogleReCaptchaProvider } from 'component-library';
import GatewayProvider from 'components/Gateway/GatewayProvider';
import createStore from './src/store';

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  const store = createStore();

  return (
    <Provider store={store}>
      <RecoilRoot>
        {/* <DockMonitor
          toggleVisibilityKey="alt-h"
          changePositionKey="alt-q"
          changeMonitorKey="alt-m"
          defaultIsVisible={false}
        >
          <LogMonitor markStateDiff />
        </DockMonitor> */}
        <GatewayProvider>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_GOOGLE_RECHAPTCHA_V3_SITE_KEY}>
            <>
              <GlobalStyle />
              {element}
            </>
          </GoogleReCaptchaProvider>
        </GatewayProvider>
      </RecoilRoot>
    </Provider>
  );
};
