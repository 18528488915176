import React, { Fragment } from 'react';
import { useYotpo } from '../useYotpo';
import { LightboxStyles, ReviewsSectionWrapper, ReviewsTitle } from './styled';

type YotpoProductProps = {
  className?: string;
  productId: string;
  productPrice?: string;
  productUrl?: string;
  productName?: string;
  productImage?: string;
  apiKey: string;
};

const Yotpo = ({
  className = '',
  productUrl,
  productId,
  productPrice,
  productName,
  productImage,
  apiKey
}: YotpoProductProps) => {
  useYotpo(apiKey);

  return (
    <Fragment>
      <LightboxStyles />
      <ReviewsSectionWrapper className={className}>
        <a id="yotpo-reviews-anchor" />
        <ReviewsTitle>Take it from our community...</ReviewsTitle>
        <div
          id="y-embedded-widget"
          className="yotpo yotpo-main-widget"
          data-appkey={apiKey}
          data-product-id={productId}
          data-price={productPrice}
          data-name={productName}
          data-url={productUrl}
          data-image-url={productImage}
        />
      </ReviewsSectionWrapper>
    </Fragment>
  );
};

export default Yotpo;
