import { Colors } from "component-library";
import { mediaQuery } from "component-library/Grid";
import styled from "styled-components";

export const SectionTitle = styled.div`
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-size: 11px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: 400;
  font-size: 11px;
  letter-spacing: 2.5px;
  line-height: 18px;
  text-align: center;
`;

export const BannerBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${mediaQuery.large`
    flex-direction: row;
  `}
  }
`;

export const StyledImage = styled.img`
  margin-bottom: 1em;
  width: 8rem;
  height: 8rem;
`;

export const Pair = styled.div`
  display: flex;
  width: 100%;
  max-width: 40rem;
  justify-content: space-around;
`;

export const SectionWrapper = styled.div`
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;

  ${mediaQuery.medium`
    flex-wrap: wrap;
  `}
  }
`;

export const StyledTitleContainer = styled.div`
  font-size: 30px;
  margin: 1rem;
  letter-spacing: 0.2px;
  font-weight: 500;
  line-height: 34px;
  -webkit-font-smoothing: subpixel-antialiased;
`;

export const Banner = styled.div`
  background-color: ${Colors.WHITE};
  text-align: center;
  padding: 1rem;
  margin-top: 2.5rem;
  flex-direction: row;
`;

export const DesktopOnly = styled.div`
  display: none;

  ${mediaQuery.medium`
    display: block;
  `};
`;

export const StyledText = styled(DesktopOnly)`
  margin: 1em;
  height: 68px;
  width: 197px;
  color: #000000;
  font-family: 'Crimson Text', 'Georgia', serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
`;