import React, { useState, useCallback } from 'react';
import styled, { css } from 'styled-components';

import LoginResponse from 'types-magenum/auth/LoginResponse';
import { StyledH2, Subtext } from '../typography';
import { FullWidthWrapper, FormContainer, ButtonWrapper, InlineSpan } from './sharedStyles';
import { TextInput, PrimaryLoadingButton } from '..';

import { customerRegister } from '../services/magento';
import { AUTH_EVENTS, AuthPageType } from '.';
import { requiredValidator, emailValidator, passwordValidator, validate } from './validators';

const emailValidators = [
  requiredValidator,
  emailValidator
];

const passwordValidators = [
  requiredValidator,
  passwordValidator
];

interface RegistrationPageProps {
  authEndpoint: string;
  onAuth: (err: string | undefined, data: LoginResponse | undefined) => void;
  onEvent: (type: string) => void;
  setCurrPage: (pageType: AuthPageType) => void;
}

const RegistrationPage = ({ authEndpoint, onAuth, onEvent, setCurrPage }: RegistrationPageProps) => {
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({ firstname: '', lastname: '', email: '', password: '' });

  const validateField = useCallback(
    (field: string, validators: Function[], value: string) => {
      const validateErrors = validate(validators, value);
      setErrors({
        ...errors,
        [field]: validateErrors.length ? validateErrors[0] : ''
      });
    },
    [errors]
  );

  const isFormValid = useCallback(() => {
    // @ts-ignore
    const hasErrors = Object.values(errors).reduce((acc: boolean, curr: string) => acc || !!curr, false);
    const isValid = !!firstname && !!lastname && !!email && !!password && !hasErrors;
    return isValid;
  }, [firstname, lastname, email, password, errors]);

  const submit = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await customerRegister(authEndpoint)({ firstname, lastname, email, password });
      onAuth(undefined, data);
      onEvent(AUTH_EVENTS.CUSTOMER_REGISTER);
    } catch (error) {
      onAuth(error.body, undefined);
    } finally {
      setIsLoading(false);
    }
  }, [firstname, lastname, email, password]);

  const goToLogin = useCallback(() => {
    setCurrPage(AuthPageType.LOGIN);
  }, []);

  return (
    <FullWidthWrapper>
      <StyledH2 secondary data-testid="auth-header" style={{ textAlign: 'center' }}>Create Account</StyledH2>
      <FormContainer>
        <TextInput
          label="first name"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          error={errors.firstname}
          onBlur={() => validateField('firstname', [requiredValidator], firstname)}
        />
        <TextInput
          label="last name"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          error={errors.lastname}
          onBlur={() => validateField('lastname', [requiredValidator], lastname)}
        />
        <TextInput
          label="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          error={errors.email}
          onBlur={() => validateField('email', emailValidators, email)}
        />
        <TextInput
          label="password"
          inputType="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
          onBlur={() => validateField('password', passwordValidators, password)}
        />
        <ButtonWrapper>
          <PrimaryLoadingButton isLoading={isLoading} disabled={!isFormValid()} onClick={submit}>
            Create Account
          </PrimaryLoadingButton>
        </ButtonWrapper>
      </FormContainer>
      <Subtext alignment="center">
        Already have an account?
        {' '}
        <InlineSpan onClick={goToLogin}>Sign In</InlineSpan>
      </Subtext>
    </FullWidthWrapper>
  );
};

export default RegistrationPage;
