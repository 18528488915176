import { optionGet } from 'faunctions';
import { placeOrderSuccess } from 'store/cart/actions';
import SalesDataOrderItem from 'mage-swagfaces/sales/SalesDataOrderItem';
import { OrderCompletedEvent } from '../analyticsWrapper';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import { OrderItemCompletedProperties } from 'itly';
import { getRepeatPropertyFromCustomerGroupId } from '../helpers/customer';
import { getIP } from 'helpers/customer/getIp';

export async function mapPlaceOrderSuccessToEvent(action: ReturnType<typeof placeOrderSuccess>): OrderCompletedEvent {
  const { order } = action.payload;
  const orderId = parseInt(action.payload.order.increment_id, 10);
  const baseCurrency = order.base_currency_code;
  const baseTax = action.payload.order.base_tax_amount || 0;
  const baseShipping = order.base_shipping_amount;
  const baseSubtotal = order.base_subtotal || 0;
  const baseDiscount = order.base_discount_amount * -1 || 0;
  const baseShippingDiscount = order.base_shipping_discount_amount || 0;
  const baseGrandTotal = order.base_grand_total;
  const billingCountry = order.billing_address.country_id;
  const billingPhone = order.billing_address.telephone;
  const billingState = order.billing_address.region;
  const billingZip = order.billing_address.postcode;
  const customerEmail = order.customer_email;
  // todo will fix this at a later date to handle for 3rd party api being down, for now return empty string
  // const customerIP = await getIP();
  const customerIP = '';
  const shipping = baseShipping - baseShippingDiscount;
  const revenue = baseGrandTotal - baseTax;
  // Filter out child skus
  const parentOrderItems = order.items.filter(oi => !oi.parent_item);
  const paymentMethod = order.payment.method;

  const eventData: OrderCompletedEvent = {
    type: 'orderCompleted',
    data: {
      byOrder: {
        billing_country: billingCountry,
        billing_phone: billingPhone,
        billing_state: billingState,
        billing_zip: billingZip,
        cart_id: order.quote_id,
        currency: baseCurrency,
        customer_ip: customerIP,
        discount: baseDiscount,
        email: customerEmail,
        order_id: orderId,
        products: parentOrderItems.map(orderItemToAnalyticsProduct),
        revenue: revenue,
        shipping: shipping,
        subtotal: baseSubtotal,
        tax: baseTax,
        coupon: order.coupon_code,
        payment_method: paymentMethod,
        repeat: getRepeatPropertyFromCustomerGroupId(order.customer_group_id)
      },
      byItem: parentOrderItems.map((item: OrderItem__Patched) => orderItemToAnalyticsOrderItemCompleted(order, item))
    }
  };
  return eventData;
}

// Calcs individual revenue
export const calcOrderItemRevenue = (item: OrderItem__Patched) => item?.base_row_total - item?.base_tax_amount;

function orderItemToAnalyticsProduct(item: OrderItem__Patched) {
  const reportingProductProperties = getReportingAttributesFromOrderItem(item);
  const sku = getParentOrItemSku(item);

  return {
    product_id: item.product_id,
    sku,
    name: item.name,
    price: item.price,
    quantity: item.qty_ordered,
    category: reportingProductProperties.reporting_product_category,
    product_line: reportingProductProperties.reporting_product_line,
    ecomm_prodid: sku
  };
}

function orderItemToAnalyticsOrderItemCompleted(
  order: SalesDataOrder,
  item: OrderItem__Patched
): OrderItemCompletedProperties {
  const reportingProductProperties = getReportingAttributesFromOrderItem(item);
  const sku = getParentOrItemSku(item);
  const au_project_version = getAuProjectVersion(item);

  return {
    cart_id: order.quote_id,
    order_id: parseInt(order.increment_id, 10),
    name: item.name,
    product_id: item.product_id,
    quantity: item.qty_ordered,
    sku,
    item_id: item.item_id,
    price: item.price,
    category: reportingProductProperties.reporting_product_category,
    product_line: reportingProductProperties.reporting_product_line,
    au_project_version,
    revenue: calcOrderItemRevenue(item),
    row_total: item.base_row_total || item.row_total
  };
}

function getParentOrItemSku(orderItem: OrderItem__Patched) {
  return optionGet('extension_attributes.product.sku')(orderItem).getOrElse(orderItem.sku);
}

function getAuProjectVersion(orderItem: OrderItem__Patched) {
  return optionGet('extension_attributes.au_project_version')(orderItem).getOrElse('1');
}

interface ReportingAttributes {
  reporting_product_category?: string;
  reporting_product_line?: string;
  reporting_product_type?: string;
}

type OrderItem__Patched = SalesDataOrderItem & {
  extension_attributes: {
    product: {
      sku: string;
      custom_attributes: { attribute_code: string; value: string }[];
    };
  };
};
function getReportingAttributesFromOrderItem(item: OrderItem__Patched): ReportingAttributes {
  return item.extension_attributes.product.custom_attributes
    .filter(
      item =>
        item.attribute_code === 'reporting_product_category' ||
        item.attribute_code === 'reporting_product_line' ||
        item.attribute_code === 'reporting_product_type'
    )
    .reduce((obj, item) => {
      obj[item.attribute_code] = item.value;
      return obj;
    }, {});
}
