import { INITIALIZED } from 'store/constants';
import { GlobalState } from './../constants';
import { optionGet, optionFind } from 'faunctions';
import { none } from 'fp-ts/es6/Option';
import { objIsEmpty } from 'helpers/objects';

export const customerSelector = (state: GlobalState) => optionGet('customer.entity')(state).getOrElse(null);

export const customerIsLoggedInSelector = (state: GlobalState) =>
  optionGet('email')(customerSelector(state))
    .map(x => !objIsEmpty(x))
    .getOrElse(false);

export const customerEmailSelector = (state: GlobalState) => customerSelector(state)?.email || '';
export const customerFirstNameSelector = (state: GlobalState) => customerSelector(state)?.firstname || '';
export const customerLastNameSelector = (state: GlobalState) => customerSelector(state)?.lastname || '';
export const customerIdSelector = (state: GlobalState) =>
  customerSelector(state)?.id || customerSelector(state)?.entity_id || '';

export const customerAddressesSelector = (state: GlobalState) =>
  optionGet('customer.entity.addresses')(state).getOrElse([]);

export const customerCustomAttributesSelector = (state: GlobalState) =>
  optionGet('customer.entity.custom_attributes')(state).getOrElse([]);

export const customerPaymentMethodsSelector = (state: GlobalState) =>
  optionGet('customer.entity.paymentMethods')(state).getOrElse([]);

export const defaultShippingAddressSelector = (state: GlobalState) =>
  optionGet('customer.entity.default_shipping')(state)
    .map(id => optionFind(x => x.id === parseInt(id), customerAddressesSelector(state)).getOrElse(none))
    .getOrElse({});

export const defaultBillingAddressSelector = (state: GlobalState) =>
  optionGet('customer.entity.default_billing')(state)
    .map(id => optionFind(x => x.id === parseInt(id), customerAddressesSelector(state)).getOrElse(none))
    .getOrElse({});

export const storeCreditSelector = (state: GlobalState) =>
  optionGet('customer.entity')(state)
    .chain(optionGet('extension_attributes'))
    .map(b => b.customer_balance_amount)
    .getOrElse(0);

// Loading State Selectors
export const customerLoadingStateSelector = (state: GlobalState) =>
  optionGet('customer.loadingStates.customer')(state).getOrElse(INITIALIZED);
export const customerShippingAddressLoadingStateSelector = (state: GlobalState) =>
  optionGet('customer.loadingStates.shippingAddress')(state).getOrElse(INITIALIZED);
export const customerPaymentMethodLoadingSelector = (state: GlobalState) =>
  optionGet('customer.loadingStates.paymentMethods')(state).getOrElse(INITIALIZED);
