import styled from 'styled-components';

export const ProgressBarTitle = styled.div`
  color: #000000;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 22px;
  text-align: center;

  @media (max-width: 780px) {
    margin-top: 1rem;
  }
`;

export const ProgressBarContainer = styled.div`
  @media (max-width: 780px) {
    display: none;
  }
`;

export const ProgressBarStepLabel = styled.g`
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: .6875rem;
  line-height: 1rem;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;
