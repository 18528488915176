import { objIsEmpty } from '../objects';
import 'isomorphic-fetch';

// Makes an http request and transforms the reponse into JSON
export const fetchJson = async (path: string, options: object = {}) => fetch(path, options)
    .then((res) => res.json())
    .catch((e) => console.error(e));

export const HTTP_GET = 'GET';
export const HTTP_PATCH = 'PATCH';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_DELETE = 'DELETE';
export const HTTP_OPTIONS = 'OPTIONS';

// buildParamString :: {string: string} -> string
export const buildParamString = (params: { [key: string]: string }): string =>
  // @ts-ignore
  Object.entries<any>(params).reduce(
    // @ts-ignore
    (accum, [key, val], index) => `${accum}${index !== 0 ? '&' : ''}${key}=${val}`,
    objIsEmpty(params) ? '' : '?'
  );

// hostWithPathAndParams :: string -> [string] -> {string: string} -> string
export const hostWithPathAndParams =
  (host: string) => (...path: string[]) => (params: { [key: string]: string } = {}): string => `${host}/${path.join('/')}${buildParamString(params)}`;

export interface ApiRequestFailure {
  reason: string;
  statusCode?: number;
  body?: any;
}

export const isApiRequestFailure = (obj: any): obj is ApiRequestFailure => typeof obj === 'object' &&
  (obj.hasOwnProperty('reason') ||
    obj.hasOwnProperty('message') ||
    (obj.hasOwnProperty('statusCode') && obj.statusCode < 500));

// apiRequest T :: string -> string -> [string] -> ?{} -> Promise<T>
export const apiRequest =
  (url: string, includeCredentials = true) => (...path: string[]) => (method: string) => (headers: { [key: string]: string } = {}) => async <T>(
    requestData: { [key: string]: any } | undefined = undefined
  ): Promise<T> => {
    try {
      const response = await fetch(
        `${hostWithPathAndParams(url)(...path)(
          requestData && method === HTTP_GET ? requestData : {}
        )}`,
        {
          method,
          credentials: includeCredentials ? 'include' : 'omit',
          headers: {
            'Content-Type': 'application/json',
            ...headers
          },
          ...(requestData && method !== HTTP_GET
            ? { body: JSON.stringify(requestData) }
            : {})
        }
      );
      if (response.status > 299) {
        return await response.json().then((x) => {
          throw {
            statusCode: response.status,
            body: x,
            reason: 'non-200 response'
          };
        });
      }
      try {
        return response.json();
      } catch (e) {
        throw {
          statusCode: response.status,
          reason: e.message
        };
      }
    } catch (e1) {
      if (!isApiRequestFailure(e1)) {
        throw {
          reason: e1.message || 'Request failed for unknown reason'
        };
      }
      throw e1;
    }
  };
