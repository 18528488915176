import React from 'react';
import { CartLoadingStates, CartState, CartAction } from './constants';
import { LoadingState, SUCCESS, FAILURE, FETCHING, NOT_FOUND } from 'store/constants';
import { assign } from 'helpers/objects';
import { CustomerLoadableEntity } from 'store/customer/helpers';
import { CustomerLoadingStates } from '../customer/constants';
import { toast } from 'react-toastify';
import ErrorModal from '../../components/ErrorModal';
import { getInternalPaymentMethodsFromTotalSegments } from './magentoSelectors';
import { CartPaymentMethod, findCustomerPaymentMethodInCartPaymentMethods } from 'helpers/billing';
import { optionGet } from 'faunctions';

export type CartLoadableEntity =
  | 'cart'
  | 'cartItems'
  | 'shippingAddress'
  | 'paymentMethods'
  | 'shippingRates'
  | 'promoCode'
  | 'giftCard'
  | 'placeOrder'
  | 'storeCredit'

/**
 * Takes an array of loadable cart entities to be updated with the specified loadingState
 *
 * ex:
 *
 * loadingState = {
 *  cartItems: INITIALIZED,
 *  shippingAddress: INITIALIZED,
 *  shippingRates: INITIALIZED,
 * }
 *
 * updateCartLoadingStates(["cartItems", "shippingAddress"], FETCHING)(cartState)
 */
export const updateLoadingStates = (
  entities: CartLoadableEntity[] | CustomerLoadableEntity[],
  loadingState: LoadingState
) => (loadingStates: CartLoadingStates | CustomerLoadingStates) => {
  let updatedStates: CartLoadingStates;

  entities.forEach(entity => {
    updatedStates = {
      ...updatedStates,
      [entity]: loadingState
    };
  });

  return assign(loadingStates)(updatedStates);
};

export const updateCartSuccess = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates) =>
  updateLoadingStates(loadableEntities, SUCCESS)(cartLoadingStates);

export const updateCartFailure = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates) =>
  updateLoadingStates(loadableEntities, FAILURE)(cartLoadingStates);

export const updateCartNotFound = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates) =>
  updateLoadingStates(loadableEntities, NOT_FOUND)(cartLoadingStates);

export const updateCartFetching = (loadableEntities: CartLoadableEntity[]) => (cartLoadingStates: CartLoadingStates) =>
  updateLoadingStates(loadableEntities, FETCHING)(cartLoadingStates);

// React toastify helper to render custom Toast component
export const renderErrorToast = (message: string, raw?: boolean) => toast.error(<ErrorModal message={message} raw={raw} />);

/**
 * This aggregates any UI-applied CustomerPaymentMethods (paypal, email) from the previous state, with any internal
 * payment methods (gift card, store credit) we may get in an updated cart response.
 *
 * This would most likely probably go away with completion of https://artifactteam.atlassian.net/browse/EN-355
 */
export const combineCartPaymentMethods = (state: CartState, action: CartAction) => {
  const maybeCartPaymentMethods: CartPaymentMethod[] = optionGet('entity.paymentMethods')(state).getOrElse([]);
  const maybeCustomerPaymentMethod = findCustomerPaymentMethodInCartPaymentMethods(maybeCartPaymentMethods);
  const totalSegments = optionGet('payload.cart.total_segments')(action).getOrElse([]);
  const internalPaymentMethods = getInternalPaymentMethodsFromTotalSegments(totalSegments);

  if (!maybeCustomerPaymentMethod.type) {
    // Omit any empty payment methods (where type === null)
    return [...internalPaymentMethods];
  }

  return [maybeCustomerPaymentMethod, ...internalPaymentMethods];
};
