import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { apiRequest, HTTP_DELETE, HTTP_POST } from 'helpers/http';

const magenumRequest = apiRequest(process.env.GATSBY_MAGENUM_BASE_URL);

export const updatePaymentMethod = (method: any) =>
  magenumRequest('cart', 'payment-method')(HTTP_POST)()<CustomerDataCustomer>(method);

export const removeStoreCredit = (method: any) =>
  magenumRequest('cart', 'payment-method')(HTTP_DELETE)()<CustomerDataCustomer>(method);
