import { fromNullable, Option } from 'fp-ts/lib/Option';
const roundToTwoDecimals = (num: number) => (decimalPlaces: number): string =>
  parseFloat(num).toFixed(decimalPlaces);
const usdPrefix = (num: string) => `$${num}`;

export const formatPrice = (decimalPlaces: number) => (num: number) =>
  usdPrefix(roundToTwoDecimals(num)(decimalPlaces));

const matchAmount = (str: string): Array<string> | null | undefined =>
  str.match(/\$\d?\d+(?:\d*\.)?\d+/);
export const findAmountInString = (str: string): Option<string> =>
  fromNullable(matchAmount(str)).map(matches => matches[0]);