import React, { FC, ReactNode } from 'react';
import styled, { keyframes } from 'styled-components';

const skBounceDelay = keyframes`
  0%, 100% { 
    transform: scale(0);
  } 60% { 
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

const AnimateSpinner = styled.span<SpinnerProps>`
  overflow: hidden;
  text-align: center;
  transform: translate(0, 0);
  margin-top: 15px;
  margin-bottom: 20px;

  &:before,
  &:after,
  > span {
    content: "";
    ${({ size }) => `width: ${size}px;`}
    margin-right: 2px;

    ${({ size }) => `height: ${size}px;`}
    ${({ color }) => `background-color: ${color};`}

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: ${skBounceDelay} 1.2s infinite ease both;
    animation: ${skBounceDelay} 1.2s infinite ease both;
  }

  &:before {
    -webkit-animation-delay: -0.48s;
    animation-delay: -0.48s;
  }

  &:after {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
    margin-right: 0;
  }

  > span {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
`;

export interface SpinnerProps {
  size?: number;
  color?: string;
}

export interface SmallLoaderProps extends SpinnerProps {
  children?: ReactNode;
  isLoading?: boolean;
}

export const SmallLoader: FC<SmallLoaderProps> = ({
  color = 'grey',
  size = 10,
  children,
  isLoading
}) => {
  if (isLoading) {
    return (
      <AnimateSpinner color={color} size={size}>
        <span />
      </AnimateSpinner>
    );
  }

  if (children) {
    return <>{children}</>;
  }
};
