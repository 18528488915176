import { isLocal, isNotServerSideRendering } from 'helpers/env';
import React, { useMemo, useState } from 'react';
import Block, { blockGraphQL } from './Block';
import { graphql, useStaticQuery } from 'gatsby';

export interface EsiBlockProps {
  id: string
  render: (content: string) => React.ReactElement
  esiTag: string
  innerHtmlContainerId?: string
}

const getExistingHtml = (selector, property) => {
  const element = global.document.querySelector(selector);
  return element ? element[property] : null;
};

export const EsiBlock = ({ id, render, innerHtmlContainerId, esiTag }: EsiBlockProps) => {
  const [preLoadedHtml, setPreLoadedHtml] = useState(null);

  const {
    allBlock: { edges: results }
  } = useStaticQuery(graphql`
    query {
      allBlock {
        edges {
          node {
            content
            identifier
            active
          }
        }
      }
    }
  `);

  const matchedBlock = results.find(x => x.node.identifier == id);
  const isValidBlock = matchedBlock && matchedBlock.node && matchedBlock.node.active;

  const _isLocal = isLocal();

  if (isNotServerSideRendering() && !preLoadedHtml) {
    const existingHtml = getExistingHtml(`#${innerHtmlContainerId}`, 'innerHTML');
    if (existingHtml) {
      setPreLoadedHtml(existingHtml);
    }
  }

  return useMemo(() => {
    if (!isValidBlock) {
      return null;
    }

    if (preLoadedHtml) {
      return render(preLoadedHtml);
    }

    if (_isLocal) {
      return <Block id={id} render={render}/>;
    }

    return render(esiTag);
  }, [_isLocal, esiTag, render, id, preLoadedHtml, isValidBlock]);
};
