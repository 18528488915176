import React, { Fragment, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled, { css } from 'styled-components';
import { StyledH1 } from '../typography';

const ReviewsSectionWrapper = styled.div`
  background-color: #efefef;
  padding-top: 1em;
`;

type YotpoProductProps = {
  className?: string;
  productSku: string;
  productPrice?: string;
  productUrl?: string;
  productName?: string;
  productImage?: string;
  apiKey: string;
};

const Yotpo = ({
  className = '',
  productUrl,
  productSku,
  productPrice,
  productName,
  productImage,
  apiKey
}: YotpoProductProps): JSX.Element => {
  useEffect(() => {
    // @ts-ignore: Because yopto does not exists on global window type
    const { yotpo } = window;
    if (yotpo) {
      yotpo.refreshWidgets();
    }
  }, []);
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          async
          src={`//staticw2.yotpo.com/${apiKey}/widget.js`}
        />
      </Helmet>
      <ReviewsSectionWrapper className={className}>
        <StyledH1 secondary alignment="center">
          What Our Customers are Saying
        </StyledH1>
        <div
          id="y-embedded-widget"
          className="yotpo yotpo-main-widget"
          data-appkey={apiKey}
          data-product-id={productSku}
          data-price={productPrice}
          data-name={productName}
          data-url={productUrl}
          data-image-url={productImage}
        />
      </ReviewsSectionWrapper>
    </>
  );
};

export default Yotpo;
