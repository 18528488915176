import React from 'react';

import Expand from './icons/expand.svg';
import Close from './icons/close.svg';
import Info from './icons/info.svg';
import Add from './icons/add.svg';
import CheckedToggle from './icons/checkedToggle.svg';
import UncheckedToggle from './icons/uncheckedToggle.svg';
import Trashcan from './icons/trashcan.svg';
import CaretLeft from './icons/caretLeft.svg';

interface IconObject {
  svg: string;
  alt: string;
}

interface IconProps {
  icon: string;
}

const iconMeta = (svg: string, alt: string): IconObject => ({
  svg,
  alt
});

const iconObject = (icon: string): IconObject => {
  switch (icon) {
    case 'expand':
      return iconMeta(Expand, 'Expand Image');
    case 'close':
      return iconMeta(Close, 'Close');
    case 'info':
      return iconMeta(Info, 'Info');
    case 'add':
      return iconMeta(Add, 'Add');
    case 'checkedToggle':
      return iconMeta(CheckedToggle, 'CheckedToggle');
    case 'uncheckedToggle':
      return iconMeta(UncheckedToggle, 'UncheckedToggle');
    case 'trashcan':
      return iconMeta(Trashcan, 'Trashcan');
    case 'caretLeft':
      return iconMeta(CaretLeft, 'CaretLeft');
    default:
      return;
  }
};

export const Icon = ({ icon }: IconProps): JSX.Element => {
  const { svg, alt } = iconObject(icon);

  return <img src={svg} alt={alt} />;
};
