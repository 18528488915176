import styled from "styled-components";

export const StyledTooltipContainer = styled.div`
  background: white;
  border: 1px solid #e3e3e3;
  padding: 10px;
  min-height: 200px;
  width: 206px;
  z-index: 10000 !important;
`;

export const StyledImg = styled.img`
  width: 100%;
  height: auto;
  font-family: Lato, "helvetica neue", "helvetica", "arial", sans-serif;
`;

export const StyledTooltipLabel = styled.p`
  font-size: 11px;
  margin-top: 12px;
  letter-spacing: 1px;
  display: inline-block;
`;

export const StyledTooltipDescription = styled.p`
  margin: .5em 0;
  line-height: 1.125rem;
  font-size: .75rem;
  font-family: Lato, "helvetica neue", "helvetica", "arial", sans-serif;
`;

export const StyledTooltipPrice = styled.p`
  margin-top: 1em;
  font-size: 14px;
  color: #747474;
  margin-bottom: .5em;
`;