import React from 'react';
import { ModalTitle } from 'components/Projects/styled';
import { ProjectDescription, StyledProjectInfoModal } from './styled';

export interface ProjectInfoModalProps {
  projectInfo: string;
}

export const ProjectInfoModal = (props: ProjectInfoModalProps): JSX.Element => {
  const { projectInfo } = props;

  return (
    <StyledProjectInfoModal>
      <ModalTitle secondary alignment="center">
        Project Info
      </ModalTitle>
      <ProjectDescription>{projectInfo}</ProjectDescription>
    </StyledProjectInfoModal>
  );
};
