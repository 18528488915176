import styled, { keyframes } from 'styled-components';
import { disabledInputCss, OptionLabel } from '../shared.styled';

export const selectAnimation = keyframes`
  0%   {box-shadow: none}
  100% {box-shadow: 0px 2px 3px black}
`;

export const SizeSwatch = styled.label`
  position: relative;
  display: flex;
  box-sizing: content-box;
  align-items: center;
  line-height: 20px;
  letter-spacing: 1px;
  margin: 0 8px 8px 0;
  padding-top: 5px;
  padding-bottom: 0.6875rem;
  border-bottom: 2px solid transparent;
  flex-direction: column;
  width: 68px;

  svg {
    margin-left: auto;
    margin-right: auto;
  }

  ${OptionLabel} {
    font-size: 14px;
    line-height: 21px;
  }

  &:not(.disabled):focus-within {
    animation: 0.04s ${selectAnimation};
    animation-duration: 0.1s;
    animation-direction: alternate;
    animation-iteration-count: 2;
  }

  ${disabledInputCss}
`;

export const DisabledIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  height: 100%;
  width: 100%;
`;

export const IconsContainer = styled.div`
  position: relative;
`;
