import styled, { keyframes } from 'styled-components';
import { disabledInputCss } from '../shared.styled';

export const selectAnimation = keyframes`
  0%   {box-shadow: none}
  100% {box-shadow: 0px 2px 3px black}
`;

export const StyledSwatchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0px 8px 8px 0px;
`;

export const StyledSwatch = styled.label`
  display: flex;
  box-sizing: content-box;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid transparent;

  &:not(.disabled):focus-within {
    animation: 0.04s ${selectAnimation};
    animation-duration: 0.1s;
    animation-direction: alternate;
    animation-iteration-count: 2;
  }

  ${disabledInputCss}
`;

export const SwatchImg = styled.img<{ isWhite: boolean }>`
  border-radius: 50%;
  border: ${props => (props.isWhite ? '1px solid #ccc !important' : 'none')};
  height: 34px;
  width: 34px;
`;

export const DisabledIconContainer = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
`;
