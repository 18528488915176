import { fromNullable } from 'fp-ts/es6/Option';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import { AddressFormValues } from 'helpers/forms';
import { assign } from '../objects';
import { optionGet } from 'faunctions';
import { isEqual } from 'helpers/conditionals';
import QuoteDataShippingMethod from 'mage-swagfaces/quote/QuoteDataShippingMethod';
import QuoteDataCartItem from 'au-js-sdk/lib/api/models/magento/quote/QuoteDataCartItem';
import { STANDARD_SHIPPING_ALTERNATE_METHOD_NAME, STANDARD_SHIPPING_METHOD_NAME } from 'store/ui/constants';
import dayjs, { Dayjs } from 'dayjs';
import dayjsBusinessTime from 'dayjs-business-time';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { DateFormat } from '../../types/DateFormat';
import fedHolidays from '@18f/us-federal-holidays';
import { DEFAULT_TIMEZONE } from 'constants/time';

dayjs.extend(advancedFormat);
dayjs.extend(dayjsBusinessTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const holidays = fedHolidays.allForYear().map(holiday => holiday.dateString);
dayjs.setHolidays(holidays);
dayjs.tz.setDefault(DEFAULT_TIMEZONE);

export const buildShippingMethodPayload = (address: CustomerDataAddress, rate?: QuoteDataShippingMethod) => {
  const shippingAddressId = optionGet('customer_address_id')(address)
    .alt(optionGet('id')(address))
    .getOrElse('')
    .toString();

  return {
    ...(shippingAddressId && { shippingAddressId }),
    ...(rate && { shippingCarrierCode: rate.carrier_code }),
    ...(rate && { shippingMethodCode: rate.method_code })
  };
};

export const buildAddressVerificationPayload = (values: AddressFormValues): { address: CustomerDataAddress } => {
  const address = fromNullable(values)
    .map(x =>
      Object.keys(x).reduce((acc, curr) => {
        const updateAcc = assign(acc);

        if (typeof values[curr] === 'object') {
          if (curr === 'country') {
            return updateAcc({ country_id: values[curr].value });
          }
          if (curr === 'region') {
            return updateAcc({ region_id: values[curr].value });
          }
          return updateAcc({ [curr]: values[curr].value });
        }

        switch (curr) {
          case 'street1':
            return updateAcc({ street: [values.street1] });
          case 'street2':
            if (values['street2'] !== '') {
              // Only push to street[] if street2 field is populated
              return updateAcc({ street: [...acc['street'], values.street2] });
            }
            return acc;
          case 'name':
            return updateAcc({ firstname: values.name });
          case 'country':
            return updateAcc({ country_id: values.value });
          case 'region':
            return updateAcc({ region_id: values.value });
          case 'postalCode':
            return updateAcc({ postcode: values.postalCode });
          case 'phone':
            return updateAcc({ telephone: values.phone });
          case 'line1':
            return updateAcc({ street: [values.line1] });
          case 'line2':
            if (values['line2'] !== '') {
              return updateAcc({ street: [...acc['street'], values.line2] });
            }
            return acc;
          case 'line3':
            if (values['line3'] !== '') {
              return updateAcc({ street: [...acc['street'], values.line3] });
            }
            return acc;
          default:
            return updateAcc({ [curr]: values[curr] });
        }
      }, {})
    )
    .getOrElse({
      firstname: '',
      lastname: '',
      country_id: '',
      street: [],
      region_id: '',
      postcode: '',
      city: ''
    });

  return { address: address as CustomerDataAddress };
};

export const renderRegionLabel = (countryCode: string) => {
  const countryCodeIs = isEqual(countryCode);
  if (countryCodeIs('US')) {
    return 'State*';
  }
  if (countryCodeIs('CA')) {
    return 'Province*';
  }
  return 'State/Province*';
};

export const getEstimatedArrivalDateRange = (minShipTime: number, maxShipTime: number, sla: number): string => {
  // Be sure to use default timezone (PST) for estimated arrival dates
  const minDate = dayjs.tz().addBusinessDays(minShipTime + sla);
  const maxDate = dayjs.tz().addBusinessDays(maxShipTime + sla);

  return formatArrivalDateRange(minDate, maxDate);
};

export const formatArrivalDateRange = (minDate: Dayjs, maxDate: Dayjs): string => {
  const format = minDate.year() === maxDate.year()
    ? DateFormat.ARRIVAL_DATE_SHORT
    : DateFormat.ARRIVAL_DATE_LONG;

  const formattedMin = minDate.format(format);
  const formattedMax = maxDate.format(format);

  return formattedMin === formattedMax
    ? formattedMin
    : `${formattedMin} - ${formattedMax}`;
};

const INELIGIBLE_CATEGORIES = ['frames'];
const INELIGIBLE_PRODUCTS = [
  'modern-wall-calendar',
  'modern-wall-calendar-refill',
  'acrylic-wall-calendar',
  'album-design-services',
  'email-giftcard'
];

const isMWTorPGF = sku => 'pressed-glass-frame' === sku || 'modern-wall-tile' === sku;

export const isEligibleForStandardShipping = (cartItems: QuoteDataCartItem[]): boolean => {
  const ineligibleCartItems = cartItems.find(cartItem => {
    return isProductIneligibleForStandardShipping(cartItem.sku, cartItem.reporting_product_category);
  });
  if (ineligibleCartItems) {
    return false;
  }
  return true;
};
export const isProductIneligibleForStandardShipping = (sku: string, reporting_product_category: string): boolean => {
  if (
    (INELIGIBLE_CATEGORIES.includes(reporting_product_category) && !isMWTorPGF(sku)) ||
    INELIGIBLE_PRODUCTS.includes(sku)
  ) {
    return true;
  }
  return false;
};

export const hasAlternateShippingRate = (
  shippingRates: QuoteDataShippingMethod[]
): boolean => {
  return shippingRates.some(
    rate => rate.method_title === STANDARD_SHIPPING_ALTERNATE_METHOD_NAME
  );
};

export const filterShippingRates = (
  shippingRates: QuoteDataShippingMethod[],
  displayShippingTest: boolean
): QuoteDataShippingMethod[] => {
  return shippingRates.filter(shippingRate => {
    const isStandardShipping = shippingRate.method_title === STANDARD_SHIPPING_METHOD_NAME;
    const isAlternateShipping = shippingRate.method_title === STANDARD_SHIPPING_ALTERNATE_METHOD_NAME;

    if (displayShippingTest) {
      return isAlternateShipping || (!isStandardShipping && !isAlternateShipping);
    } else {
      return isStandardShipping || (!isStandardShipping && !isAlternateShipping);
    }
  });
};

export const getFilteredShippingRates = (
  shippingRates: QuoteDataShippingMethod[],
  displayShippingTest: boolean
): QuoteDataShippingMethod[] => {
  if (!hasAlternateShippingRate(shippingRates)) {
    return shippingRates;
  }

  return filterShippingRates(shippingRates, displayShippingTest);
};

export const removeAlternateFromShippingMethodTitle = (methodTitle: string) =>
  methodTitle.replace(/\bAlternate\b/, '').trim();
