// @flow

// Breakpoint widths
export const __BREAKPOINT_TINY__ = 480;
export const __BREAKPOINT_SMALL__ = 780;
export const __BREAKPOINT_MEDIUM__ = 1024;
export const __BREAKPOINT_LARGE__ = 1600;

// Breakpoint sizes
export const SCREEN_SIZE_TINY = 'SCREEN_SIZE_TINY';
export const SCREEN_SIZE_SMALL = 'SCREEN_SIZE_SMALL';
export const SCREEN_SIZE_MEDIUM = 'SCREEN_SIZE_MEDIUM';
export const SCREEN_SIZE_LARGE = 'SCREEN_SIZE_LARGE';

export const SCREEN_SIZES = {
  SCREEN_SIZE_TINY,
  SCREEN_SIZE_SMALL,
  SCREEN_SIZE_MEDIUM,
  SCREEN_SIZE_LARGE
};
