import React from 'react';
import { StyledRadioInput } from '../styled';
import { SwatchProps } from '../index';
import {
  StyledTextSwatch,
  TextSwatchContainer,
  OptionLabel,
  SoldOutLabel,
  SoldOutContainer,
  SoldOutIcon
} from './styled';

export const TextSwatch = ({
  id,
  name,
  title = '',
  label,
  checked,
  disabled,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  return (
    <TextSwatchContainer>
      <StyledTextSwatch
        id={`option-value-label-${title}`}
        className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        htmlFor={`option-value-${id}`}
        onClick={disabled ? undefined : onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        aria-checked={checked}
      >
        <StyledRadioInput
          id={`option-value-${id}`}
          type="radio"
          name={name}
          value={id}
          checked={checked}
          disabled={false}
          onChange={disabled ? undefined : onChange}
          onFocus={disabled ? undefined : onClick}
          ref={ref}
        />
        <OptionLabel disabled={disabled} paperType={id.includes('paper_type')}>{label}</OptionLabel>
        {disabled && (
          <SoldOutContainer>
            <SoldOutIcon icon="close" />
            <SoldOutLabel>Sold Out</SoldOutLabel>
          </SoldOutContainer>
        )}
      </StyledTextSwatch>
    </TextSwatchContainer>
  );
};

export default TextSwatch;
