import React from 'react';
import CCIcon, { CCCardType } from 'components/Billing/CCIcon';
import styled from 'styled-components';

export const AmazonPayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 4rem;
`;

const AmazonPayMethodBlock = () => {
  return (
    <AmazonPayWrapper>
      <CCIcon type={CCCardType.AMAZON_PAY}/>
    </AmazonPayWrapper>
  );
};

export default AmazonPayMethodBlock;
