import { createAction } from 'redux-actions';

import { APPLY_AFTERPAY_CHECKOUT_TYPE } from './constants';
import { APPLY_AFTERPAY_REDIRECT_URL } from './constants';
import { APPLY_AFTERPAY_PAYMENT_METHOD } from './constants';
import { APPLY_AFTERPAY_SESSION_ID } from './constants';
import { APPLY_AFTERPAY_CHECKSUM } from './constants';

import MagenumPaymentMethodPostBody  from 'types-magenum/quote/PaymentMethodPostBody';

export const applyAfterpayPaymentMethod = (paymentMethod: MagenumPaymentMethodPostBody) =>
  createAction(APPLY_AFTERPAY_PAYMENT_METHOD)({ paymentMethod });

export const applyAfterpayRedirectUrl = (afterpayToken: string) =>
  createAction(APPLY_AFTERPAY_REDIRECT_URL)(afterpayToken);

export const applyAfterpaySessionId = (afterpaySessionId: string) =>
  createAction(APPLY_AFTERPAY_SESSION_ID)(afterpaySessionId);

export const applyAfterpayCheckoutType = (afterpayCheckoutType: string) =>
  createAction(APPLY_AFTERPAY_CHECKOUT_TYPE)(afterpayCheckoutType);

export const applyAfterpayChecksum = (afterpayChecksum: string) =>
  createAction(APPLY_AFTERPAY_CHECKSUM)(afterpayChecksum);
