import { StyledH2 } from 'component-library';
import styled, { css } from 'styled-components';

export const ModalTitle = styled(StyledH2)`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.25px;
  color: #333;
  margin-top: 10px;
  margin-bottom: 16px;
`;

export const CancelButton = styled.button`
  width: 160px;
  height: 48px;
  background: #fff0;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #333;
  opacity: 1;
  border-color: #fff0;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
`;

export const SubmitButton = styled.button`
  width: 160px;
  height: 48px;
  background: #333;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #fff;
  opacity: 1;
  border-color: #333;
  border-radius: 4px;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
`;

export const ProjectThumbnail = styled.div<{
  isLoading: boolean;
  url: string;
}>`
  ${({ url }) =>
    css`
      background: url("${url}");
    `}

  ${({ isLoading }) =>
    isLoading &&
    css`
      background-repeat: repeat;
    `}

  ${({ isLoading }) =>
    !isLoading &&
    css`
      background-color: #f0f0f0;
      background-repeat: no-repeat;
    `}

  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
`;