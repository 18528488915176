import { CheckoutStepCompletedEvent } from '../analyticsWrapper';
import { analyticsTrackCheckoutStepCompleted } from 'store/cart/actions';
import { createCheckoutStepEventProperties } from '../tracking';

export function mapCheckoutStepShippingCompletedActionToEvent(
  action: ReturnType<typeof analyticsTrackCheckoutStepCompleted>
): CheckoutStepCompletedEvent {
  const { step, checkoutId, stepAutoCompleted, cart } = action.payload;
  return {
    type: 'checkoutStepCompleted',
    data: createCheckoutStepEventProperties(step, checkoutId, stepAutoCompleted, cart)
  };
}
