import React from 'react';
import styled from 'styled-components';
import { StyledP } from '../typography';
import { Badge } from '../Badge';
import { mediaQuery } from '../Grid';

interface ProductBlockProps {
  imgUrl: string;
  name: string;
  details: string;
  href: string;
  badge?: string;
}

const ProductBlockWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
`;

const ProductImage = styled.img`
  width: 100%;
  max-width: 236px;
`;

const ProductBadge = styled.div`
  Z-index: 1;
  position: absolute;
  padding: 12px;
  ${mediaQuery.medium`
    padding: 16px;
 `};
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 15px 0;

  ${StyledP} {
    margin: 2px 0;
  }
`;

const ProductBlock = ({ href, imgUrl, name, details, badge }: ProductBlockProps) => (
  <ProductBlockWrapper>
    { badge && (
    <ProductBadge>
      <Badge>{badge}</Badge>
    </ProductBadge>
    )}
    <a href={href}>
      <ProductImage src={imgUrl} alt={`${name}-img`} data-testid="productImage" />
    </a>
    <ProductDetails>
      <a href={href}>
        <StyledP secondary>{name}</StyledP>
      </a>
      <StyledP secondary>{details}</StyledP>
    </ProductDetails>
  </ProductBlockWrapper>
);

export default ProductBlock;
