import React, { FC, ReactNode } from 'react';
import { MediaContext } from './Context';
import { Configuration, MediaType, UrlsType } from '../types/event';

export interface MediaProviderProps {
  children: ReactNode
  configuration?: Configuration
  media?: MediaType
  urls?: UrlsType
}

export const MediaProvider: FC<MediaProviderProps> = ({ children, configuration, media, urls }) => {
  const initialValue = {
    configuration,
    media,
    urls
  };

  return <MediaContext.Provider value={initialValue}>{children}</MediaContext.Provider>;
};
