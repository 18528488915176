import styled from 'styled-components';
import { PrimaryButton } from "../Button";

export const MobileAvailability = styled.div`
    box-sizing: border-box;
    font-size: 16px;
    line-height: 1.7;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px 4px;
    text-align: center;
    max-width: 320px;
`;

export const MobileAvailabilityHeadline = styled.h4`
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 18px;
    margin-top: 0;
`;

export const MobileAvailabilityIcon = styled.div`
    margin-bottom: 16px;
`;

export const MobileAvailabilityIconAppStore = styled.img`
    width: 75%;
`;

export const MobileAvailabilityOutputWrapper = styled.div`
    background-color: #f2f2f2;
    text-align: center;
`;

export const AddToCartButton = styled(PrimaryButton) `
    background-color: #333 !important;
    &:focus {
        background-color: #000 !important;
    }
`;
