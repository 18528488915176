import { CustomerLoadingStates } from './constants';
import { SUCCESS, FAILURE, FETCHING } from 'store/constants';
import { updateLoadingStates } from 'store/cart/helpers';

export type CustomerLoadableEntity = 'shippingAddress' | 'customer' | 'paymentMethods'

export const updateCustomerSuccess = (loadableEntities: CustomerLoadableEntity[]) => (
  customerLoadingStates: CustomerLoadingStates
) => updateLoadingStates(loadableEntities, SUCCESS)(customerLoadingStates);

export const updateCustomerFailure = (loadableEntities: CustomerLoadableEntity[]) => (
  customerLoadingStates: CustomerLoadingStates
) => updateLoadingStates(loadableEntities, FAILURE)(customerLoadingStates);

export const updateCustomerFetching = (loadableEntities: CustomerLoadableEntity[]) => (
  customerLoadingStates: CustomerLoadingStates
) => updateLoadingStates(loadableEntities, FETCHING)(customerLoadingStates);
