import { analyticsTrackCheckoutError } from 'store/cart/actions';
import { CheckoutErrorEvent } from '../analyticsWrapper';

export function mapCheckoutErrorActionToEvent(
  action: ReturnType<typeof analyticsTrackCheckoutError>): CheckoutErrorEvent {
  const { error, errorMessage, cartId } = action.payload;
  return {
    type: 'checkoutError',
    data: {
      error,
      error_message: errorMessage,
      ...(cartId && { cart_id: cartId })
    }
  };
}