import styled, { keyframes } from 'styled-components';
import { disabledInputCss } from '../shared.styled';

const selectAnimation = keyframes`
  0%   {boxshadow: none}
  100% {box-shadow: 0px 2px 3px black}
`;

export const TextSwatchContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0px 8px 8px 0px;
`;

export const StyledTextSwatch = styled.label`
  display: flex !important;
  position: relative;
  flex-direction: column;
  box-sizing: content-box;
  justify-content: center;
  background-color: #eee;
  border: 1px solid transparent;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  height: 2.5rem;
  align-items: center;
  width: 100px;
  height: 40px;
  font-size: 14px;
  letter-spacing: 0.01em;

  ${disabledInputCss}

  &:not(.disabled):focus-within {
    animation: 0.04s ${selectAnimation};
    animation-duration: 0.1s;
    animation-direction: alternate;
    animation-iteration-count: 2;
  }
`;
