import React from 'react';
import { SwatchProps } from '../../types';
import { StyledRadioInput } from '../index.styled';
import { SoldOutLabel, SoldOutLabelContainer } from '../shared.styled';
import { StyledContainer, StyledImage, StyledLabel } from './index.styled';

const FoilColorSwatch = ({
  id,
  name,
  label,
  mediaUrl,
  backgroundUrl,
  checked,
  disabled,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  return (
    <>
      <StyledContainer className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}>
        <StyledLabel
          id={`option-value-${id}`}
          htmlFor={`option-${id}`}
          aria-selected={checked}
          aria-label={label}
          onClick={disabled ? undefined : onClick}
          onMouseOver={disabled ? undefined : onMouseOver}
          onMouseOut={disabled ? undefined : onMouseOut}
          tabIndex={-1}
        >
          <StyledRadioInput
            id={`option-${id}`}
            key={`option-${id}`}
            type="radio"
            name={name}
            value={id}
            checked={checked}
            disabled={disabled}
            onChange={disabled ? undefined : onChange}
            onFocus={disabled ? undefined : onClick}
            tabIndex={0}
            role={'radio'}
            ref={ref}
          />
          <StyledImage backgroundUrl={backgroundUrl} alt={label} src={mediaUrl} />
        </StyledLabel>
        {disabled && (
          <SoldOutLabelContainer>
            <SoldOutLabel>Sold Out</SoldOutLabel>
          </SoldOutLabelContainer>
        )}
      </StyledContainer>
    </>
  );
};

export default FoilColorSwatch;
