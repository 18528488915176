import DirectoryDataCountryInformation from 'mage-swagfaces/directory/DirectoryDataCountryInformation';
import DirectoryDataRegionInformation from 'mage-swagfaces/directory/DirectoryDataRegionInformation';
import { optionGet, optionFind } from 'faunctions';
import { immutableUnshift } from 'helpers/arrays';
import { findRegionById, findRegionByCode, findRegionByName } from 'helpers/static/index';
import { findCountryById } from 'helpers/static/index';
import { isEqual } from 'helpers/conditionals';
import QuoteDataAddress from 'mage-swagfaces/quote/QuoteDataAddress';
import { fromNullable } from 'fp-ts/es6/Option';
import { isAddressData } from 'au-js-sdk/lib/models/Address';

export interface AddressFormValues {
  firstname: string;
  lastname: string;
  country_id: { label: string; value: string };
  street1: string;
  street2: string;
  city: string;
  postcode: string;
  region_id: { label: string; value: string };
  telephone: string;
}

export interface AddressContactFormValues {
  name: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  postalCode: string;
  region?: string;
  country: string;
  phone?: string;
  groupIds: string[];
  markedAsDeleted?: boolean;
}

// Map countries/regions response into React Select options
export const mapCountriesToOptions = (countries: DirectoryDataCountryInformation[]) =>
  countries.map(r => ({
    label: r.full_name_locale,
    value: r.two_letter_abbreviation
  }));

export const mapRegionsToOptions = (regions: DirectoryDataRegionInformation[]) =>
  regions.map(r => ({ label: r.name, value: r.id }));

export const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const postcodeRegExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const requireAllFormValidator = values => {
  const errors = {};

  Object.entries(values).map(([key, value]) => {
    if (!value && value !== false) {
      errors[key] = 'This field is required';
    }
  });

  return errors;
};

export const requireSomeFormValidator = (values, ignoreFields) => {
  const errors = {};

  Object.entries(values).map(([key, value]) => {
    if (ignoreFields.includes(key)) {
      return;
    }

    if (!value && value !== false) {
      errors[key] = 'This field is required';
    }
  });

  return errors;
};

export const addressFormValidator = (values: AddressFormValues) => {
  const {
    firstname,
    lastname,
    country_id: { value: country_id },
    street1,
    city,
    region_id,
    postcode,
    telephone
  } = values;

  /* A region_id will either come as a dropdown option ({ label: "", value: ""}) or a string
     We'll check if region_id has a nested value. If undefined, we'll fall back to what is presumably the string */
  const region = region_id?.label ? region_id.value : region_id;

  const postCodeValidation = (postcode: string, countryId: string) => {
    if (isEqual(countryId)('US') && !postcodeRegExp.test(postcode)) {
      return {
        postcode: '(Enter a valid zipcode)'
      };
    }
    return !postcode && { postcode: '(Required)' };
  };

  return {
    ...(!firstname && { firstname: '(Required)' }),
    //...(!lastname && { lastname: '(Required)' }),
    ...(!country_id && { country_id: '(Required)' }),
    ...(!street1 && { street1: '(Required)' }),
    ...(!city && { city: '(Required)' }),
    ...(!region && { region_id: '(Required)' }),
    ...postCodeValidation(postcode, country_id)
    //...(!phoneRegExp.test(telephone) && {
    //  telephone: '(Please enter a valid phone number)'
    //})
  };
};

interface FormLabel {
  label: string;
  value: string;
}

export interface FormAddress {
  country_id: FormLabel;
  region_id: FormLabel;
  firstname: string;
  lastname: string;
  street1: string;
  street2: string;
  city: string;
  postcode: string;
  telephone: string;
  groupIds?: Array<string>;
}

export interface AddressData {
  name: string;
  line1: string;
  line2?: string;
  line3?: string;
  city: string;
  postalCode: string;
  region?: string;
  country: string;
  phone?: string;
  groupIds: string[];
  markedAsDeleted?: boolean;
}

export interface DropdownValue {
  label: string;
  value: string;
}
export const getCountryIdByName = (
  countryName: string,
  countries: DirectoryDataCountryInformation[]
): DropdownValue => {
  if (countryName === '') {
    return { label: 'United States', value: 'US' };
  }
  return fromNullable(countries.find(c => c.full_name_locale === countryName))
    .map(_country => ({ label: _country.full_name_locale, value: _country.two_letter_abbreviation }))
    .getOrElse({ label: 'United States', value: 'US' });
};

export const getRegionIdByName = (
  countryLabel: DropdownValue,
  regionCode: string,
  countries: DirectoryDataCountryInformation[]
): DropdownValue => {
  const country = findCountryById(countryLabel.value)(countries);
  if (country.available_regions.length) {
    let region = findRegionByCode(regionCode)(country);

    // Fall back to name
    if (!region.id) {
      region = findRegionByName(regionCode)(country);
    }
    return {
      label: region.name,
      value: region.id
    };
  }
  return {
    label: '',
    value: ''
  };
};
export const initialFormValues = (
  addr?: QuoteDataAddress,
  countries?: DirectoryDataCountryInformation[]
): FormAddress => {
  if (addr) {
    // Build country option
    const countryId = optionGet('country_id')(addr).getOrElse('');
    const country_id = fromNullable(countries.find(c => c.two_letter_abbreviation === countryId))
      .map(_country => ({ label: _country.full_name_locale, value: _country.two_letter_abbreviation }))
      .getOrElse({ label: 'United States', value: 'US' });

    // Build region option
    const country = findCountryById(country_id.value)(countries);
    const region_id = country.available_regions.length // Only build an option if we have a dropdown of regions
      ? optionGet('region_id')(addr)
          .map((id: number) => findRegionById(id.toString())(country))
          .map((r: DirectoryDataRegionInformation) => ({
            label: r.name,
            value: r.id
          }))
          .getOrElse({ label: '', value: '' })
      : optionGet('region.region')(addr).getOrElse(''); // Otherwise return the raw region string

    // When editing an existing customer address, we need to send the address id through to update the correct address
    const {
      id = null,
      customer_address_id = null,
      firstname = '',
      lastname = '',
      street = [],
      city = '',
      postcode = '',
      telephone = ''
    } = addr;
    const [street1 = '', street2 = ''] = street;

    /* When editing an applied shipping address from the shipping page, we're editing a QuoteDataAddress which
      contains both id (QuoteAddress id) and customer_address_id (CustomerAddress id). This ensures we include
      the correct CustomerAddress id  */
    const addressId = customer_address_id || id;

    return {
      ...(addressId && { id: addressId }),
      firstname: firstname ? firstname : '',
      lastname,
      country_id,
      street1: street1 ? street1 : '',
      street2: street2 ? street2 : '',
      city,
      postcode: postcode ? postcode : '',
      region_id: region_id ? region_id : { label: '', value: '' },
      telephone: telephone ? telephone : ''
    };
  }

  return {
    firstname: '',
    lastname: '',
    country_id: { label: 'United States', value: 'US' },
    street1: '',
    street2: '',
    city: '',
    postcode: '',
    region_id: { label: '', value: '' },
    telephone: ''
  };
};

export const initialContactFormValues = (
  addr?: AddressData,
  countries?: DirectoryDataCountryInformation[]
): FormAddress => {
  if (addr) {
    const countryId = getCountryIdByName(addr.country, countries);
    const regionId = getRegionIdByName(countryId, addr.region, countries);
    return {
      firstname: addr.name ?? '',
      lastname: '',
      street1: addr.line1 ?? '',
      street2: addr.line2 ?? '',
      city: addr.city ?? '',
      postcode: addr.postalCode ?? '',
      region_id: regionId,
      country_id: countryId,
      telephone: addr.phone ?? '',
      groupIds: addr.groupIds ?? []
    };
  }
  return {
    firstname: '',
    lastname: '',
    street1: '',
    street2: '',
    city: '',
    postcode: '',
    region_id: { label: '', value: '' },
    country_id: { label: '', value: '' },
    telephone: '',
    groupIds: []
  };
};

export const sortCountryList = (countries: DirectoryDataCountryInformation[]) => {
  const USA = countries.filter(x => x.two_letter_abbreviation === 'US')[0];
  const CAN = countries.filter(x => x.two_letter_abbreviation === 'CA')[0];

  const withoutUSACAN = countries
    // Map response into an array of country names
    .map(x => x.full_name_locale)
    // Sort country names alphabetically
    .sort()
    // Map sorted array of strings back into country objects
    .map(x => optionFind(y => y.full_name_locale === x, countries).getOrElse(null))
    // Remove USA and CAN
    .filter(x => x.two_letter_abbreviation !== 'US' && x.two_letter_abbreviation !== 'CA');

  return fromNullable(withoutUSACAN)
    .map(immutableUnshift(CAN)) // Add Canada,
    .map(immutableUnshift(USA)) // And US to beginning of list
    .getOrElse([]);
};

/** Verifies that all required address form values are populated */
export const isAddressFormPopulated: (values: AddressFormValues) => boolean = (values: AddressFormValues) => {
  let isPopulated = true;
  Object.keys(values).forEach(x => {
    if ((x !== 'street2')) {
      if (values[x] === '') {
        isPopulated = false;
      } else if (values[x].value === '') {
        isPopulated = false;
      }
    }
  });
  return isPopulated;
};

export const isAbbreviatedFormPopulated: (values: AddressFormValues) => boolean = (values: AddressFormValues) => {
  let isPopulated = true;

  Object.keys(values).forEach(x => {
    if (x !== 'street2' && x !== 'lastname' && x !== 'telephone') {
      if (values[x] === '') {
        isPopulated = false;
      } else if (values[x]?.value === '') {
        isPopulated = false;
      }
    }
  });
  return isPopulated;
};
