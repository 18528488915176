import styled from 'styled-components';
import React from 'react';
import Icon from '../icons';
import { GenericOverlay } from '../GenericOverlay';
import { CardWithShadow } from '../Card';

export const PopupText = styled.p`
  font-size: 16px;
  margin-bottom: 0em;
`;

export const Wrapper = styled(GenericOverlay)`
  width: 100vw;
  bottom: 0;
  position: fixed;
  padding-top: .6rem;
  color: #434343;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 1.37rem;
  line-height: 2rem;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-box-shadow: 0 0 10px 6px rgba(0,0,0,.06);
  box-shadow: 0 0 10px 6px rgba(0,0,0,.06);
  padding: 2.5rem;
  pointer-events: auto;
  max-height: 100vh;
  overflow-y: auto;
  z-index: 200;
  min-height: 100%;
  ${CardWithShadow} {
    max-height: 100%;
    width: 100%;
    top: inherit;
    margin-top: 50vh;
  }
  @media (max-width: 780px) {
    line-height: 1.5rem;
    padding: 0;
    width: 100%;
    margin: 0 auto;
    position: relative;
    ${CardWithShadow} {
      top: 0 !important;
      margin: 0;
    }
  }
`;

export const ComponentWrapper = styled.div`
  width: 80%;
  margin: auto;
  text-align: center;
  @media (max-width: 1200px) {
    width: 90%;
  }
  @media (max-width: 780px) {
    width: 90%;
    position: fixed;
  }
`;

export const CloseButton = styled.div`
  background-color: transparent;
  border: none;
  color: rgba(0,0,0,0.3);
  font-size: 28px;
  padding: 0;
  position: absolute;
  right: 25px;
  top: 20px;
  cursor: pointer;

  @media screen and (max-width: 780px) {
    top: 0.25rem;
    right: 0.25rem;
  }
`;

export const CloseIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
	height: 2.5rem;
	width: 2.5rem;
`;
