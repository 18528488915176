import { analyticsTrackNewCardAdded} from 'store/cart/actions';
import { NewCardAddedEvent } from '../analyticsWrapper';

export function mapNewCardAddedActionToEvent(
  action: ReturnType<typeof analyticsTrackNewCardAdded>): NewCardAddedEvent {
  const { cartId, shippingOptions } = action.payload;

  return {
    type: 'newCardAdded',
    data: {
      cart_id: cartId,
      shipping_options: shippingOptions
    }
  };
}