import React, { CSSProperties } from 'react';
import Show from 'components/Functional/Show';
import {
  StyledTooltipContainer,
  StyledImg,
  StyledTooltipLabel,
  StyledTooltipDescription,
  StyledTooltipPrice,
  ImageContainer,
  SoldOutIconWrapper,
  SoldOutIcon,
  DetailsContainer
} from './index.styled';

export interface TooltipProps {
  style?: CSSProperties;
  imgUrl: string;
  label: string;
  isSoldOut?: boolean;
  description?: string;
  priceDescription?: string;
}

const TooltipComponent = ({
  style,
  imgUrl,
  label,
  isSoldOut,
  description,
  priceDescription
}: TooltipProps): JSX.Element => {
  return (
    <StyledTooltipContainer style={style}>
      <ImageContainer>
        {isSoldOut && (
          <SoldOutIconWrapper>
            <SoldOutIcon icon="close" isWhite={label?.toLowerCase() === 'white'} />
          </SoldOutIconWrapper>
        )}
        <StyledImg src={imgUrl} alt="tooltip image" />
      </ImageContainer>
      <Show when={!!label}>
        <DetailsContainer>
          <StyledTooltipLabel isSoldOut={isSoldOut}>{label?.toLowerCase()}</StyledTooltipLabel>
          <Show when={isSoldOut}>
            <StyledTooltipDescription>{'Sold Out'}</StyledTooltipDescription>
          </Show>
          <Show when={!!description}>
            <StyledTooltipDescription>{description}</StyledTooltipDescription>
          </Show>
          <Show when={!!priceDescription}>
            <StyledTooltipPrice>{priceDescription}</StyledTooltipPrice>
          </Show>
        </DetailsContainer>
      </Show>
    </StyledTooltipContainer>
  );
};

export const Tooltip = React.memo(TooltipComponent);