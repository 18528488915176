/**
 * Check to see if Yotpo API is ready.
 * @param yotpo
 */
export function isYotpoReady(yotpo, { log = (str: string, ...rest: any) => {} }) {
  if (!yotpo) {
    log('Yotpo global doesn\'t exist yet');
  } else {
    log('Yotpo global exists, checking if ready:', {
      'getState() exists': !!yotpo.getState,
      'getState() is `ready`': !!(typeof yotpo.getState === 'function' && yotpo.getState() === 'ready'),
      'refreshWidgets() exists': !!yotpo.refreshWidgets,
      'refreshWidgets() is a function': typeof yotpo.refreshWidgets === 'function'
    });
  }
  return (
    yotpo &&
    yotpo.getState &&
    typeof yotpo.getState === 'function' &&
    yotpo.getState() === 'ready' &&
    yotpo.refreshWidgets &&
    typeof yotpo.refreshWidgets === 'function'
  );
}

/**
 * We have a global promise here to make sure we only load the yotpo script into
 * the page once.
 */
let yotpoScriptLoadingPromise: Promise<void> | undefined = undefined;

/**
 * If it hasn't been added yet, asynchronously add the yotpo <script> to the DOM.
 * If it has, then just return the promise indicating load/error state.
 * @param yotpoAPIKey
 * @param options
 */
export function ensureYotpoScriptLoaded(yotpoAPIKey: string, { log = (str: string, ...rest: any) => {} } = {}) {
  // Only add the <script> if it hasn't already been added.
  if (yotpoScriptLoadingPromise === undefined) {
    log('<script> not present, adding to document');
    // This has been lifted verbatim from https://support.yotpo.com/en/article/generic-other-platforms-installing-yotpo
    // It was minified, it has just been expanded and had the API key &
    // Onload/onerror handlers added.
    const e = document.createElement('script');
    e.type = 'text/javascript';
    e.async = true;
    e.src = `//staticw2.yotpo.com/${yotpoAPIKey}/widget.js`;
    // Adding onload/onerror handlers before inserting <script> into the document.
    yotpoScriptLoadingPromise = new Promise((resolve, reject) => {
      e.onload = () => {
        log('<script> loaded');
        try {
          // @ts-ignore
          const api = new Yotpo.API(window.yotpo); // this is what's throwing locally...
          resolve(api.instance);
        } catch (yotpoInitError) {
          console.log('Yotpo init error: ', yotpoInitError);
        }
      };
      e.onerror = reject;
    });
    // Finally, add <script> to the document.
    const t = document.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(e, t);
  }
  // This promise reflects the loading state of the <script>.
  return yotpoScriptLoadingPromise;
}
