import { hydrateProduct } from '../../helpers';
import React, { FC, ReactNode, useMemo } from 'react';
import { ProductType, RawProduct, UrlsType } from '../../types/event';
import { StrikethroughValues } from 'types/StrikethroughValues';
import { ProductContext } from './Context';
import { PDPProduct } from 'templates/PDP';

export interface ProductProviderProps {
  children: ReactNode;
  rawProduct: RawProduct;
  product: ProductType;
  urls: UrlsType;
  addToCartUrl: string;
  strikethroughValues: StrikethroughValues;
  pdpPageQuery?: PDPProduct;
}

export const ProductProvider: FC<ProductProviderProps> = ({
  children,
  rawProduct,
  product,
  urls,
  addToCartUrl,
  strikethroughValues,
  pdpPageQuery
}) => {
  // Calculate product hydration just the first time
  const hydratedProduct = useMemo(
    () => (product ? hydrateProduct(rawProduct, product, strikethroughValues, pdpPageQuery, urls) : undefined),
    [product, rawProduct, strikethroughValues, urls, pdpPageQuery]
  );

  const initialValue = {
    rawProduct,
    product: hydratedProduct,
    urls,
    addToCartUrl
  };

  return <ProductContext.Provider value={initialValue}>{children}</ProductContext.Provider>;
};
