export enum ModalType {
  BILLING_FORM_MODAL = 'BILLING_FORM_MODAL',
  PAYMENT_METHOD_SELECTION_MODAL = 'PAYMENT_METHOD_SELECTION_MODAL',
  SHIPPING_ADDRESS_SELECTION_MODAL = 'SHIPPING_ADDRESS_SELECTION_MODAL',
  SHIPPING_ADDRESS_VERIFICATION_MODAL = 'SHIPPING_ADDRESS_VERIFICATION_MODAL',
  CUSTOMER_REGISTRATION_MODAL = 'CUSTOMER_REGISTRATION_MODAL',
  CUSTOMER_LOGIN_MODAL = 'CUSTOMER_LOGIN_MODAL',
  SHIPPING_ADDRESS_FORM_MODAL = 'SHIPPING_ADDRESS_FORM_MODAL',
  HIDE_MODAL = 'HIDE_MODAL',
  SET_MODAL = 'SET_MODAL',
  OPEN_PREVIOUS_MODAL = 'OPEN_PREVIOUS_MODAL',
  SEND_MODAL_DATA = 'SEND_MODAL_DATA',
  GIFT_WRAP_SELECTION_MODAL = 'GIFT_WRAP_SELECTION_MODAL',
  SEARCH_MODAL = 'SEARCH_MODAL',
  DELETE_PROJECT_MODAL = 'DELETE_PROJECT_MODAL',
  OPEN_MOBILE_PROJECT_FILTERS_MODAL = 'OPEN_MOBILE_PROJECT_FILTERS_MODAL',
  PROJECT_INFO_MODAL = 'PROJECT_INFO_MODAL',
  RENAME_PROJECT_MODAL = 'RENAME_PROJECT_MODAL',
  LIVE_PREVIEW_MODAL = 'LIVE_PREVIEW_MODAL',
  PRODUCT_OPTIONS_MODAL = 'PRODUCT_OPTIONS_MODAL',
  PRODUCT_IMAGE_MODAL = 'PRODUCT_IMAGE_MODAL',
  CREATE_CONTACT_GROUP = 'CREATE_CONTACT_GROUP',
  EDIT_CONTACT_GROUP = 'EDIT_CONTACT_GROUP',
  EDIT_CONTACT = 'EDIT_CONTACT',
  ADD_CONTACT = 'ADD_CONTACT'
}

export interface Modal {
  type: ModalType;
  hideable: boolean;
  fullscreen: boolean;
  className: string;
  paddedModal: boolean;
  data?: {
    [key: string]: any;
  };
}

export interface ModalState {
  history: Modal[];
  current: Modal;
}
