import { StrikethroughValues } from 'types/StrikethroughValues';
import { newrelic } from 'helpers/reporting/newrelic';

const BY_PERCENT = 'by_percent';
const BY_FIXED = 'by_fixed';
const GIFT_CARD_SKU = 'email-giftcard';

export const getStrikethroughPrice = (
  strikethroughValues: StrikethroughValues,
  productPrice: number,
  productSku: string
): number | null => {
  const strikethroughPrice: number | null = null;

  if (strikethroughValues && productSku !== GIFT_CARD_SKU) {
    const { discountType, discountAmount } = strikethroughValues;

    if (discountType === BY_PERCENT) {
      if (discountAmount > 100) {
        reportInvalidDiscount(
          'DISCOUNT_BY_PERCENT_GREATER_THAN_100',
          invalidStrikethroughAndPriceValues(strikethroughValues, productPrice)
        );

        return productPrice;
      }

      return strikethroughPriceByPercent(productPrice, discountAmount);
    }

    if (discountType === BY_FIXED) {
      if (discountAmount > productPrice) {
        reportInvalidDiscount(
          'FIXED_DISCOUNT_AMOUNT_GREATER_THAN_PRODUCT_PRICE',
          invalidStrikethroughAndPriceValues(strikethroughValues, productPrice)
        );

        return productPrice;
      }

      return strikethroughPriceByFixed(productPrice, discountAmount);
    }
  }

  return strikethroughPrice;
};

const strikethroughPriceByPercent = (productPrice: number, discountAmount: number): number => {
  const discount = productPrice * (discountAmount / 100);
  return productPrice - discount;
};

const strikethroughPriceByFixed = (productPrice: number, discountAmount: number): number =>
  productPrice - discountAmount;

const reportInvalidDiscount = (type: string, values: object): void => {
  newrelic('addPageAction')(type, values);
};

const invalidStrikethroughAndPriceValues = (strikethroughValues: StrikethroughValues, price: number): object => ({
  strikethroughValues,
  price
});
