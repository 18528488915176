import styled from 'styled-components';

export const ErrorWrapper = styled.div`
  border: 0.1rem solid #c11e1e;
  background-color: #fff;
  padding: 1rem 2rem;
  text-transform: uppercase;
  z-index: 15;
  text-align: center;
  word-break: break-word;
  position: relative;

  p {
    color: #c11e1e;
  }

  span {
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
  }
`;

export const ToastContainerWrapper = styled.div`
  .Toastify__toast {
    padding: 0;
    box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2);
  }
`;
