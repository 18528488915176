import React from 'react';
import styled from 'styled-components';
import GenericOverlay from '../../GenericOverlay';
import { SecondaryButton } from '../../Button';
import Icon from '../../icons';

export const Container = styled(GenericOverlay)`
  height: 20rem;
  width: 37rem;
  background-color: #F8F8F8;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MainHeaderText = styled.div`
  display: flex;
  padding-top: 3px;
`;

export const HeaderRow = styled.div`
  padding: 0 1.7rem;
  height: 2.85rem;
  display: flex;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ErrorItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.7rem;
  font-size: 1rem;
  line-height: 21px;
  background-color: white;
  height: 3rem;
  box-shadow: inset 0 -1px 0 0 #F2F2F2;
  align-items: flex-start;
  display: flex;
  align-items: center;
`;

export const IconWarning = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  margin-right: 0.3rem;
`;

export const ErrorText = styled.div`
  width: 40%;
  color: #A94F3E;
`;

export const ButtonTitlePad = styled.div`
  padding-top: 2px;
`;

export const GrayText = styled.div`
  color: #BCBCBC;
  width: 20%;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ErrorName = styled.div`
  width: 40%;
  overflow: hidden;
  color: #000000;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 1rem 1rem 1rem;
`;

export const ErrorListWrapper = styled.div`
  margin: 0 1rem;
  background-color: #fff;
  min-height: 11rem;
  max-height: 11rem;
  overflow: scroll;
`;

export const ErrorThumb = styled.img`
  height: 1.75rem;
  width: 1.75rem;
  margin-right: 0.5rem;
`;

export const OutlinedButton = styled(SecondaryButton)`
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
`;
