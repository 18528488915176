import cartReducer from './cart/reducer';
import modalReducer from './modal/reducer';
import customerReducer from './customer/reducer';
import uiReducer from './ui/reducer';

export default {
  cart: cartReducer,
  modal: modalReducer,
  customer: customerReducer,
  ui: uiReducer
};
