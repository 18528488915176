import React from 'react';
import { pathToMagentoBase } from 'helpers/env';
import { AULink } from 'components/AULink';
import { isLinkSameOriginAsHost, LOGOUT_ROUTE } from 'helpers/http';
import { clearAll, deleteSessionStorage } from 'helpers/storage';
import { SEGMENT_IO_ANONYMOUS_ID, SPLIT_IO_USER_KEY_COOKIE } from 'au-js-sdk/lib/services/split.io/constants';
import { SEGMENT_TRACKING_PREFERENCES_COOKIE } from 'components/SegmentConsentBanner';
import { ADDRESS_SESSION_KEYS } from 'constants/storage';

interface LogoutButtonProps {
  className?: string;
  id: string;
}

const LogoutButton = ({ className, id }: LogoutButtonProps) => {
  const onLogout = e => {
    e.preventDefault();

    // Leave the segment anonymous id so that we can track a user between authenticated and anonymous
    clearAll([SEGMENT_IO_ANONYMOUS_ID, SPLIT_IO_USER_KEY_COOKIE, SEGMENT_TRACKING_PREFERENCES_COOKIE]);

    // remove the session storage keys if they exist
    ADDRESS_SESSION_KEYS.forEach((key: string) => deleteSessionStorage(key));

    // If magento is at a different origin from gatsby (for instance, if we're running locally and using staging for magento)
    // We can't fetch, because of a cors issue with the redirects.  This instead actually visits the logout page.
    if (!isLinkSameOriginAsHost(pathToMagentoBase('/customer/account/logout/'))) {
      window.location.href = pathToMagentoBase('/customer/account/logout/');
      return;
    }

    fetch(LOGOUT_ROUTE, {
      redirect: 'follow',
      credentials: 'include'
    })
      .then(res => {
        window.location.href = process.env.GATSBY_SITE_URL;
      })
      .catch(e => {
        console.error(e);
      });
  };

  return (
    <AULink id={id} path={pathToMagentoBase('/customer/account/logout/')} className={className} onClick={onLogout}>
      Logout
    </AULink>
  );
};

export default LogoutButton;
