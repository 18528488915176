import { analyticsTrackFirstPurchase } from 'store/cart/actions';
import { FirstPurchaseEvent } from '../analyticsWrapper';
import { createFirstPurchaseEventProperties } from '../tracking';

export function mapFirstPurchaseActionToEvent(
  action: ReturnType<typeof analyticsTrackFirstPurchase>
): FirstPurchaseEvent {
  const { subtotal, orderId } = action.payload;
  return {
    type: 'firstPurchase',
    data: createFirstPurchaseEventProperties(subtotal, orderId)
  };
}
