import { getSplitTreatmentWithSideEffect } from 'au-js-sdk/lib/services/split.io/helpers';
import { customerEmailSelector, customerIdSelector } from '../../store/customer/selectors';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import itly from 'itly';
import { getSplitUserKey } from 'au-js-sdk/lib/services/split.io/cookies';
import { SPLIT_TRAFFIC_TYPES } from 'au-js-sdk/lib/services/split.io/constants';
import { featureFlagsSelector } from 'store/ui/selectors';
import { setFeatureFlag } from 'store/ui/actions';
import { useDebounce } from 'hooks/useDebounce';

declare global {
  interface Window {
    auSplitsFetched: { [name: string]: boolean };
  }
}

export const useSplitTreatment = (
  name: string,
  cookie: string,
  trafficType: SPLIT_TRAFFIC_TYPES = SPLIT_TRAFFIC_TYPES.ANONYMOUS,
  isReady = true
): { treatmentStatus: boolean } => {
  const customerEmail = useSelector(customerEmailSelector);
  const customerId = useSelector(customerIdSelector);
  const featureFlags = useSelector(featureFlagsSelector);
  const dispatch = useDispatch();

  //This state is only used if this instantiation of the hook is the one that is making the getTreatment
  // Call. Since emails are optional for anonymous splits, we may need to call getTreatment twice if an
  // Email comes from redux shortly after we call for the initial treatment. We debounce the value
  // Because we just want to trigger the Split Treatment Received event once.
  const [treatmentResult, setTreatmentResult] = useState<boolean>(null);
  const debouncedTreatementResult = useDebounce<boolean>(treatmentResult, 1000);

  //Grab the flag we care about from redux feature flag state
  const flagState = useMemo(() => {
    return featureFlags[name];
  }, [name, featureFlags]);

  const getTreatment = useCallback(
    async (email: string, id: number, trafficType: SPLIT_TRAFFIC_TYPES, isReady: boolean) => {
      const isTrafficTypeReady = trafficType !== SPLIT_TRAFFIC_TYPES.MAGENTO || (email && id);
      if (!isReady || !isTrafficTypeReady) {
        return false;
      }
      dispatch(setFeatureFlag(name, false, true));
      if (!window.auSplitsFetched) {
        window.auSplitsFetched = {};
      }
      window.auSplitsFetched[name] = true;
      const userKey = getSplitUserKey();
      const treatmentResult = await getSplitTreatmentWithSideEffect(name, cookie, trafficType)(
        email,
        id?.toString(),
        userKey
      );
      dispatch(setFeatureFlag(name, treatmentResult, false));
      setTreatmentResult(treatmentResult);

      return treatmentResult;
    },
    [cookie, name]
  );

  //If there's multiple components rendered that have this hook, the first will make the getTreatment
  // Call, and the others will see that it's fetching and not make the call to split. The selector
  // Will update the treatmentStatus for the others after the first hook dispatches the result.
  useEffect(() => {
    const isSplitFetching = window.auSplitsFetched && window.auSplitsFetched[name] === true;
    if (flagState?.value == null && !isSplitFetching) {
      getTreatment(customerEmail, customerId, trafficType, isReady);
    }
  }, [customerEmail, customerId, trafficType, getTreatment, isReady]);

  useEffect(() => {
    if (debouncedTreatementResult !== null) {
      const userKey = getSplitUserKey();
      itly.splitTreatmentReceived({
        split_name: name,
        split_anonymous_key: userKey,
        split_treatment: debouncedTreatementResult ? 'true' : 'false'
      });
    }
  }, [debouncedTreatementResult]);

  return {
    treatmentStatus: flagState ? flagState.value : undefined
  };
};
