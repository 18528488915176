import { useCallback, useEffect, useRef } from 'react';

export interface MeasureLoadTimeProps {
  isLoading: boolean;
  onLoadingFinish: (loadTime: number, endTime: number) => void;
  onLoadingStart?: (startTime: number) => void;
  debounce?: number;
}

/**
 * useMeasureLoadTime
 *
 * Measures the time it takes for isLoading to switch from true to false
 *
 * @param isLoading boolean representing the loading state of the action in question
 * @param onLoadingFinish called when loading completes, must be a stable function reference
 * @param onLoadingStart optional, called when loading starts, must be a stable function reference
 * @param debounce optional, threshold of time that loading state can "flicker" without triggering a new load sequence
 */
export const useMeasureLoadTime = ({
  isLoading,
  onLoadingFinish,
  onLoadingStart,
  debounce = 500
}: MeasureLoadTimeProps): void => {
  const timerRunning = useRef(false);
  const startTime = useRef(performance.now());

  const trackLoadTime = useCallback((startTime: number, endTime: number) => {
    const loadTime = Math.round(endTime - startTime);

    onLoadingFinish(loadTime, endTime);

    timerRunning.current = false;
  }, [onLoadingFinish]);

  useEffect(() => {
    const shouldStartTimer = isLoading && !timerRunning.current;
    const shouldStopTimer = !isLoading && timerRunning.current;
    if (shouldStartTimer) {
      startTime.current = performance.now();
      timerRunning.current = true;

      onLoadingStart?.(startTime.current);
    } else if (shouldStopTimer) {
      const endTime = performance.now();

      // Delay tracking the end time to allow for loading states that "flicker"--loading state gets set to false but
      // then back to true shortly after as they resume loading something. The last load end time will be sent to
      // onLoadingFinished after the debounce time elapses without any changes to the loading state.
      const timer = setTimeout(() => trackLoadTime(startTime.current, endTime), debounce);

      return () => clearTimeout(timer);
    }
  }, [isLoading, debounce, onLoadingStart, trackLoadTime]);
};