import { createContext } from 'react';
import { ProductType, RawProduct, UrlsType } from '../../types/event';

export interface ProductConsumerProps {
  rawProduct?: RawProduct
  product?: ProductType
  urls?: UrlsType
  addToCartUrl?: string
}

const ProductContext = createContext<ProductConsumerProps>({});

const { Consumer: ProductConsumer } = ProductContext;

export { ProductConsumer, ProductContext };
