import React from 'react';
import PaymentMethodTileRadio from 'components/Billing/PaymentMethodSelection/PaymentMethodTileRadio';
import { PAYMENT_METHOD_AFTERPAY } from 'au-types/lib/magento/sales/payment';
import { isPaymentMethodAfterpay } from '../helpers';

export const AfterpayPaymentMethodTileRadio = ({ handleChange, paymentMethodToken }) => (
  <PaymentMethodTileRadio
    dataTestId="afterpay-tile"
    paymentMethod={{
      type: PAYMENT_METHOD_AFTERPAY,
      token: PAYMENT_METHOD_AFTERPAY,
      is_default: false,
      is_expired: false
    }}
    onChange={handleChange}
    checked={isPaymentMethodAfterpay(paymentMethodToken)}
  />
);
