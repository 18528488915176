import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`

*[class*="buttonBack"],
*[class*="buttonFirst"],
*[class*="buttonLast"],
*[class*="buttonNext"],
.buttonBack,
.buttonFirst,
.buttonLast,
.buttonNext,
.buttonNext,
*[class*="dot__"],
.dot__{
  cursor:pointer
}

*[class*="image___"],
.image___ {
  display: block;
  width: 100%;
  height: 100%
}

*[class*="spinner___"],
.spinner___ { 
  position:absolute;
  top:calc(50% - 15px);
  left:calc(50% - 15px);
  width:30px;
  height:30px;
  animation-name:spin___S3UuE;
  animation-duration:1s;
  animation-timing-function:linear;
  animation-iteration-count:infinite;
  border:4px solid #a9a9a9;
  border-top-color:#000;
  border-radius:30px
}

*[class*="spin___"],
@keyframes spin___ {
  0% {
    transform:rotate(0deg)
  } to { 
    transform: rotate(1turn)
  }
}

*[class*="container___"],
.container___ {
  position: relative;
  overflow: hidden; 
  height: 100%;
  width: 100%
}

*[class*="overlay___"],
.overlay___ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: zoom-in;
  transition: opacity .3s,
  transform .3s
}

*[class*="hover___"],
*[class*="loading___"],
*[class*="zoom___"],
.hover___,
.loading___,
.zoom___ {
  opacity: 1
}

*[class*="imageLoadingSpinnerContainer"],
.imageLoadingSpinnerContainer {
  position: absolute;
  top: 0; 
  right: 0; 
  bottom: 0;
  left: 0;
  background-color: #f4f4f4
}

*[class*="slide___"],
.slide___ {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none
}
.slide___:focus {
  outline: none!important
}

*[class*="slideHorizontal"],
.slideHorizontal {
  float: left
}[dir=rtl] 
.slideHorizontal {
  direction:rtl; 
  transform:scaleX(-1)
}

*[class*="horizontalSlider"],
.horizontalSlider {
  position: relative;
  overflow: hidden
}[dir=rtl] 
.horizontalSlider {
  direction: ltr;
  transform: scaleX(-1)
}
*[class*="horizontalSliderTray"],
.horizontalSliderTray {
  overflow: hidden;
  width: 100%
}

*[class*="slideInner"],
.slideInner { 
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

*[class*="focusRing"],
.focusRing {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight
}

@media (-webkit-min-device-pixel-ratio: 0) { 
  .focusRing {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color
  }
}

*[class*="verticalSlider"],
.verticalSlider { 
  position: relative;
  overflow: hidden
}

*[class*="verticalSliderTray"],
.verticalSliderTray {
  overflow: hidden
}

*[class*="verticalTray"],
.verticalTray {
  float: left
}

*[class*="verticalSlideTrayWrap"],
.verticalSlideTrayWrap {
  overflow: hidden
}

*[class*="sliderTray"],
.sliderTray {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0
}

*[class*="sliderAnimation"],
.sliderAnimation { 
  transition: transform .5s;
  transition-timing-function: cubic-bezier(.645,.045,.355,1);
  will-change: transform
}

*[class*="masterSpinnerContainer"],
.masterSpinnerContainer { 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4
}




.icon {
  background: transparent;
  width: 1rem;
  height: 1rem;
  box-sizing: initial;
  border: 0px;
  outline: none;
}

.icon svg {
  width: 1rem;
  height: 1rem;
}

.icon--circle {
  border-radius: 50%;
}

.icon--large {
  padding: .5rem;
  width: 5rem;
  height: 5rem;
}

.icon--2x {
  width: 3rem;
  height: 3rem;
}

.icon--4x {
  width: 4rem;
  height: 4rem;
}

.icon--blue-border {
  border: 1px solid #4990E2;
}

.icon--white path[fill="#000"] {
  fill: #fff;
}

.icon--blue path[fill="#000"] {
  fill: #4990E2;
}

.icon--animate-rotate {
  animation: spin .5s linear infinite;
}

.icon__wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.icon--tiny {
  width: 20px;
  height: 20px;
}

.icon--small {
  width: 24px;
  height: 24px;
}

.icon--medium {
  width: 40px;
  height: 40px;
}

.icon--no-padding {
  padding: 0;
}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }

._buttonBack_113ph_1 {
  cursor: pointer;
}

._buttonFirst_y31jn_1 {
  cursor: pointer;
}

._buttonNext_p1bs6_1 {
  cursor: pointer;
}

._buttonLast_x8dvv_1 {
  cursor: pointer;
}

._dot_27k82_1 {
  cursor: pointer;
}

._image_u458c_1 {
  display: block;
  width: 100%;
  height: 100%;
}

._container_11gb8_1 {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

._overlay_11gb8_8 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: opacity 0.3s, transform 0.3s;
  transition: opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  cursor: zoom-in;
}

._hover_11gb8_19 {
  opacity: 1;
  -webkit-transform: scale(2);
  transform: scale(2);
}

._loading_11gb8_24 {
  opacity: 1;
}

._imageLoadingSpinnerContainer_11gb8_28 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f2e8;
}

._slideInner_fhwgk_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

._slideHorizontal_fhwgk_1 {
  float: left;
}

._slide_fhwgk_1 {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none;
}

._focusRing_fhwgk_26 {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  ._focusRing_fhwgk_26 {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

._horizontalSlider_al8x6_1 {
  position: relative;
  overflow: hidden;
}

._horizontalSliderTray_al8x6_1 {
  overflow: hidden;
  width: 100%;
}

._verticalSlider_al8x6_11 {
  position: relative;
  overflow: hidden;
}

._verticalSliderTray_al8x6_1 {
  overflow: hidden;
}

._verticalTray_al8x6_20 {
  float: left;
}

._verticalSlideTrayWrap_al8x6_24 {
  overflow: hidden;
}

._sliderTray_al8x6_28 {
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

._masterSpinnerContainer_al8x6_37 {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f7f2e8;
}

._spinner_1dguc_1 {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  -webkit-animation-name: a;
  animation-name: a;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  border-width: 4px;
  border-style: solid;
  border-top-color: #000;
  border-right-color: #a9a9a9;
  border-bottom-color: #a9a9a9;
  border-left-color: #a9a9a9;
  border-radius: 30px;
}

@-webkit-keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

@keyframes a {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}

.photo-slider__slides .carousel__slide img {
  width: 100%;
}

button.photo-slider__thumb {
  /* Magento CSS overrides */
  background: transparent;
  padding: 0;
  margin: 0;
  font-size: 0;
  border-radius: 0;
  border: 0;
  line-height: 0;
  /* End Magento CSS overrides */
  margin-right: 1rem;
  display: inline-block;
  width: 3.75rem;
  position: relative;
  flex-shrink: 0;
  opacity: 1;
  margin-bottom: 1rem;
}

button.photo-slider__thumb:last-of-type {
  margin-right: 0rem;
}

button.photo-slider__thumb[disabled]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  box-shadow: inset 0 0 0 1px black;
  z-index: 10;
}

.photo-slider__thumbs {
  display: flex;
  justify-content: space-between;
}

/*# sourceMappingURL=react-carousel.es.css.map */
.carousel {
  position: relative;
}

.carousel:after {
  content: "";
  display: table;
  clear: both;
}

.carousel__slide {
  opacity: 1;
  transition: opacity 0.5s linear;
}

.carousel__slide[tabindex="-1"] {
  transition: opacity 0s;
  opacity: 0;
}

.carousel__slide-focus-ring {
  display: flex;
  border: 0px solid rgba(0, 0, 0, 0.3);
  outline: none;
  padding: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.carousel__dot-group {
  text-align: center;
  position: absolute;
  bottom: 5px;
  width: 100%;
}

.carousel__dot {
  border-radius: 100%;
  width: 6px;
  height: 6px;
  padding: 0;
  margin: 0 2px;
  border: 0;
  background-color: #FFFFFF;
}

.carousel__dot--selected {
  background-color: rgba(95,87,87,1) !important;
  opacity: 1 !important;
}

/* PDP Redesign A/B Test Changes */
.PDPRedesign--PhotoSlider-Arrow {
  display: none;
}

.photo-slider__thumbs {
  display: none;
}

.photo-slider {
  display: flex;
  flex-direction: column;
}

.video-thumbnail-container {
  position: relative;
}

.play-btn--thumb {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 3.5rem;
  width: 100%;
  background-color: #0003;
}

@media screen and (min-width: 768px) {
  
  .photo-slider {
    display: flex;
  }

  .photo-slider__slides{
    margin-bottom: 0px;
    width: 90% !important;
    margin-left: 1.25rem;
    padding-top: 1rem;
  }
  
  .photo-slider__thumbs{
    flex-direction: column;
    min-height: 10rem;
    padding-top: .65em;
    display: flex;
  }

  button.photo-slider__thumb{
    margin: 8px 0 !important;
  }

  .carousel__dot-group {
    display: none !important;
  }

  .carousel__dot {
    height: 100%;
  }

  .PDPRedesign--PhotoSlider-Arrow{
    display: none !important;
  }

  [class*="buttonNext"].PDPRedesign--PhotoSlider-Arrow{
    display: none !important;
  }

  [class*="buttonBack"].PDPRedesign--PhotoSlider-Arrow{
    display: none !important;
  }

  [class*="-pt-tiny Grid__Column"]:first-child{
    padding-bottom: 3.5rem;
  }
}

.pdp-vimeo-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.pdp-vimeo-play,
.pdp-vimeo-play:hover {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(255,255,255,.1);
  border: 2px solid black;
  text-indent: -999px;
  transition: opacity .3s ease;
  overflow: hidden;
  opacity: .3;
}

.pdp-vimeo-play:hover {
  opacity: 1;
}

.pdp-vimeo-play:after {
  content: '';
  border-left: 8px solid black;
  border-right: 8px solid transparent;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  display: block;
  position: absolute;
  top: 52%;
  left: 61%;
  transform: translate(-50%, -50%);
}

.pdp-vimeo-play--paused:after {
  width: 3px;
  left: 50%;
  height: 8px;
  border: 0;
  border-left: 2px solid black;
  border-right: 2px solid black;
}

/* react inner image zoom start */
.iiz {
  max-width: 100%;
  margin: 0;
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.iiz--drag .iiz__zoom-img--visible {
  cursor: -webkit-grab;
  cursor: grab;
}

.iiz__img {
  max-width: 100%;
  height: auto;
  display: block;
  pointer-events: none;
  visibility: visible;
  opacity: 1;
}

.iiz__img--hidden {
  visibility: hidden;
  opacity: 0;
}

.iiz__img--abs {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
}

.iiz__zoom-img {
  width: auto !important;
  max-width: none !important;
  position: absolute;
  visibility: hidden;
  opacity: 0;
  display: block;
}

.iiz__zoom-img--visible {
  visibility: visible;
  opacity: 1;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.iiz__zoom-portal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
}

.iiz__btn {
  background: rgba(255, 255, 255, 0.8);
  width: 40px;
  height: 40px;
  border: none;
  outline: none;
  padding: 0;
  position: absolute;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
}

.iiz__btn:before {
  content: " ";
  background-position: center;
  background-repeat: no-repeat;
  display: block;
}

.iiz__hint {
  bottom: 10px;
  right: 10px;
  pointer-events: none;
}

.iiz__hint:before {
  content: " ";
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 19.9 19.9'%3E%3Cpath d='M13.9 7.4C13.9 3.8 11 .9 7.4.9S.9 3.8.9 7.4s2.9 6.5 6.5 6.5 6.5-2.9 6.5-6.5zm5.3 12.5l-6.7-7.2c-1.4 1.3-3.2 2.1-5.1 2.1-4.1 0-7.4-3.3-7.4-7.4S3.3 0 7.4 0s7.4 3.3 7.4 7.4c0 1.7-.6 3.4-1.7 4.7l6.8 7.2-.7.6z' fill='%23000222'/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.iiz__close {
  top: 10px;
  right: 10px;
  visibility: hidden;
  opacity: 0;
}

.iiz__close--visible {
  visibility: visible;
  opacity: 1;
}

.iiz__close::before {
  content: " ";
  width: 29px;
  height: 29px;
  background-image:
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222)),
    -webkit-gradient(linear, left top, left bottom, from(#222), to(#222));
  background-image: linear-gradient(#222, #222), linear-gradient(#222, #222);
  background-size: 100% 1px, 1px 100%;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.no-scroll {
  overflow: hidden;
  position: relative;
  height: 100%;
}
/* react inner image zoom end */
`;
