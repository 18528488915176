import { newRelicPageAction } from 'helpers/reporting/newrelic';
import { isReturnToProjectData, ReturnToProjectData } from 'types/ReturnToProjectData';

const RETURN_TO_PROJECT_DATA = 'return_to_project_data';

export const getReturnToProjectData = (): ReturnToProjectData | null => {
  const data = localStorage.getItem(RETURN_TO_PROJECT_DATA);
  if (!data?.length) {
    return null;
  }

  let parsedData: any;
  try {
    parsedData = JSON.parse(decodeURIComponent(data));
  } catch (ex) {
    newRelicPageAction('return_to_project_error', { type: 'parse', parsedData, data, ex });
  }

  if (isReturnToProjectData(parsedData)) {
    return parsedData as ReturnToProjectData;
  } else {
    newRelicPageAction('return_to_project_error', { type: 'typeguard', parsedData, data });
    return null;
  }
};

export const clearReturnToProjectData = (): void => {
    localStorage.removeItem(RETURN_TO_PROJECT_DATA);
};

export const clearReturnToProjectDataForProjects = (projectIds: string[]): void => {
  const data = getReturnToProjectData();
  if (!data?.projectUrl) {
    return;
  }

  const returnToProjectID = new URL(data.projectUrl).searchParams?.get('projectId');
  if (!returnToProjectID) {
    return;
  }

  if (projectIds?.includes(returnToProjectID)) {
    clearReturnToProjectData();
  }
};
