import { TrackingProp } from 'react-tracking';
import { useEffect } from 'react';
import { ProductViewedEvent } from '../analyticsWrapper';
import { Product } from 'types/Product';
import { ProductViewedProperties } from 'itly';
import { convertToReportingProduct } from '../helpers/product';

export function useTrackProductViewed(
  tracking: TrackingProp<ProductViewedEvent>,
  product: Product,
  ...dependencies: any[]
) {
  useEffect(() => {
    const data: ProductViewedProperties = convertToReportingProduct(product);
    tracking.trackEvent({ type: 'productViewed', data });
  }, dependencies);
}
