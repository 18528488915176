import React, { useContext, useEffect } from 'react';
import GatewayContext from './GatewayContext';

interface GatewayDestProps {
  name: string
  component?: string | React.FC
  unmountOnEmpty?: boolean
}

function GatewayDest({ name, component, unmountOnEmpty, ...attrs }: GatewayDestProps) {
  const { addContainer, removeContainer, getContainerChildren } = useContext(GatewayContext);
  const children = getContainerChildren(name);

  useEffect(() => {
    addContainer(name);
    return () => {
      removeContainer(name);
    };
  }, []);

  const nonNullChildren = children.filter(it => Boolean(it));

  return unmountOnEmpty && !nonNullChildren.length
    ? null
    : React.createElement(component || 'div', attrs, nonNullChildren);
}

export default GatewayDest;
