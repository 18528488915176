import { ErrorLabel, Select, StyledLabel } from 'component-library';
import { selectCustomStyles, StyledField } from 'components/Billing/BillingForm/styled';
import { FormikActions, FormikErrors, FormikTouched } from 'formik';
import { AddressFormValues } from 'helpers/forms';
import React, { useState, useEffect } from 'react';
import { StyledNativeSelect } from './styled';

interface LabeledFieldProps {
  label: string;
  name: string;
  errors: FormikErrors<AddressFormValues>;
  touched: FormikTouched<AddressFormValues>;
  id?: string;
  disabled?: boolean;
}

export const LabeledField = ({
  label,
  name,
  errors,
  touched,
  id = '',
  disabled = false
}: LabeledFieldProps): JSX.Element => (
  <StyledLabel as="label">
    {label}
    {errors[name] && touched[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    <StyledField
      name={name}
      id={id}
      border={errors[name] && touched[name] && '1px solid #C11E1E'}
      disabled={disabled}
    />
  </StyledLabel>
);

interface LabeledSelectProps {
  label: string;
  name: string;
  options: { label: string; value: string }[];
  values: AddressFormValues;
  errors: FormikErrors<AddressFormValues>;
  touched: FormikTouched<AddressFormValues>;
  setFieldValue: FormikActions<AddressFormValues>['setFieldValue'];
  setFieldTouched: FormikActions<AddressFormValues>['setFieldTouched'];
  disabled?: boolean;
  onChange?: (opt: { label: string; value: string }) => void;
}

export const LabeledSelect = ({
  label,
  name,
  options,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  disabled = false,
  onChange = null,
}: LabeledSelectProps): JSX.Element => (
  <StyledLabel as="label">
    {label}
    {errors[name] && touched[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
    <Select
      name={name}
      isLoading={disabled}
      styles={selectCustomStyles}
      value={values[name]}
      options={options}
      onChange={
        onChange ||
        (opt => {
          setFieldValue(name, opt);
        })
      }
      onBlur={() => setFieldTouched(name, true)}
      error={errors[name] && touched[name]}
    />
  </StyledLabel>
);

interface LabeledNativeSelectProps {
  label: string;
  name: string;
  options: { label: string; value: string }[];
  values: AddressFormValues;
  errors: FormikErrors<AddressFormValues>;
  touched: FormikTouched<AddressFormValues>;
  setFieldValue: FormikActions<AddressFormValues>['setFieldValue'];
  setFieldTouched: FormikActions<AddressFormValues>['setFieldTouched'];
  disabled?: boolean;
  autoComplete?: string;
}

export const LabeledNativeSelect = ({
  label,
  name,
  options,
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  autoComplete,
  disabled = false
}: LabeledNativeSelectProps): JSX.Element => {
  const [selectedValue, setSelectedValue] = useState<{ label: string; value: string }>(values[name]);

  const handleSelectChange: React.ChangeEventHandler<HTMLSelectElement> = event => {
    const index = (event.nativeEvent.target as HTMLSelectElement).selectedIndex;
    const region_id = { label: event.nativeEvent.target[index].text, value: event.target.value };

    setSelectedValue(region_id);
    setFieldValue(name, region_id);
  };

  useEffect(() => {
    if (values[name]?.value !== selectedValue?.value) {
      setSelectedValue(values[name]);
    }
  }, [selectedValue, setSelectedValue, values, name]);

  return (
    <StyledLabel as="label">
      {label}
      {errors[name] && touched[name] && <ErrorLabel>{errors[name]}</ErrorLabel>}
      <StyledNativeSelect
        name={name}
        value={selectedValue.value}
        error={errors[name] && touched[name]}
        id={name}
        autoComplete={autoComplete}
        disabled={disabled}
        onChange={handleSelectChange}
        onBlur={() => setFieldTouched(name, true)}
      >
        <option value=""></option>
        {options.map(o => {
          return (
            <option key={o.value} value={o.value}>
              {o.label}
            </option>
          );
        })}
      </StyledNativeSelect>
    </StyledLabel>
  );
};
