import styled from 'styled-components';
import { disabledInputCss, hoverStateCss } from '../styled';

export const StyledSwatchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledSwatch = styled.label`
  display: flex;
  box-sizing: content-box;
  border-radius: 50%;
  border: 2px solid transparent;
  width: 42px;
  height: 42px;

  ${disabledInputCss};

  ${hoverStateCss};
  :hover:not(.disabled),
  &.checked {
    img {
      background-clip: content-box;
    }
  }
`;

export const SwatchImg = styled.img<{ backgroundUrl?: string }>`
  border-radius: 50%;
  background-image: url(${props => props.backgroundUrl});
  height: 42px;
  border: 2px solid transparent;
  width: 42px;
  transition-property: padding;
  transition-duration: 0.2s;
`;

export const DisabledIconContainer = styled.div`
  position: absolute;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  background-color: rgb(255, 255, 255, 0.85);
`;
