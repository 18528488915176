import { useProductContext } from './useProductContext';

export const useProduct = () => {
  const { rawProduct, product, urls, addToCartUrl } = useProductContext();

  return {
    rawProduct,
    product,
    addToCartUrl,
    urls
  };
};
