import React from 'react';
import styled from 'styled-components';
import { PrimaryButton as Button } from '../../Button';
import Icon from '../../icons';

export const AddIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  display: block;
  min-height: 4.9rem;
  min-width: 4.9rem;
  margin-bottom: 0.5rem;
`;

export const AlbumsBrowserItem = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 12rem;
  min-width: 100%;
  padding: 1rem 1rem 1rem 0;
  background-color: #ffffff !important;
  max-height: 14rem;
`;
export const AlbumsOverviewContainer = styled.div`
  padding-left: 2rem;
  width: 100%;
  @media (max-width: 780px) {
    padding: 0;
  }
`;

export const AddPhotosButton = styled(Button)`
  align-items: center;
  border: 1px solid #000000;
  background-color: #ffffff !important;
  color: #000000 !important;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 0.6875rem;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  flex-direction: column;
  height: 100%;
  max-height: 8rem;
  justify-content: center;
  max-width: 10rem;
  position: relative;
  width: 100%;
  transition: box-shadow 0.2s linear;
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
    outline: none;
  }
  @media (max-width: 780px) {
    display: none;
  }
`;

export const AddIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
`;

export const SignInButtonWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: #f2f2f2;
  color: #000000;
  padding: 1rem;
  margin: 0 auto;
  font-family: 'Crimson Text', 'Georgia', serif;
  justify-content: center;
  font-size: 1rem;
  line-height: 1.5rem;
  white-space: normal;
  button {
    display: inline-block;
    font-size: 1rem;
  }
`;

export const LogInButton = styled.button`
  display: inline-block;
  font-size: 1rem;
  background: none;
  text-decoration: underline;
  border: none;
  cursor: pointer;
`;
export const AlbumButton = styled(Button)`
  display: inline-block;
  box-sizing: border-box;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 -1px 1px 0 rgba(0, 0, 0, 0.05);
  background-size: cover;
  background-position: center;
  cursor: pointer;
  height: 100%;
  position: relative;
  transition: box-shadow 0.2s linear;
  width: 100% !important;
  min-width: 100%;
  &:focus {
    outline: none;
  }
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  }
  @media (max-width: 780px) {
    height: 12rem;
    width: 90%;
  }
`;

export const AlbumButtonText = styled.span`
  background-color: #fff;
  bottom: 0;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-size: 0.75rem;
  left: 0;
  line-height: 1.5rem;
  overflow: hidden;
  position: absolute;
  padding: 0.1rem;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 100%;
  white-space: nowrap;
  p {
    padding: 0;
    margin: 0;
  }
`;

export const DateText = styled.p`
  font-size: 0.5rem;
  line-height: 1.2rem;
  color: #000000;
`;
export const AlbumsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  @media (max-width: 780px) {
    padding-top: 2rem;
    justify-content: center;
  }
`;

export const AlbumItem = styled(AlbumsBrowserItem)`
  width: 240px;
  max-width: 240px;
  min-width: 0;
  height: 200px;
  @media (max-width: 780px) {
    width: 80vw;
    max-width: 85vw;
    height: 200px;
    margin-top: 2rem;
  }
`;
