import { OptionType } from 'types/SelectDropdown';

export const PROJECTS_FETCH_COUNT = 200;
export const PROJECTS_PER_PAGE = 12;

export enum SortingDropdownOptions {
  MODIFIED_NEWEST = 'modified-newest',
  MODIFIED_OLDEST = 'modified-oldest',
  CREATED_NEWEST = 'created-newest',
  CREATED_OLDEST = 'created-oldest',
  ORDERED_NEWEST = 'ordered-newest',
  NAME = 'name',
  NAME_INVERSE = 'name-inverse'
}

export enum FilterProjectCategories {
  BOOKS = 'books',
  PRINTS = 'prints',
  FRAMES = 'frames',
  CALENDARS = 'calendars',
  CARDS = 'cards'
}

export const SORTING_OPTIONS: OptionType[] = [
  { value: SortingDropdownOptions.MODIFIED_NEWEST, label: 'Date Modified: Newest to Oldest' },
  { value: SortingDropdownOptions.MODIFIED_OLDEST, label: 'Date Modified: Oldest to Newest' },
  { value: SortingDropdownOptions.CREATED_NEWEST, label: 'Date Created: Newest to Oldest' },
  { value: SortingDropdownOptions.CREATED_OLDEST, label: 'Date Created: Oldest to Newest' },
  { value: SortingDropdownOptions.ORDERED_NEWEST, label: 'Date Ordered: Newest to Oldest' },
  { value: SortingDropdownOptions.NAME, label: 'Project Name: A-Z' },
  { value: SortingDropdownOptions.NAME_INVERSE, label: 'Project Name: Z-A' }
];

export const FILTERING_OPTIONS: OptionType[] = [
  { value: FilterProjectCategories.BOOKS, label: 'Photo Books' },
  { value: FilterProjectCategories.PRINTS, label: 'Prints' },
  { value: FilterProjectCategories.FRAMES, label: 'Frames' },
  { value: FilterProjectCategories.CALENDARS, label: 'Calendars' },
  { value: FilterProjectCategories.CARDS, label: 'Cards' }
];
