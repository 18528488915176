import { isNumber, isType, RuleSet, isString } from 'au-js-sdk/lib/types/typeguard';
import { isObject } from 'formik';

export interface ReturnToProjectData {
  sku: string;
  productName: string;
  projectName: string;
  projectOptions: Record<string, any>;
  projectUrl: string;
  percentComplete: number;
}

const returnToProjectDataRuleSet: RuleSet = {
  sku: isString,
  productName: isString,
  projectName: isString,
  projectOptions: isObject,
  projectUrl: isString,
  percentComplete: isNumber
};

export const isReturnToProjectData = (data: any): data is ReturnToProjectData =>
  isType(data, returnToProjectDataRuleSet);
