import React from 'react';
import { SingleInputForm, mediaQuery } from 'component-library';
import styled from 'styled-components';
import Grid from '../../../component-library/Grid';

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px #d2d2d0 solid;
  max-width: 100%;
  align-items: center;
  ${mediaQuery.medium`
    display: flex;
    flex-direction: row;
  `};
`;

export const DesktopFooterNav = styled.div`
  display: none;
  ${mediaQuery.medium`
    display: block;
  `};
`;

export const FooterNavWrapper = styled.div`
  display: block;
  margin: 4rem;
  ${mediaQuery.medium`
    display: flex;
    justify-content: space-between;
  `};
`;

export const FooterEmailForm = styled(SingleInputForm)`
  align-self: center;
  input,
  button {
    height: 37px;
  }
  input:not([type='submit']) {
    border: ${props => (props.error ? '1px solid red' : '1px #333333 solid')};
    height: auto;
  }
  input::placeholder {
  font-family: 'Lato', serif;
  font-size: 14px;
  }
  ${mediaQuery.medium`
    width: 100%;
  `};
`;

export const MobileFooterNav = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: grid;
  }
`;

export const CopyrightGrid = styled(Grid)`
  @media screen and (max-width: 768px) {
  flex-direction: column-reverse;
  justify-content: space-between;
  }
`;
