import React from 'react';
import { EsiBlock } from 'components/CMS/EsiBlock';
import { PromoBanner } from '../Layouts/styled';

const baseUrl = process.env.GATSBY_MAGENTO_BASE_URL;

export const PromoBannerESIBlock = () => (
  <EsiBlock
    esiTag={`<esi:include src="${baseUrl}/index.php/rest/V1/au/cmsBlock/top_banner?contentOnly=true"></esi:include>`}
    render={(content) => (
      <PromoBanner className="widget block block-banners">
        {/* The wrapping divs here are required to share styles with Magento */}
        <ul className="banner-items">
          <li className="banner-item">
            <div
              id="banner-item-content"
              className="banner-item-content"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </li>
        </ul>
      </PromoBanner>
    )}
    id="top_banner"
    innerHtmlContainerId="banner-item-content"
  />
);
