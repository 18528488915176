import React from 'react';
import { CLPStar } from 'components/Yotpo/YotpoStarsCLP';

interface YotpoStars {
  yotpoStars?: {
    totalReviews: number;
    score: number;
  };
}

interface StarsAndReviews {
  stars: YotpoStars[];
  reviews: number;
}

export const getStarsAndReviews: StarsAndReviews = (yotpoStars: YotpoStars, StarComponent = CLPStar) => {
  let stars = [];
  let totalReviews = 0;
  const maxStars = 5;

  if (!yotpoStars || !yotpoStars.score) {
    stars = new Array(maxStars).fill(0);
    stars = stars.map((_, index) => <StarComponent key={index} type="empty-star"/>);
  } else {
    const starScore = yotpoStars ? yotpoStars.score : 0;
    totalReviews = yotpoStars.totalReviews;
    const roundedScore = parseFloat(starScore.toFixed(2));
    const fullStarCount = Math.floor(roundedScore);
    const fractionalStarScore = Math.round((roundedScore - fullStarCount) * 100);

    stars = new Array(fullStarCount).fill(0);
    stars = stars.map((_, index) => <StarComponent key={index} type="star"/>);

    if (roundedScore < maxStars) {
      let nextStarType = 'star';
      if (fractionalStarScore < 34) {
        nextStarType = 'empty-star';
      } else if (fractionalStarScore >= 34 && fractionalStarScore < 66) {
        nextStarType = 'half-star';
      }

      stars.push(<StarComponent key={fullStarCount} type={nextStarType}/>);

      const emptyStars = maxStars - stars.length;

      if (emptyStars) {
        for (let i = fullStarCount + 1; i < maxStars; i++) {
          stars.push(<StarComponent key={i} type="empty-star"/>);
        }
      }
    }
  }
  return { stars, totalReviews };
};