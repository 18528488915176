import { isEqual } from 'lodash';

// Return the given content if the value x is truthy. Otherwise return null.
export const showIf = (content: any) => (x: any) => (x ? content : null);

export const showIfOrElse =
  (content: any) => (fallbackContent: any) => (x: any) => (x ? content : fallbackContent);

// // Return whether or not something is exactly equal to true.
// export const isTrue = (x: any) => (x === true);

// // Return the given content if all of the given booleans are exactly true. Otherwise return null.
// export const showIfAll = (content: any) => (...bs: Array<boolean>) => (bs.every(isTrue) ? content : null);

// // Checks if any values in an array are exactly true.
// export const any = (xs: Array<any>) => xs.some(isTrue);

// // Checks if all values in an array are exactly true.
// export const all = (xs: Array<any>) => xs.every(isTrue);

// // Wraps the given content in a given function if the given boolean is exactly true. Otherwise just returns the content.
// export const wrapIf = (content: any) => (wrapper: any => any) => (b: boolean): any => (
//   isTrue(b) ? wrapper(content) : content
// );

// // Takes an array of predicates and a value x and returns whether or not the value satifies every predicate.
// export const meetsAll = <T>(...predicates: Array<T => boolean>) => (x: T) => (
//   predicates.reduce((result, f) => result && f(x), true)
// );

// // Takes an array of predicates and a value x and returns whether or not the value satisfies at least one predicate.
// export const meetsAny = <T>(...predicates: Array<T => boolean>) => (x: T) => (
//   predicates.reduce((result, f) => result || f(x), false)
// );

// @flow

// String constant for default case.
const DEFAULT = 'DEFAULT';

// Checks that every even-indexed element is a function.
const casesValid = (cases: Array<any | Function>): boolean => cases.every(
    (element, index) => index % 2 === 0 || typeof element === 'function'
  );

// Returns the index of the first case that matches the expression. Otherwise returns -1.
const findMatchIndex = (expr: any, cases: Array<any | Function>): number => cases.findIndex(
    (element, index) => index % 2 === 0 && isEqual(expr, element)
  );

/*
  A mostly-pure match function, inspired by Rust's match statement.
  Cases of any type can be used.

  Example usage:

    match(
      'hello', () => 'howdy partner',
      'goodbye', () => 'see you around',
      match.default, () => 'still here?',
    )('goodbye');

  That would evaluate to 'see you around'.
*/
const arrayIncludesDefault = (array: Array<any>) => array.find(
    (element) => element && typeof element === 'string' && element.includes(DEFAULT)
  );
export const match =
  (...cases: Array<any | Function>) => (expr: any): any => {
    if (cases.length % 2) {
      // Throw error if cases array isn't even
      throw new Error(
        'Match: Passed array contains an uneven number of elements.'
      );
    } else if (!arrayIncludesDefault(cases)) {
      // Throw error if cases array doesn't include a default case
      throw new Error('Match: Passed array does not contain a default case.');
    } else if (!casesValid(cases)) {
      // Throw error if cases array is otherwise invalid
      throw new Error('Match: Passed array is invalid.');
    } else {
      const matchedIndex = findMatchIndex(expr, cases);

      return matchedIndex === -1
        ? cases[findMatchIndex(DEFAULT, cases) + 1]()
        : cases[matchedIndex + 1]();
    }
  };

match.default = DEFAULT;

export const has =
  (obj?: Object) => (prop: string): boolean => obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    Object.prototype.hasOwnProperty.call(obj, prop);

// Return whether or not something is exactly equal to true.
export const isTrue = (x: any) => x === true;

// Return the given content if all of the given booleans are exactly true. Otherwise return null.
export const showIfAll =
  (content: any) => (...bs: Array<boolean>) => (bs.every(isTrue) ? content : null);

// Checks if any values in an array are exactly true.
export const any = (xs: Array<any>) => xs.some(isTrue);

// Checks if all values in an array are exactly true.
export const all = (xs: Array<any>) => xs.every(isTrue);

// Wraps the given content in a given function if the given boolean is exactly true. Otherwise just returns the content.
export const wrapIf =
  (content: any) => (wrapper: (value: any) => any) => (b: boolean): any => (isTrue(b) ? wrapper(content) : content);

// Takes an array of predicates and a value x and returns whether or not the value satifies every predicate.
export const meetsAll =
  <T>(...predicates: any[]) => (x: T) => predicates.reduce((result, f) => result && f(x), true);

// Takes an array of predicates and a value x and returns whether or not the value satisfies at least one predicate.
export const meetsAny =
  <T>(...predicates: any[]) => (x: T) => predicates.reduce((result, f) => result || f(x), false);

// Helper function for matching against an ADT.
export function matchAdt<A, B>(matcher: A): (match: (matcher: A) => B) => B {
  return (match) => match(matcher);
}

export const isNotServerSideRendering = () => typeof window !== 'undefined';
