import styled from 'styled-components';
import { ProductOption } from 'features/pdp3/components/ProductOption';
import { StyledRadioGroup } from 'au-gatsby-ds/components/SwatchSelect/styled';
import { StyledTextSwatch, TextSwatchContainer } from 'au-gatsby-ds/components/SwatchSelect/TextSwatch/styled';

export const LivePreviewModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  div.kvfysmfp {
    height: 100%;
    background-color: #F0F0F0;
  }
`;

export const ModalInputsContainer = styled.div`
  padding: 24px 0 32px 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: auto;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
`;

export const ModalInputsHeader = styled.label`
  font-family: 'Crimson Text, Minion Pro';
  font-weight: 200;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: #000;
`;

export const ModalInputSelector = styled(ProductOption)`
  margin: 20px auto 0 auto;

  ${StyledRadioGroup} {
    margin: 0 auto;
    gap: 0px;
  }

  ${TextSwatchContainer} {
    flex-grow: 1;
    margin 0;

    :first-child {
        > label {
            border-radius: 2px 0px 0px 2px;
        }
    }
    
    :last-child {
        > label {
            border-radius: 0px 2px 2px 0px;
        }
    }

    :first-child:last-child {
        > label {
            border-radius: 2px;
        }
    }
  }

  ${StyledTextSwatch} {
    width: auto;
    text-transform: capitalize;
  }
`;
