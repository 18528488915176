import { ReactNode } from 'react';
import { showIf } from 'helpers/conditionals';

interface ShowProps {
  when: boolean
  children: ReactNode
}

const Show = ({ when, children }: ShowProps) => showIf(when)(children);

export default Show;
