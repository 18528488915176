import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../Grid/index';

interface StepProps {
  number: number;
  text: string;
  isActive: boolean;
  isLast: boolean;
}

interface StepNumberProps {
  isActive: boolean;
}

const StepContainer = styled.div`
  display: none;
  flex-direction: column;
  align-items: center;
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: 11px;
  width: fit-content;
  margin: 0 5px;

  ${mediaQuery.medium`
    display: flex;
  `}
`;

const StepNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  border: 1px solid black;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 8px;
  line-height: 1.7rem;

  ${(props: StepNumberProps) => props.isActive
    && css`
      background-color: #000000;
      color: #ffffff;
    `}
`;

const StepText = styled.span`
  letter-spacing: 3px;
  text-transform: uppercase;
  min-width: 50px;
  max-width: 100px;
  word-break: break-word;
  text-align: center;
`;

const StepPaddingLine = styled.div`
  width: 60px;
  height: 0;
  border-top: 1px solid #000000;
  margin-top: ${1.75 / 2}rem;
  display: none;

  ${mediaQuery.medium`
    display: flex;
  `}
`;

const Step = ({
  number,
  text,
  isActive = false,
  isLast = false
}: StepProps) => (
  <>
    <StepContainer>
      <StepNumber isActive={isActive}>{number}</StepNumber>
      <StepText>{text}</StepText>
    </StepContainer>
    {!isLast ? <StepPaddingLine /> : null}
  </>
);

export default Step;
