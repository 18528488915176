import styled from 'styled-components';

export const StyledOptionContainer = styled.div`
  margin: 20px auto 40px auto;
  min-width: 300px;
`;

export const StyledRadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  flex: 1;
  margin: 0px 8px 8px 0px;
  position: relative;
  text-align: center;

  label {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
    :focus {
      outline: none;
    }
  }
`;

export const StyledH4 = styled.h4`
  color: #000;
  font-family: Lato, 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  display: block;
  font-size: 14px;
  text-transform: lowercase;
  line-height: 14px;
  margin-bottom: 20px;
  margin-top: 0;

  ::first-letter {
    text-transform: capitalize;
  }

  @media (min-width: 48em) {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledAttributeValue = styled.span`
  color: #757575;
  padding-left: 0.25rem;
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  opacity: 0;

  :checked,
  :hover {
    color: #000;
    border: 1px solid #000;
    z-index: 1 !important;
  }
`;
