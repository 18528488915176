import { Reviews } from 'au-gatsby-ds/components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useYotpo } from 'components/Yotpo/useYotpo';
import { getStarsAndReviews } from 'components/Yotpo/helpers';

type YotpoStarsProductProps = {
  apiKey: string;
  productId?: string;
  productName?: string;
  yotpoStars?: {
    totalReviews: number;
    score: number;
  };
  useNewStyles?: boolean;
};

const StyledYotpoStars = styled.div<{ useNewStyles: boolean }>`
  &.yotpo span {
    ${props => props.useNewStyles && Reviews}
  }

  &.yotpo {
    height: 20px;

    .yotpo-stars {
      margin-right: 4px;
    }

    .yotpo-bottomline {
      .yotpo-icon-star {
        color: #000000;
      }

      .yotpo-icon-half-star:after {
        content: '\\e61a';
        position: absolute;
        left: 0;
        top: 0;
        color: #000000;
        width: 48%;
        overflow: hidden;
      }
    }

    .yotpo-regular-box {
      padding: 0;
      margin: 0;
      border: none;
    }

    .yotpo-small-box,
    .yotpo-first-review,
    .yotpo-review,
    .yotpo-pager {
      display: none;
    }

    .text-m {
      color: #080707;
    }
  }

  .write-review-btn-hidden {
    display: none;
  }
`;

const Star = ({ type }) => <span className={`yotpo-icon yotpo-icon-${type} rating-star pull-left`}></span>;

const YotpoStars: React.FC<YotpoStarsProductProps> = ({ apiKey, yotpoStars, useNewStyles = false, StarComponent = Star }: YotpoStarsProductProps) => {
  useYotpo(apiKey);

  const { stars, totalReviews } = getStarsAndReviews(yotpoStars, StarComponent);

  const [anchorTarget, setAnchorTarget] = useState(null);

  const handleClick = event => {
    event.preventDefault();
    anchorTarget.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    setAnchorTarget(document.getElementById('yotpo-reviews-anchor'));
  }, []);

  return (
    <StyledYotpoStars useNewStyles={useNewStyles} className="yotpo">
      <a href="#yotpo-reviews-anchor" onClick={handleClick}>
        <div className="standalone-bottomline">
          <div className="yotpo-bottomline pull-left star-clickable">
            <span className="yotpo-stars">{stars}</span>
            <span className={useNewStyles ? '' : 'text-m'}>{totalReviews} Reviews</span>
          </div>
        </div>
      </a>
    </StyledYotpoStars>
  );
};

export default YotpoStars;
