import { FAILURE, FETCHING, INITIALIZED, SUCCESS } from '../constants';
import {
  APPLY_GIFT_CARD_FAILURE,
  APPLY_GIFT_CARD_REQUEST,
  APPLY_GIFT_CARD_SUCCESS,
  APPLY_GIFT_WRAP_FAILURE,
  APPLY_GIFT_WRAP_REQUEST,
  APPLY_GIFT_WRAP_SUCCESS,
  APPLY_PAYMENT_METHOD,
  CLEAR_PAYMENT_METHOD,
  APPLY_PROMO_CODE_FAILURE,
  APPLY_PROMO_CODE_REQUEST,
  APPLY_PROMO_CODE_SUCCESS,
  APPLY_SHIPPING_ADDRESS_FAILURE,
  APPLY_SHIPPING_ADDRESS_REQUEST,
  APPLY_SHIPPING_ADDRESS_SUCCESS,
  APPLY_SHIPPING_METHOD_REQUEST,
  APPLY_STORE_CREDIT_FAILURE,
  APPLY_STORE_CREDIT_REQUEST,
  APPLY_STORE_CREDIT_SUCCESS,
  BRAINTREE_VALIDATION_FAILURE,
  BRAINTREE_VALIDATION_REQUEST,
  BRAINTREE_VALIDATION_SUCCESS,
  CartAction,
  CartLoadingStates,
  CartState,
  FETCH_CART_FAILURE,
  FETCH_CART_REQUEST,
  FETCH_CART_SUCCESS,
  FETCH_SHIPPING_RATES_FAILURE,
  FETCH_SHIPPING_RATES_REQUEST,
  FETCH_SHIPPING_RATES_SUCCESS,
  PLACE_ORDER_FAILURE,
  PLACE_ORDER_REQUEST,
  PLACE_ORDER_SUCCESS,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  REMOVE_CART_ITEM_FAILURE,
  REMOVE_CART_ITEM_REQUEST,
  REMOVE_CART_ITEM_SUCCESS,
  REMOVE_GIFT_CARD_FAILURE,
  REMOVE_GIFT_CARD_REQUEST,
  REMOVE_GIFT_CARD_SUCCESS,
  REMOVE_PROMO_CODE_FAILURE,
  REMOVE_PROMO_CODE_REQUEST,
  REMOVE_PROMO_CODE_SUCCESS,
  REMOVE_STORE_CREDIT_FAILURE,
  REMOVE_STORE_CREDIT_REQUEST,
  REMOVE_STORE_CREDIT_SUCCESS,
  SAVE_PAYMENT_METHOD_REQUEST,
  SAVE_PAYMENT_METHOD_SUCCESS,
  UPDATE_CART_ITEM_QTY_FAILURE,
  UPDATE_CART_ITEM_QTY_REQUEST,
  UPDATE_CART_ITEM_QTY_SUCCESS,
  APPLY_SHIPPING_METHOD_SUCCESS,
  FETCH_TOTALS_SUCCESS,
  FETCH_TOTALS_REQUEST,
  SAVE_PAYMENT_METHOD_FAILURE,
  APPLY_AMAZON_PAY_ADDRESS_REQUEST,
  APPLY_AMAZON_PAY_ADDRESS_SUCCESS,
  APPLY_AMAZON_PAY_ADDRESS_FAILURE,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_REQUEST,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_SUCCESS,
  UPDATE_AMAZON_PAY_CHECKOUT_SESSION_FAILURE,
  DELETE_CART_PAYMENT_METHODS_REQUEST,
  DELETE_CART_PAYMENT_METHODS_SUCCESS,
  DELETE_CART_PAYMENT_METHODS_FAILURE,
  SET_FREE_SHIPPING_APPLIED,
  QUEUE_ADD_ITEM_TO_CART_PAYLOAD
} from './constants';

import {
  APPLY_AFTERPAY_PAYMENT_METHOD,
  APPLY_AFTERPAY_REDIRECT_URL,
  APPLY_AFTERPAY_SESSION_ID,
  APPLY_AFTERPAY_CHECKOUT_TYPE,
  APPLY_AFTERPAY_CHECKSUM
} from 'features/afterpay/store/constants';

import {
  CartLoadableEntity,
  updateCartFailure,
  updateCartFetching,
  updateCartSuccess,
  updateCartNotFound
} from './helpers';
import { getInternalPaymentMethodsFromTotalSegments, pullUIEntitesOutOfQuoteDataCart } from './magentoSelectors';
import { immutableUnshift } from 'helpers/arrays';
import { findCustomerPaymentMethodInCartPaymentMethods } from '../../helpers/billing/index';
import { ERR_OOS } from 'store/magento/constants';
import { GlobalAction, CLEAR_STORE } from '../global/constants';

const initCartLoadingStates: CartLoadingStates = {
  cart: INITIALIZED,
  cartItems: INITIALIZED,
  shippingAddress: INITIALIZED,
  paymentMethods: INITIALIZED,
  shippingRates: INITIALIZED,
  promoCode: INITIALIZED,
  giftCard: INITIALIZED,
  placeOrder: INITIALIZED,
  storeCredit: INITIALIZED
};

const initCartState: CartState = {
  loadingStates: initCartLoadingStates,
  entity: null,
  queuedAddToCartItemPayload: null
};

export default (state: CartState = initCartState, action: CartAction | GlobalAction) => {
  const updateWithFetching = (entities: CartLoadableEntity[]) => updateCartFetching(entities)(state.loadingStates);
  const updateWithSuccess = (entities: CartLoadableEntity[]) => updateCartSuccess(entities)(state.loadingStates);
  const updateWithFailure = (entities: CartLoadableEntity[]) => updateCartFailure(entities)(state.loadingStates);
  const updateWithNotFound = (entities: CartLoadableEntity[]) => updateCartNotFound(entities)(state.loadingStates);

  switch (action.type) {
    case APPLY_GIFT_WRAP_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart']),
        entity: {
          ...state.entity,
          items: state.entity.items.map(x =>
            x.item_id !== action.payload.item_id
              ? x
              : {
                  ...x,
                  extension_attributes: {
                    ...x.extension_attributes,
                    gift_wrapping: action.payload.extension_attributes.gift_wrapping,
                    gift_message: {
                      ...action.payload.extension_attributes.gift_message
                    }
                  }
                }
          )
        },
        err: null
      };
    }
    case APPLY_GIFT_WRAP_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['cart']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments
        },
        err: null
      };
    }
    case APPLY_GIFT_WRAP_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['cart']),
        err: action.payload.err
      };
    }
    case FETCH_CART_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart', 'cartItems', 'giftCard', 'promoCode']),
        err: null
      };
    }
    case FETCH_CART_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['cart', 'cartItems', 'giftCard', 'promoCode']),
        entity: {
          ...state.entity,
          ...action.payload.cart,
          // Bring frequently accessed data to the top level
          ...pullUIEntitesOutOfQuoteDataCart(state, action)
        },
        err: null
      };
    }
    case FETCH_CART_FAILURE: {
      const { response } = action.payload;
      return {
        ...state,
        loadingStates:
          response && response.statusCode === 404 ? updateWithNotFound(['cart']) : updateWithFailure(['cart']),
        err: action.payload.err
      };
    }

    case FETCH_TOTALS_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart'])
      };
    }

    case FETCH_TOTALS_SUCCESS: {
      const totalSegments = action.payload;
      return {
        ...state,
        loadingStates: updateWithSuccess(['cart']),
        entity: {
          ...state.entity,
          total_segments: totalSegments
        }
      };
    }

    case UPDATE_CART_ITEM_QTY_REQUEST: {
      const { quantity, cartItemId } = action.payload;
      // Eagerly update state to reflect the updated quantity that will be sent in the request
      return {
        ...state,
        loadingStates: updateWithFetching(['cartItems', 'cart']),
        entity: {
          ...state.entity,
          items: state.entity.items.map(x =>
            x.item_id !== cartItemId
              ? x
              : {
                  ...x,
                  qty: quantity
                }
          )
        },
        err: null
      };
    }
    case UPDATE_CART_ITEM_QTY_SUCCESS: {
      const { item, previousItemId } = action.payload;
      return {
        ...state,
        loadingStates: updateWithSuccess(['cartItems', 'cart']),
        entity: {
          ...state.entity,
          items: state.entity.items.map(x => ({
            ...(x.item_id === previousItemId
              ? {
                  ...x,
                  item_id: item.item_id,
                  qty: item.qty,
                  price: item.price,
                  ...(item.product_option || x.product_option
                    ? {
                        product_option: {
                          ...(x.product_option || {}),
                          ...(item.product_option || {})
                        }
                      }
                    : {})
                }
              : x)
          }))
        }
      };
    }
    case UPDATE_CART_ITEM_QTY_FAILURE: {
      const { err, item, oldQuantity } = action.payload;
      return {
        ...state,
        err,
        loadingStates: updateWithFailure(['cartItems', 'cart']),
        entity: {
          ...state.entity,
          items: state.entity.items.map(x =>
            x.item_id === item.item_id
              ? {
                  ...x,
                  qty: oldQuantity
                }
              : x
          )
        }
      };
    }
    case REMOVE_CART_ITEM_REQUEST: {
      const { item } = action.payload;

      return {
        ...state,
        loadingStates: updateWithFetching(['cartItems']),
        entity: {
          ...state.entity,
          items: state.entity.items.filter(x => x.item_id !== item.item_id)
        },
        err: null
      };
    }
    case QUEUE_ADD_ITEM_TO_CART_PAYLOAD:
      return {
        ...state,
        queuedAddToCartItemPayload: action.payload
      };
    case ADD_TO_CART_SUCCESS:
    case REMOVE_CART_ITEM_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['cartItems']),
        queuedAddToCartItemPayload: null,
        err: null
      };
    }
    case ADD_TO_CART_FAILURE:
    case REMOVE_CART_ITEM_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['cartItems']),
        queuedAddToCartItemPayload: null,
        err: action.payload.err
      };
    }
    case APPLY_SHIPPING_ADDRESS_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['shippingAddress']),
        err: null
      };
    }
    case APPLY_SHIPPING_ADDRESS_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['shippingAddress']),
        entity: {
          ...state.entity,
          ...action.payload.cart,
          shippingAddress: action.payload.address,
          // Bring frequently accessed data to the top level
          ...pullUIEntitesOutOfQuoteDataCart(state, action)
        },
        err: null
      };
    }
    case APPLY_SHIPPING_ADDRESS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['shippingAddress']),
        err: action.payload.err
      };
    }
    case APPLY_AMAZON_PAY_ADDRESS_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['shippingAddress']),
        err: null
      };
    }
    case APPLY_AMAZON_PAY_ADDRESS_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['shippingAddress']),
        entity: {
          ...state.entity,
          ...action.payload.cart,
          // Bring frequently accessed data to the top level
          ...pullUIEntitesOutOfQuoteDataCart(state, action)
        },
        amazonPayRedirectUrl: action.payload.amazonPayRedirectUrl,
        err: null
      };
    }
    case APPLY_AMAZON_PAY_ADDRESS_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['shippingAddress']),
        err: action.payload.err
      };
    }
    case UPDATE_AMAZON_PAY_CHECKOUT_SESSION_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(action.payload.entities || ['cart']),
        err: null
      };
    }
    case UPDATE_AMAZON_PAY_CHECKOUT_SESSION_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(action.payload.entities || ['cart']),
        err: null
      };
    }
    case UPDATE_AMAZON_PAY_CHECKOUT_SESSION_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(action.payload.entities || ['cart']),
        err: action.payload.err
      };
    }
    case APPLY_PROMO_CODE_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart', 'promoCode']),
        promoCodeErr: null
      };
    }
    case APPLY_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['cart', 'promoCode']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments
        },
        promoCodeErr: null
      };
    }
    case APPLY_PROMO_CODE_FAILURE: {
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          cart: SUCCESS,
          promoCode: FAILURE
        },
        promoCodeErr: action.payload.err
      };
    }
    case REMOVE_PROMO_CODE_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['promoCode', 'cart']),
        promoCodeErr: null
      };
    }
    case REMOVE_PROMO_CODE_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['promoCode', 'cart']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments
        },
        promoCodeErr: null
      };
    }
    case REMOVE_PROMO_CODE_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['promoCode', 'cart']),
        promoCodeErr: action.payload.err
      };
    }
    case FETCH_SHIPPING_RATES_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['shippingRates', 'cart']),
        err: null
      };
    }
    case FETCH_SHIPPING_RATES_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['shippingRates', 'cart']),
        entity: {
          ...state.entity,
          shippingRates: action.payload.shippingRates
        },
        err: null
      };
    }
    case FETCH_SHIPPING_RATES_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['shippingRates']),
        err: action.payload.err
      };
    }
    case BRAINTREE_VALIDATION_REQUEST: {
      return {
        ...state,
        braintreeValidationState: FETCHING
      };
    }
    case BRAINTREE_VALIDATION_FAILURE: {
      return {
        ...state,
        braintreeValidationState: FAILURE
      };
    }
    case BRAINTREE_VALIDATION_SUCCESS: {
      return {
        ...state,
        braintreeValidationState: SUCCESS
      };
    }
    // This is actually adding a cc to the customers payment methods
    case SAVE_PAYMENT_METHOD_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['paymentMethods']),
        err: null
      };
    }
    case SAVE_PAYMENT_METHOD_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['paymentMethods']),
        err: null
      };
    }
    case SAVE_PAYMENT_METHOD_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['paymentMethods']),
        err: action.payload.err
      };
    }
    case APPLY_PAYMENT_METHOD: {
      return {
        ...state,
        entity: {
          ...state.entity,
          paymentMethods: immutableUnshift(action.payload.paymentMethod)(state.entity.paymentMethods)
        },
        err: null
      };
    }
    case APPLY_AFTERPAY_PAYMENT_METHOD: {
      return {
        ...state,
        entity: {
          ...state.entity,
          paymentMethods: immutableUnshift(action.payload.paymentMethod)(state.entity.paymentMethods)
        },
        err: null
      };
    }

    case APPLY_AFTERPAY_REDIRECT_URL: {
      return {
        ...state,
        afterpayRedirectUrl: action.payload,
        err: null
      };
    }

    case APPLY_AFTERPAY_SESSION_ID: {
      return {
        ...state,
        afterpaySessionId: action.payload,
        err: null
      };
    }
    case APPLY_AFTERPAY_CHECKOUT_TYPE: {
      return {
        ...state,
        afterpayCheckoutType: action.payload,
        err: null
      };
    }
    case APPLY_AFTERPAY_CHECKSUM: {
      return {
        ...state,
        afterpayChecksum: action.payload,
        err: null
      };
    }
    case CLEAR_PAYMENT_METHOD: {
      return {
        ...state,
        entity: {
          ...state.entity,
          paymentMethods: []
        },
        err: null
      };
    }
    case DELETE_CART_PAYMENT_METHODS_REQUEST: {
      const { paymentMethodToken } = action.payload;

      return {
        ...state,
        entity: {
          ...state.entity,
          paymentMethods: state.entity.paymentMethods.filter(x => x.token !== paymentMethodToken)
        },
        err: null
      };
    }
    case DELETE_CART_PAYMENT_METHODS_SUCCESS: {
      return {
        ...state,
        err: null
      };
    }
    case DELETE_CART_PAYMENT_METHODS_FAILURE: {
      return {
        ...state,
        err: action.payload.err
      };
    }
    case PLACE_ORDER_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['placeOrder']),
        err: null
      };
    }
    case PLACE_ORDER_SUCCESS: {
      return {
        loadingStates: updateWithSuccess(['placeOrder']),
        completedOrder: action.payload.order,
        err: null
      };
    }
    case PLACE_ORDER_FAILURE: {
      return {
        ...state,
        braintreeValidationState: FAILURE,
        loadingStates: updateWithFailure(['placeOrder']),
        err: action.payload.err
      };
    }
    case APPLY_SHIPPING_METHOD_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart']),
        err: null,
        entity: {
          ...state.entity,
          shippingMethod: action.payload.shippingMethod
        }
      };
    }
    case SET_FREE_SHIPPING_APPLIED: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart']),
        err: null,
        entity: {
          ...state.entity,
          hasFreeShippingBeenApplied: action.payload.hasBeenApplied
        }
      };
    }
    case APPLY_SHIPPING_METHOD_SUCCESS: {
      return {
        ...state,
        loadingStates: updateWithSuccess(['cart']),
        err: null,
        entity: {
          ...state.entity,
          ...action.payload.cart,
          // Bring frequently accessed data to the top level
          ...pullUIEntitesOutOfQuoteDataCart(state, action)
        }
      };
    }
    case APPLY_STORE_CREDIT_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['storeCredit']),
        err: null
      };
    }
    case APPLY_STORE_CREDIT_SUCCESS: {
      const { totalSegments } = action.payload;
      return {
        ...state,
        loadingStates: updateWithSuccess(['storeCredit']),
        entity: {
          ...state.entity,
          total_segments: totalSegments,
          paymentMethods: getInternalPaymentMethodsFromTotalSegments(totalSegments)
        },
        siteCreditErr: null
      };
    }
    case APPLY_STORE_CREDIT_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['storeCredit']),
        siteCreditErr: action.payload.err
      };
    }
    case REMOVE_STORE_CREDIT_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['storeCredit']),
        siteCreditErr: null
      };
    }
    case REMOVE_STORE_CREDIT_SUCCESS: {
      // If the user had a payment method selected before, preserve that payment method
      const maybePayment = findCustomerPaymentMethodInCartPaymentMethods(state.entity.paymentMethods);
      const paymentMethods = maybePayment.token
        ? [maybePayment, ...state.entity.paymentMethods.filter(x => x.type !== 'storecredit')]
        : [...state.entity.paymentMethods.filter(x => x.type !== 'storecredit')];

      return {
        ...state,
        loadingStates: updateWithSuccess(['storeCredit']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments,
          paymentMethods
        },
        siteCreditErr: null
      };
    }
    case REMOVE_STORE_CREDIT_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['storeCredit']),
        siteCreditErr: action.payload.err
      };
    }
    case APPLY_GIFT_CARD_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart', 'giftCard']),
        giftCardErr: null
      };
    }
    case APPLY_GIFT_CARD_SUCCESS: {
      const maybePayment = findCustomerPaymentMethodInCartPaymentMethods(state.entity.paymentMethods);
      const paymentMethods = maybePayment.token
        ? [maybePayment, ...getInternalPaymentMethodsFromTotalSegments(action.payload.totalSegments)]
        : [...getInternalPaymentMethodsFromTotalSegments(action.payload.totalSegments)];

      return {
        ...state,
        loadingStates: updateWithSuccess(['cart', 'giftCard']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments,
          paymentMethods
        },
        giftCardErr: null
      };
    }
    case APPLY_GIFT_CARD_FAILURE: {
      return {
        ...state,
        loadingStates: {
          ...state.loadingStates,
          cart: SUCCESS,
          giftCard: FAILURE
        },
        giftCardErr: action.payload.err
      };
    }
    case REMOVE_GIFT_CARD_REQUEST: {
      return {
        ...state,
        loadingStates: updateWithFetching(['cart', 'giftCard']),
        giftCardErr: null
      };
    }
    case REMOVE_GIFT_CARD_SUCCESS: {
      const maybePayment = findCustomerPaymentMethodInCartPaymentMethods(state.entity.paymentMethods);
      const paymentMethods = maybePayment.token
        ? [maybePayment, ...getInternalPaymentMethodsFromTotalSegments(action.payload.totalSegments)]
        : getInternalPaymentMethodsFromTotalSegments(action.payload.totalSegments);

      return {
        ...state,
        loadingStates: updateWithSuccess(['cart', 'giftCard']),
        entity: {
          ...state.entity,
          total_segments: action.payload.totalSegments,
          paymentMethods
        },
        giftCardErr: null
      };
    }
    case REMOVE_GIFT_CARD_FAILURE: {
      return {
        ...state,
        loadingStates: updateWithFailure(['cart', 'giftCard']),
        giftCardErr: action.payload.err
      };
    }
    case ERR_OOS: {
      return {
        ...state,
        braintreeValidationState: FAILURE,
        loadingStates: updateWithFailure(['placeOrder'])
      };
    }
    case CLEAR_STORE: {
      return initCartState;
    }
    default: {
      return {
        ...state
      };
    }
  }
};
