import { TrackingProp } from 'react-tracking';
import { useEffect } from 'react';
import { PageViewedEvent } from '../analyticsWrapper';
import { PageViewedProperties } from 'itly';

export function useTrackPageViewed(
  tracking: TrackingProp<PageViewedEvent>,
  pageType: string,
  pageName: string,
  ...dependencies: any[]
) {
  useEffect(() => {
    const data: PageViewedProperties = { page_type: pageType, page_name: pageName };
    tracking.trackEvent({ type: 'pageViewed', data });
  }, dependencies);
}
