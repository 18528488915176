import { newrelic } from 'helpers/reporting/newrelic';
import { getCustomAttribute, getCustomerFlashId, getCustomerFlashToken } from 'helpers/mage-helpers/magento';
import { getCrudCoreBaseUrl, getGatsbySiteUrl, getYotpoApiKey } from '../../../helpers/env';

export const prependProductURL = (canonical_url: string): string => !/^https?:\/\//i.test(canonical_url) ? `${getGatsbySiteUrl()}/${canonical_url}` : canonical_url;

export const postOrderToYotpo = async (order, customer) => {
  const flashId = getCustomerFlashId(customer);
  const flashToken = getCustomerFlashToken(customer);

   try {
    const orderData = {
      order_id: order.increment_id,
      email: order.customer_email,
      customer_name: `${order.customer_firstname} ${order.customer_lastname}`,
      order_date: order.created_at
    };
    // Filtering out the child product to only send parent
    const productData = order.items
      .filter(item => !item.parent_item)
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.product_id]: {
            name: item.name && item.name.trim(),
            url: prependProductURL(
              getCustomAttribute('canonical_url')(item.extension_attributes.product.custom_attributes)
            ),
            price: item.price
          }
        }),
        {}
      );

    const response = await fetch(`${getCrudCoreBaseUrl()}/api/user/third-party-auth/yotpo/user-token`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${flashToken}` 
      },
      body: JSON.stringify({ flashId })
    });
    const uToken = await response.json();
    const yotpoOrderResponse = await fetch(`https://api.yotpo.com/apps/${getYotpoApiKey()}/purchases/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...orderData,
        validate_data: true,
        platform: 'magento',
        utoken: uToken.access_token,
        products: productData
      })
    });
    const content = await yotpoOrderResponse.json();
   } catch (e) {
    newrelic('noticeError')(e);
  } 
};
