import React from 'react';
import styled from 'styled-components';
import Icon from '../../../icons';

type PhotoItemContainerProps = {
  fullSize?: boolean
};

type PhotoImageProps = {
  fullSize?: boolean
};

export const PhotoItemContainer = styled.div`
  display: inline-block;
  background-size: contain;
  background-position: center;
  cursor: pointer;
  width: 190px;
  height: 150px;
  max-height: 150px;
  position: relative;
  transition: box-shadow 0.4s linear;
  background-repeat: no-repeat;
  @media (max-width: 780px) {
    display: flex;
    justify-content: center;
    max-width: 90vw;
    max-height: 65vh;
    min-height: 10rem;
    width: 90vw;
    height: 200px;
    background-repeat: no-repeat;
  }
`;

export const PhotoItemContainerFullSize = styled(PhotoItemContainer)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30rem;
  height: auto;
  max-height: none;
  @media (max-width: 780px) {
    width: 90vw;
    height: fit-content;
  }  
`;

export const PhotoImage = styled.img`
  display: block;
  max-width:190px;
  max-height:190px;
  width: auto;
  height: auto;
  &:focus {
      outline:none
  };
  &:hover {
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
  }
  @media (max-width: 780px) {
    max-width: 90vw;
    max-height: none;
    background-repeat: no-repeat;
  }
`;

export const PhotoImageFullSize = styled(PhotoImage)`
  max-width: none;
  max-height: none;
  width: inherit;
  @media (max-width: 780px) {
    max-width: 70vw;
  }
`;
export const CheckIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
    height: 2.9rem;
    width: 2.9rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 30%;
  `;
