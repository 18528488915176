import { useEffect } from 'react';
import LazyLoad from 'vanilla-lazyload';

const NOOP = () => null;

const lazyLoadOptions = {
  threshold: 50,
  elements_selector: '.lazy',
  callback_enter: NOOP,
  callback_exit: NOOP,
  callback_cancel: NOOP,
  callback_loading: NOOP,
  callback_loaded: NOOP,
  callback_error: NOOP,
  callback_finish: NOOP
};

declare global {
  interface Window {
    lazyLoadInstance: any;
  }
}

export default function useLazyLoad() {
  useEffect(() => {
    // Only initialize it one time for the entire application
    if (!window.lazyLoadInstance) {
      window.lazyLoadInstance = new LazyLoad(lazyLoadOptions);
    }

    window.lazyLoadInstance.update();
  });
}
