import React, { ChangeEventHandler, MouseEventHandler, RefObject, useEffect, useState } from 'react';
import { Icon } from '../Svg/icon';
import {
  InformationIcon,
  StyledAttributeValue,
  StyledH3,
  StyledOptionContainer,
  StyledRadioGroup,
  SwatchTitleContainer
} from './styled';
import { Modal, ModalType } from '../../../../src/store/modal/constants';
import { checkScrollElement } from '../../../../src/features/pdp3/helpers/productOption';

export const scrollIntoSelectedSwatch = (selectedSwatchId: string, currentModal: Modal): void => {
  if (selectedSwatchId) {
    const currentModalType = currentModal.type;
    try {
      const inputContainerElements = document.querySelectorAll(`[id=${CSS.escape(selectedSwatchId)}]`);
      inputContainerElements.forEach(element => {
        if (currentModalType === ModalType.LIVE_PREVIEW_MODAL || currentModalType === ModalType.PRODUCT_OPTIONS_MODAL) {
          //If we are in the modal, and we change the selected swatch, scroll to the element on the pdp
          checkScrollElement(element, '.PdpApp');
        }
      });
    } catch (error) {
      console.error('Error scrolling into selected swatch: ', error);
    }
  }
};

export interface SwatchSelectProps {
  id: string;
  label: string;
  displayName: string;
  hoverLabel?: string;
  className?: string;
  children: JSX.Element[];
  ref?: RefObject<HTMLDivElement>;
  overflowWrap?: boolean;
  gridLayout?: boolean;
  onTitleClick?: () => void;
  onDoubleClick?: any;
  onDoubleTap?: () => void;
  hideLabels?: boolean;
  selectedFieldId?: string;
  currentModal: Modal;
}
export interface SwatchProps {
  id: string;
  name: string;
  title?: string;
  label: string;
  displayName?: string;
  mediaUrl?: string;
  backgroundUrl?: string;
  hoverImage?: string;
  checked?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: ChangeEventHandler;
  onMouseOver?: MouseEventHandler<HTMLLabelElement>;
  onMouseOut?: MouseEventHandler<HTMLLabelElement>;
}
const SwatchSelect = ({
  id,
  label,
  displayName,
  hoverLabel,
  className,
  children,
  overflowWrap,
  gridLayout,
  hideLabels,
  onTitleClick,
  onDoubleClick,
  onDoubleTap,
  selectedFieldId,
  currentModal
}: SwatchSelectProps): JSX.Element => {
  const [tapCount, setTapCount] = useState(0);
  const handleTouchStart = () => {
    if (tapCount === 1) {
      setTapCount(0);
      if (onDoubleTap) {
        onDoubleTap();
      }
    } else {
      setTapCount(tapCount + 1);
      setTimeout(() => setTapCount(0), 300);
    }
  };

  const [isMouseHover, setIsMouseHover] = useState(false);

  useEffect(() => {
    const isMobile = typeof window === 'object' && window.innerWidth < 768;
    isMobile && scrollIntoSelectedSwatch(`option-value-label-${selectedFieldId}`, currentModal);
  }, [selectedFieldId]);

  return (
    <StyledOptionContainer
      id={`option-${id}`}
      className={className}
      onDoubleClick={onDoubleClick}
      onTouchStart={handleTouchStart}
      onMouseEnter={() => setIsMouseHover(true)}
      onMouseLeave={() => setIsMouseHover(false)}
    >
      {!hideLabels && (
        <SwatchTitleContainer>
          <StyledH3 id={`option-title-${id}`} aria-label={label} onClick={onTitleClick}>
            {displayName.toLowerCase()}
          </StyledH3>
          <StyledAttributeValue id={`${label}-selected-option-value`} onClick={onTitleClick}>
            {hoverLabel}
          </StyledAttributeValue>
          {onTitleClick && (
            <InformationIcon visible={isMouseHover}>
              <Icon icon="info" />
            </InformationIcon>
          )}
        </SwatchTitleContainer>
      )}
      <StyledRadioGroup options={children.length} role="radiogroup" className={gridLayout ? 'grid' : ''} overflowWrap={overflowWrap} id={id}>
        {children}
      </StyledRadioGroup>
    </StyledOptionContainer>
  );
};

export default SwatchSelect;
