import { SKU_EVERYDAY_PHOTO_BOOK } from 'helpers/projects/redirect';

export const SUPPORTED_ATTRIBUTES_DISPLAY_TYPES = ['dropdown', 'swatch'];

export const LEGACY_CARD_ATTRIBUTE_KEYS = ['card_quantity'];

// Category constants
export const CALENDARS = 'calendars';
export const CARDS = 'cards';
/**
 * @todo
 * 1. Handle individual cards category
 * 2. Display like books, a card per page
 * 3. Force data into layers through an action
 * 4. Build UI
 * 5. Add to cart checking
 * 6. Character limiting
 */
export const INDIVIDUAL_CARDS = 'individual cards';
export const PRINTS = 'prints';
export const BOOKS = 'books';
export const SPREAD_BOOKS = 'spread books';
export const FRAMES = 'frames';
export const OTHER = 'other';
export const ESCORT_CARDS = 'colorblock-escort-cards';
export const FOLDED_CARDS = 'folded_cards';
export const SIGNATURE_SPREAD_BOOKS = 'signature spread books';

// Filter constants
export const HOLIDAY_MULTISELECT = 'filter_holiday_multiselect';
export const CHRISTMAS_FILTER_OPTION = 'Christmas';
export const NEW_YEAR_FILTER_OPTION = 'New Year';
export const CHRISTMAS_URL_PATH = '/photo-cards/christmas-cards';
export const NEW_YEAR_URL_PATH = '/photo-cards/new-year-cards';
export const PHOTO_BOOKS_URL_PATH = '/photo-books';

export const LAYFLAT_BOOKS_URL_PATH = '/photo-books/by-feature/layflat';
export const WEDDING_BOOKS_URL_PATH = '/wedding/photo-albums';
export const HARDCOVER_BOOKS_URL_PATH = '/photo-books/hardcovers';

export const PAPERBACK_BOOKS_URL_PATH = '/photo-books/paperback-photo-books';
export const TRAVEL_BOOKS_URL_PATH = '/photo-books/by-occasion/travel';
export const BABY_BOOKS_URL_PATH = '/photo-books/by-feature/baby-albums';
export const FAMILY_BOOKS_URL_PATH = '/photo-books/by-occasion/family';
export const WEDDING_GUEST_BOOKS_URL_PATH = '/photo-books/wedding-guest-books';
export const PHOTO_PRINTS_URL_PATH = '/photo-prints';
export const POSTER_PRINTS_URL_PATH = '/photo-prints/poster-print';
export const PHOTO_HOLDERS_URL_PATH = '/photo-holders';
export const FRAMED_PRINTS_URL_PATH = '/frames';
export const WALL_FRAMES_URL_PATH = '/wall-decor';
export const PHOTO_CALENDARS_URL_PATH = '/photo-calendars';
export const THANK_YOU_CARDS_URL_PATH = '/photo-cards/thank-you-photo-cards';
export const PHOTO_CARDS_URL_PATH = '/photo-cards';
export const BIRTHDAY_PHOTO_CARDS_URL_PATH = '/photo-cards/birthday';
export const JUST_BECAUSE_CARDS_URL_PATH = '/photo-cards/just-because';
export const PHOTO_GRADUATION_CARDS_URL_PATH = '/graduation-announcements';
export const WEDDING_CARDS_URL_PATH = '/wedding/cards';
export const SAVE_THE_DATE_CARDS_URL_PATH = '/save-the-date';
export const WEDDING_THANK_YOU_CARDS_URL_PATH = '/wedding/thank-you-cards';
export const WEDDING_URL_PATH = '/wedding';
export const WEDDING_PRINTS_URL_PATH = '/wedding/prints';
export const WEDDING_GIFTS_URL_PATH = '/wedding/personalized-gifts';
export const WEDDING_FRAMES_URL_PATH = '/wedding/frames';
export const BEST_SELLERS_URL_PATH = '/photo-gifts/bestsellers';
export const ALL_CUSTOM_PHOTO_GOODS_URL_PATH = '/all-photo-books-prints';
export const GIFTS_UNDER_50_URL_PATH = '/photo-gifts/gifts-under-50';
// Unique Page IDs
export const WOODEN_BOX_PAGE_ID = 'complete_your_set-walnut-box';
export const END_SHEET_PAGE_ID = 'back_endsheet';

// Product SKUs with special instructions
export const SKU_SIG_LAY = 'signature-layflat-photo-album';
export const SKU_LAYFLAT = 'layflat';
export const SKU_WEDDING_LAY = 'wedding-layflat-photo-album';
export const SKU_GUESTBOOK = 'guestbook';
export const SKU_ELOPEMENT_LAYFLAT = 'elopement-layflat';
export const SKU_PHOTO_GUEST_BOOK = 'guest-book';
export const SKU_CURATION_SERVICES = 'curation-services';
export const SKU_BRASS_WOOD_DISPLAY_BOX = 'brass-wood-display-box';
export const SKU_PHOTO_WRAPPED_LAYFLAT = 'photo-wrapped-layflat';
export const SKU_GALLERY_WALL_DESIGN_SERVICE = 'gallery-wall-design-service';

// CATEGORY FEATURES
// Categories that should include a cover
export const CATEGORIES_HIDE_PRICE = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that includes envelopes
export const CATEGORIES_WITH_ENVELOPES = [CARDS, FOLDED_CARDS];

export const CATEGORIES_WITH_CUTOUT_MASK = [FOLDED_CARDS];

// Categories that should include a cover
export const CATEGORIES_WITH_COVERS = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that support manipulated page order and count
export const CATEGORIES_WITH_PAGE_MANIPULATION = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should show a welcome screen upon initial editor open
export const CATEGORIES_WITH_WELCOME = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should enable clicking half of the page
export const CATEGORIES_WITH_PARTIAL_PAGE_SELECTION = [SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should display inside covers
export const CATEGORIES_WITH_INSIDE_COVERS = [BOOKS];

// Categories that should display endsheets that are editable
export const CATEGORIES_WITH_ENDSHEETS = [SIGNATURE_SPREAD_BOOKS];

// Categories that should display two pages side-by-side
export const CATEGORIES_WITH_TWO_UP = [BOOKS];

// Categories that should include random designs
export const CATEGORIES_WITH_RANDOM_DESIGNS = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should show the filter dropdown in the layout picker
export const CATEGORIES_WITH_LAYOUT_FILTER = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that are any book
export const CATEGORIES_BOOK = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

export const CATEGORIES_WITH_WOODEN_BOX = [SIGNATURE_SPREAD_BOOKS];

// Categories that should have a coffee table preview
export const CATEGORIES_WITH_PREVIEW = [BOOKS, SPREAD_BOOKS, SIGNATURE_SPREAD_BOOKS];

// Categories that should prefix design ids with "mat"
export const CATEGORIES_WITH_MAT_PREFIX = [FRAMES];

// Categories that should match page count to quantity
export const CATEGORIES_PAGE_COUNT_MATCH_QUANTITY = [INDIVIDUAL_CARDS];

// Products that should have the same color for every single page
export const SKUS_WITH_UNIFORM_COLOR = [ESCORT_CARDS];

export const SKUS_WITH_COVER_TEXT_V3 = [SKU_SIG_LAY];

export const MOBILE_DISPLAY_UPLOAD_PHOTOS_CTA = '1';
export const MOBILE_DISPLAY_NEW_APP_DOWNLOAD_PROMPT_CTA = '2';
export const MOBILE_DISPLAY_SHOW_ADD_BUTTON = '3';
export const NO_APP_NO_MOBILE = 'No App No Mobile';
export const YES_APP_NO_MOBILE = 'Yes App No Mobile';
export const YES_APP = 'Yes App';

export const DESIGN_SERVICES_BOOKS: { sku: string, url?: string }[] = [
  { sku: SKU_SIG_LAY },
  { sku: SKU_LAYFLAT },
  { sku: SKU_GUESTBOOK },
  { sku: SKU_PHOTO_GUEST_BOOK },
  { sku: SKU_WEDDING_LAY },
  { sku: SKU_ELOPEMENT_LAYFLAT },
  { sku: SKU_PHOTO_WRAPPED_LAYFLAT },
  { sku: SKU_EVERYDAY_PHOTO_BOOK, url: '/everyday-photo-book-design-service' }
];
