import styled from 'styled-components';
import { disabledInputCss, hoverStateCss } from '../styled';

export const StyledSwatchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

export const StyledSwatch = styled.label`
  display: flex;
  box-sizing: content-box;
  border-radius: 50%;
  border: 2px solid transparent;
  width: 42px;
  height: 42px;

  ${disabledInputCss}
  ${hoverStateCss}
`;

export const SwatchImg = styled.img<{ isWhite: boolean }>`
  border-radius: 50%;
  border: ${props => (props.isWhite ? '1px solid #ccc !important' : 'none')};
  height: 42px;
  width: 42px;
  transition-property: padding;
  transition-duration: 0.2s;
`;

export const DisabledIconContainer = styled.div`
  position: absolute;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: inherit;
  height: inherit;
  background-color: rgb(255, 255, 255, 0.85);
`;
