import React, { useRef, useEffect, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import {
  ProductOptionDetails,
  ProductOptionsImage,
  ProductionOptionsModalContainer as ProductOptionsModalContainer,
  OptionTitle,
  OptionDescription,
  ProductOptionsCloseButton,
  ProductOptionsCloseButtonContainer,
  CloseIcon,
  CloseText
} from './styled';
import { useProductForm } from 'features/pdp/hooks/useProductForm';
import { useProductFormInput } from 'features/pdp/hooks/useProductFormInput';
import { useDispatch } from 'react-redux';
import SvgContainer from 'au-gatsby-ds/components/Svg';
import { hideModal } from 'store/modal/actions';
import { ModalOptionsContainer, ModalOptionsContainerProps } from '../ModalOptionsContainer';
import { computeInitialOffsetToTop, computeInitialOffsetToBottom } from 'features/pdp/helpers/quickPinchZoom';
import { isNotServerSideRendering } from 'helpers/env';

interface ProductOptionsModalProps {
  optionType: string;
  description?: any; // make optional go away
}

const isFullscreen = (id: string): boolean => {
  return ['foil_color', 'fabric_color', 'cover_color', 'frame_finish', 'envelope'].includes(id);
};

const PLACEHOLDER_IMAGE_URL =
  'https://media.artifactuprising.com/media/catalog/product/placeholder/default/Placeholder_4.png?width=1000&auto=webp';

export const ProductOptionsModal = ({ optionType }: ProductOptionsModalProps) => {
  const dispatch = useDispatch();

  const imgRef = useRef<HTMLImageElement>();
  const zoomRef = useRef();
  const [scale, setScale] = useState(null);

  const { inputs } = useProductForm();
  const filteredInput = inputs.filter(input => input.id === optionType);
  const { id, name, label, displayName, type, options } = filteredInput[0];
  const { value } = useProductFormInput({ name, displayName, options });
  const optionDescription = options[value].hoverDescription;
  const selectedOptionName = options[value].displayName;

  let selectedOptionHoverImage = options[value].hoverImage;

  //Preload hover images into browser cache
  useEffect(() => {
    for (const id in options) {
      if (options[id]?.hoverImage) {
        const img = new Image();
        img.src = options[id].hoverImage;
      }
    }
  }, []);

  const modalInputContainerProps: ModalOptionsContainerProps = {
    id,
    name,
    label,
    displayName,
    type,
    options
  };

  const onUpdate = ({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      setScale(scale);
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  };

  useEffect(() => {
    if (isNotServerSideRendering()) {
      const setCurrentUrl = () => window.history.pushState(null, document.title, window.location.href);
      const popStateHandler = () => {
        setCurrentUrl();
        dispatch(hideModal());
      };

      setCurrentUrl();
      window.addEventListener('popstate', popStateHandler);

      return () => {
        window.removeEventListener('popstate', popStateHandler);
        window.history.back();
      };
    }
  }, []);

  return (
    <ProductOptionsModalContainer>
      <ProductOptionDetails>
        <OptionTitle>{displayName.toLowerCase()}</OptionTitle>
        <OptionDescription>{optionDescription}</OptionDescription>
        <ModalOptionsContainer {...modalInputContainerProps} />
      </ProductOptionDetails>
      <ProductOptionsImage fullscreen={isFullscreen(id)} zoomedIn={scale > 1}>
        <QuickPinchZoom
          computeInitialOffset={isFullscreen(id) ? computeInitialOffsetToTop : computeInitialOffsetToBottom}
          draggableUnZoomed={false}
          onUpdate={onUpdate}
          tapZoomFactor={2}
          maxZoom={2}
          doubleTapZoomOutOnMaxScale
          ref={zoomRef}
        >
          <img
            ref={imgRef}
            src={selectedOptionHoverImage || PLACEHOLDER_IMAGE_URL}
            alt="option-image"
            onError={event => {
              const imgElement = event.target as HTMLImageElement;
              if (imgElement.src !== selectedOptionHoverImage) {
                imgElement.src = selectedOptionHoverImage;
              } else {
                imgElement.src = PLACEHOLDER_IMAGE_URL;
              }
            }}
            onLoad={() => zoomRef?.current?.scaleTo({ x: 0, y: -1000, scale: 1, animated: false })}
          />
        </QuickPinchZoom>
      </ProductOptionsImage>
      <ProductOptionsCloseButtonContainer>
        <ProductOptionsCloseButton onClick={() => dispatch(hideModal())}>
          <CloseText>{selectedOptionName}</CloseText>
          <CloseIcon>
            <SvgContainer icon={'close'} />
          </CloseIcon>
        </ProductOptionsCloseButton>
      </ProductOptionsCloseButtonContainer>
    </ProductOptionsModalContainer>
  );
};
