import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProduct } from './useProduct';
import { PDPFields } from './useProductForm';
import { watchFields, getUseCaseAttributes } from '../helpers';
import { MediaObject } from 'types/media';

export type UseCaseProperties = {
  title: string;
  useCaseImage: MediaObject | null;
}
export const useProductUseCase = (): UseCaseProperties => {
  const form = useFormContext<PDPFields>();
  const { product } = useProduct();
  const useCaseFields = useMemo(() => {
    if (product.customOptions) {
      return product.customOptions.filter(option => option.use_case_option === '1');
    }
    return [];
  }, [product.customOptions]);
  const useCaseFieldNames = useCaseFields.map(option => option.title);
  // Send params to `form.watch` to only watch the needed values
  const useCaseValues = watchFields(form.watch, useCaseFieldNames);

  const useCaseProperties: UseCaseProperties = useMemo(
    () => getUseCaseAttributes(product.name, useCaseValues, useCaseFields),
    [product.name, useCaseValues, useCaseFields]
  );

  return useCaseProperties;
};
