import { RefObject, useState, MouseEvent, CSSProperties, useMemo } from 'react';
import { ProductAttributeOption } from '../helpers';

export type OnOptionMouseHandler = (
  option: ProductAttributeOption,
  ref: RefObject<HTMLDivElement>,
  e: MouseEvent<HTMLLabelElement>
) => void

export interface UseTooltipReturn {
  showTooltip: boolean;
  containerStyles?: CSSProperties;
  hoveredOption?: ProductAttributeOption;
  handleOptionMouseOver: OnOptionMouseHandler;
  handleOptionMouseOut: OnOptionMouseHandler;
}

export const useTooltip = (): UseTooltipReturn => {
  const [hoveredOption, setHoveredOption] = useState<ProductAttributeOption | undefined>(undefined);
  const [ref, setInputRef] = useState<RefObject<HTMLDivElement> | undefined>(undefined);

  const handleOptionMouseOver: OnOptionMouseHandler = (option, ref) => {
    if (option.hoverImage) {
      setHoveredOption(option);
      setInputRef(ref);
    }
  };

  const handleOptionMouseOut: OnOptionMouseHandler = () => {
    setHoveredOption(undefined);
    setInputRef(undefined);
  };

  const showTooltip = !!hoveredOption && !!ref;

  const showOnLeft = ref?.current.getBoundingClientRect().left >= 250;
  const containerStyles: CSSProperties = useMemo(
    () => ({
      top: ref?.current ? `${ref?.current.offsetTop - 250}px` : undefined,
      left: showOnLeft ? 'unset' : '100%',
      right: showOnLeft ? '100%' : 'unset'
    }),
    [ref]
  );

  return {
    showTooltip,
    hoveredOption,
    handleOptionMouseOver,
    handleOptionMouseOut,
    containerStyles: showTooltip ? containerStyles : undefined
  };
};
