import {
  ContactGroupWrapper,
  ModalTitle,
  ModalTextInput,
  ButtonContainer,
  FAQContent,
  SubmitButton,
  ModalForm
} from '../styled';
import React, { useState } from 'react';
import { hideModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import { ErrorLabel } from 'component-library';
import ModalHeader from 'components/Modal/ModalHeader';

const UpdateContactGroupModal = ({ contactGroup, updateContactGroupData }) => {
  const [contactGroupName, setContactGroupName] = useState(contactGroup.groupName);
  const dispatch = useDispatch();

  const [error, setError] = useState('');

  const handleUpdateContactGroup = async () => {
    if (!contactGroupName) {
      setError('Please enter a name for the contact group.');
    } else {
      updateContactGroupData(contactGroupName);
    }
  };

  return (
    <ContactGroupWrapper>
      <ModalHeader title={'Update Contact Group'} />

      <ModalForm>
        <label>
          Contact Group Name
          <ModalTextInput
            type="text"
            name="name"
            onChange={e => setContactGroupName(e.target.value)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleUpdateContactGroup();
              }
            }}
            value={contactGroupName}
          />
          {error && <ErrorLabel>{error}</ErrorLabel>}
        </label>
      </ModalForm>
      <ButtonContainer>
        <FAQContent>
          <a href="https://help.artifactuprising.com/hc/en-us/articles/360000040271" target="_blank" rel="noreferrer">
            Need help? Check out our FAQs
          </a>
        </FAQContent>
        <SubmitButton
          onClick={() => {
            handleUpdateContactGroup();
          }}
        >
          UPDATE
        </SubmitButton>
      </ButtonContainer>
    </ContactGroupWrapper>
  );
};

export default UpdateContactGroupModal;
