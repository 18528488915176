import React from 'react';
import styled from 'styled-components';

import { PrimaryButton as Button } from '../Button';
import Icon from '../icons';

export const BackIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
    display: block;
    position: absolute;
    transform: translate(-50%, -50%);
    margin-top: 1px;
    line-height: 24px;
    margin-right: 2rem;
  `;
export const UploadAmountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  svg {
      display: flex;
  }
`;
export const UploadAmountText = styled.div`
  margin-right: 10px;
  color: rgba(0,0,0,0.5);
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: 0.75rem;
  line-height: 16px;
`;
export const BackButton = styled(Button)`
  cursor: pointer;
  height: 2.5rem;
  padding: 0 !important;
  border-radius: 0;
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.5);
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 0.875rem;
  letter-spacing: normal;
  line-height: normal;
  text-transform: none;
  margin-left: 1rem;
  border: none;
  display: flex;
  align-items: center;
  min-width: 123px;
  justify-content: flex-start;
  @media (max-width: 780px) {
     margin-left: 3rem;
  }
  span{
    width: 1rem;
  }

`;
export const AlbumsBrowserSection = styled.section`
  height: fit-content;
  width: 100%;
`;
export const AlbumsBrowserHeader = styled.header`
  display: grid;
  grid-template-columns: 33.3333% 33.3333% 33.3333%;
  justify-items: center;
  margin: 0 2rem;
  @media (max-width: 780px) {
    margin: 0;
    grid-template-columns: 50% 50%;
  }

`;
export const AlbumsBrowserHeaderItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  height: 50px;
  padding-top: 10px;
  justify-items: center;
  width: 100%;
  h1 {
    display: flex; 
    justify-items: center;
    align-items:center;
    font-size: 1rem;
    font-weight: normal;
    margin: auto;
    height: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
`;
export const SortHeaderItem = styled(AlbumsBrowserHeaderItem)`
  justify-self: flex-end;  
`;
export const BackHeaderItem = styled(AlbumsBrowserHeaderItem)`
  justify-self: flex-start;
`;
export const TitleHeaderItem = styled(AlbumsBrowserHeaderItem)`
`;
export const GoogleHeaderDisconnect = styled.div`
  display: flex;
  justify-content: center;
`;
