import { useLayoutEffect } from 'react';

const useLockBodyScroll = (enable = false) => {
  useLayoutEffect(() => {
    // Get original body overflow
    const originalStyle = window.getComputedStyle(document.body).overflow;

    if (enable) {
      // Prevent scrolling on mount
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (enable) {
        document.body.style.overflow = originalStyle;
      }
    };
    // Re-enable scrolling when component unmounts
  }, []); // Empty array ensures effect is only run on mount and unmount
};

export default useLockBodyScroll;
