import { removeUserCookies } from 'helpers/http';
import { Dispatch } from 'redux';
import { clearStoreAction } from 'store/global/actions';
import { clearLocalStorage } from 'au-js-sdk/lib/helpers/browser';

export const clearAll = (cookiesToPersist: Array<string> = [], dispatch?: Dispatch) => {
  clearLocalStorage();
  removeUserCookies(cookiesToPersist);
  dispatch && dispatch(clearStoreAction());
};

export const checkSessionStorageExpiration = (createdAt: number) => {
  const currentTime = Math.round(new Date().getTime() / 1000);

  const unixDaySeconds = 60 * 60 * 24;
  const expiresAt = createdAt + unixDaySeconds;

  return currentTime <= expiresAt;
};

export const getSessionStorage = (flashId: string, name: string) => {
  try {
    const result = sessionStorage.getItem(name);

    return JSON.parse(result);
  } catch (error) {
    console.log(error);
    window.newrelic.addPageAction('session_storage_failed_parse_session_storage', {
      error: error.toString(),
      errorStack: error.stack,
      action: 'parseSessionStorage',
      userId: flashId
    });
    window.newrelic.noticeError(error);
  }
};
export const setSessionStorage = (flashId: string, name: string, data: any) => {
  try {
    sessionStorage.setItem(name, JSON.stringify(data));
  } catch (error) {
    console.log(error);
    window.newrelic.addPageAction('session_storage_failed_stringify_session_storage', {
      error: error.toString(),
      errorStack: error.stack,
      action: 'stringifySessionStorage',
      userId: flashId
    });
    window.newrelic.noticeError(error);
  }
};

export const deleteSessionStorage = (name: string) => {
  sessionStorage.removeItem(name);
};
