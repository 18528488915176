import React from 'react';
import styled from 'styled-components';
import { Colors, mediaQuery } from 'component-library';
import { AULink } from 'components/AULink';

const Breadcrumb = styled.div`
  color: ${Colors.DARK_GRAY};
  font-size: 14px;
  text-transform: uppercase;
`;

const BreadcrumbLink = styled(AULink)`
  color: ${Colors.LIGHT_GRAY};
  font-size: 14px;
  text-transform: uppercase;

  &:after {
    content: '/';
    padding: 0 6px;
  }
`;

const BreadcrumbContainer = styled.div<{isClp: boolean}>`
  display: flex;
  flex-wrap: wrap;
  padding: ${props => props.isClp ? '0 0' : '0 25px'};
  margin: 24px;

  ${mediaQuery.medium`
    margin: 16px 0;
  `};
`;

interface Page {
  name: string;
  urlPath: string;
}

interface BreadcrumbProps {
  className?: string;
  pages: Page[];
  isClp?: boolean;
}

const Breadcrumbs = ({ pages, className, isClp = false }: BreadcrumbProps) => {
  const lastPage = pages[pages.length - 1];
  const beforeLastPage = pages.slice(0, pages.length - 1);

  return (
    <BreadcrumbContainer className={className} isClp={isClp}>
      <>
        {beforeLastPage.map(({ name, urlPath }) => (
          <BreadcrumbLink path={urlPath} id={`breadcrumb-${name}`} className="breadcrumb" key={name}>
            {name}
          </BreadcrumbLink>
        ))}
        {lastPage && <Breadcrumb>{lastPage.name}</Breadcrumb>}
      </>
    </BreadcrumbContainer>
  );
};

export default Breadcrumbs;

export const DesktopBreadcrumbs = styled(Breadcrumbs)`
  display: none;
  justify-content: flex-start;
  width: 100%;
  align-items: center;

  ${mediaQuery.medium`
    display: flex;
  `};
`;

export const MobileBreadcrumbs = styled(Breadcrumbs)`
  margin: 0 0 24px;

  ${mediaQuery.medium`
    display: none;
  `};
`;
