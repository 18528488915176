import React, { FC, ReactNode } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ProductProvider, MediaProvider } from '..';
import { PDPFields } from '../../hooks/useProductForm';
import { StrikethroughValues } from 'types/StrikethroughValues';
import { EventBody } from 'features/legacy-pdp/types/event';
import { PDPProduct } from 'templates/PDP';

export interface PDPProviderProps extends EventBody {
  children: ReactNode;
  strikethroughValues: StrikethroughValues;
  pdpPageQuery?: PDPProduct; //Only available on PDP page since it uses data from the page query
}

export const PDPProvider: FC<PDPProviderProps> = ({
  children,
  rawProduct,
  product,
  urls,
  addToCartUrl,
  configuration,
  media,
  strikethroughValues,
  pdpPageQuery
}) => {
  const methods = useForm<PDPFields>();

  return (
    <ProductProvider
      rawProduct={rawProduct}
      product={product}
      urls={urls}
      addToCartUrl={addToCartUrl}
      strikethroughValues={strikethroughValues}
      pdpPageQuery={pdpPageQuery}
    >
      <MediaProvider configuration={configuration} media={media} urls={urls}>
        <FormProvider {...methods}>{children}</FormProvider>
      </MediaProvider>
    </ProductProvider>
  );
};
