import styled from 'styled-components';

export const PromotionalMessagingContainer = styled.div`
  font-size: 12px;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-weight: 600;
  line-height: 1.5em;
  color: #757575;
  letter-spacing: 2.5px;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 8px;
  margin-bottom: 1em;
`;