import styled from 'styled-components';
import { mediaQuery, StyledH2, StyledP } from 'component-library';

export const AddressSelectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  text-align: center;

  ${mediaQuery.medium`
    margin-bottom: 1rem;
  `}

  h2 {
    margin: 0;
    margin-bottom: 1rem;
  }
`;

export const HeaderText = styled(StyledH2)`
  margin: 0;
`;

export const ShippingAddressSelectionWrapper = styled.form`
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AddressTileList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;

  ${mediaQuery.medium`
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 auto;
    align-items: normal;
    margin-bottom: 2rem;
    max-width: 42rem;
  `}

  ${mediaQuery.large`
    max-width: 60rem;
  `}
`;

export const AddressTile = styled.div`
  display: flex;
  align-items: flex-start;
  border: 1px solid #000;
  width: 16rem;
  height: 11rem;
  margin: 15px;
  padding: 10px;
  cursor: pointer;
`;

export const SpacedText = styled(StyledP)`
  letter-spacing: 3px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const AddressTileContent = styled.div`
  padding-left: 5px;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AddNewButton = styled(AddressTile)`
  display: flex;
  align-items: center;
  justify-content: center;
`;
