import React from 'react';
import { ErrorWrapper } from './styled';
import { StyledP, Icon } from 'component-library';
import { ToastContainer, toast } from 'react-toastify';
import styled from 'styled-components';
import { ToastContainerWrapper } from 'components/ErrorModal/styled';

export const ErrorModalToast = () => (
  <ToastContainerWrapper>
    <ToastContainer closeButton={false} position={toast.POSITION.TOP_CENTER} />
  </ToastContainerWrapper>
);

const RawMessage = styled.div`
  color: #c11e1e;
  text-align: left;
`;

interface ErrorModalProps {
  message: string
  closeToast?: () => void
  raw?: boolean
}

const ErrorModal = ({ message = '', closeToast, raw }: ErrorModalProps) => (
  <ErrorWrapper>
    { raw
      ? <RawMessage dangerouslySetInnerHTML={{__html: message}}/>
      : <StyledP secondary>{message}</StyledP>
    }
    <Icon icon="close" size="small" />
  </ErrorWrapper>
);

export default ErrorModal;
