import { optionGet, optionFind } from 'faunctions';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { tryCatch } from 'fp-ts/es6/Either';
import { isNotEmpty } from 'helpers/arrays';
import { fromNullable } from 'fp-ts/es6/Option';
import { combineCartPaymentMethods } from './helpers';
import { CartState, CartAction } from './constants';
import { isDiscountAPromoCode, getDiscountSegmentPromoCode } from 'helpers/mage-helpers/magento';
import { getBillingAddressFromCart, getShippingAddressFromCart, getShippingMethodFromCart } from 'helpers/cart';
import QuoteDataCartItem from 'mage-swagfaces/quote/QuoteDataCartItem';

// TODO these aren't selectors
// Selectors to be used with cart `total_segments` QuoteDataTotalSegment[]
export const getSubtotalFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  getTotalSegment('subtotal')(totalSegments).value;

export const getSubtotalWithPromoApplied = (
  totalSegments: QuoteDataTotalSegment[],
  cartItems = [],
  isShippingShown = true
): number => {
  if (isShippingShown) {
    return getSubtotalFromTotalSegments(totalSegments) + getPromoCodeDiscountFromTotalSegments(totalSegments).value;
  } else {
    const cartItemsTotalPrice = getSubtotalFromCartItems(cartItems);
    const promoCodeDiscount = getPromoCodeDiscountValueFromCartItems(cartItems);
    const giftCardDiscount = getGiftCardDiscountValueFromCart(totalSegments, cartItemsTotalPrice + promoCodeDiscount);
    const storeCreditDiscount = getAppliedStoreCreditFromTotalSegments(totalSegments);

    const subtotal = cartItemsTotalPrice + (promoCodeDiscount + giftCardDiscount + storeCreditDiscount);

    return subtotal > 0 ? subtotal : 0;
  }
};

export const getSubtotalFromCartItems = (cartItems: QuoteDataCartItem[]) => {
  if (!cartItems || !cartItems.length) {
    return 0;
  }

  return cartItems.reduce((acc, item) => {
    return acc + item.price * item.qty;
  }, 0);
};

export const getShippingTotalFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  getTotalSegment('shipping')(totalSegments).value;

export const getTaxFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  getTotalSegment('tax')(totalSegments).value;

export const getGrandTotalFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  getTotalSegment('grand_total')(totalSegments).value;

export const getAppliedStoreCreditFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  getTotalSegment('customerbalance')(totalSegments).value;

export const getGiftCardDiscountValueFromCart = (totalSegments: QuoteDataTotalSegment[], subtotal: number) => {
  const giftCardAmount = getGiftCardsFromTotalSegments(totalSegments).reduce((acc, value) => acc + value.a, 0);
  return giftCardAmount > subtotal ? -subtotal : -giftCardAmount;
};

export const getGiftCardsFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  optionGet('extension_attributes.gift_cards')(getTotalSegment('giftcardaccount')(totalSegments))
    .map(x =>
      tryCatch(() => JSON.parse(x)).getOrElseL(e => {
        console.error(e);
        return [];
      })
    )
    .getOrElse([]);

export const getGiftWrappingTotalsFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  parseInt(
    optionGet('extension_attributes.gw_items_price')(getTotalSegment('giftwrapping')(totalSegments)).getOrElse('0')
  );

export const getInternalPaymentMethodsFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) => {
  // NOTE: This only pulls INTERNAL payment methods out of the cart object. eg: store credit & gift card
  const maybeStoreCredit = getAppliedStoreCreditFromTotalSegments(totalSegments) ? [{ type: 'storecredit' }] : [];
  const maybeGiftCards = isNotEmpty(getGiftCardsFromTotalSegments(totalSegments))
    ? getGiftCardsFromTotalSegments(totalSegments).map((gc: { c: string }) => ({ type: 'giftcard', code: gc.c }))
    : [];

  return [...maybeStoreCredit, ...maybeGiftCards];
};

export const getPromoCodeDiscountFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) =>
  fromNullable(getTotalSegment('discount')(totalSegments))
    .map(x => {
      // TotalSegments[discount] comes back with the title attribute set to "Discount (SAVETEN)"
      // This RegEx parses out and returns the actual promo code within the parenthesis ie: "SAVETEN"
      if (isDiscountAPromoCode(x)) {
        return {
          ...x,
          title: getDiscountSegmentPromoCode(x)
        };
      }
      return x;
    })
    .getOrElse({ value: 0, title: null, code: null });

export const getPromoCodeDiscountValueFromTotalSegments = (totalSegments: QuoteDataTotalSegment[]) => {
  return getPromoCodeDiscountFromTotalSegments(totalSegments)?.value;
};

/* 
  This function is used to calculate the promo code discount, not including the shipping cost
  The reason this is needed is that a promo code discount could show a value that includes the shipping cost 
  even though the user has not seen the shipping amount yet in the checkout flow,
  thus appearing that the discount amount is greater than what it should be
*/
export const getPromoCodeDiscountValueFromCartItems = (cartItems: QuoteDataCartItem[]): number => {
  if (!cartItems || !cartItems.length) {
    return 0;
  }

  return cartItems.reduce((acc, item) => {
    return acc - item.discount_amount;
  }, 0);
};

export const getTotalSegment = (code: string) => (totalSegments: QuoteDataTotalSegment[]) => {
  const _totalSegments = fromNullable(totalSegments).getOrElse([]);
  return optionFind(ts => code === ts.code, _totalSegments).getOrElse({ value: 0, code: null });
};

export const pullUIEntitesOutOfQuoteDataCart = (state: CartState, action: CartAction) => ({
  billingAddress: getBillingAddressFromCart(action.payload.cart),
  shippingAddress: getShippingAddressFromCart(action.payload.cart),
  shippingMethod: getShippingMethodFromCart(action.payload.cart),
  paymentMethods: combineCartPaymentMethods(state, action)
});
