import { optionFind } from 'faunctions';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { optionGet } from 'faunctions';
import { arrayFindKeyWithValue } from '../arrays';
import QuoteDataTotalSegment from 'mage-swagfaces/quote/QuoteDataTotalSegment';
import { option } from 'fp-ts';
import FrameworkAttribute from 'mage-swagfaces/framework/FrameworkAttribute';

export const getCustomAttribute = (customAttr: string) => (customAttrs: any[]) =>
  optionGet('value')(arrayFindKeyWithValue('attribute_code')(customAttr)(customAttrs)).getOrElse(null);

export const getDiscountSegmentPromoCode = (discountSegment: QuoteDataTotalSegment): string | null =>
  option
    .fromNullable(/\(([^)]+)\)/.exec(discountSegment.title))
    .map(x => x[1])
    .getOrElse(null);

export const isDiscountAPromoCode = (discountSegment: QuoteDataTotalSegment): boolean =>
  !!getDiscountSegmentPromoCode(discountSegment);

// Magento Customer Data Helpers
export const getCustomerCustomAttribute = (attribute: string, customer: CustomerDataCustomer) =>
  optionFind((attr: FrameworkAttribute) => attr.attribute_code === attribute, customer.custom_attributes)
    .map(attr => attr.value)
    .getOrElse('');

export const getCustomerFlashId = (customer: CustomerDataCustomer) => getCustomerCustomAttribute('flash_id', customer);
export const getCustomerFlashToken = (customer: CustomerDataCustomer) => getCustomerCustomAttribute('flash_auth_token', customer);
