import React from 'react';
import ShippingAddressFormModal from 'components/Shipping/ShippingAddressFormModal';

const AddContactModal = ({ groupId, flashId, flashToken, addContactData }) => {
  const newContact = {
    name: '',
    line1: '',
    line2: '',
    line3: '',
    city: '',
    postalCode: '',
    region: '',
    country: '',
    phone: '',
    groupIds: [groupId],
    markedAsDeleted: false
  };
  return <ShippingAddressFormModal abbreviated={true} sendModalData={addContactData} flashId={flashId} flashToken={flashToken} skipValidation={true} address={newContact} />;
};

export default AddContactModal;
