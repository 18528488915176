import { css } from 'styled-components';
import { Breakpoint } from '../../helpers/Breakpoint';

export const H1 = css`
  font-family: 'Crimson Text, Minion Pro';
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.75px;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -1px;
  }
`;

export const H2 = css`
  font-family: 'Crimson Text, Minion Pro';
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 32px;
  letter-spacing: -0.5px;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.5px;
  }
`;

export const H3 = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  letter-spacing: -0.5px;
  color: #000000;

  @media ${Breakpoint.medium} {
    //no difference
  }
`;

export const H4 = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 100%;
  color: #000000;

  @media ${Breakpoint.medium} {
    //no difference
  }
`;

export const H5 = css`
  font-family: 'Crimson Text, Minion Pro';
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 100%;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-weight: 300;
    font-size: 24px;
  }
`;

export const H6 = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: -0.25px;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 16px;
  }
`;

export const P1 = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.25px;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 16px;
  }
`;

export const P2 = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 14px;
    color: #333333;
  }
`;

export const Table = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 100%;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 14px;
  }
`;

export const Reviews = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 100%;
  color: #000000;

  @media ${Breakpoint.medium} {
    font-size: 12px;
  }
`;

export const Callout = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 100%;
  color: #333333;

  @media ${Breakpoint.medium} {
    font-size: 14px;
    color: #000000;
  }
`;

export const Subheader = css`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 8px;
  letter-spacing: 3px;
  color: #757575;
  text-transform: uppercase;

  @media ${Breakpoint.medium} {
    font-size: 10px;
    line-height: 10px;
  }
`;
