import styled from 'styled-components';

export const GoogleButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  margin: 0 auto;
`;

export const GoogleButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  height: 4rem;
  width: 100%;
`;

export const GoogleButton = styled.button`
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  width: 100%;
  height: 54px;
  cursor: pointer;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || '#000'};

  &:hover {
    background-color: ${props => (props.disabled ? 'none' : '#eee')};
  }
`;

export const StyledGoogleLogo = styled.span`
  height: 40px;
  width: 40px;
`;

export const SeparatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;
    margin-bottom: 24px;
  }
`;

export const Separator = styled.hr`
  display: block;
  height: 0px;
  border: 0;
  border-top: 1px solid #757575;
  width: 100%;
`;

export const SeparatorLabel = styled.span`
  text-transform: uppercase;
  font-weight: 900;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.25px;
  color: #757575;
`;
