interface LoadingPerformanceMarkNames {
  start: string;
  end: string;
}

/**
 * getLoadingMarkNames
 *
 * Returns mark names that can be used with the [performance.mark()](https://developer.mozilla.org/en-US/docs/Web/API/Performance/mark) API
 * to track the start/end load times for a given component
 *
 * @param name the name of the component in question
 */
export const getLoadingMarkNames = (name: string): LoadingPerformanceMarkNames => ({
  start: `${name} start`,
  end: `${name} end`
});