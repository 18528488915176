import styled, { createGlobalStyle } from 'styled-components';
import { StyledH2, mediaQuery } from 'component-library';
import noReviews from '../no-reviews.png';

export const ReviewsSectionWrapper = styled.div`
  background-color: #efefef;
  margin: 0;
  padding: 40px 1rem;

  /* We need to override some of Yotpo's default styles */
  .yotpo {
    &.yotpo-main-widget {
      width: auto;
      max-width: 774px;

      .yotpo-user-related-fields {
        position: absolute;
        top: 12px;
      }

      .yotpo-review-wrapper {
        margin-top: 15px;
      }

      .yotpo-bottomline-box-1 {
        margin: 0 !important;
        max-width: 100% !important;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: ". . stars words words";
        float: initial !important;
      }

      .yotpo-regular-box.yotpo-bottomline.yotpo-bottomline-2-boxes {
        height: auto;
        margin-bottom: 48px;
      }

      .yotpo-label {
        display: none;
      }

      .yotpo-bottomline {
        .based-on {
          padding-top: 0;
        }

        .yotpo-sum-reviews {
          padding: 0;
        }

        .yotpo-stars {
          display: flex;
          justify-content: center;
          grid-area: stars;
          padding: 0;
        }

        .yotpo-sum-reviews {
          display: flex;
          flex-flow: column;
          justify-content: center;
          grid-area: words;
          margin-left: 5px;
        }
      }

      .yotpo-icon-btn-big.transparent-color-btn.yotpo-visible-mobile {
        display: none !important;
      }

    }

    .yotpo-footer  {
      display: block;
      margin-left: 0 !important;
    }

    .yotpo-action {
      display: none;
    }

    .yotpo-syndication-reference {
      display: none;
    }

    .yotpo-nav {
      display: flex;
      justify-content: space-between;
      width: 100%;
      border: none;
      margin-bottom: 8px;

      span {
        color: #757575 !important;
        font-weight: normal;
      }

      .yotpo-clr {
        display: none;
      }

      ul {
        .yotpo-nav-tab.yotpo-active {
          .yotpo-nav-wrapper {
            border: none;
          }
        }
      }
    }

    .yotpo-review .yotpo-main {
      margin: 0;

      div {
        color: #000000;
        font-size: 16px;
        font-weight: 300;
        line-height: 28px;
      }

      .yotpo-multiple-rating-fields {
        position: relative;
        margin-top: 10px;

        ${mediaQuery.medium`
          margin: 0;
        `};
      }

      .yotpo-user-field {
        line-height: 16px;
      }
    }

    .yotpo-main {
      .yotpo-user-related-fields{
        display: block !important;

        ${mediaQuery.medium`
          display: none !important;
        `};
      }
    }

    .yotpo-user-field-description {
      display: none;
    }

    .yotpo-user-field-answer {
      display: flex;
      font-size: 14px;
      color: #757575;

      :before {
        content: "Occasion: ";
        margin-right: 4px;
      }
    }

    .yotpo-regular-box {
      padding: 0;
      margin: 0 0 16px;
      border: none;

      &:not(.yotpo-bottomline) {
        background: #ffffff;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
        padding: 16px;
        color: #000000;

        .content-title {
          color: #000000;
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          padding-bottom: 15px;

          ${mediaQuery.medium`
            padding-bottom: 38px;
          `}
        }

      }

      .yotpo-icon-profile {
        display: none !important;
      }

      .yotpo-header-actions {
        display: block !important;
        position: absolute;
        top: 0;
        right: 0;
      }

      .yotpo-header:not(.yotpo-store-owner) .yotpo-header-element:not(.yotpo-header-actions) {
        display: flex;
        width: 100%;

        ${mediaQuery.medium`
          position: absolute;
          top: 26px;
        `};

        .yotpo-clr {
          display: none;
        }

        > * {
          margin-right: 8px;
        }

        .y-label.yotpo-user-name {
          color: #757575;
          font-size: 12px;
          font-weight: 300;
          order: 2;
        }

        .yotpo-review-stars {
          margin-top: 0;
        }

        .yotpo-user-related-fields {
          display: none !important;

          ${mediaQuery.medium`
            display: block !important;
          `};
        }
      }
    }

    .yotpo-dropdown-wrapper {
      display: none;
    }

    .yotpo-small-box {
      padding: 0;
    }

    .yotpo-first-review {
      margin-top: 16px;
    }

    .yotpo-first-review-stars {
      display: none;
    }

    .yotpo-first-review-content {
      :before {
        content: "";
        height: 121px;
        width: 164px;
        background-image: url("${noReviews}");
        background-size: cover;
        display: block;
        margin: auto;

        ${mediaQuery.medium`
          height: 177px;
          width: 240px;
        `};
      }
      :after {
        content: "No reviews";
        font-size: 20px;
        color: #000000;

        ${mediaQuery.medium`
          font-size: 20px;
        `};
      }

      span,
      .write-first-review-button {
        display: none !important;
      }
    }

    .yotpo-dropdown {
      .list-category[data-value="votes_up desc"],
      .list-category[data-value="votes_down desc"] {
        display: none;
      }
    }

    .yotpo-review-date {
      color: #757575;
    }

    .yotpo-icon-empty-star {
      &:before {
        content: "\\e60e";
        color: #e0e0e0;
      }
    }
  }

  .yotpo-icon-star {
    color: #000000 !important;
  }

  .yotpo-icon-half-star:after {
    content: "\\e61a";
    position: absolute;
    left: 0;
    top: 0;
    color: #000000;
    width: 48%;
    overflow: hidden;
  }

  .yotpo-dropdown-wrapper .mobile-collapse {
    display: none;
  }

  .yotpo-nav-tab[data-type="questions"] {
    display: none;
  }

  .yotpo-bottomline-box-2 {
    display: none;
  }

  .label-with-tooltip {
    display: none;
  }

  ${mediaQuery.medium`
    padding: 80px 1rem;
  `};
`;

export const ReviewsTitle = styled.div`
  margin: 0 0 8px;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: 0.2px;
  -webkit-font-smoothing: subpixel-antialiased;
  line-height: 34px;
  text-align: center;
  font-family: "Crimson Text", Georgia, serif;

  ${mediaQuery.medium`
  font-size: 30px;
  margin-bottom: 18px;
`};
`;

export const LightboxStyles = createGlobalStyle`
  .yotpo-lightbox {
    background-color: blue;

    .yotpo-icon-profile {
      display: none !important;
    }

    .yotpo-review-stars {
      margin-top: 0px !important;
      position: initial !important;
      order: -1;

      ${mediaQuery.medium`
        margin-top: 8px !important;
      `}
    }

    .yotpo-user-title {
      display: none !important;
    }

    .yotpo-footer {
      display: none;
    }

    .yotpo-small-box {
      display: none;

    }

    .yotpo-main {
      display: none !important;

      ${mediaQuery.medium`
        display: block !important;
        margin: 32px 0;
      `};
    }

    .yotpo-header {
      display: flex !important;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 13px;

      * {
        color: white !important;
      }

      ${mediaQuery.medium`
        margin: 0;
        display: table !important;
        border-bottom: 1px solid;

        * {
          color: black !important;
        }
      `};
    }

    .yotpo-header-element:not(.yotpo-icon-profile) {
      display: flex !important;
      align-items: center;

      ${mediaQuery.medium`
        display: block !important;
      `};
    }

    .yotpo-lightbox-content-container {
      position: initial;
    }

    .yotpo-lightbox-mobile-content {
      padding-top: 0 !important;
    }

    .yotpo-modal-content {
      background-color: black !important;
    }

    .yotpo-lightbox-fade-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      ${mediaQuery.medium`
        position: initial;
      `}
    }

    .yotpo-review-date {
      line-height: initial;
    }

    .yotpo-lightbox-arrows {
      top: 50% !important;
    }
  }
`;
