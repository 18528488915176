import { useEffect, useState } from 'react';
import loadScript from 'load-script';
import { isNotServerSideRendering } from 'helpers/env';

const acsbInitConfig = {
  statementLink: '',
  footerHtml: '',
  hideMobile: true,
  hideTrigger: false,
  language: 'en',
  position: 'right',
  leadColor: '#333333',
  triggerColor: '#757575',
  triggerRadius: '0',
  triggerPositionX: 'right',
  triggerPositionY: 'top',
  triggerIcon: 'people',
  triggerSize: 'small',
  triggerOffsetX: 0,
  triggerOffsetY: 140,
  mobile: {
    triggerSize: 'small',
    triggerPositionX: 'right',
    triggerPositionY: 'top',
    triggerOffsetX: 0,
    triggerOffsetY: 360,
    triggerRadius: '0'
  }
};

export const getAccessibeScriptUrl = () => {
  return 'https://acsbapp.com/apps/app/dist/js/app.js';
};

const useAccessibe = () => {
  const [initialized, setInitialized] = useState<boolean>(false);

  // Load checkout script for amazon payments
  const loadAccessibeScript = () => {
    loadScript(getAccessibeScriptUrl(), (err: string) => {
      if (err) {
        console.log(err);
        return;
      }

      setInitialized(true);
    });
  };

  // Create accessibe script
  const renderAccessibeButton = () => {
    if (isNotServerSideRendering() && initialized && window.acsbJS) {
      window.acsbJS.init(acsbInitConfig);
    }
  };

  useEffect(() => {
    loadAccessibeScript();
  }, []);

  useEffect(() => {
    if (initialized) {
      renderAccessibeButton();
    }
  }, [initialized]);

  return {
    initialized
  };
};

export default useAccessibe;
