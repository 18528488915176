import styled from 'styled-components';

export const DeskTopImageGalleryContainer = styled('div')`
  display: block;
  overflow: scroll;
  scrollbar: hidden;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export const TwoColumnContainer = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 0.2rem;
  grid-gap: 0.2rem;
`;

export const LivePreviewContainer = styled('div')`
  position: relative;
  padding-top: 100%;
  background: #f0f0f0;
`;

export const LivePreviewContainerInner = styled('div')`
  width: 100%;
  position: absolute;
  top: 0;
  left; 0;
`;

export const MobileImageGalleryContainer = styled('div')``;
