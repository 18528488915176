import styled from 'styled-components';

export const disabledInputCss = `
  &:not(.disabled) {
    :hover,
    &.checked {
      border-color: #000;
    }

    &not(.checked) {
      border: none;
    }
  }

  &.disabled {
    cursor: default;
    opacity: 0.9;
    color: #757575;
  }
`;

export const SoldOutLabelContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
`;

export const SoldOutLabel = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #757575;
  text-decoration: none;
  letter-spacing: normal;
  margin: auto;
`;

export const OptionLabel = styled.span<{ disabled: boolean }>`
  text-decoration: ${props => (props.disabled ? 'line-through' : 'none')};
`;
