import React from 'react';
import styled, { css } from 'styled-components';
import withTooltip from '../Tooltip';
import Information from '../icons/Information';
import { StyledP } from '../typography/index';

interface DefinitionItemOptions {
  tooltip?: React.ReactNode;
  primary?: boolean;
}
interface DefinitionItem {
  term: string;
  definition: string;
  options?: DefinitionItemOptions;
}
interface DefinitionListProps {
  items: DefinitionItem[];
  className?: string;
  color?: string;
}

const InfoIcon = ({ className = '' }: { className?: string }) => (
  <span className={className}>
    <Information />
  </span>
);

const StyledInfoIcon = styled(InfoIcon)`
  height: 15px;
  position: relative;
  width: 15px;
  svg {
    width: 15px;
    height: 15px;
  }
  path {
    fill: #6aa3be;
  }
`;

export const ToolTipIcon = withTooltip(StyledInfoIcon);

const DefinitionListUl = styled.ul`
  display: block;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    flex-flow: row wrap;
    display: flex;
    justify-content: space-between;
  }
  li:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

const DefinitionItemWrapper = styled(StyledP)<{
  primary: boolean;
  color: string;
}>`
  margin: 0;

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ primary }) =>
    primary &&
    css`
      font-weight: bold;
    `}
`;

const DefinitionList = ({ items = [], className, color = 'black' }: DefinitionListProps) => (
  <DefinitionListUl className={className}>
    {items.map(({ term, definition, options }: DefinitionItem) => (
      <li key={`${term}${definition}`}>
        <DefinitionItemWrapper primary={!!(options && options.primary)} color={color}>
          {term}
          {options && options.tooltip ? <ToolTipIcon>{options.tooltip}</ToolTipIcon> : null}
        </DefinitionItemWrapper>
        <DefinitionItemWrapper primary={!!(options && options.primary)} color={color}>
          {definition}
        </DefinitionItemWrapper>
      </li>
    ))}
  </DefinitionListUl>
);

export default DefinitionList;
