import React from 'react';
import styled, { css } from 'styled-components';
import { mediaQuery } from '../Grid';
import * as Colors from '../../constants/colors';

export interface TypographyProps {
  color?: string;
  fontFamily?: string;
  lightBlue?: boolean;
  primary?: boolean;
  secondary?: boolean;
  alignment?: 'left' | 'center' | 'right';
  fontStyle?: 'italic' | 'bold';
}

// THIS IS WARNING ON CIRCULAR DEPENDENCY HERE AND NOT IN COMPONENT-LIBRARY -- NEED TO FIX/LOOK AT/DO SOMETHING
export const baseStyles = css`
  color: ${props => (props.color ? props.color : '#000')};
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-weight: normal;
  line-height: 1.0;
  -webkit-font-smoothing: antialiased;

  /* Font Colors */
  ${({ lightBlue }: TypographyProps) =>
    lightBlue &&
    css`
      color: #629eba;
    `}

  /* Font Families */
  ${({ primary }: TypographyProps) =>
    primary &&
    css`
      font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
    `}

  ${({ secondary }: TypographyProps) =>
    secondary &&
    css`
      font-family: 'Crimson Text', 'Georgia', serif;
    `}

  /* Text Align */
  ${({ alignment }: TypographyProps) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}

  /* Font Style */
  ${({ fontStyle }: TypographyProps) =>
    fontStyle &&
    css`
      font-style: ${fontStyle};
    `}
`;

export const StyledA = styled.a<TypographyProps>`
  text-decoration: underline !important;
`;

export const StyledH1 = styled.h1<TypographyProps>`
  ${baseStyles}
  font-size: 2.8125rem;
  line-height: 4.0625rem;
`;

export const StyledH2 = styled.h2<TypographyProps>`
  ${baseStyles};
  line-height: 1.625rem;

  ${mediaQuery.medium`
    font-size: 1.9375rem;
  `};
`;

export const StyledH3 = styled.h3<TypographyProps>`
  ${baseStyles};
  font-size: 1.375rem;
  line-height: 2rem;
`;

export const StyledH4 = styled.h4<TypographyProps>`
  ${baseStyles};
  font-size: 1rem;
  line-height: 1.4375rem;
`;

export const StyledP = styled.p<TypographyProps>`
  ${baseStyles};
  font-size: 1rem;
  line-height: 1.35rem;
`;

export const StyledProjectName = styled.p<TypographyProps>`
  ${baseStyles};
  font-size: 1rem;
  line-height: 1.35rem;
  max-width: 210px;
  word-wrap: break-word;
`;

export const StyledPAlt = styled.p<TypographyProps>`
  ${baseStyles};
  font-size: 0.875rem;
  line-height: 1.35rem;
  color: rgba(0, 0, 0, 0.5);
`;

export const Subtext = styled.p<TypographyProps>`
  ${baseStyles};
  font-size: 0.8rem;
  line-height: 1.2rem;
`;

export const ArticleBodyTitle = styled.h1`
  ${baseStyles};
  font-size: 24px;
  letter-spacing: 0;
  line-height: 28px;
  text-align: center;
  text-transform: none;
  margin: 0 8% 22px;
`;

export const ArticleBodyText = styled.p`
  ${baseStyles};
  font-size: 19px;
  line-height: 28px;
`;

export const StyledLabel = styled.p`
  ${baseStyles};
  font-size: 0.6875rem;
  line-height: 1.35rem;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  && > p,
  && > label {
    display: block;
    margin-bottom: 0;
    margin-top: 0;
    ${mediaQuery.medium`
      margin-left: .5rem;
      display: inline-block;
    `};
  }
`;

export const StyledLabelSpan = styled.span`
  ${baseStyles};
  font-size: 0.6875rem;
  line-height: normal;
  font-family: 'Lato', 'helvetica neue', 'helvetica', 'arial', sans-serif;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

const _PromotionalText = styled.span`
  ${baseStyles};
  font-size: 14px;
  font-weight: 600;
`;
export const PromotionalText = (props: any) => <_PromotionalText color={Colors.DARK_ORANGE} {...props} />;
