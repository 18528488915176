import styled from 'styled-components';

export const StyledOptionContainer = styled.div`
  margin: 20px auto 40px auto;
  min-width: 300px;
`;

export const StyledRadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: scroll;
  flex: 1;
  margin-bottom: 8px;
  position: relative;
  text-align: center;

  label {
    cursor: pointer;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
  supported by Chrome and Opera */
    :focus {
      outline: none;
    }
  }
`;

export const StyledH4 = styled.h4`
  color: #000;
  font-family: Lato, 'helvetica neue', 'helvetica', 'arial', sans-serif;
  font-style: normal;
  font-weight: 500;
  display: block;
  font-size: 14px;
  text-transform: lowercase;
  line-height: 14px;
  margin-bottom: 20px;
  margin-top: 0;

  ::first-letter {
    text-transform: capitalize;
  }

  @media (min-width: 48em) {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 16px;
  }
`;

export const StyledAttributeValue = styled.span`
  color: #757575;
  padding-left: 0.25rem;
`;

export const StyledRadioInput = styled.input`
  position: absolute;
  display: none;

  :checked,
  :hover {
    color: #000;
    border: 1px solid #000;
    z-index: 1 !important;
  }
`;

export const disabledInputCss = `
  &.disabled {
    cursor: not-allowed;
    opacity: 0.9;
    color: #757575;
  }
`;
export const hoverStateCss = `
  :hover:not(.disabled),
  &.checked {
    border: 2px solid transparent;
    img {
      border: 2px solid #000;
      padding: 2px;
    }
    span {
      font-weight: 700;
    }
  }
`;

export const SoldOutLabelContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0px;
`;

export const SoldOutLabel = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  color: #757575;
  text-decoration: none;
  letter-spacing: normal;
  margin: auto;
`;

export const OptionLabel = styled.span<{ disabled: boolean }>`
  font-size: 12px;
  padding-top: 6px;
  text-decoration: ${props => (props.disabled ? 'line-through' : 'none')};
`;
