/**
 * We have a global registry of promises to keep tabs on the singleton tasks
 * which have run.
 */
const singletonRegistry = new Map<any, Promise<void>>();

/**
 * @param fn An async function.
 * @returns Function which wraps`fn` and ensures it will only execute once
 *          regardless of the number of times it's called.
 */
export function ensureRunsOnce(fn: (...args: any) => Promise<void>, opts: { debug?: boolean } = {}) {
  return (...args) => {
    if (!singletonRegistry.has(fn)) {
      const promise = fn(...args, opts);
      singletonRegistry.set(fn, promise);
    }
    return singletonRegistry.get(fn);
  };
}
