import React from 'react';
import Select from 'react-select';
import styled from 'styled-components';

export default (styled(Select)`
  > div {
    border: 1px solid #d1d1d1;
    border-radius: 1px;
    font-family: 'Crimson Text', 'Georgia', serif;
    font-size: 16px;
    margin-bottom: 20px;
    text-transform: capitalize;
    letter-spacing: 0;

    ${({ error }) => error && 'border: 1px solid #c11e1e;'}
  }
` as React.ComponentType) as new <T>() => Select<T>;
