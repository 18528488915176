import React from 'react';
import styled from 'styled-components';
import ReactSelect from 'react-select';
import { PrimaryButton, SecondaryButton } from '../../Button';
import Icon from '../../icons';

export const BlackButton = styled(PrimaryButton)`
  transition: box-shadow 0.2s linear;
  line-height: initial;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: auto;

  &:focus {
    outline: #000;
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.2);
  }
`;

export const AddImage = styled.img`
    width: 1.6rem;
    height: 1.6rem;
    margin: 0.3rem;
    @media screen and (max-width: 780px) {
        width: 1rem;
        height: 1rem;
        margin: 0.3rem;
  }
`;

export const BlankButton = styled(SecondaryButton)`
  border: 0;
  background-color: transparent;
  color: inherit;
  letter-spacing: normal;
  margin: 0;
  text-transform: inherit;
`;

export const ButtonWrapper = styled.div`
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: row;
	flex-direction: row;
	-webkit-align-items: stretch;
	align-items: stretch;
	-webkit-justify-content: center;
	justify-content: center;
	margin-top: 2rem;
  margin-bottom: 3rem;
  @media (max-width: 780px) {
    -webkit-flex-direction: column;
    flex-direction: column;
    margin-bottom: 1rem;
    min-width: 100%;
  }

`;

export const ButtonLarge = styled.button`
  cursor: auto;
	height: 9rem !important;
	width: 100%;
	border: none;
	opacity: 0.65;
	background-color: #FFFFFF;
	color: #000000;
	font-family: "Crimson Text", "Georgia", serif;
	font-size: 22px;
	line-height: 32px;
	text-align: center;
	transition: box-shadow 0.2s linear;
	box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 -1px 1px 0 rgba(0,0,0,0.05);
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
  outline: none;
  opacity: 1;
  
  &:disabled {
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.2), 0 -1px 1px 0 rgba(0,0,0,0.05);
    opacity: 0.5;
  }

  span {
    text-align: center;
    display: block;
  }
  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
  @media (max-width: 780px) {
    align-items: center;
		box-sizing: border-box;
		display: flex;
		font-size: 1rem;
		height: 4rem;
		justify-content: left;
		margin-bottom: 1rem;
		padding: 0 1rem;
		width: 100%;
  }
`;

export const ButtonLargeActive = styled(ButtonLarge)`
  cursor: auto;
  box-shadow: 0 4px 16px 0 rgba(0,0,0,0.2);
  border: 2px solid #4A90E2 !important;
  height: 9rem !important;
  width: 100%;
  outline: none;
  background-color: #FFFFFF;
  color: #000000;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 22px;
  line-height: 32px;
  text-align: center;

  @media (max-width: 1200px) {
    font-size: 1.25rem;
  }
`;

export const OptionContainer = styled.div`
	flex: 1;
	margin-left: 1rem;
	margin-right: 1rem;
  min-height: 230px;
  border: 1px solid #333333;
  border-radius: 0.6rem;
  padding: 2rem;
  span {
    max-width: fit-content !important;
  }
  @media (max-width: 1200px) {
    max-width: 80%;
    min-height: auto;
  }
  @media (max-width: 780px) {
    width: 100%;
    max-width: 95vw;
    margin: 2rem 0;
    padding: 1.4rem;
  }
`;

export const AddingTypeTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    margin-top: 0.6rem;
    font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
    font-size: '1rem',
  }
`;
export const TextContainer = styled.div`
  margin-top: 0.5rem;
`;

export const QuicklyText = styled.div`
  margin-bottom: 0.5rem;
	margin-left: auto;
	margin-right: auto;
	color: #000000;
	font-family: "Crimson Text", "Georgia", serif;
	font-size: 1rem;
	max-width: 30rem;
	line-height: 1.5rem;
	text-align: center;
`;

export const PlaceholderIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  height: 2.9rem;
  width: 2.9rem;
  margin-bottom: 0.5rem;

  @media (max-width: 780px) {
		margin: 0.4rem 0 0 0;
  }
`;

export const AddImageIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  height: 2.9rem;
  width: 2.9rem;
  max-width: fit-content;
  margin-bottom: 0.5rem;
  @media (max-width: 780px) {
		margin: 0.4rem 0 0 0;
  }
`;

export const Spinner = styled.div`
  height: 1rem;
  width: 5.3rem;
  z-index: 100;
`;

export const InputWrapper = styled.div`
  min-height: 50px;
  flex-direction: column;
`;

export const OverLimitWrapper = styled.div`
  min-height: 50px;
  flex-direction: column;
`;

export const Select = styled(ReactSelect)`
  height: 3.4rem;
	width: 75%;
	margin: 0 auto;
  margin-bottom: 1rem;
  
  @media (max-width: 1200px) {
    min-width: auto;
    
    .Select-control {
      min-width: auto;
    }
	}
`;

export const Inputs = styled.div`
  margin: 0 auto 7px;

  &::placeholder {
    color: #D1D1D1;
  }
  @media (max-width: 780px) {
    width: 100%;
  }
`;

export const Input = styled.div`
  > input {
    border: none;
    outline: none;
    text-align: center;
    border-bottom: 1px solid black;
    width: 70%;
    background-color: #F2F2F2;
    line-height: 2rem;
    padding: 0.5rem 0;
    color: #000000;
    font-size: 1rem;
    &::placeholder {
      font-family: "Crimson Text", "Georgia", serif;
      font-size: 1.375rem;
      line-height: 2;
      font-weight: normal;
      color: #C0C0C0;
    }

    @media (max-width: 780px) {
      .title-3::-webkit-placeholder {
        font-size: 0.8rem;
      }

      .title-3::placeholder {
        font-size: 0.8rem;
      }
    }
  }
`;

export const ErrorText = styled.div`
  font-size: 11px;
	line-height: 16px;
	text-align: center;
  transition: 0.3s;
  opacity: 0;
`;

export const ErrorTextActive = styled.div`
  transition: 0.3s;
  opacity: 1;
  color: #A94F3E;
  font-family: "Lato", "helvetica neue", "helvetica", "arial", sans-serif;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;

export const OptionWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const OptionThumb = styled.div`
  height: 2.5rem;
  width: 2.5rem;
  margin-left: 1rem;
  margin-right: 2rem;
`;

/* overwrite react-select styling in this component */
export const SelectUp = styled.div`
  > .Select-menu-outer {
    position: absolute !important;
    top: auto !important;
    bottom: calc(100% - 1px) !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  > .is-open .Select-control {
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-bottom-right-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
  }

  @media (max-width: 780px) {
    width: 100%;
  }
`;

export const BottomBuffer = styled.div`
	margin-bottom: 1.5rem;
`;
