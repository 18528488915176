import React from 'react';
import './VimeoVideoContainer.css';

type VimeoParam = '0' | '1' | 'true' | 'false';

/**
 * Vimeo Player Parameters
 * @see {@link https://help.vimeo.com/hc/en-us/articles/12426260232977-Player-parameters-overview|Vimeo Player Params} for full list of params and behavior
 */
interface VimeoPlayerParams {
    background?: VimeoParam;
    autoplay?: VimeoParam;
    transparent?: VimeoParam;
    controls?: VimeoParam;
    keyboard?: VimeoParam;
    muted?: VimeoParam;
    api?: VimeoParam;
}

interface VimeoVideoContainerProps extends VimeoPlayerParams, Pick<React.HTMLAttributes<HTMLDivElement>, 'onClick' | 'onMouseEnter' | 'onMouseLeave'> {
    videoId?: string;
    isMobile?: boolean;
}

const VIMEO_PLAYER_URL = 'https://player.vimeo.com/video/';

export const VimeoVideoContainer = React.forwardRef<HTMLIFrameElement, VimeoVideoContainerProps>(({ videoId, isMobile, onMouseEnter, onMouseLeave, onClick, ...vimeoParams }, ref) => {
    const {
        background = '1',
        autoplay = '1',
        transparent = '1',
        controls = '0',
        keyboard = '1',
        muted = '1',
        api = '1'
    } = vimeoParams;

    const urlParams = new URLSearchParams({
        background,
        autoplay,
        transparent,
        controls,
        keyboard,
        muted,
        api
    });

    if (!videoId) {
        return null;
    }

    const iframeSrc = `${VIMEO_PLAYER_URL}${videoId}?${urlParams.toString()}`;

    return (
        <div className="vimeo-video-wrapper">
            <iframe ref={ref} className="vimeo-video-iframe" title={videoId} src={iframeSrc} />

            <div
                className="vimeo-video-iframe-overlay"
                onClick={onClick}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        </div>
    );
});