import { adjustHiDPI, isVideo } from '../helpers/ui';
import React, { useCallback, useContext, useState, useEffect } from 'react';
import Player from '@vimeo/player';
import { CarouselProvider, Slider, Slide, Dot, DotGroup, CarouselInjectedProps } from 'pure-react-carousel';
import CatalogImage from '../CatalogImage';
import { PhotoSlideProps, PhotoSliderProps, PhotoSlideThumbProps, VimeoProps } from '../types';
import { PhotoSliderWrapper, PhotoThumbsWrapper, Badge, Badges, IframeOverlay } from './index.styled';
import styled from 'styled-components';
import { setWindowScrollable } from '../helpers/window';
import { CarouselContext } from 'pure-react-carousel';

const VIMEO_PLAYER_URL = 'https://player.vimeo.com/video/';
const VIMEO_PARAMETERS = '?background=1&autoplay=1&transparent=1&api=1';
class Vimeo extends React.Component<VimeoProps> {
  iframe: any;
  player: any;
  constructor(props: VimeoProps) {
    super(props);

    this.renderVimeoIframe = this.renderVimeoIframe.bind(this);
    this.setRef = this.setRef.bind(this);
  }

  setRef(elem: any) {
    this.iframe = elem;
    this.setPlayer();
  }

  componentDidMount() {
    this.setPlayer();
  }

  setPlayer() {
    if (!this.iframe) {
      return;
    }

    this.player = new Player(this.iframe);
  }

  renderVimeoIframe() {
    const { videoId } = this.props;

    return (
      <>
        <iframe
          title={videoId}
          // eslint-disable-next-line @typescript-eslint/unbound-method
          ref={this.setRef}
          src={`${VIMEO_PLAYER_URL}${videoId}${VIMEO_PARAMETERS}`}
          style={{ width: '100%', height: '100%' }}
          frameBorder="0"
        />
        <IframeOverlay></IframeOverlay>
      </>
    );
  }

  render() {
    return <div className="pdp-vimeo-container">{this.renderVimeoIframe()}</div>;
  }
}

const PhotoSlide = (props: PhotoSlideProps) => {
  const { index, media, badges } = props;

  // The filter on badges exists because API has a habit of providing badge
  // values that are empty strings, these should be treated as no badge.
  const hasBadges = Array.isArray(badges) && badges.filter(b => b).length > 0;
  const handleZoom = useCallback((zoom: boolean) => {
    if (props.onZoom) {
      props.onZoom(zoom);
    }
  }, []);

  // Use context to get currentSlide state
  // We're only rendering videos when on the currentSlide to prevent the vimeo player
  // from breaking and showing a black box when returning to video slide
  const carouselContext = useContext(CarouselContext);
  const [currentSlide, setCurrentSlide] = useState(carouselContext.state.currentSlide);
  useEffect(() => {
    function onChange() {
      setCurrentSlide(carouselContext.state.currentSlide);
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <Slide index={index}>
      {hasBadges && (
        <Badges>{badges ? badges.map((badge, i) => <Badge key={`badge-${i}`}>{badge}</Badge>) : null}</Badges>
      )}
      {isVideo(media) && currentSlide === index ? <Vimeo videoId={media.videoId} /> : null}
      {!isVideo(media) && (
        <>
          <CatalogImage
            alt={media.caption}
            src={media.url}
            css={{ borderRadius: '2px' }}
            fullscreenOnMobile
            onZoom={handleZoom}
            widths={{
              tiny: adjustHiDPI(400), // Small breakpoint is 376px
              small: adjustHiDPI(600), // Medium breakpoint is 576px
              medium: adjustHiDPI(800), // The largest the image will render here is 100vw at 768px breakpoint
              large: adjustHiDPI(1000) // The max width of the GlobalLayout wrapper is set to 1180px, this image will never render larger than half of that
            }}
            zoom
          />
        </>
      )}
    </Slide>
  );
};

function PhotoSlideThumb(props: PhotoSlideThumbProps) {
  const { index, media } = props;

  return (
    <Dot
      slide={index}
      style={{ height: '60px' }}
      className={`${isVideo(media) ? 'video-thumbnail-container' : ''} photo-slider__thumb`}
    >
      <>
        {isVideo(media) ? (
          <div className="play-btn--thumb">
            <img
              alt="Product Demo Video"
              title="Product Demo Video"
              src="https://cdn.artifactuprising.com/assets/pdp+images/play-btn--thumb.png"
            />
          </div>
        ) : (
          <CatalogImage
            alt={media.caption}
            src={media.url}
            widths={{
              tiny: 150,
              small: 150,
              medium: 150,
              large: 150
            }}
            fullscreenOnMobile
            css={{ borderRadius: '2px' }}
            height={'60px'}
          />
        )}
      </>
    </Dot>
  );
}
const Wrapper = styled.div`
  width: 100%;

  * {
    box-sizing: border-box;
  }
  picture {
    width: 100%;
  }
  img {
    height: auto;
    max-width: 100%;
  }
`;
export default class PhotoSlider extends React.Component<PhotoSliderProps> {
  constructor(props: PhotoSliderProps) {
    super(props);
    this.carouselRef = React.createRef();
  }
  // Reference to the carousel provider (for dynamically changing carousel)
  // Using "any" here because CarouselProvider doesn't know it's sending back
  // injected props, so errors if you explicitly type this with
  // CarouselInjectedProps
  carouselRef: React.RefObject<any>;

  handleZoom = (zoom: boolean): void => {
    // If we are zoomed in on an image, don't let the carousel move forward.
    // Otherwise, the carousel moves behind the image on pan.
    if (this.carouselRef.current && this.carouselRef.current !== null) {
      (this.carouselRef.current as CarouselInjectedProps).carouselStore.setStoreState({ touchEnabled: !zoom });
    }
    setWindowScrollable(!zoom);
  };

  // Need this to prevent the Carousel resetting whenever the attributes change.
  shouldComponentUpdate(nextProps: PhotoSliderProps): boolean {
    return !!nextProps.livePreviewUrl;
  }

  render() {
    const { photos, badges, livePreviewUrl } = this.props;

    let orderedPhotos = photos.sort((a, b) => (a.videoUrl ? -1 : 1));
    const slideCount = livePreviewUrl ? photos.length + 1 : photos.length;

    const livePreviewSlide = livePreviewUrl && (
      <PhotoSlide index={orderedPhotos.length} media={{ caption: 'Live Preview', url: livePreviewUrl }} />
    );

    const livePreviewThumb = livePreviewUrl && (
      <PhotoSlideThumb index={orderedPhotos.length} media={{ caption: 'Live Preview Thumb', url: livePreviewUrl }} />
    );

    return (
      <Wrapper>
        <CarouselProvider
          naturalSlideWidth={1000}
          naturalSlideHeight={1000}
          totalSlides={slideCount}
          currentSlide={0} //currentSlide is only the first shown slide on mount
          dragEnabled={false}
          touchEnabled={true}
          ref={this.carouselRef}
        >
          <div className="photo-slider">
            <PhotoSliderWrapper>
              <PhotoThumbsWrapper>
                <div className="photo-slider__thumbs">
                  {orderedPhotos.map((photo, i) => (
                    <PhotoSlideThumb key={i} index={i} media={photo} />
                  ))}
                  {livePreviewThumb}
                </div>
              </PhotoThumbsWrapper>
              <Slider className="photo-slider__slides">
                {orderedPhotos.map((photo, i) => (
                  <PhotoSlide key={i} index={i} media={photo} badges={badges} onZoom={this.handleZoom} />
                ))}
                {livePreviewSlide}
              </Slider>
            </PhotoSliderWrapper>
          </div>
          <DotGroup />
        </CarouselProvider>
      </Wrapper>
    );
  }
}
