import React from 'react';
import { MagentoMenuItem } from '../../../../types/Navigation';
import DropdownSection from './DropdownSection';

interface DropdownProps {
  parentName: string;
  menuItems: Array<MagentoMenuItem>;
  leftDropdownChildren?: JSX.Element;
  rightDropdownChildren?: JSX.Element;
}

const Dropdown = ({ parentName, menuItems, leftDropdownChildren, rightDropdownChildren }: DropdownProps) => {
  const [left, right] = menuItems.reduce(
    ([left, right], item: MagentoMenuItem) => [
      [...left, ...(item.align !== 'right' ? [item] : [])],
      [...right, ...(item.align === 'right' ? [item] : [])]
    ],
    [[], []]
  );

  const singleColumnClass = !left.length || !right.length ? 'SingleColumn' : '';

  return (
    <div className={`DesktopNav__Dropdown ${singleColumnClass}`}>
      <div className={`DesktopNav__Dropdown-Column ${singleColumnClass}`}>
        {left.map((mi, i) => (
          <DropdownSection menuItem={mi} key={i} children={leftDropdownChildren} parentName={parentName} />
        ))}
      </div>
      <div className={`DesktopNav__Dropdown-Column ${singleColumnClass}`}>
        {right.map((mi, i) => (
          <DropdownSection menuItem={mi} key={i} children={rightDropdownChildren} parentName={parentName} />
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
