import React, { useState } from 'react';
import { CardWithShadow, FullScreen, CloseButton } from '../ProductImageGalleryCard';
import useLockBodyScroll from '../hooks/useBodyScrollLock';
import { Icon } from '..';
import { useDispatch } from 'react-redux';
import { hideModal } from 'store/modal/actions';

const WHITE_OUT = 'white-out';

const clickHandler = (e: any, onClickOutside?: () => void) => {
  if (e.target === e.currentTarget && onClickOutside) {
    e.preventDefault();
    onClickOutside();
  }
};

export const WhiteOut = FullScreen.withComponent('div');

interface ProductImageGalleryOverlayProps {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
  type?: string;
  fullscreen?: boolean;
  paddedModal?: boolean;
  gallery?: boolean;
}

export const ProductImageGalleryOverlay = ({
  children,
  className = '',
  onClose,
  type = '',
  fullscreen = false,
  paddedModal = true,
}: ProductImageGalleryOverlayProps): React.ReactElement => {
  const dispatch = useDispatch();
  const [closeHover, setCloseHover] = useState<boolean>(false)

  useLockBodyScroll();

  const handleCloseModal = () => {
    dispatch(hideModal());
  };

  const handleCloseHover = () => setCloseHover(!closeHover)

  return (
    <WhiteOut
      className={`${WHITE_OUT} ${className}`}
      onMouseDown={e => {
        clickHandler(e, handleCloseModal);
      }}
      role="presentation"
    >
      <div id={`${type}Modal`}>
        <CardWithShadow data-fullscreen={fullscreen} className={paddedModal ? '' : 'no-padding'}>
          {onClose ? (
            <CloseButton onClick={onClose} onMouseOver={handleCloseHover} onMouseOut={handleCloseHover}>
              <Icon icon="close" size="small" color={closeHover ? '#000' : '#fff'} />
            </CloseButton>
          ) : null}
          {children}
        </CardWithShadow>
      </div>
    </WhiteOut>
  );
};

export default ProductImageGalleryOverlay;
