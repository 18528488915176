import { graphql, useStaticQuery } from 'gatsby';
import { CategorySLA, isCategorySLA } from 'types/Product';

export const useProductSelectors = () => {
  const queryResult = useStaticQuery<UseProductStaticQuery>(useProductQuery);
  const { allProduct, allYotpoProductBottomline } = queryResult;

  const findProductBySku = (sku: string) => allProduct?.edges?.find(edge => edge.node.sku === sku);

  const getReportingProductCategory = (sku: string) => findProductBySku(sku)?.node?.reportingProductCategory;
  const getProductName = (sku: string) => findProductBySku(sku)?.node?.name;
  const getLivePreviewAttributes = (sku: string) => findProductBySku(sku)?.node?.livePreviewAttributes;
  const getBasePDPImage = (sku: string) => findProductBySku(sku)?.node?.legacy_pdp_render_info?.media[0]?.thumb;
  const getAttributes = (sku: string) => findProductBySku(sku)?.node?.legacy_pdp_render_info?.attributes;
  const getEditorVersion = (sku: string) => findProductBySku(sku)?.node?.editorVersion;
  const getStrikethroughValues = (sku: string) => findProductBySku(sku)?.node?.strikethroughValues;
  const getSLACategory = (sku: string): CategorySLA | undefined => {
    const categoryName = findProductBySku(sku)?.node?.categories?.find(c => c.name.includes('Day SLA'))?.name;
    if (!isCategorySLA(categoryName)) {
      return undefined;
    }

    return categoryName;
  };
  const getYotpoProductBottomline = (productId: number): YotpoProductBottomLine =>
    allYotpoProductBottomline?.edges?.find(edge => edge.node.productIdentifier === productId.toString())?.node;
  const getProductById = (productIdentifier: number) =>
    allProduct?.edges?.find(edge => edge?.node?.productId === productIdentifier);
  const getCanonicalUrl = (sku: string) => findProductBySku(sku)?.node?.canonicalUrl;

  return {
    findProductBySku,
    getProductName,
    getLivePreviewAttributes,
    getBasePDPImage,
    getReportingProductCategory,
    getAttributes,
    getEditorVersion,
    getStrikethroughValues,
    getSLACategory,
    getYotpoProductBottomline,
    getProductById,
    getCanonicalUrl
  };
};

export interface QueryProduct {
  sku: string;
  editorVersion: string;
  name: string;
  reportingProductCategory: string;
  reportingProductLine: string;
  productId: number;
  price: number;
  livePreviewAttributes: string[];
  categories: {
    name: string;
  }[];
  legacy_pdp_render_info: {
    attributes: LegacyPDPRenderInfoAttribute[];
    media: {
      thumb: string;
    }[];
  };
  strikethroughValues: {
    discountAmount: number;
    discountType: string;
    promoCode: string;
  };
  canonicalUrl: string;
}
export interface UseProductStaticQuery {
  allProduct: {
    edges: {
      node: QueryProduct;
    }[];
  };
  allYotpoProductBottomline: {
    edges: {
      node: YotpoProductBottomLine;
    }[];
  };
}
export interface LegacyPDPRenderInfoAttribute {
  label: string;
  options: {
    display_name: string;
    label: string;
  }[];
}

export interface YotpoProductBottomLine {
  score: number;
  productIdentifier: string;
  totalReviews: number;
}

const useProductQuery = graphql`
  query UseProductQuery {
    allProduct {
      edges {
        node {
          sku
          editorVersion
          name
          reportingProductLine
          price
          productId
          reportingProductCategory
          livePreviewAttributes
          categories {
            name
          }
          legacy_pdp_render_info {
            attributes {
              label
              options {
                display_name
                label
              }
            }
            media {
              thumb
            }
          }
          strikethroughValues {
            discountAmount
            discountType
            promoCode
          }
          canonicalUrl
        }
      }
    }
    allYotpoProductBottomline {
      edges {
        node {
          score
          totalReviews
          productIdentifier
        }
      }
    }
  }
`;
