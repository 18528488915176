import { TrackingProp } from 'react-tracking';
import Itly, { FilterSelectedProperties } from 'itly';
import { FilterSelectedEvent } from '../analyticsWrapper';

export function trackFilterSelected(
  tracking: TrackingProp<FilterSelectedEvent>,
  attributeCode: string,
  optionSelected: string,
  filterState: any
) {
  const data: FilterSelectedProperties = {
    applied_filters: filterState?.filters,
    filter_option: attributeCode,
    filter_value: optionSelected
  };

  Itly.filterSelected(data);
}
