import React from 'react';
import ImageGallery from 'react-image-gallery';
import './ProductImageGallery.css';
import { MediaObject } from 'features/pdp/types/media';
import { renderPhoto, renderVideo } from 'components/PhotoSliderV2/helpers';
import { isVideo } from 'component-library/helpers/ui';

interface ProductImageGalleryModalProps {
  src: string;
  photos?: MediaObject[];
}

export const ProductImageGalleryModal = ({ src, photos }: ProductImageGalleryModalProps) => {

  const mappedGallery = photos.map(photo => ({
    originalWidth: '300px',
    originalHeight: '400px',
    renderItem: isVideo(photo) ? renderVideo : renderPhoto,
    isMobile: true,
    zoom: false,
    modalImageZoom: true,
    ...photo
  }));

  const sourceIndex: number = photos.findIndex(
    (galleryPhoto: MediaObject) => galleryPhoto.url === src || galleryPhoto.videoUrl === src
  );

  return (
    <ImageGallery
      items={mappedGallery}
      startIndex={sourceIndex}
      thumbnailPosition="left"
      showPlayButton={false}
      showFullscreenButton={false}
      showNav={true}
      showThumbnails={false}
      showBullets={false}
      swipeThreshold={20}
      flickThreshold={0.2}
      slideInterval={0}
      slideDuration={0}
      onErrorImageURL={'https://media.artifactuprising.com/media/catalog/product/placeholder/default/Placeholder.png'}
      additionalClass={'image-gallery-modal'}
    />
  );
};
