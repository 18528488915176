import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useProduct } from './useProduct';
import { PDPFields } from './useProductForm';
import { getProductDefaultsFromUrl, getProductPriceForOptions, watchFields } from '../helpers';
import { PriceObject } from '../components/ProductDetails/ProductPrice';

export const useProductPrice = (prefix?: string): PriceObject => {
  const form = useFormContext<PDPFields>();
  const { product } = useProduct();

  // Send params to `form.watch` to only watch the needed values to calculate the product price
  const formValues = useMemo(() => form ? watchFields(form.watch) : {}, [form]);

  const defaultsFromUrl = useMemo(() => product ? getProductDefaultsFromUrl(product) : {}, [product]);

  const formValuesWithDefaults = Object.keys(formValues).length === 0 ? defaultsFromUrl : formValues;

  const price = useMemo(() => product ? getProductPriceForOptions(product, formValuesWithDefaults, prefix) : undefined, [prefix, formValuesWithDefaults, product]);

  // these are skus which will be flagged as cards, but should not be
  // ex: Wedding Envelope Seals (ESS1)
  const notCardSkus = [
    'ESS1',
    'holiday-envelope-seals',
    'photo-display-ornament',
    'tis-the-season-gift-tag',
    'ornament-add-on',
    'merry-christmas-gift-tag',
    'festive-stamp-gift-tag'
  ];

  if (notCardSkus.includes(product.sku)) {
    price.isCard = false;
  }

  return price;
};