import React from 'react';
import styled, { css } from 'styled-components';
import Step from './Step';
import { StyledLabel } from '../typography';
import { mediaQuery } from '../Grid/index';

const capitalizeFirstLetter = (x: string) => x.charAt(0).toUpperCase() + x.slice(1);

interface StepperProps {
  steps: string[];
  activeStep: string;
}

const StepperContainer = styled.div`
  display: flex;
`;

const StepContainer = styled.div`
  display: flex;
`;

const MobileStep = styled.div<{ isActive: boolean }>`
  margin-right: 10px;
  p {
    opacity: 0.25;
  }

  ${({ isActive }) => isActive
    && css`
      p {
        font-weight: 900;
        opacity: 1;
      }
    `}

  ${mediaQuery.medium`
    display: none;
  `}
`;

const ProgressStepper = ({ steps, activeStep }: StepperProps) => {
  const activePos: number = steps.indexOf(activeStep);

  return (
    <StepperContainer>
      {steps.map((step: string, idx: number) => {
        const isActive = idx <= activePos;
        const isLast = idx === steps.length - 1;

        return (
          <StepContainer key={idx}>
            <MobileStep isActive={idx === activePos}>
              <StyledLabel>
                {idx + 1}
                .
                {capitalizeFirstLetter(step)}
              </StyledLabel>
            </MobileStep>
            <Step
              text={step}
              number={idx + 1}
              isActive={isActive}
              isLast={isLast}
            />
          </StepContainer>
        );
      })}
    </StepperContainer>
  );
};

export default ProgressStepper;
