import {
  ContactGroupWrapper,
  ModalTitle,
  ModalTextInput,
  ButtonContainer,
  FAQContent,
  CancelButton,
  SubmitButton,
  ModalForm,
  StepTwoButtonContainer,
  ModalSubTitle
} from './styled';
import React, { useState, useEffect } from 'react';
import { hideModal } from 'store/modal/actions';
import { useDispatch } from 'react-redux';
import { createContactGroup, uploadCsvText } from 'services/crudcore/address-management';
import Show from 'component-library/Functional/Show';
import AddressImport, { AddressImportProps } from './AddressImport';
import { ErrorLabel } from 'component-library';

const ContactGroupModal = ({ contactGroups, flashId, flashToken, finishCreate }) => {
  const [contactGroupName, setContactGroupName] = useState('');
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [csvUploadStep, setCsvUploadStep] = useState(false);
  const [csvFileName, setCsvFileName] = useState(undefined);
  const [csvText, setCsvText] = useState(undefined);
  const [createdContactGroupId, setCreatedContactGroupId] = useState(undefined);

  const [error, setError] = useState('');

  useEffect(() => {
    if (csvText) {
      setIsLoading(false);
    }
  }, [csvText, setIsLoading]);

  const handleCreateContactGroup = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const duplicateName = contactGroups.filter(
      contactGroup => contactGroup.groupName.toLowerCase() === contactGroupName.toLowerCase()
    );

    if (duplicateName.length > 0) {
      alert('You already have another group with that name. Please choose another name.');
      setContactGroupName('');
      setIsLoading(false);
    } else {
      let createdContactGroup;
      try {
        const createdContactGroup = await createContactGroup(flashId, flashToken, contactGroupName);
        if (step === 2 && !csvUploadStep) {
          finishCreate(createdContactGroup);
          setIsLoading(false);
          dispatch(hideModal());
        } else {
          await uploadCsvText(flashId, flashToken, createdContactGroup.groupId, csvText);
          finishCreate(createdContactGroup);
          setIsLoading(false);
          dispatch(hideModal());
        }
      } catch (error) {
        console.log(error);
        window.newrelic.noticeError(error);
        alert(
          'Something went wrong!\nWe were unable to create your contact group at this time, please try again later.'
        );
      }

      if (createdContactGroup) {
        try {
          await uploadCsvText(flashId, flashToken, createdContactGroup.groupId, csvText);
          finishCreate(createdContactGroup);
          setIsLoading(false);

          if (csvUploadStep && csvFileName) {
            dispatch(hideModal());
          }
        } catch (error) {
          console.log(error);
          window.newrelic.noticeError(error);
          alert('Error uploading CSV, please make sure the template is correct and try again.');
          setIsLoading(false);
        }
      }
    }
  };

  const handleStepBack = () => {
    if (csvUploadStep) {
      setCsvUploadStep(false);
    }

    if (csvText && csvFileName) {
      setCsvText(undefined);
      setCsvFileName(undefined);
    }

    setStep(1);
  };

  const handleManualContactEntry = async () => {
    setIsLoading(true);
    const createdContactGroup = await createContactGroup(flashId, flashToken, contactGroupName);
    setCreatedContactGroupId(createdContactGroup.groupId);
    finishCreate(createdContactGroup);
    setIsLoading(false);
    dispatch(hideModal());
  };

  const addressUploadProps: AddressImportProps = {
    setCsvText,
    csvUploadStep,
    setCsvUploadStep,
    csvFileName,
    setCsvFileName,
    setIsLoading,
    handleManualContactEntry
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (step === 1 && !csvUploadStep) {
        if (!contactGroupName) {
          setError('Please enter a name for the contact group.');
          return;
        }
        const duplicateName = contactGroups.some(
          contactGroup => contactGroup.groupName.toLowerCase() === contactGroupName.toLowerCase()
        );
        if (duplicateName) {
          setError('You already have another group with that name. Please choose another name.');
          return;
        }
        setStep(2);
      } else if (step === 2) {
        handleCreateContactGroup();
      }
    }
  };

  return (
    <ContactGroupWrapper onKeyDown={handleKeyPress}>
      <ModalTitle secondary alignment="left" onClick={() => dispatch(hideModal())}>
        Create New Group
      </ModalTitle>
      <Show when={step === 1 && !csvUploadStep}>
        <ModalSubTitle>Name your group to keep your contacts organized.</ModalSubTitle>
      </Show>
      <Show when={step === 2 && !csvUploadStep}>
        <ModalSubTitle>Use our Google sheets template, or add your name and addresses manually.</ModalSubTitle>
      </Show>
      <Show when={csvUploadStep}>
        <ModalSubTitle>Be sure to export your template file as a .csv</ModalSubTitle>
      </Show>
      <Show when={step === 1}>
        <ModalForm>
          <label>
            <ModalTextInput
              type="text"
              name="name"
              onChange={e => setContactGroupName(e.target.value)}
              value={contactGroupName}
            />
            {error && <ErrorLabel>{error}</ErrorLabel>}
          </label>
        </ModalForm>
        <ButtonContainer>
          <FAQContent>
            <a href="https://help.artifactuprising.com/hc/en-us/articles/360000040271" target="_blank" rel="noreferrer">
              Need help? Check out our FAQs
            </a>
          </FAQContent>
          <SubmitButton
            onClick={() => {
              if (!contactGroupName) {
                setError('Please enter a name for the contact group.');
              } else {
                setError('');
                setStep(2);
              }
            }}
            disabled={contactGroupName.length === 0 || isLoading}
          >
            {isLoading ? 'LOADING...' : 'NEXT'}
          </SubmitButton>
        </ButtonContainer>
      </Show>
      <Show when={step === 2}>
        <AddressImport {...addressUploadProps} />
        <StepTwoButtonContainer>
          <FAQContent>
            <a href="https://help.artifactuprising.com/hc/en-us/articles/360000040271" target="_blank" rel="noreferrer">
              Need help? Check out our FAQs
            </a>
          </FAQContent>
          <CancelButton onClick={handleStepBack} disabled={isLoading}>
            BACK
          </CancelButton>
          <SubmitButton
            onClick={() => {
              handleCreateContactGroup();
            }}
            disabled={contactGroupName.length === 0 || isLoading}
          >
            {isLoading ? 'LOADING...' : 'SAVE'}
          </SubmitButton>
        </StepTwoButtonContainer>
      </Show>
    </ContactGroupWrapper>
  );
};

export default ContactGroupModal;
