import { apiRequest, HTTP_DELETE, HTTP_GET, HTTP_POST, HTTP_PUT } from 'helpers/http';

import { CustomerPaymentMethod, PaymentMethodData } from 'mage-swagfaces/braintree/PaymentMethods';
import CustomerDataAddress from 'mage-swagfaces/customer/CustomerDataAddress';
import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import SalesDataOrder from 'mage-swagfaces/sales/SalesDataOrder';
import LoginRequest from 'types-magenum/auth/LoginRequest';
import RegisterRequest from 'types-magenum/auth/RegisterRequest';
import VerifyAddressPostBody from 'types-magenum/quote/VerifyAddressPostBody';

const magenumRequest = apiRequest(process.env.GATSBY_MAGENUM_BASE_URL);

export const saveNewCustomerAddress = (customerAddr: CustomerDataAddress) =>
  magenumRequest('customer', 'addresses')(HTTP_POST)()<CustomerDataCustomer>(customerAddr);

export const updateCustomerAddress = (customerAddr: CustomerDataAddress) =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()<CustomerDataAddress>(customerAddr);

export const deleteCustomerAddress = (customerAddr: CustomerDataAddress) =>
  magenumRequest('customer', 'addresses')(HTTP_DELETE)()<CustomerDataAddress>(customerAddr);

export const setDefaultBillingAddress = (id: number) =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()({
    id,
    default_billing: 1
  });

export const setDefaultShippingAddress = (id: number) =>
  magenumRequest('customer', 'addresses')(HTTP_PUT)()({
    id,
    default_shipping: 1
  });

export const validateShippingAddress = (shippingAddr: VerifyAddressPostBody) =>
  magenumRequest('cart', 'shipping-address', 'verify')(HTTP_POST)()<CustomerDataAddress>(shippingAddr);

export const getCustomer = () => magenumRequest('customer')(HTTP_GET)()<CustomerDataCustomer>();

export const createCustomer = (customer: RegisterRequest) =>
  magenumRequest('auth', 'register')(HTTP_POST)()<{
    customer: CustomerDataCustomer;
    sessionId: string;
  }>(customer);

export const customerLogin = (customer: LoginRequest) =>
  magenumRequest('auth', 'login')(HTTP_POST)()<{
    customer: CustomerDataCustomer;
    sessionId: string;
  }>(customer);

export const newsletterSignUp = (email: string) =>
  magenumRequest('track', 'newsletter')(HTTP_POST)()<{
    email: string;
  }>({ email, unsubscribed: false });

export const getPaymentMethods = () =>
  magenumRequest('customer', 'payment-methods')(HTTP_GET)()<CustomerPaymentMethod[]>();

export const savePaymentMethod = (paymentMethod: PaymentMethodData['payment_method']) =>
  magenumRequest('customer', 'payment-methods')(HTTP_POST)()<CustomerPaymentMethod>(paymentMethod);

export const deletePaymentMethod = (paymentMethodToken: string) =>
  magenumRequest('customer', 'payment-method', paymentMethodToken)(HTTP_DELETE)()<CustomerPaymentMethod>();

export const getOrderDetails = (orderId: string) =>
  magenumRequest('sales', 'order', orderId)(HTTP_GET)()<SalesDataOrder>();
