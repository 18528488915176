import { ProductType, RawProduct, UrlsType } from '../types/event';
import { StrikethroughValues } from 'types/StrikethroughValues';
import { getProductAttributes } from './attrs';
import { PDPProduct } from 'templates/PDP';

export const hydrateProduct = (
  rawProduct: RawProduct,
  product: ProductType,
  strikethroughValues: StrikethroughValues,
  pdpPageQuery?: PDPProduct, //Only exists when on PDP since this is the page query for the template
  urls?: UrlsType
): ProductType => {
  const additionalDataJson = rawProduct.additional_data ? JSON.parse(rawProduct.additional_data) : '';

  // Add new fields here on demand when needed using `rawProduct`
  const nextProduct: ProductType = {
    ...product,
    editorVersion: rawProduct.editor_version || '1',
    customOptions: rawProduct.custom_options,
    promotionalMessage: rawProduct.promotional_messaging,
    accordionTitleShippingTime: rawProduct.accordion_title_shipping_time,
    giftcardAmounts: rawProduct.giftcard_amounts,
    catalogPriceRules: rawProduct.catalog_price_rules,
    reportingProductCategory: rawProduct.reporting_product_category,
    reportingProductLine: rawProduct.reporting_product_line,
    productType: rawProduct.line,
    tierPrices: rawProduct.advanced_pricing
      ? rawProduct.advanced_pricing.find(p => p.id === product.id)?.tierPrices
      : [],
    hammerCategory: rawProduct.hammer_category,
    metaDescription: rawProduct.meta_description,
    hasDustJacket: rawProduct.has_dust_jacket === '1',
    customProductType: rawProduct.custom_product_type === false ? null : rawProduct.custom_product_type,
    strikethroughValues,
    stickyCTAMessaging: rawProduct.sticky_cta_messaging || '',
    pricingDetailsHtml: rawProduct.pricing_details || '',
    FAQHtml: rawProduct.faq || '',
    reportingProductType: rawProduct.reporting_product_type,
    isEditable: rawProduct.is_editable === '1',
    descriptionAuDifference: pdpPageQuery?.descriptionAuDifference,
    pricingDetailsHtml: additionalDataJson ? additionalDataJson.additional_page_price : '',
    pagePricingDetails: rawProduct.page_pricing_details,
    printPricing: additionalDataJson ? additionalDataJson.price_per_print : '',
    additionalFoilPrice: additionalDataJson ? additionalDataJson.additional_foil_cost : '',
    shippingAlert: rawProduct.shipping_alert,
    shippingInformation: rawProduct.shipping_information,
    sla: rawProduct.sla,
    outOfStockPermutations: rawProduct.outOfStockPermutations || null
  };

  return {
    ...nextProduct,
    attributes: getProductAttributes(nextProduct)
  };
};
