import styled from 'styled-components';
import { StyledH4, StyledP } from '../typography';
import { sizes } from 'components/Grid';

export const DetailsParagraph = styled(StyledP)`
  color: #6c6c6c;
  line-height: 0.6rem;
  font-size: 0.85rem;
`;

export const ProjectNameWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.6875rem;
  cursor: pointer;
  width: fit-content;
  padding-right: 0.5rem;

  @media (min-width: ${sizes.medium}px) {
    align-items: flex-start;
  }

  & > span {
    display: flex;
    align-items: center;
    height: min-content;

    & > svg {
      background: #629eba;
      margin-left: 8px;
    }
  }
`;

export const ProjectName = styled(DetailsParagraph)`
  display: inline-block;
`;

export const DetailsTitle = styled(StyledH4)`
  margin: 0 0 18px 0;
  cursor: pointer;
`;
