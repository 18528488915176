import styled, { css } from 'styled-components';

export const fieldStyling = css<{ border?: string }>`
  width: 100%;
  background: #fff;
  border: ${({ border }) => border || '1px solid #d1d1d1'};
  border-radius: 1px;
  font-family: "Crimson Text", "Georgia", serif;
  font-size: 16px;
  height: 2.2rem;
  line-height: 1.5;
  border-radius: 0;
  padding-left: 0.5rem;
  flex-grow: 1;
  min-width: 50px;
  outline: none;
  margin-bottom: 20px;
`;

export default styled.input<{ border?: string }>`
  ${fieldStyling}
`;
