import styled from 'styled-components';

export const AuthErrorMessage = styled.div`
  width: 100%;
  background-color: rgba(203, 16, 0, 0.05);

  p {
    color: #CB0000;
    font-size: 13px;
    padding: 4px 0;
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-flow: column;
`;

export const FullWidthWrapper = styled.div`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  button {
    display: block !important;
    margin-top: 4rem !important;
  }
`;

export const FooterButtons = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    border: none;
    background: none;
    cursor: pointer;
    text-decoration: underline;

    p {
      font-size: 13px !important;
    }
  }
`;

export const InlineSpan = styled.span`
  display: inline;
  text-decoration: underline;
  cursor: pointer;
`;
