import styled from 'styled-components';
import { StyledLabel } from 'component-library';

export const ShippingFormModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 38rem;

  ${StyledLabel} {
    cursor: pointer;
  }

  h2 {
    margin: 0.75rem auto;
  }
`;
