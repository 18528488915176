import React from 'react';
import { StyledButton } from './styled';

type ButtonProps = {
  id: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
  onClick?: (e?: React.BaseSyntheticEvent) => Promise<void> | void;
};

const Button = ({ id, disabled = false, className, children, onClick }: ButtonProps): JSX.Element => {
  const classNames = `${className} ${disabled ? 'disabled' : ''}`;

  return (
    <StyledButton id={id} className={classNames} onClick={onClick} disabled={disabled}>
      {children}
    </StyledButton>
  );
};

export default Button;
