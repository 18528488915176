import { optionGet } from 'faunctions';
import { useLayoutEffect } from 'react';
import { TrackingProp } from 'react-tracking';

import { DeviceSize, useWindowSize } from 'hooks/useWindowSize';

import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';

import { GleamEmailSubmittedEvent } from '../analyticsWrapper';
import { updateItlyContext } from '../context';
import { createGleamEmailSubmittedProperties } from '../tracking';
import itly from 'itly';
import { useSelector } from 'react-redux';
import { customerIdSelector } from 'store/customer/selectors';

export const useTrackGleamEmailSubmitted = async (tracking: TrackingProp<GleamEmailSubmittedEvent>) => {
  const { deviceSize } = useWindowSize();
  const customerId = useSelector(customerIdSelector);

  const waitForGleamForm = selector => {
    return new Promise(resolve => {
      const observer = new MutationObserver(mutations => {
        if (document.querySelector(selector)) {
          resolve(document.querySelector(selector));
          observer.disconnect();
        }
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      });
    });
  };

  useLayoutEffect(() => {
    const onDOMContentLoaded = async () => {
      const gleamForm = (await waitForGleamForm('.gcap-form')) as HTMLFormElement;

      gleamForm.addEventListener('submit', eventData => {
        const userEmail = optionGet('target[1].value')(eventData).getOrElse('');
        if (userEmail) {
          const { customer } = createGleamEmailSubmittedProperties(userEmail);
          tracking.trackEvent({
            type: 'gleamEmailSubmitted',
            data: {
              customer: customer as CustomerDataCustomer
            }
          });

          itly.emailSubscribed({
            customer_id: customerId.toString(),
            email: userEmail,
            subscription: 'subscribed',
            location: 'popup',
            last_updated: new Date().toISOString()
          });
        }
      });
    };

    onDOMContentLoaded();
  }, []);

  useLayoutEffect(() => {
    if (deviceSize === DeviceSize.SMALL) {
      updateItlyContext('is_mobile_view', true);
    } else {
      updateItlyContext('is_mobile_view', false);
    }
  }, [deviceSize]);
};
