import { useMemo } from 'react';
import { convertToReportingProduct } from 'analytics/itly/helpers/product';
import itly from 'itly';
import { useFormContext } from 'react-hook-form';
import { dependentInputsPredicate, ProductAttributeOption, scrubValues, watchFields, maybePrefix } from '../helpers';
import { useProduct } from './useProduct';

interface UseProductFormInputReturn {
  value: any;
  visibleOptions: ProductAttributeOption[];
  handleChange: (name: string, value: string) => void;
}
interface UseProductFormInputProps {
  name: string;
  displayName: string;
  options: Record<string, ProductAttributeOption>;
  prefix: string;
}

export const useProductFormInput = ({
  name,
  displayName,
  options,
  prefix
}: UseProductFormInputProps): UseProductFormInputReturn => {
  const { watch, setValue } = useFormContext();
  const { product } = useProduct();

  const handleChange = (name: string, value: string) => {
    setValue(name, value);
    itly.pdpOptionClicked({
      ...convertToReportingProduct(product),
      option_value: value,
      option_attribute: name,
      option_display_name: displayName
    });
  };

  const observedFields = useMemo(
    () =>
      options
        ? Object.values(options)
            .map(option => option.dependsOnOption)
            .filter(Boolean)
        : [],
    [options]
  );

  const observedFormValues = watchFields(watch, maybePrefix(prefix, observedFields));
  const scrubbedValues = scrubValues(prefix, observedFormValues);

  // VisibleOptions contains all the options that are not hidden and all dependencies are met
  const visibleOptions = useMemo(
    () =>
      options
        ? Object.values<ProductAttributeOption>(options)
            ?.filter(option => !option.hidden)
            ?.filter(dependentInputsPredicate(scrubbedValues, true)) || []
        : [],
    [observedFormValues, options]
  );

  const value = watch(name);

  return { value, visibleOptions, handleChange };
};
