import { useEffect, useState } from 'react';
import { useAfterpayScript } from '../../afterpay/hooks/useAfterpayScript';
import useAfterpay from '../../afterpay/hooks/useAfterpay';
import { useProduct } from '../hooks/useProduct';
import { PriceObject } from 'features/legacy-pdp/components/ProductPrice';

export interface PDPPriceProps {
  price: PriceObject;
  skipSubtotal?: boolean;
  placementSelector?: string;
}

export const PDPPrice = ({
  price,
  skipSubtotal = false,
  placementSelector = '.afterpay-price-selector'
}: PDPPriceProps): null => {
  const { isAfterpayReady, createPlacement } = useAfterpayScript();
  const { showAfterpayOnPDP } = useAfterpay();
  const { rawProduct } = useProduct();
  const [madePlacement, setMadePlacement] = useState<boolean>(false);
  const quantity = price?.quantity;

  const showSubtotal = !skipSubtotal && price.isCard && price.quantity > 1;
  const amountSelector = showSubtotal ? '#product-details-subtotal' : '.afterpay-price-selector';

  useEffect(() => {
    if ((price?.isCard && price?.quantity <= 1 && !skipSubtotal) || madePlacement) {
      return;
    }

    if (showAfterpayOnPDP && isAfterpayReady && rawProduct?.is_afterpay_product) {
      const attributes = { amountSelector: amountSelector, logoType: 'lockup', size: 'xs', showInterestFree: false };

      createPlacement(placementSelector, attributes);
      setMadePlacement(true);
    }
  }, [
    skipSubtotal,
    isAfterpayReady,
    showAfterpayOnPDP,
    quantity,
    price?.isCard,
    price?.quantity,
    madePlacement,
    rawProduct?.is_afterpay_product,
    amountSelector,
    placementSelector,
    createPlacement
  ]);

  return null;
};
