import { ApiRequestFailure, isApiRequestFailure } from 'helpers/http';
import { ERROR_MESSAGE_GENERIC } from 'components/ErrorBoundary';

export const ERROR_MESSAGE_LOCKED = 'Sorry, your account has been temporarily blocked because of too many incorrect password attempts. Please try again later.';
export const ERROR_MESSAGE_FIREWALL = 'Sorry, your account has been temporarily blocked for security reasons. Please contact customer support for assistance.';

export const translateErrorMessage = (requestFailure: ApiRequestFailure): string => {
    // If we have a failed to fetch error, we've been blocked by the firewall because of too many requests
    // Note that we are assuming any failed to fetch is a firewall error, because we don't actually get a status
    // code, just an exception.
    if (requestFailure.message && requestFailure.message.includes('Failed to fetch')) {
        return ERROR_MESSAGE_FIREWALL;
    }
    if (requestFailure.body?.message) {
        // If we have locked in the response, magento has locked the user's account
        if (requestFailure.body.message.includes('locked')){
            return ERROR_MESSAGE_LOCKED;
        }
        return requestFailure.body?.message;
    }
    return ERROR_MESSAGE_GENERIC;
};