import { CustomUICartState } from 'store/cart/constants';
import itly from 'itly';

export const trackPaymentMethodChange = (paymentMethod: string, cart: CustomUICartState): void => {
  const shippingOptions = getAnalyticsShippingOptions(cart);
  itly.paymentMethodChanged({
    payment_method: paymentMethod,
    shipping_options: shippingOptions,
    cart_id: cart.entity_id
  });
};

const getAnalyticsShippingOptions = (cart: CustomUICartState) => {
  return cart.shippingRates.map(rate => ({
    type: rate.method_title,
    cost: rate.amount,
    selected: cart.shippingMethod.includes(rate.method_code)
  }));
};