import { RefObject } from 'react';
import { matchAdt } from '../helpers/conditionals';

// Constants
export const PHOTO_TYPE_FLASH = 'PHOTO_TYPE_FLASH';
export const PHOTO_TYPE_LOCAL = 'PHOTO_TYPE_LOCAL';
export const PHOTO_TYPE_THIRD_PARTY = 'PHOTO_TYPE_THIRD_PARTY';
export const DIRECT_UPLOAD_TYPE = 'direct-upload';

export type VimeoProps = {
  videoId: string;
};

export interface PhotoSlideProps {
  index: number;
  media: SliderPhoto | Video;
  badges?: string[];
  onZoom?: (zoom: boolean) => void;
}

export type PhotoSliderProps = {
  photos: Array<MediaObject>;
  badges: Array<string>;
  livePreviewUrl: string;
  carouselRef?: RefObject<HTMLDivElement>;
};

export type MediaObject = {
  thumb: string;
  img: string;
  full: string;
  url: string;
  caption: string;
  position: string;
  isMain: boolean;
  type: string;
  videoUrl: string | null | undefined;
  videoId: string | null | undefined;
};

export interface PhotoSlideThumbProps {
  index: number;
  media: Video | SliderPhoto;
}

export interface Video {
  videoUrl: string;
  videoId: string;
}

export type Dimensions = {
  height: number;
  width: number;
};

export type Metadata = {
  resolution?: string;
  colorProfile?: string;
  colorSpace?: string;
  orientation?: string;
  createdAt?: string;
  location?: string;
  format?: string;
  size?: string;
  width?: string;
  height?: string;
  fileName?: string;
  rawMetadata?: any;
};

export interface SliderPhoto {
  url: string;
  caption: string;
}

type PhotoMatcher<T> = {
  LegacyPhoto: (_: { id: number; mediaId: string }) => T;

  FlashPhoto: (_: {
    id: string;
    mediaId: string;
    type: 'PHOTO_TYPE_FLASH';
    dimensions: Dimensions;
    metadata?: Metadata;
    originId?: string;
    service?: 'instagram' | 'dropbox' | 'facebook' | 'google';
  }) => T;

  LocalPhoto: (_: {
    id: string;
    type: 'PHOTO_TYPE_LOCAL';
    file: File;
    dimensions: Dimensions;
    failed: boolean;
    errorMessage?: string;
    failedAlbumId?: string;
    mediaId?: string;
  }) => T;

  ThirdPartyPhoto: (_: {
    id: string;
    type: 'PHOTO_TYPE_THIRD_PARTY';
    externalUrl: string;
    dimensions: Dimensions;
    originId?: string;
    service: 'instagram' | 'dropbox' | 'facebook' | 'google';
    failed: boolean;
    errorMessage?: string;
    failedAlbumId?: string;
  }) => T;
};

type Photo = <T>(_: PhotoMatcher<T>) => T; // eslint-disable-line no-undef

const extract: (_: any) => Object = matchAdt({
  LegacyPhoto: (p: any) => p,
  FlashPhoto: (p: any) => p,
  LocalPhoto: (p: any) => p,
  ThirdPartyPhoto: (p: any) => p
});

export default {
  extract
};
