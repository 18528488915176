import NewRelicBrowser from 'new-relic-browser';
import { isLocal, isServerSideRendering } from 'helpers/env';

export const noShippingRatesError = 'no shipping rates';
export const noShippingRatesErrorMessage =
  'We were unable to access Shipping Options for your cart. You may have items in your cart that can\'t be shipped to your selected address. Please contact customer service at help@artifactuprising.com';

declare global {
  interface Window {
    newrelic?: typeof NewRelicBrowser;
  }
}

const handler = {
  // This method is called whenever a property of the target object is accessed
  get: function(target, prop, receiver) {
    // Check if the property is a function
    if (typeof target[prop] === "function") {
      // Return a wrapper function that invokes the original function in a try catch block
      return function(...args) {
        try {
          // Call the original function with the given arguments and context
          return target[prop].apply(this, args);
        } catch (error) {
          // Handle the error here
          console.error(error);
          // You can also do other things, such as logging, reporting, or rethrowing the error
        }
      };
    } else {
      // If the property is not a function, return it as it is
      return target[prop];
    }
  }
};

if (!isServerSideRendering() && window.newrelic) {
  window.newrelic = new Proxy(window.newrelic, handler);
}

/**
 * `newrelic` provides a safe way of calling newrelic apis
 * @param method
 */
// @deprecated
export const newrelic = method => (...params) => {
  if (typeof window !== 'object') {
    return;
  }

  if (window.newrelic) {
    try {
    window.newrelic[method](...params);
    } catch (e) {
      console.log('new relic error', e);
    }
    return;
  }
};

export const canNR = (): boolean => !!(!isServerSideRendering() && window.newrelic);

export const nrError = (errorName: string, error: Error, data: any): void => {
  if (!canNR()) {
    return;
  }

  try {
    throw error;
  } catch (err) {
    window.newrelic.noticeError(error, {
      message: error.message,
      errorName,
      ...data
    });
  }
};

export const newRelicPageAction = (name: string, attributes: { [key: string]: string | number }): void => {
  if (!canNR) {
    return;
  }

  if (isLocal()) {
    console.log('newrelicpageaction', name, attributes);
  } else {
    window.newrelic.addPageAction(name, attributes);
  }
};
