import styled, { keyframes } from 'styled-components';
import { disabledInputCss, SoldOutLabel, SoldOutLabelContainer } from '../shared.styled';

const selectAnimation = keyframes`
  0%   {box-shadow: none}
  100% {box-shadow: 0px 2px 3px black}
`;

export const StyledContainer = styled.div`
  margin-right: 14px;
  padding: 5px 0 7px 0;
  border-bottom: 2px solid transparent;
  position: relative;

  &:not(.disabled):focus-within {
    animation: 0.04s ${selectAnimation};
    animation-direction: alternate;
    animation-iteration-count: 2;
  }

  ${disabledInputCss};

  ${SoldOutLabelContainer} {
    bottom: -4px;
  }

  &.disabled {
    opacity: 0.75;
    padding-bottom: 0;
  }
`;

export const StyledImage = styled.img<{ backgroundUrl?: string }>`
  background-image: url(${props => props.backgroundUrl});
  width: 34px;
  height: 37px;
`;

export const StyledLabel = styled.label`
  cursor: pointer;
  user-select: none;
`;

export const DisabledIconContainer = styled.div`
  position: absolute;
  left: 12px;
  top: 12px;
`;
