import { createContext } from 'react';
import { MediaType, Configuration, UrlsType } from '../types/event';

export interface MediaConsumerProps {
  configuration?: Configuration
  media?: MediaType
  urls?: UrlsType
}

const MediaContext = createContext<MediaConsumerProps>({});

const { Consumer: MediaConsumer } = MediaContext;

export { MediaConsumer, MediaContext };
