import { ensureRunsOnce } from 'helpers/singletons/ensureRunsOnce';
import { useState, useLayoutEffect } from 'react';
import { isNotServerSideRendering } from 'helpers/env';

export interface AfterpayAttributes {
  amountSelector: string;
  size?: string;
  logoType?: string;
  styles?: {
    fontFamily?: string;
    textColor?: string;
  };
}

export const useAfterpayScript = () => {
  const [isAfterpayReady, setIsAfterpayReady] = useState(false);

  const createPlacement = (targetSelector: string, attributes: AfterpayAttributes) => {
    if (!isAfterpayReady || !window.Afterpay) {
      return;
    }

    window.Afterpay.createPlacements({
      targetSelector: targetSelector,
      attributes: attributes
    });
  };

  useLayoutEffect(() => {
    ensureRunsOnce(async () => {
      const messageScript = document.createElement('script');
      messageScript.type = 'text/javascript';
      messageScript.async = true;
      messageScript.src = '//js.afterpay.com/afterpay-1.x.js';

      if (isNotServerSideRendering() && window) {
        if (window.Afterpay) {
          setIsAfterpayReady(true);
          return;
        }

        window.addEventListener('Afterpay.ready', () => {
          setIsAfterpayReady(true);
        });
      }

      const loadScript = new Promise<void>((resolve, reject) => {
        messageScript.onload = () => resolve();
        messageScript.onerror = reject;
      });

      setTimeout(() => {
        // Finally, add <script> to the document.
        const sibling = document.getElementsByTagName('script')[0];
        sibling.parentNode.insertBefore(messageScript, sibling);
      }, 0);

      return loadScript;
    })();
  }, []);

  return {
    isAfterpayReady,
    createPlacement
  };
};
