import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export interface MetaObject {
  name: string;
  content: string;
}

interface SEOProps {
  title: string;
  meta?: MetaObject[];
  keywords?: string[];
  description?: string;
  lang?: string;
  ogType?: string;
  currentPage?: number;
}

export const SEO = (props: SEOProps) => {
  const { description, meta, keywords, title, currentPage } = props;

  const lang = props.lang || 'en';
  const ogType = props.ogType || 'website';

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const metaKeywords = keywords ? [ { name: 'keywords', content: keywords.join(', ') } ] : [];

  let seoTitle = title;
  if (currentPage && currentPage !== 1) {
    seoTitle = `Page ${currentPage} - ${seoTitle}`;
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={seoTitle}
      titleTemplate={'%s'}
      link={[
        {
          rel: 'shortcut icon',
          type: 'image/png',
          href: 'https://assets.artifactuprising.com/assets/images/AU_FaviconNEW.png'
        }
      ]}
      meta={[
        { name: 'description', content: metaDescription },
        { property: 'og:title', content: title },
        { property: 'og:description', content: metaDescription },
        { property: 'og:type', content: ogType },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:creator', content: site.siteMetadata.author },
        { name: 'twitter:title', content: title },
        { name: 'twitter:description', content: metaDescription },
        { name: 'viewport', content: 'width=device-width, initial-scale = 1.0, maximum-scale=1.0, user-scalable=no' },
        ...metaKeywords,
        ...(meta || [])
      ]}
    />
  );
};
