interface AnObject {
  [key: string]: any;
}

export const assign =
  <T>(obj: AnObject) => (keyVal: AnObject) => ({ ...obj, ...keyVal } as T);

export const objIsEmpty = (obj: AnObject) => !Object.keys(obj).length;

export const has =
  (prop: string) => (obj: AnObject): boolean => obj !== undefined &&
    obj !== null &&
    typeof obj === 'object' &&
    Object.prototype.hasOwnProperty.call(obj, prop);
