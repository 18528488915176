import styled from 'styled-components';
import React from 'react';
import { SecondaryButton as Button } from '../../Button';
import Icon from '../../icons';

export const GenericUploadWrapper = styled.div`
  width: 100%;
  margin: 1.75rem 0px 0px 0px;
  border: 2px dashed #D1D1D1;
  background-color: rgba(242,242,242,0.5);

  @media (max-width: 780px) {
    border: 0px;
    background-color: transparent;
  }
`;

export const GenericUploadText = styled.div`
  color: rgba(0,0,0,0.5);
  font-size: 1rem;
  line-height: 21px;
  text-align: center;
  margin-bottom: 3rem;

  @media (max-width: 780px) {
    display: none;
  }
`;

export const AddImage = styled.img`
  cursor: pointer;
  padding: 1rem;
  @media screen and (max-width: 780px) {
    padding-top: 10vh;  
  }
`;

export const DragText = styled.div`
  margin-bottom: 0.2rem;

  @media (max-width: 780px) {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

export const AddPhotosContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  button{
    margin: 1rem;
  }
`;
export const DragButton = styled(Button)`
  width: 172px;
  margin: 4rem auto 5px auto;
  width: 172px;
  height: 40px;
  cursor: pointer;
  background-color: inherit !important;
  padding: 3px 5px 0px 5px;
  font-size: 11px;
  line-height: 2.4rem;
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
`;

export const IconRow = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin: 0 auto;
`;

export const IconColumn = styled.div`
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  margin: 0 auto;
`;

export const StyledIcon = styled(({ className, ...props }) => (
  <Icon iconClassName={className} {...props} />
))`
  fill: #000;
  width: 10rem;
  height: 10rem;
`;
