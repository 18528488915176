import { isAttributeHidden } from './form';
import { GiftcardAmount, ProductType } from '../types/event';
import { formatPrice } from 'features/legacy-pdp/helpers/currency';
import { isType, RuleSet, isString } from 'au-js-sdk/lib/types/typeguard';
import { isNotServerSideRendering } from 'helpers/env';
import { Product } from '../../../types/Product';

export const GIFT_CARD_PRODUCT_TYPE = 'gift card';
export const EMAIL_GIFT_CARD_PRODUCT_LINE = 'email giftcard';

export enum FieldRenderType {
  SwatchText = 'swatch_text',
  TextSwatch = 'text_swatch',
  ImageSwatch = 'image_swatch',
  FoilSwatch = 'foil_swatch',
  BooksizeSwatch = 'booksize_swatch',
  OptionsSelect = 'drop_down',
  TextInput = 'text_input',
  HiddenInput = 'hidden',
  Date = 'date'
}

export interface ProductAttributeOption {
  id: string;
  name: string;
  title: string;
  label: string;
  price?: number;
  displayName?: string;
  displayLabel?: string;
  hoverDescription?: string;
  hoverLabel?: string;
  hoverImage?: string;
  hoverPrice?: string;
  mediaUrl?: string;
  disabled?: boolean;
  hidden?: boolean;
  dependsOnOption?: string;
  dependsOnValues?: string;
}

const productAttributeOptionRuleset: RuleSet = {
  id: isString,
  name: isString,
  title: isString,
  label: isString
};

export const isProductAttributeOption = (data: unknown): data is ProductAttributeOption =>
  isType(data, productAttributeOptionRuleset);

const QUANTITY_URL_PARAM = 'qty';
export const QUANTITY_OPTION_ID = 'card_quantity';
const QUANTITY_OPTION_LABEL = 'card quantity';

export interface ProductAttribute {
  id: string;
  name: string;
  label: string;
  price?: number;
  displayName: string;
  type: FieldRenderType;
  hidden?: boolean;
  required: boolean;
  dependsOnOption?: string;
  dependsOnValues?: string;
  options?: Record<string, ProductAttributeOption>;
}

export const getSwatchAsset = (imageUrl = '') => {
  if (!imageUrl) {
    return '';
  }
  const split = imageUrl.split('/');
  const last = split[split.length - 1];
  return `/${last.charAt(0)}/${last.charAt(1)}/${last.slice(0, -4)}_2x${last.slice(-4)}`;
};

export const getUrlForOption = (option: ProductAttributeOption, type: string, mediaUrl: string): string => {
  if (type === FieldRenderType.ImageSwatch) {
    return `${mediaUrl}attribute/swatch/swatch_image/56x56${option.displayLabel}?auto=webp`;
  }
  if (type === FieldRenderType.FoilSwatch) {
    const shouldRenderOutlinedWhiteFoil = option.id === 'white';
    return `${mediaUrl}attribute/swatch/swatch_thumb/110x90/l/a/pdp-redesign-0827-${option.title}-foil${
      shouldRenderOutlinedWhiteFoil ? '-outlined' : ''
    }.png?auto=webp`;
  }
  return `${mediaUrl}attribute/swatch/${type}/56x56${option.displayLabel}?auto=webp`;
};

export const compareOptions = (a, b) => Number(a.sort_order) - Number(b.sort_order);

export const getGiftcardAttributes = (product): ProductAttribute[] => {
  let defaultGiftcardAttributes = getDefaultGiftcardAttributes(product);

  if (product.giftcardAmounts && product.giftcardAmounts.length === 1 && product.giftcardAmounts[0].value == 0) {
    defaultGiftcardAttributes = defaultGiftcardAttributes.filter(attr => attr.id !== 'giftcard_amount');
  }

  return [
    ...defaultGiftcardAttributes,
    ...(product.reportingProductLine === EMAIL_GIFT_CARD_PRODUCT_LINE ? getEmailGiftCardAttributes() : [])
  ];
};

const getDefaultGiftcardAttributes = product => [
  {
    id: 'giftcard_amount',
    name: 'giftcard_amount',
    displayName: 'Choose Amount',
    label: 'Choose Amount',
    type: FieldRenderType.OptionsSelect,
    required: true,
    options: product.giftcardAmounts
      ?.sort((a, b) => a.value - b.value)
      .map((amount: GiftcardAmount) => {
        const formattedValue = formatPrice(2)(amount.value);
        return {
          value: amount.value,
          id: amount.value,
          label: formattedValue,
          displayName: formattedValue,
          displayLabel: formattedValue,
          name: formattedValue,
          title: formattedValue
        };
      }),
    value: ''
  },
  {
    id: 'giftcard_sender_name',
    name: 'giftcard_sender_name',
    displayName: 'Sender Name',
    label: 'Sender Name',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_recipient_name',
    name: 'giftcard_recipient_name',
    displayName: 'Recipient Name',
    label: 'Recipient Name',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_message',
    name: 'giftcard_message',
    displayName: 'Personal Note',
    label: 'Personal Note',
    type: FieldRenderType.TextInput,
    required: false,
    value: ''
  }
];

const getEmailGiftCardAttributes = () => [
  {
    id: 'giftcard_recipient_email',
    name: 'giftcard_recipient_email',
    displayName: 'Recipient Email',
    label: 'Recipient Email',
    type: FieldRenderType.TextInput,
    required: true,
    value: ''
  },
  {
    id: 'giftcard_delivery_date',
    name: 'giftcard_delivery_date',
    displayName: 'Delivery Date',
    label: 'Delivery Date',
    type: FieldRenderType.Date,
    required: true,
    value: ''
  }
];

export const getProductAttributes = (product: ProductType): ProductAttribute[] => {
  const attributesFromCustomOptions =
    product.customOptions?.sort(compareOptions).map(customOption => {
      const nextCustomOption: ProductAttribute = {
        id: customOption.default_title || customOption.title,
        name: customOption.default_title || customOption.title,
        displayName: customOption.display_name || customOption.default_title || customOption.title,
        label: customOption.default_title || customOption.title,
        type: customOption.react_pdp_input_type as FieldRenderType,
        required: customOption.is_require == '1',
        dependsOnOption: customOption.depends_on_option ? customOption.depends_on_option : undefined,
        dependsOnValues: customOption.depends_on_values ? customOption.depends_on_values : undefined,
        price: customOption.price ? parseFloat(customOption.price) : 0,
        options: {}
      };

      return {
        ...nextCustomOption,
        hidden: isAttributeHidden(nextCustomOption),
        options: customOption.values.sort(compareOptions).reduce((options, option) => {
          const nextOption = {
            id: option.default_title || option.title,
            displayName: option.display_name || option.default_title || option.title,
            displayLabel: getSwatchAsset(option.hover_image),
            name: option.default_title || option.title,
            title: option.default_title || option.title,
            label: option.display_name || option.default_title || option.title,
            hoverDescription: option.hover_description,
            hoverLabel: option.hover_label,
            hoverImage: option.hover_image,
            hoverPrice: option.hover_price,
            disabled: option.in_stock !== '1',
            hidden: option.visible_on_pdp !== '1',
            price: option.price ? parseFloat(option.price) : 0,
            dependsOnOption: option.depends_on_option ? option.depends_on_option : undefined,
            dependsOnValues: option.depends_on_values ? option.depends_on_values : undefined
          };

          const correctedDisplayName = nextOption.displayName.replace(/&quot;/g, '"');
          return {
            ...options,
            [nextOption.id]: {
              ...nextOption,
              displayName: correctedDisplayName,
              label: nextOption.label.replace(/&quot;/g, '"'),
              mediaUrl: getUrlForOption(nextOption, customOption.react_pdp_input_type, product.mediaUrl)
            }
          };
        }, {})
      };
    }) || [];

  if (!product.tierPrices?.length) {
    return attributesFromCustomOptions;
  }

  // We need to add `card_quantity` only when there are tier prices on the product
  const cartQuantity: ProductAttribute = {
    id: QUANTITY_OPTION_ID,
    name: QUANTITY_OPTION_ID,
    displayName: QUANTITY_OPTION_LABEL,
    label: QUANTITY_OPTION_LABEL,
    required: true,
    type: FieldRenderType.OptionsSelect,
    options: product.tierPrices.reduce((options, tierPrice) => {
      const quantity = tierPrice.quantity.toString();

      const option: ProductAttributeOption = {
        label: quantity,
        id: quantity,
        displayName: quantity,
        displayLabel: quantity,
        name: quantity,
        title: quantity
      };

      return {
        ...options,
        [option.id]: option
      };
    }, {})
  };

  return [cartQuantity, ...attributesFromCustomOptions];
};

// Key/value pair
export type UrlAttribute = [string, string];

export const mapUrlParamsToAttributes = (product: ProductType): UrlAttribute[] => {
  const uri = isNotServerSideRendering() ? window.location.href : '';
  const productUrlKey = (product.url.split('/').pop() || '').trim();
  const [, tail] = decodeURIComponent(uri)
    .replace(/\+/g, ' ')
    .split(`${productUrlKey}/`);

  let urlAttrs = [];
  if (tail) {
    //Removes query params to isolate attributes
    urlAttrs = tail.split('?');
  }

  const urlParams = urlAttrs[0] ? urlAttrs[0].split('/') : [];
  return mapParamsToAttributes(product, urlParams);
};

export const mapParamsToAttributes = (product: ProductType, params: string[]) => {
  const attributesArr = [...product?.attributes];

  return params.reduce((acc, value) => {
    let firstMatchingAttr = null;

    for (let i = 0; i < attributesArr.length; i++) {
      const attr = attributesArr[i];
      const newValue = !isQuantity(attr, value)
        ? Object.keys(attr.options).find(optionValue => optionValue === value)
        : mapQuantityParamToOptionLabel(value, Object.values(attr.options));

      if (newValue) {
        firstMatchingAttr = attr;
        attributesArr.splice(i, 1);
        break;
      }
    }

    return firstMatchingAttr ? [...acc, [firstMatchingAttr.name, value]] : acc;
  }, []);
};

export const getObjectFromUrlAttributes = (attributes: UrlAttribute[]): Record<string, string> => {
  const result = {};
  attributes.forEach(([key, value]) => result[key] = value);
  return result;
};

export const getProductDefaultsFromUrl = (product: ProductType): Record<string, string> => {
  const urlAttributes = mapUrlParamsToAttributes(product);
  return getObjectFromUrlAttributes(urlAttributes);
};

export const getProductUrlForOptions = (product: ProductType, options: string[]): string => {
  return `${product.url}/${options.sort().join('/')}`;
};

export const getProductNameForOptions = (product: Product, options: string[]): string => {
  return [product.name, ...options.sort()].join(' ');
};

export const getProductOptionsFromParams = (product: ProductType, params: string[]): Record<string, string> => {
  const attributes = mapParamsToAttributes(product, params);
  return getObjectFromUrlAttributes(attributes);
};

const isQuantity = (attr: ProductAttribute, value: string) => {
  return attr && attr.id === QUANTITY_OPTION_ID && value.toLowerCase().includes(QUANTITY_URL_PARAM);
};

const getQuantityUrlParam = (urlParam: string): string => {
  const paramArr = urlParam.split(QUANTITY_URL_PARAM);

  if (paramArr && paramArr.length === 2 && isNumbersOnlyString(paramArr[1])) {
    return paramArr[1];
  } else {
    return '';
  }
};

const mapQuantityParamToOptionLabel = (urlParam: string, optionValues: ProductAttributeOption[]): string => {
  const quantityStr = getQuantityUrlParam(urlParam);
  const mappedOption = optionValues.find(option => option.label.substr(0, quantityStr.length) === quantityStr);
  return mappedOption && quantityStr;
};

const NUMBERS_ONLY_REGEX = /^[0-9]+$/;

const isNumbersOnlyString = (str: string): boolean => {
  return str.match(NUMBERS_ONLY_REGEX) !== null;
};
