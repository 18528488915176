import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import rootSaga from './sagas';
import reducers from './reducers';
import createSagaMiddleware from '@redux-saga/core';
import { itlyBeacon } from 'analytics/itly/analyticsWrapper';
import { digtialDataMiddleware } from 'analytics/middleware/DigitalDataManager';
import { errorToastMiddleware } from './middleware/errorToastMiddleware';
import { renderErrorToast } from './cart/helpers';
import { ReactNode } from 'react';
import { RenderResult } from '@testing-library/react';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: any
    devToolsExtension: any
  }

  function renderWithRedux(component: ReactNode, initialState?: any): RenderResult
}

const windowGlobal = typeof window !== 'undefined' && window;

// Enable redux devtools in all environments except production
const devtools =
  process.env.GATSBY_SITE_ENV !== 'prod' && windowGlobal.devToolsExtension
    ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    : f => f;
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = (windowGlobal && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middlewareEnhancer = composeEnhancers(
  applyMiddleware(
    sagaMiddleware,
    itlyBeacon,
    digtialDataMiddleware,
    errorToastMiddleware(renderErrorToast)
  ),
  devtools
);
const rootReducer = combineReducers(reducers);

export default (initialState = null) => {
  const params = [rootReducer, ...(initialState ? initialState : []), middlewareEnhancer];
  const store = createStore(...params);
  sagaMiddleware.run(rootSaga);
  return store;
};
