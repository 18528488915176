import { getPublicConfigVars } from 'helpers/config';
import { getMagentoBaseUrl } from './../../helpers/env';

export const SPLIT_IO_FEATURE_FLAG_CARD_ADDRESSING = 'FeatureFlag-CardAddressing';
export const SPLIT_IO_FEATURE_FLAG_CARD_ADDRESSING_COOKIE = 'au_split_feature_flag_card_addressing';

const { GATSBY_MAGENUM_BASE_URL } = getPublicConfigVars();

export interface AccountSectionNavBody {
  name: string;
  urlPath: string;
  isGatsbyPage: boolean;
}

export interface AccountSection {
  name: string;
  url: string;
}

export const buildAccountSection = (name: string, url: string): AccountSection => ({
  name,
  url
});

export const buildAccountSectionNavBody = (
  name: string,
  urlPath: string,
  isGatsbyPage = true
): AccountSectionNavBody => ({
  name,
  urlPath,
  isGatsbyPage
});

export const MY_ACCOUNT: AccountSection = buildAccountSection('My Account', '/customer/account');
export const MY_PROJECTS: AccountSection = buildAccountSection('My Projects', '/account/projects/');
export const GALLERIES: AccountSection = buildAccountSection('Galleries', '/account/galleries/');
export const STORED_PAYMENT_METHODS: AccountSection = buildAccountSection(
  'Stored Payment Methods',
  '/account/stored-payment-methods/'
);
export const ORDER_HISTORY: AccountSection = buildAccountSection('Orders', '/account/order-history/');
export const ORDER_DETAILS: AccountSection = buildAccountSection(
  'Order Details',
  `${getMagentoBaseUrl()}/sales/order/view/order_id`
);
export const ADDRESS_BOOK: AccountSection = buildAccountSection('Address Book', '/account/address-book');
export const ADDRESS_LISTS: AccountSection = buildAccountSection('Address Lists', '/account/address-lists/');
export const ACCOUNT_BALANCE: AccountSection = buildAccountSection(
  'Account Balance',
  `${getMagentoBaseUrl()}/storecredit/info`
);
export const EDIT_ACCOUNT: AccountSection = buildAccountSection(
  'My Details',
  `${getMagentoBaseUrl()}/customer/account/edit`
);
export const EDIT_ADDRESS: AccountSection = buildAccountSection(
  'Edit Address',
  `${getMagentoBaseUrl()}/customer/address/edit/id`
);
export const EMAIL_PREFERENCES: AccountSection = buildAccountSection(
  'Email Preferences',
  `${process.env.GATSBY_WUFOO_URL}`
);
export const CHANGE_PASSWORD: AccountSection = buildAccountSection(
  'Change Password',
  `${getMagentoBaseUrl()}/customer/account/edit/changepass/1`
);
export const CREATE_ORDER_TICKET: AccountSection = buildAccountSection(
  'Create Order',
  `${getMagentoBaseUrl()}/zendesk/ticket/create`
);
export const REORDER: AccountSection = buildAccountSection(
  'Reorder',
  `${getMagentoBaseUrl()}/sales/order/reorder/order_id`
);
export const REFER_A_FRIEND: AccountSection = buildAccountSection('Refer a Friend', '/share');
export const SHARE_YOUR_STORY: AccountSection = buildAccountSection('Share Your Story', '/tellon');

// Magenum endpoint for fetching the JSON representation of the top menu navigation links
export const MAGENTO_CMS_TOP_MENU_URL = `${GATSBY_MAGENUM_BASE_URL}/cms/top-menu`;

export const AccountSectionPages = {
  '/account/': buildAccountSectionNavBody(MY_ACCOUNT.name, MY_ACCOUNT.url),
  '/account/projects/': buildAccountSectionNavBody(MY_PROJECTS.name, MY_PROJECTS.url),
  '/account/galleries/': buildAccountSectionNavBody(GALLERIES.name, GALLERIES.url),
  '/account/stored-payment-methods/': buildAccountSectionNavBody(
    STORED_PAYMENT_METHODS.name,
    STORED_PAYMENT_METHODS.url
  ),
  '/account/order-history/': buildAccountSectionNavBody(ORDER_HISTORY.name, ORDER_HISTORY.url),
  '/account/address-book/': buildAccountSectionNavBody(ADDRESS_BOOK.name, ADDRESS_BOOK.url),
  '/account/address-lists/': buildAccountSectionNavBody(ADDRESS_LISTS.name, ADDRESS_LISTS.url),
  '/account/my-details/': buildAccountSectionNavBody(EDIT_ACCOUNT.name, EDIT_ACCOUNT.url, false),
  '/account/account-balance/': buildAccountSectionNavBody(ACCOUNT_BALANCE.name, ACCOUNT_BALANCE.url, false),
  '/account/email-preferences/': buildAccountSectionNavBody(EMAIL_PREFERENCES.name, EMAIL_PREFERENCES.url, false),
  '/share/': buildAccountSectionNavBody(REFER_A_FRIEND.name, REFER_A_FRIEND.url),
  '/tellon/': buildAccountSectionNavBody(SHARE_YOUR_STORY.name, SHARE_YOUR_STORY.url, false)
};
