import React from 'react';
import Helmet from 'react-helmet';

export interface ProductMetaTags {
  metaTitle?: string;
  name: string;
  metaDescription?: string;
  description: string;
  image?: string;
  urlKey?: string;
}

export interface PDPMetaTagsProps {
  product: ProductMetaTags;
  siteUrl: string;
}

export const PDPMetatags = ({ product, siteUrl }: PDPMetaTagsProps): JSX.Element => (
  <Helmet
    meta={[
      { name: 'title', content: product.metaTitle || product.name },
      { name: 'description', content: product.metaDescription || '' },
      { name: 'robots', content: 'index,follow' },
      { name: 'format-detection', content: 'telephone=no' },
      { name: 'og:type', content: 'og:product' },
      { name: 'og:image', content: product.image },
      { name: 'og:url', content: `${siteUrl}/${product.urlKey || ''}` },
      { name: 'product:price:currency', content: 'USD' }
    ]}
  />
);
