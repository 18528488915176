import React from 'react';
import { SoldOutLabel, StyledRadioInput } from '../styled';
import { SwatchProps } from 'au-gatsby-ds/components/SwatchSelect';
import { StyledSwatchContainer, StyledSwatch, DisabledIconContainer, SwatchImg } from './styled';
import SvgContainer from 'au-gatsby-ds/components/Svg';

const isWhiteImageSwatch = (args: (string | undefined)[]) => {
  return args && !!args.find(arg => arg?.toLowerCase().includes('white'));
};

const CircularImageSwatch = ({
  id,
  name,
  title = '',
  label,
  displayName,
  mediaUrl,
  checked,
  disabled,
  onClick,
  onChange,
  onMouseOver,
  onMouseOut,
  ref
}: SwatchProps): JSX.Element => {
  const isWhite = isWhiteImageSwatch([title, label, displayName]);

  return (
    <StyledSwatchContainer>
      <StyledSwatch
        id={`option-value-label-${title}`}
        className={`${checked ? 'checked' : ''} ${disabled ? 'disabled' : ''}`}
        htmlFor={`option-value-${id}`}
        onClick={disabled ? undefined : onClick}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        aria-checked={checked}
        aria-label={displayName}
      >
        {disabled && (
          <DisabledIconContainer>
            <SvgContainer icon="close" />
          </DisabledIconContainer>
        )}
        <StyledRadioInput
          id={`option-value-${id}`}
          type="radio"
          name={name}
          value={id}
          checked={checked}
          disabled={disabled}
          onChange={disabled ? undefined : onChange}
          onFocus={disabled ? undefined : onClick}
          ref={ref}
        />
        <SwatchImg alt={label} src={mediaUrl} isWhite={isWhite} />
      </StyledSwatch>
      {disabled && <SoldOutLabel>Sold Out</SoldOutLabel>}
    </StyledSwatchContainer>
  );
};

export default CircularImageSwatch;
