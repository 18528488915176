import Itly from 'itly';
import { useEffect } from 'react';

export function useTrackSearchStarted() {
  useEffect(() => trackSearchStarted(), []);
}

export function trackSearchStarted() {
  Itly.searchStarted();
}
