import React, { useRef, useMemo, useState } from 'react';
import QuickPinchZoom, { make3dTransformValue } from 'react-quick-pinch-zoom';
import { FieldRenderType, ProductAttribute } from 'features/pdp/helpers/attrs';
import { useProductLivePreview } from 'features/pdp/hooks/useProductLivePreview';
import { useProductContext } from 'features/pdp/hooks/useProductContext';
import { useProductForm } from 'features/pdp/hooks/useProductForm';
import { useMedia } from 'features/pdp/hooks/useMedia';
import { LivePreviewModalContainer, ModalInputsContainer, ModalInputSelector, ModalInputsHeader } from './styled';
import { ModalOptionsContainer } from '../ModalOptionsContainer';
import { makeSingular } from 'helpers/strings';

const getLivePreviewInputs = (inputs: ProductAttribute[], productType: string): ProductAttribute[] => {
  const emptyInput = { id: '', name: null, label: null, required: null, displayName: null, type: null };

  const livePreviewInputs = inputs.filter(({ id, type }) => {
    if (type !== FieldRenderType.HiddenInput) {
      switch (productType) {
        case 'books':
          return id === 'fabric_color' || id === 'foil_color' || id === 'cover_design' || id === 'theme';
        case 'prints':
          return id === 'print_size' || id === 'print_border';
        case 'frames':
          return id === 'frame_finish' || id === 'frame_size';
        case 'calendars':
          return id.endsWith('calendar_design');
        case 'cards':
          return id === 'foil_color' || id === 'greeting';
        default:
          return false;
      }
    }
    return false;
  });

  return livePreviewInputs.length ? livePreviewInputs : [emptyInput];
};

export const LivePreviewModal = (): JSX.Element => {
  const livePreviewUrl = useProductLivePreview();
  const imgRef = useRef<HTMLImageElement>();
  const { photos } = useMedia();

  const onUpdate = ({ x, y, scale }) => {
    const { current: img } = imgRef;

    if (img) {
      const value = make3dTransformValue({ x, y, scale });
      img.style.setProperty('transform', value);
    }
  };

  const { inputs } = useProductForm();
  const { product } = useProductContext();
  const { reportingProductCategory: category } = product;

  const livePreviewInputs = useMemo(() => getLivePreviewInputs(inputs, category), [inputs, category]);

  const [{ id, displayName, type, options, label }, setCurrentInput] = useState<ProductAttribute>(livePreviewInputs[0]);

  const onInputChange = (_, value) => setCurrentInput(livePreviewInputs.find(({ id }) => id === value));

  return (
    <LivePreviewModalContainer>
      <QuickPinchZoom onUpdate={onUpdate} tapZoomFactor={4} maxZoom={4} doubleTapZoomOutOnMaxScale>
        <img ref={imgRef} src={livePreviewUrl || (photos && photos[0].url)} alt="zoomed-live-preview" />
      </QuickPinchZoom>
      <ModalInputsContainer>
        <ModalInputsHeader>{`A closer look at your ${makeSingular(category)}`}</ModalInputsHeader>
        {id && (
          <>
            <ModalOptionsContainer
              id={id}
              name={id}
              displayName={displayName}
              label={label}
              type={type}
              options={options}
            />
            <ModalInputSelector
              id="live-preview-modal-inputs"
              name="live-preview-modal-inputs"
              type={FieldRenderType.TextSwatch}
              required={false}
              options={livePreviewInputs.map(i => ({ ...i, label: i.displayName.toLowerCase(), title: i.id }))}
              selected={{ id, label, title: id, name: id }}
              onChange={onInputChange}
              label="live-preview-modal-inputs"
              displayName={displayName}
              hideLabels={true}
            />
          </>
        )}
      </ModalInputsContainer>
    </LivePreviewModalContainer>
  );
};
