import styled from 'styled-components';

export const PhotoElementsContainer = styled.div`
  * > {
    padding: 10px;
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 2rem;
  @media (max-width: 780px) {
    margin: 0;
  }
`;
export const PhotosContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: auto;
  max-height: fit-content;
  @media (max-width: 780px) {
    justify-content: center;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
