exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-account-balance-tsx": () => import("./../../../src/pages/account/account-balance.tsx" /* webpackChunkName: "component---src-pages-account-account-balance-tsx" */),
  "component---src-pages-account-address-book-tsx": () => import("./../../../src/pages/account/address-book.tsx" /* webpackChunkName: "component---src-pages-account-address-book-tsx" */),
  "component---src-pages-account-address-lists-tsx": () => import("./../../../src/pages/account/address-lists.tsx" /* webpackChunkName: "component---src-pages-account-address-lists-tsx" */),
  "component---src-pages-account-create-tsx": () => import("./../../../src/pages/account/create.tsx" /* webpackChunkName: "component---src-pages-account-create-tsx" */),
  "component---src-pages-account-email-preferences-tsx": () => import("./../../../src/pages/account/email-preferences.tsx" /* webpackChunkName: "component---src-pages-account-email-preferences-tsx" */),
  "component---src-pages-account-galleries-tsx": () => import("./../../../src/pages/account/galleries.tsx" /* webpackChunkName: "component---src-pages-account-galleries-tsx" */),
  "component---src-pages-account-my-details-tsx": () => import("./../../../src/pages/account/my-details.tsx" /* webpackChunkName: "component---src-pages-account-my-details-tsx" */),
  "component---src-pages-account-order-details-tsx": () => import("./../../../src/pages/account/order-details.tsx" /* webpackChunkName: "component---src-pages-account-order-details-tsx" */),
  "component---src-pages-account-order-history-tsx": () => import("./../../../src/pages/account/order-history.tsx" /* webpackChunkName: "component---src-pages-account-order-history-tsx" */),
  "component---src-pages-account-projects-tsx": () => import("./../../../src/pages/account/projects.tsx" /* webpackChunkName: "component---src-pages-account-projects-tsx" */),
  "component---src-pages-account-stored-payment-methods-tsx": () => import("./../../../src/pages/account/stored-payment-methods.tsx" /* webpackChunkName: "component---src-pages-account-stored-payment-methods-tsx" */),
  "component---src-pages-checkout-afterpay-complete-index-tsx": () => import("./../../../src/pages/checkout/afterpay/complete/index.tsx" /* webpackChunkName: "component---src-pages-checkout-afterpay-complete-index-tsx" */),
  "component---src-pages-checkout-amazon-pay-complete-index-tsx": () => import("./../../../src/pages/checkout/amazon-pay/complete/index.tsx" /* webpackChunkName: "component---src-pages-checkout-amazon-pay-complete-index-tsx" */),
  "component---src-pages-checkout-billing-tsx": () => import("./../../../src/pages/checkout/billing.tsx" /* webpackChunkName: "component---src-pages-checkout-billing-tsx" */),
  "component---src-pages-checkout-cart-tsx": () => import("./../../../src/pages/checkout/cart.tsx" /* webpackChunkName: "component---src-pages-checkout-cart-tsx" */),
  "component---src-pages-checkout-confirmation-tsx": () => import("./../../../src/pages/checkout/confirmation.tsx" /* webpackChunkName: "component---src-pages-checkout-confirmation-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-checkout-shipping-tsx": () => import("./../../../src/pages/checkout/shipping.tsx" /* webpackChunkName: "component---src-pages-checkout-shipping-tsx" */),
  "component---src-pages-customer-account-tsx": () => import("./../../../src/pages/customer/account.tsx" /* webpackChunkName: "component---src-pages-customer-account-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-success-tsx": () => import("./../../../src/pages/login/success.tsx" /* webpackChunkName: "component---src-pages-login-success-tsx" */),
  "component---src-pages-reviews-index-tsx": () => import("./../../../src/pages/reviews/index.tsx" /* webpackChunkName: "component---src-pages-reviews-index-tsx" */),
  "component---src-pages-share-tsx": () => import("./../../../src/pages/share.tsx" /* webpackChunkName: "component---src-pages-share-tsx" */),
  "component---src-pages-uploads-index-tsx": () => import("./../../../src/pages/uploads/index.tsx" /* webpackChunkName: "component---src-pages-uploads-index-tsx" */),
  "component---src-pages-uploads-success-tsx": () => import("./../../../src/pages/uploads/success.tsx" /* webpackChunkName: "component---src-pages-uploads-success-tsx" */),
  "component---src-templates-clp-index-tsx": () => import("./../../../src/templates/CLP/index.tsx" /* webpackChunkName: "component---src-templates-clp-index-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../../../src/templates/ContentPage.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-pdp-tsx": () => import("./../../../src/templates/PDP.tsx" /* webpackChunkName: "component---src-templates-pdp-tsx" */),
  "component---src-templates-product-category-174-wedding-albums-collection-tsx": () => import("./../../../src/templates/ProductCategory/174-wedding_albums_collection.tsx" /* webpackChunkName: "component---src-templates-product-category-174-wedding-albums-collection-tsx" */)
}

