export enum GlobalActionType {
  CLEAR_STORE = 'CLEAR_STORE',
}

export const {
    CLEAR_STORE
} = GlobalActionType;

export interface GlobalAction {
  type: GlobalActionType
  payload?: any
  meta?: {
    redirect: boolean
    path: string
  }
}
