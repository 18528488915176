import React from 'react';
import { StyledSvgContainer } from './styled';
import { Icon } from './icon';

interface SvgContainerProps {
  icon: string;
  className?: string;
}

const SvgContainer = ({ icon, className }: SvgContainerProps): JSX.Element => {
  return (
    <StyledSvgContainer className={className}>
      <Icon icon={icon} />
    </StyledSvgContainer>
  );
};

export default SvgContainer;
