import { Box } from '@material-ui/core';
import styled from 'styled-components';

export const ChildContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Puller = styled(Box)`
  border-radius: 3px;
  border: 1.5px solid #333333;
  background: #333333;
`;
