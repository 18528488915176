import styled, { css }  from 'styled-components';
import { Badge as _Badge, BadgeProps } from "../Badge";
import { mediaQuery } from "../Grid";

export const Badge = styled(_Badge)`
  span {
    font-size: 12px;
    display: block;
    letter-spacing: 1.5px;
  }
`;

export const IframeOverlay = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
`

export const Badges = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  padding: 12px;

  > div {
    padding: 4.5px 8px;
    border-radius: 3px;
  }

  ${mediaQuery.medium`
    padding: 1rem;
  `};

  > *:not(:last-child) {
    margin-right: 16px;
  }
`;

export const PhotoSliderWrapper = styled.div`
  ${mediaQuery.medium`
    display: flex;
    align-items: flex-start;
  `}
  
  .carousel__slide--hidden {
    visibility: hidden;
  }
`;

export const PhotoThumbsWrapper = styled.div`
  ${mediaQuery.medium`
    display: flex;
    flex-direction: column;
  `}
`;
