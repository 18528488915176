import { navigate } from 'gatsby';
import { optionGet } from 'faunctions';
import { MAGENTO_CMS_TOP_MENU_URL } from 'constants/navigation';
import { getServerConfigVars } from '../config';
import { fetchJson, setUrlParam, setUrlParams } from '../http';
import { getGatsbySiteUrl, isNotServerSideRendering } from 'helpers/env';
import { MagentoMenuItem, MenuItemEdge } from 'types/Navigation';

const { MAGENUM_INTEGRATION_TOKEN } = getServerConfigVars();

type RedirectParams = {
  isNotBlocklisted?: boolean;
  replace?: boolean;
};

export const GATSBY_LINK_BLOCKLIST = [
  'account',
  'ideas',
  'help',
  'blog',
  'wedding-album-design-services',
  'gift-cards',
  'apps.apple',
  // Wedding Albums Collection
  'wedding/wedding-collections/albums'
];

export const GATSBY_LINK_WHITELIST = ['account/galleries'];

export const checkIsWhitelisted = (path: string) => GATSBY_LINK_WHITELIST.some(text => path.includes(text));

export const checkIsBlocklisted = (path: string) =>
  checkIsWhitelisted(path) ? false : GATSBY_LINK_BLOCKLIST.some(text => path.includes(text));

// Fetches top menu navigation links from au-magenum
export const fetchTopMenuData = async () =>
  await fetchJson(MAGENTO_CMS_TOP_MENU_URL, {
    headers: {
      Authorization: MAGENUM_INTEGRATION_TOKEN,
      'Content-Type': 'application/json'
    }
  }).then(json => optionGet('content')(json).getOrElse([]));

const isAbsoluteLink = (path: string) =>
  /^(https?|http?|file|ftps?|mailto|javascript|data:image\/[^;]{2,9};):/i.test(path);
const isRelativeLink = (path: string) => !isAbsoluteLink(path);

export const toAbsolutePath = (path: string): string => {
  if (isRelativeLink(path)) {
    const isNotSSR = isNotServerSideRendering();
    const searchUrl = isNotSSR ? window.location.search : '';

    const urlParams = new URLSearchParams(searchUrl);
    const prNumber = urlParams.get('PR');
    const nextPath = prNumber ? setUrlParams(path, { PR: prNumber }) : path;
    return nextPath.startsWith('/') ? nextPath : `/${nextPath}`;
  }
  return path;
};

export const redirectToPage = async (url: string, options?: RedirectParams): Promise<void> => {
  if (isRelativeLink(url)) {
    const isNotSSR = isNotServerSideRendering();
    const searchUrl = isNotSSR ? window.location.search : '';

    const urlParams = new URLSearchParams(searchUrl);
    const prNumber = urlParams.get('PR');
    url = prNumber ? setUrlParams(url, { PR: prNumber }) : url;
    if (options?.isNotBlocklisted !== false) {
      return navigate(url, options?.replace ? { replace: options.replace } : {});
    }
  }
  window.location.href = url;
};

export const getCurrentPathname = () => (isNotServerSideRendering() ? window.location.pathname : '');
export const getCurrentURL = () => window.location.href;

export const getLastPathnameAttribute = () => {
  const pathname = getCurrentPathname();
  if (pathname) {
    const pathElements = pathname.split('/');
    return pathElements[pathElements.length - 1];
  } else {
    return undefined;
  }
};

export const flattenMenuItemNodes = (menuItemNodes: Array<MenuItemEdge>): Array<MagentoMenuItem> => {
  let magentoMenuItems: Array<MagentoMenuItem> = [];
  menuItemNodes.forEach(menuItemNode => {
    magentoMenuItems.push(...flattenMenuItem(menuItemNode.node.items));
  });
  magentoMenuItems = magentoMenuItems.filter((item, index) => {
    return magentoMenuItems.findIndex(found => item.path === found.path) === index;
  });
  return magentoMenuItems;
};

const flattenMenuItem = (menuItems: Array<MagentoMenuItem>): Array<MagentoMenuItem> => {
  const magentoMenuItems: Array<MagentoMenuItem> = [];
  menuItems.forEach(item => {
    if (!item.children) {
      magentoMenuItems.push(item);
    } else {
      magentoMenuItems.push(...flattenMenuItem(item.children));
    }
  });
  return magentoMenuItems;
};

export const getGoogleRedirectURL = (fullPathURL: string, redirect = '') => {
  let currentQueryStringParams;

  if (redirect) {
    currentQueryStringParams = new URL(redirect).searchParams;
  } else {
    currentQueryStringParams = new URL(getCurrentURL()).searchParams;
  }

  let redirectTo = currentQueryStringParams?.get('redirectTo');
  if (!redirectTo) {
    redirectTo = '/';
  }

  // Pass PR query string parameter through for ephemeral environment use
  const pullRequestNumber = currentQueryStringParams?.get('PR');

  if (isRelativeLink(fullPathURL) && pullRequestNumber) {
    redirectTo = setUrlParam(redirectTo, 'PR', pullRequestNumber);
  }

  let successPageWithRedirect = `${getGatsbySiteUrl()}/login/success?redirectTo=${encodeURIComponent(redirectTo)}`;

  // Also use the ephemeral environment on the login/success page
  if (pullRequestNumber) {
    successPageWithRedirect = setUrlParam(successPageWithRedirect, 'PR', pullRequestNumber);
  }

  // Pass through hammer category for auth redirect hook on success page
  const tryEditorV2ForHammerCategory = currentQueryStringParams?.get('tryEditorV2ForHammerCategory');
  if (tryEditorV2ForHammerCategory) {
    successPageWithRedirect = setUrlParam(
      successPageWithRedirect,
      'tryEditorV2ForHammerCategory',
      tryEditorV2ForHammerCategory
    );
  }

  // Pass through sku for auth redirect hook on success page
  const tryEditorV2ForSku = currentQueryStringParams?.get('tryEditorV2ForSku');
  if (tryEditorV2ForSku) {
    successPageWithRedirect = setUrlParam(successPageWithRedirect, 'tryEditorV2ForSku', tryEditorV2ForSku);
  }

  const nextFullPathURL = setUrlParam(fullPathURL, 'relayState', successPageWithRedirect);

  return nextFullPathURL;
};

export const loginPageRedirect = (page = '/login'): void => {
  const baseDomain = process.env.GATSBY_SITE_URL;
  const path = window.location.pathname;
  const redirectTo = path.length > 1 ? `?redirectTo=${path}` : '';
  const queryString = window.location.search;
  const formattedQueryString = redirectTo.length ? queryString.replace('?', '&') : queryString;
  const loginUrl = `${baseDomain}${page}${redirectTo}${formattedQueryString}`;

  redirectToPage(loginUrl);
};
