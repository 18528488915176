import { analyticsApplyPromoCodeError } from 'store/cart/actions';
import { ApplyPromoCodeErrorEvent } from '../analyticsWrapper';

export function mapApplyPromoCodeErrorToEvent(
  action: ReturnType<typeof analyticsApplyPromoCodeError>): ApplyPromoCodeErrorEvent {
  const { promoCode, promoCodeError, cartId } = action.payload;
  return {
    type: 'applyPromoCodeError',
    data: {
      promo_code: promoCode,
      promo_code_error: promoCodeError,
      ...(cartId && { cart_id: cartId })
    }
  };
}