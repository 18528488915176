import React from 'react';
import styled from 'styled-components';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import { getStarsAndReviews } from 'components/Yotpo/helpers';

type YotpoStarsProductProps = {
  yotpoStars?: {
    totalReviews: number;
    score: number;
  };
};

interface StarProps {
  type: 'star' | 'half-star' | 'empty-star';
}

const StyledYotpoStars = styled.div`
  &.yotpo {
    height: 20px;
    
    .star-container {
      display: flex;
      align-items: center;
    }

    .icon {
      margin-right: 4px;
      color: #000000;
      width: 0.7rem;
    }

    .empty-star {
      color: #ffffff; /* White fill */
      stroke: #000000; /* Black outline */
      stroke-width: 12;
      width: 0.7rem;
    }

    .review-total {
      font-size: 0.7rem;
      color: #080707;
      padding-bottom: 0.2rem;
    }
  }
`;

export const CLPStar : StarProps = ({ type }) => {
  if (type === 'star') {
    return <FaStar className="icon" />;
  }
  if (type === 'half-star') {
    return <FaStarHalfAlt className="icon" />;
  }
  return <FaRegStar className="icon empty-star" />;
};

const YotpoStars: React.FC<YotpoStarsProductProps> = ({ yotpoStars, StarComponent = CLPStar }: YotpoStarsProductProps) => {
  const { stars, totalReviews } = getStarsAndReviews(yotpoStars, StarComponent);

  return (
    <StyledYotpoStars className="yotpo">
      <div className="star-container">
        <span className="yotpo-stars">{stars}</span>
        <span className="review-total">({totalReviews})</span>
      </div>
    </StyledYotpoStars>
  );
};

export default YotpoStars;