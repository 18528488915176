import { ContextProperties } from './../../itly';
import { ItlyFilters } from 'components/CLPFilters/helpers';

// Singleton context that is sent to every itly call
// Values are updated at runtime using `updateItlyContext`
const itlyContext: ContextProperties = {};

export const updateItlyContext = (key: string, value: string | boolean | ItlyFilters): void => {
  itlyContext[key] = value;
};

export default itlyContext;
