import { useCallback } from 'react';
import { apiRequest } from '../helpers/http';

/**
 * React hook to secure access to an api request instance
 *
 * @param {string} url, api base url
 * @param {boolean} includeCredentials, defines whenever the api request includes env credentials
 */
export const useApiRequest = (url: string, includeCredentials = false) => useCallback(apiRequest(url, includeCredentials), [url, includeCredentials]);
