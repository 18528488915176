import React from 'react';
import Modal from 'components/Modal';
import { ErrorModalToast } from '../ErrorModal/index';
import GatewayModal from 'components/GatewayModal';

interface OverlayComponentsProps {}

const OverlayComponents = (props: OverlayComponentsProps) => (
  <>
    <Modal />
    <GatewayModal />
    <ErrorModalToast />
  </>
);

export default OverlayComponents;
