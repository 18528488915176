import { useEffect } from 'react';
import { useFoilColorBackground } from 'features/pdp/hooks/useSelectedOptions';

interface FoilColorBackgroundProps {
  onChange: (foilColor?: string) => void;
}

const FoilColorBackground = ({ onChange }: FoilColorBackgroundProps): JSX.Element => {
  const foilColorBackground = useFoilColorBackground();

  useEffect(() => {
    onChange(foilColorBackground);
  }, [foilColorBackground, onChange]);

  return null;
};

export default FoilColorBackground;
