import { optionGet } from 'faunctions';

import { GlobalState, INITIALIZED, EMPTY_OBJECT } from 'store/constants';
import { getPromoCodeDiscountFromTotalSegments } from './magentoSelectors';
import { findCustomerPaymentMethodInCartPaymentMethods, amazonpay, paypal, afterpay } from 'helpers/billing';
import { FREE_SHIPPING_METHOD } from 'store/ui/constants';

export const cartSelector = (state: GlobalState) => optionGet('cart.entity')(state).getOrElse(EMPTY_OBJECT);

export const cartIdSelector = (state: GlobalState) => cartSelector(state).entity_id;

export const isVirtualCartSelector = (state: GlobalState) =>
  optionGet('cart.entity.is_virtual')(state).getOrElse(false);

export const cartErrorSelector = (state: GlobalState) => optionGet('cart.err')(state).getOrElse('');

export const giftCardErrorSelector = (state: GlobalState) => optionGet('cart.giftCardErr')(state).getOrElse('');

export const siteCreditErrorSelector = (state: GlobalState) => optionGet('cart.siteCreditErr')(state).getOrElse('');

export const promoCodeErrSelector = (state: GlobalState) => optionGet('cart.promoCodeErr')(state).getOrElse('');

export const cartItemsSelector = (state: GlobalState) => optionGet('cart.entity.items')(state).getOrElse([]);

export const queuedAddToCartItemPayloadSelector = (state: GlobalState) => 
  optionGet('cart.queuedAddToCartItemPayload')(state).getOrElse(null);

export const shippingAddressSelector = (state: GlobalState) =>
  optionGet('cart.entity.shippingAddress')(state).getOrElse(EMPTY_OBJECT);

export const billingAddressSelector = (state: GlobalState) =>
  optionGet('cart.entity.billingAddress')(state).getOrElse(EMPTY_OBJECT);

export const shippingMethodSelector = (state: GlobalState) =>
  optionGet('cart.entity.shippingMethod')(state).getOrElse('');

export const hasFreeShippingBeenAppliedSelector = (state: GlobalState) =>
  optionGet('cart.entity.hasFreeShippingBeenApplied')(state).getOrElse(false);

export const isFreeShippingAppliedToCartSelector = (state: GlobalState): boolean =>
  !!(state.cart?.entity?.extension_attributes?.shipping_assignments || []).find(
    shippingAssignment => shippingAssignment.shipping.method === FREE_SHIPPING_METHOD
  );

export const shippingRatesSelector = (state: GlobalState) =>
  optionGet('cart.entity.shippingRates')(state).getOrElse([]);

export const promoCodeSelector = (state: GlobalState) => {
  const totalSegments = optionGet('cart.entity.total_segments')(state).getOrElse([]);
  const promoCode = getPromoCodeDiscountFromTotalSegments(totalSegments);
  return optionGet('title')(promoCode).getOrElse('');
};

export const braintreeValidationStateSelector = (state: GlobalState) =>
  optionGet('cart.braintreeValidationState')(state).getOrElse(null);

export const totalSegmentsSelector = (state: GlobalState) =>
  optionGet('cart.entity.total_segments')(state).getOrElse([]);

export const cartPaymentMethodsSelector = (state: GlobalState) =>
  optionGet('cart.entity.paymentMethods')(state).getOrElse([]);

export const completedOrderSelector = (state: GlobalState) => optionGet('cart.completedOrder')(state).getOrElse(null);

export const amazonPayRedirectUrlSelector = (state: GlobalState) =>
  optionGet('cart.amazonPayRedirectUrl')(state).getOrElse(null);

export const isPaypalEnabledSelector = (state: GlobalState) =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === paypal;

export const isAmazonpayEnabledSelector = (state: GlobalState) =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === amazonpay;

export const isAfterpayAppliedToCartSelector = (state: GlobalState) =>
  findCustomerPaymentMethodInCartPaymentMethods(cartPaymentMethodsSelector(state)).type === afterpay;

// Loading state selectors
export const cartLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.cart')(state).getOrElse(INITIALIZED);
export const cartItemsLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.cartItems')(state).getOrElse(INITIALIZED);
export const shippingAddressLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.shippingAddress')(state).getOrElse(INITIALIZED);
export const paymentMethodLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.paymentMethods')(state).getOrElse(INITIALIZED);
export const shippingRatesLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.shippingRates')(state).getOrElse(INITIALIZED);
export const promoCodeLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.promoCode')(state).getOrElse(INITIALIZED);
export const giftCardLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.giftCard')(state).getOrElse(INITIALIZED);
export const placeOrderLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.placeOrder')(state).getOrElse(INITIALIZED);
export const storeCreditLoadingStateSelector = (state: GlobalState) =>
  optionGet('cart.loadingStates.storeCredit')(state).getOrElse(INITIALIZED);
