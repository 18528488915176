import React from 'react';
import {
  GoogleButton,
  GoogleButtonContainer,
  GoogleButtonWrapper,
  Separator,
  SeparatorContainer,
  SeparatorLabel,
  StyledGoogleLogo
} from './styled';
import { SmallLoader } from 'components/AULoader/SmallLoader';
import GoogleLogo from '../../icons/GoogleLogo.svg';
import SvgContainer from '../../icons/SvgContainer';

interface IGoogleButtonProps {
  disabled: boolean;
  isLoading: boolean;
  color: string;
  handleClick: () => void;
  description: string;
}

const StyledGoogleButton = ({ disabled, isLoading, color, handleClick, description }: IGoogleButtonProps) => {
  return (
    <GoogleButtonContainer key="googleLogin">
      <GoogleButtonWrapper>
        <GoogleButton onClick={handleClick} color={disabled ? '#ccc' : color} disabled={isLoading}>
        {isLoading ? (
            <SmallLoader isLoading={isLoading} />
          ) : (
            <>
              <StyledGoogleLogo>{SvgContainer(GoogleLogo)}</StyledGoogleLogo>
              {description}
            </>
          )}
        </GoogleButton>
      </GoogleButtonWrapper>
      <SeparatorContainer>
        <Separator style={{ margin: '8px 33px 0 0' }} />
        <SeparatorLabel>or</SeparatorLabel>
        <Separator style={{ margin: '8px 0 0 33px' }} />
      </SeparatorContainer>
    </GoogleButtonContainer>
  );
};

export default StyledGoogleButton;
