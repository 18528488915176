import React from 'react';
import styled from 'styled-components';
import { StyledH3 } from '../typography';

interface CalloutBlockProps {
  title?: string;
  children: React.ReactNode;
  className?: string;
  css?: any;
}

const CalloutBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #f2f2f2;
  justify-content: center;
  flex-flow: column wrap;
  color: #000000;
  font-size: 0.85rem;
  padding: 1.5rem 1.25rem;
  :not(:last-child) {
    margin-bottom: 1rem;
  }

  > h3 {
    margin: 0;
    margin-bottom: 0.8rem;
  }

  > div {
    width: 100%;
  }
`;

const CalloutBlock = ({
  title,
  children,
  className = ''
}: CalloutBlockProps) => (
  <CalloutBlockWrapper className={className}>
    {title ? <StyledH3 secondary>{title}</StyledH3> : null}
    <div>{children}</div>
  </CalloutBlockWrapper>
);

export default CalloutBlock;
