import { useEffect } from 'react';
import { newrelic } from 'helpers/reporting/newrelic';
import { createRunner } from './runner';
import { ensureYotpoScriptLoaded, isYotpoReady } from './yotpo';

// Global state to track first execution.
let isFirstPageLoad = true;

export function initYotpoFirstPageLoadState() {
  // ** This global handler executes on every page (including non-PDP pages) **
  window.addEventListener('load', event => {
    // If the user lands in Gatsby from a non PDP page, we need to clear the
    // `isFirstPageLoad` flag once the page is loaded so that the Yotpo widgets
    // Are manually refreshed when they eventually navigate to a PDP.
    if (isFirstPageLoad) {
      isFirstPageLoad = false;
    }
  });
}

// Creating a global/singleton runner to ensure that we don't trigger multiple
// Widget refresh tasks when we have multiple Yotpo components in the page.
const yotpoRunner = createRunner({
  isDebugging: process.env.GATSBY_SITE_ENV !== 'prod',
  name: 'yotpo',
  fn: async ({ yotpoAPIKey, log, id }) => {
    log('Parent component mounted, starting');
    const api = await ensureYotpoScriptLoaded(yotpoAPIKey, { log });
    const ready = isYotpoReady(api, { log });
    if (!ready) {
      log('Not ready, retrying');
      return { retry: true };
    }

    // Yotpo handles initializing/refreshing its widgets on the initial page
    // Load. We don't want to trigger an unnecessary refresh so we just record
    // The fact that the page load occurred and then bail.
    if (isFirstPageLoad) {
      isFirstPageLoad = false;
      log('First page load detected, stopping');
      return { retry: false };
    }

    // TODO: Yotpo's support suggested we add this method call to prevent
    //       Errors with refreshWidgets() on staging/prod. We don't have a
    //       Good explanation for why it's required.
    // @ts-ignore
    api.initWidgets();

    let scheduleRetry = false;
    try {
      // @ts-ignore
      api.refreshWidgets();

      log('refreshWidgets() success!');
    } catch (e) {
      console.warn(`[${id}] yotpo.refreshWidgets() failed, caught error:`, e);
      newrelic('noticeError')(e, { type: 'HandledYotpoError' });
      log('Error handled');
      // In event of failure, schedule retry
      scheduleRetry = true;
    }

    return { retry: scheduleRetry };
  }
});

export function useYotpo(yotpoAPIKey: string, { debug = false } = {}) {
  useEffect(() => {
    yotpoRunner.start({ yotpoAPIKey, debug });
    return yotpoRunner.reset;
  }, []);
}