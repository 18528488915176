import React, { RefObject } from 'react';

import { StyledH4, StyledAttributeValue, StyledRadioGroup, StyledOptionContainer } from './index.styled';

export interface RadioInputProps {
  id: string;
  label: string;
  displayName: string;
  hoverLabel?: string;
  children: JSX.Element | JSX.Element[];
  ref?: RefObject<HTMLDivElement>;
}

const RadioInputGroupWrapper = ({
  id,
  label,
  displayName,
  hoverLabel,
  children,
  ref
}: RadioInputProps): JSX.Element => {
  return (
    <StyledOptionContainer ref={ref} id={`option-${id}`}>
      <StyledH4 id={`option-title-${id}`} aria-label={label}>
        <span>{displayName}</span>
        <StyledAttributeValue id={`${label}-selected-option-value`}>{hoverLabel}</StyledAttributeValue>
      </StyledH4>

      <StyledRadioGroup role="radiogroup">{children}</StyledRadioGroup>
    </StyledOptionContainer>
  );
};

export default RadioInputGroupWrapper;
