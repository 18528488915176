import CustomerDataCustomer from 'mage-swagfaces/customer/CustomerDataCustomer';
import { LoadingState } from '../constants';
import { MagentoErrors } from 'store/magento/constants';

export interface CustomerLoadingStates {
  shippingAddress: LoadingState;
  customer: LoadingState;
  paymentMethods: LoadingState;
}

export interface CustomerState {
  loadingStates: CustomerLoadingStates;
  // @todo: this should eventually be replaced by our own UI Customer state
  entity: CustomerDataCustomer;
}

export type CustomerLoginSuccessArgs = {
  redirectTo: string
  editorVersion?: string
  hammerCategory?: string
  sku?: string
  message?: string
}

export enum CustomerActionType {
  CUSTOMER_LOGIN_SUCCESS = 'CUSTOMER_LOGIN_SUCCESS',
  FETCH_CUSTOMER_REQUEST = 'FETCH_CUSTOMER_REQUEST',
  FETCH_CUSTOMER_SUCCESS = 'FETCH_CUSTOMER_SUCCESS',
  FETCH_CUSTOMER_FAILURE = 'FETCH_CUSTOMER_FAILURE',
  UPDATE_CUSTOMER_ADDRESS_REQUEST = 'UPDATE_CUSTOMER_ADDRESS_REQUEST',
  UPDATE_CUSTOMER_ADDRESS_SUCCESS = 'UPDATE_CUSTOMER_ADDRESS_SUCCESS',
  UPDATE_CUSTOMER_ADDRESS_FAILURE = 'UPDATE_CUSTOMER_ADDRESS_FAILURE',
  ADD_CUSTOMER_ADDRESS_REQUEST = 'ADD_CUSTOMER_ADDRESS_REQUEST',
  ADD_CUSTOMER_ADDRESS_SUCCESS = 'ADD_CUSTOMER_ADDRESS_SUCCESS',
  ADD_CUSTOMER_ADDRESS_FAILURE = 'ADD_CUSTOMER_ADDRESS_FAILURE',
  DELETE_CUSTOMER_ADDRESS_REQUEST = 'DELETE_CUSTOMER_ADDRESS_REQUEST',
  DELETE_CUSTOMER_ADDRESS_SUCCESS = 'DELETE_CUSTOMER_ADDRESS_SUCCESS',
  DELETE_CUSTOMER_ADDRESS_FAILURE = 'DELETE_CUSTOMER_ADDRESS_FAILURE',
  FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST = 'FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST',
  FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS = 'FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS',
  FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE = 'FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE',
  DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST = 'DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST',
  DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS = 'DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS',
  DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE = 'DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE',
  VALIDATE_SHIPPING_ADDRESS_REQUEST = 'VALIDATE_SHIPPING_ADDRESS_REQUEST',
  VALIDATE_SHIPPING_ADDRESS_SUCCESS = 'VALIDATE_SHIPPING_ADDRESS_SUCCESS',
  VALIDATE_SHIPPING_ADDRESS_FAILURE = 'VALIDATE_SHIPPING_ADDRESS_FAILURE',
  REFRESH_CART_STATE = 'REFRESH_CART_STATE'
}

export const {
  CUSTOMER_LOGIN_SUCCESS,
  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,
  UPDATE_CUSTOMER_ADDRESS_REQUEST,
  UPDATE_CUSTOMER_ADDRESS_SUCCESS,
  UPDATE_CUSTOMER_ADDRESS_FAILURE,
  ADD_CUSTOMER_ADDRESS_REQUEST,
  ADD_CUSTOMER_ADDRESS_SUCCESS,
  ADD_CUSTOMER_ADDRESS_FAILURE,
  FETCH_CUSTOMER_PAYMENT_METHODS_REQUEST,
  FETCH_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  FETCH_CUSTOMER_PAYMENT_METHODS_FAILURE,
  DELETE_CUSTOMER_PAYMENT_METHODS_REQUEST,
  DELETE_CUSTOMER_PAYMENT_METHODS_SUCCESS,
  DELETE_CUSTOMER_PAYMENT_METHODS_FAILURE,
  VALIDATE_SHIPPING_ADDRESS_REQUEST,
  VALIDATE_SHIPPING_ADDRESS_SUCCESS,
  VALIDATE_SHIPPING_ADDRESS_FAILURE,
  REFRESH_CART_STATE,
  DELETE_CUSTOMER_ADDRESS_REQUEST,
  DELETE_CUSTOMER_ADDRESS_SUCCESS,
  DELETE_CUSTOMER_ADDRESS_FAILURE
} = CustomerActionType;

export interface CustomerAction {
  type: CustomerActionType | MagentoErrors
  payload?: any
}
