import { Column, Grid } from 'component-library';
import { LabeledField, LabeledSelect, LabeledNativeSelect } from 'components/Forms';
import { optionFind, optionGet } from 'faunctions';
import { FormikProps } from 'formik';
import {
  AddressFormValues,
  AddressContactFormValues,
  mapCountriesToOptions,
  mapRegionsToOptions,
  sortCountryList
} from 'helpers/forms';
import React from 'react';

import { Show } from 'components/Functional';
import { isEmpty, isNotEmpty } from 'helpers/arrays';
import { findCountryById } from 'helpers/static/index';
import { renderRegionLabel } from 'helpers/shipping';
import { useCountries } from 'hooks/useCountries';
import DirectoryDataRegionInformation from 'mage-swagfaces/directory/DirectoryDataRegionInformation';

/**
 * AddressFormPartial is a combination of Formik <Field /> and Elevate Components
 * Note: Intended to be used with FormikProps, with AddressFormValues (see: helpers/forms)
 *
 * Usage:
 *   <AddressFormPartial { ...formikProps } />
 */

type AddressFormPartialProps = FormikProps<AddressFormValues> & {
  disabled?: boolean;
  abbreviated?: boolean;
} & AddressContactFormValues;

const AddressFormPartial = (formikProps: AddressFormPartialProps): JSX.Element => {
  const { values, setFieldValue, disabled, abbreviated } = formikProps;
  const countryData = useCountries();
  const countries = sortCountryList(countryData);
  const regions: DirectoryDataRegionInformation[] = optionFind(
    x => x.two_letter_abbreviation === values.country_id?.value || values.country,
    countries
  )
    .chain(optionGet('available_regions'))
    .getOrElse([]);

  //TODO: We should be able to conditionally lay out, rather than having two whole copies here
  return (
    <Grid>
      {!abbreviated ? (
        <>
          <Column size={12} medium={6}>
            <LabeledField disabled={disabled} label="First Name*" name="firstname" {...formikProps} />
          </Column>
          <Column size={12} medium={6}>
            <LabeledField disabled={disabled} label="Last Name*" name="lastname" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledSelect
              {...formikProps}
              disabled={disabled}
              name="country_id"
              label="Country*"
              options={mapCountriesToOptions(countries)}
              onChange={opt => {
                setFieldValue('country_id', opt);

                // Find the regions of the selected country
                const nextRegions = findCountryById(opt.value)(countries).available_regions || [];

                if (isEmpty(nextRegions)) {
                  // If there are no available_regions, we'll render a text field for the region and clear the value
                  setFieldValue('region_id', '');
                } else {
                  // If regions are available, reset the region's Select option value to clear out any stale regions
                  setFieldValue('region_id', { label: '', value: '' });
                }
              }}
            />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Address Line 1*" name="street1" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Address Line 2" name="street2" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="City*" name="city" {...formikProps} />
          </Column>
          <Column size={12} medium={6}>
            <Show when={isEmpty(regions)}>
              <LabeledField
                disabled={disabled}
                label={renderRegionLabel(values.country_id?.value)}
                name="region_id"
                {...formikProps}
              />
            </Show>
            <Show when={isNotEmpty(regions)}>
              <LabeledNativeSelect
                name="region_id"
                autoComplete="address-level1"
                disabled={disabled}
                label={renderRegionLabel(values.country_id?.value)}
                options={mapRegionsToOptions(regions)}
                {...formikProps}
              />
            </Show>
          </Column>
          <Column size={12} medium={6}>
            <LabeledField disabled={disabled} label="Zip Code*" name="postcode" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Phone Number*" name="telephone" {...formikProps} />
          </Column>
        </>
      ) : (
        <>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Name*" name="firstname" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Street Address 1*" name="street1" {...formikProps} />
          </Column>
          <Column size={12}>
            <LabeledField disabled={disabled} label="Street Address 2" name="street2" {...formikProps} />
          </Column>
          <Column size={12} medium={6}>
            <LabeledField disabled={disabled} label="City*" name="city" {...formikProps} />
          </Column>
          <Column size={12} medium={6}>
            <Show when={isEmpty(regions)}>
              <LabeledField
                disabled={disabled}
                label={renderRegionLabel(values.country_id?.value)}
                name="region_id"
                {...formikProps}
              />
            </Show>
            <Show when={isNotEmpty(regions)}>
              <LabeledNativeSelect
                name="region_id"
                autoComplete="address-level1"
                disabled={disabled}
                label={renderRegionLabel(values.country_id?.value)}
                options={mapRegionsToOptions(regions)}
                {...formikProps}
              />
            </Show>
          </Column>
          <Column size={12} medium={6}>
            <LabeledField disabled={disabled} label="Zip Code*" name="postcode" {...formikProps} />
          </Column>
          <Column size={12} medium={6}>
            <LabeledSelect
              {...formikProps}
              disabled={disabled}
              name="country_id"
              label="Country*"
              options={mapCountriesToOptions(countries)}
              onChange={opt => {
                setFieldValue('country_id', opt); // Find the regions of the selected country
                const nextRegions = findCountryById(opt.value)(countries).available_regions || [];

                if (isEmpty(nextRegions)) {
                  // If there are no available_regions, we'll render a text field for the region and clear the value
                  setFieldValue('region_id', '');
                } else {
                  setFieldValue('region_id', { label: '', value: '' });
                }
              }}
            />
          </Column>
        </>
      )}
    </Grid>
  );
};

export default AddressFormPartial;
