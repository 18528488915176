import { Product } from 'types/Product';
import { ProductPropertyGroup } from '../types/ProductPropertyGroup';

export const convertToReportingProduct = (
  product: Product
): ProductPropertyGroup => {
  return {
    sku: product.sku,
    product_id: product.productId || parseInt(product.id),
    name: product.name,
    // Need to convert possible null values to a number for Itly's type validation.
    price: product.lowestPrice || product.price || undefined,
    is_customizable_product: product.isEditable,
    category: product.reportingProductCategory,
    product_line: product.reportingProductLine,
    // The API returns `null` when `reportingProductType` isn't set, we need
    // To turn that into `undefined` for Itly's runtime validation for
    // Optional properties.
    product_type: product.reportingProductType || undefined,
    //Blueshift prefers array
    products: [
      {
        sku: product.sku,
        product_id: product.productId || parseInt(product.id),
        name: product.name,
        price: product.lowestPrice || product.price || undefined,
        category: product.reportingProductCategory,
        product_line: product.reportingProductLine
      }
    ],
    ecomm_prodid: product.sku
  };
};
