import { FC } from 'react';
import TextSwatch from 'au-gatsby-ds/components/SwatchSelect/TextSwatch';
import FoilColorSwatch from '../components/ProductOption/FoilColorSwatch';
import CircularImageSwatch from '../components/ProductOption/CircularImageSwatch';
import SquareImageSwatch from '../components/ProductOption/SquareImageSwatch';
import { FieldRenderType } from '../../pdp/helpers';
import { SwatchProps } from 'au-gatsby-ds/components/SwatchSelect';

const SWATCH_COMPONENTS: Record<string, FC<SwatchProps>> = {
  [FieldRenderType.SwatchText]: TextSwatch,
  [FieldRenderType.TextSwatch]: TextSwatch,
  [FieldRenderType.ImageSwatch]: CircularImageSwatch,
  [FieldRenderType.FoilSwatch]: FoilColorSwatch,
  [FieldRenderType.BooksizeSwatch]: SquareImageSwatch
};

const SQUARE_IMAGE_SWATCH_LABELS: string[] = ['calendar_design'];

const isSquareImageSwatchLabel = (label: string): boolean =>
  !!SQUARE_IMAGE_SWATCH_LABELS.find(squareLabel => label && label.includes(squareLabel));

export const getComponentForProductOption = (type: string, label: string): FC<SwatchProps> => {
  if (isSquareImageSwatchLabel(label)) {
    return SquareImageSwatch;
  }
  return SWATCH_COMPONENTS[type] || TextSwatch;
};

export const isTextSwatch = (type: string, label: string): boolean =>
  !isSquareImageSwatchLabel(label) && (type === FieldRenderType.SwatchText || type === FieldRenderType.TextSwatch);

//Checks if element is a child of the parentSelector
const checkIsParent = (element: Element, parentSelector: string): boolean => {
  const parent = document.querySelector(parentSelector);
  let node = element.parentNode;

  while (node != null) {
    if (node === parent) {
      return true;
    }
    node = node.parentNode;
  }
  return false;
};

//This checks if the parentSelector matches the parent of the element to determine when to scroll to the element
export const checkScrollElement = (element: Element, parentSelector: string): void => {
  const parentNode = checkIsParent(element, parentSelector);
  if (parentNode) {
    element.parentElement.parentElement.scrollTo({
      behavior: 'auto',
      left: element.parentElement.offsetLeft - 8
    });
  }
};