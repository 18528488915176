import styled from 'styled-components';
import { mediaQuery, PrimaryButton } from 'component-library';
import { StyledOptionContainer } from 'component-library/ProductOption/index.styled';

export const FormTitle = styled.h3`
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
  color: #333333;

  ${mediaQuery.medium`
    color: #000000;
  `};
`;

export const ErrorMessage = styled.span`
  color: red;
`;

export const FieldGroup = styled.div`
  ${StyledOptionContainer} {
    margin: 20px auto 0px auto;
  }
  margin-bottom: 40px;
`;

export const TooltipContainer = styled.div`
  position: absolute;
  right: 100%;
  background: white;
  top: 50%;
  @media screen and (max-width: 780px) {
    display: none;
  }
`;

export const DSButton = styled(PrimaryButton)`
  background-color: #ffffff !important;
  border-radius: 2px;
  border: 1.5px solid #000;
  color: #333333;
  margin-top: 15px;
`;

export const DScontent = styled.p`
  position: relative;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
  color: #757575;
  padding-bottom: 24px;
`;

export const FreeAddressingBannerContainer = styled.div`
    background-color: #63091C;
    text-align: center;
    padding: 21px;
    margin: -20px 0 24px 0;
    
    &::before {
      content: 'Free Addressing Through 12/16';
      color: white;
      display: block;
      font-family: Lato;
      font-size: 16px;
      font-style: normal;
      font-weight: 900;
      line-height: 20px; /* 142.857% */
      letter-spacing: 0.5px;
      margin-bottom: 10px;
    }
    
    &::after {
      content: 'Enjoy free return and recipient addressing on your holiday cards when you order by 12/16.';
      color: white;
      font-family: Lato;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
    }
  }
`;
