import styled, { css } from 'styled-components';
import { StyledP } from '../../typography';

export const CheckboxContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: flex-start;
  cursor: pointer;

  span {
    border: 1px solid #000;
    height: 18px;
    width: 18px;
    margin-top: 5px;
    margin-right: 5px;

    ${({ disabled }) => disabled
      && css`
        border: 1px solid #ccc;
        background-color: #ccc;

        svg {
          display: none;
        }
      `}
  }

  &:active span {
    box-shadow: 0 0 20px #4990e2;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked ~ span {
    background-color: #000;

    svg {
      display: block;
    }

    ${({ disabled }) => disabled
      && css`
        background-color: #ccc;
      `}
  }

  input:checked ~ span:after {
    display: block;
  }
`;

export const CheckboxLabel = styled(StyledP)<{ disabled?: boolean }>`
  margin-top: 0.35rem;
  margin-bottom: 0.25rem;
  line-height: 1rem;

  ${({ disabled }) => disabled
    && css`
      color: gray;
    `}
`;
