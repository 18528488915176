import styled from 'styled-components';
import { StyledOptionContainer, StyledRadioGroup } from 'au-gatsby-ds/components/SwatchSelect/styled';
import { FieldRenderType } from 'features/pdp/helpers';
import { TextSwatchContainer } from 'au-gatsby-ds/components/SwatchSelect/TextSwatch/styled';
import { mediaQuery } from 'component-library';
import { StyledSwatchContainer } from 'features/pdp3/components/ProductOption/SquareImageSwatch/styled';

export const ModalOptionsWrapper = styled.div<{ type: FieldRenderType; optionsLength: number; wrapperWidth: number }>`
  max-width: 100%;
  overflow-x: auto;

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
  scrollbar-width: none; /* Hide scrollbar for Firefox */

  ${StyledOptionContainer} {
    margin: 0;
    margin-top: 24px;

    padding: '0';
  }

  ${StyledRadioGroup} {
    margin: 0 auto;
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  ${TextSwatchContainer} {
    animation: transition 0.3s ease;
  }

  div[class*='StyledSwatchContainer'] {
    :first-child {
      margin-left: 8px;
    }

    :last-child {
      margin-right: 8px;
    }

    animation: transition 0.3s ease;
  }

  div[class*='TextSwatchContainer'] {
    :first-child {
      margin-left: 8px;
    }

    :last-child {
      margin-right: 8px;
    }

    animation: transition 0.3s ease;
  }

  @keyframes transition {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  ${mediaQuery.medium`
    overflow-x: visible;
    
    ${StyledOptionContainer} {
      min-width: unset;
    }

    ${StyledRadioGroup} {
      flex-wrap: wrap;
    }

    ${TextSwatchContainer} {
      width: 48%;
    }

    div[class*='StyledSwatchContainer'] {
      :first-child {
        margin-left: 0px;
      }

      :last-child {
        margin-right: 0px;
      }
    }

    div[class*='TextSwatchContainer'] {
      :first-child {
        margin-left: 0px;
      }

      :last-child {
        margin-right: 0px;
      }
    }
  `}
`;
