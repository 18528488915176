import styled from 'styled-components';
import ReactSelect from 'react-select';
import { PrimaryButton as Button } from '../../Button';

export const AlbumControlsWrapper = styled.div`
  display: flex;
`;

export const AutoFillButton = styled(Button)`
  margin-right: 1rem;
  max-height: 31px;
  border: 1px #000000 solid;
  color: #000000;
  background-color: #ffffff !important;
  text-align: center;
  line-height: 0.2rem;
  min-height: 38px;
`;
