/** Return the given content if x is truthy, otherwise return null */
export const showIf = (x: boolean) => (content: any) => (x ? content : null);

/** Return the given content if x is truthy, otherwise return fallback content */
export const showIfOrElse = (x: boolean) => (content: any) => (fallbackContent: any) => (x ? content : fallbackContent);

/** Returns true if values are equal */
export const isEqual: any = (a: any) => (b: any) => a === b;

export const isTrue = (x: any) => x === true;
export const isFalse = (x: any) => c === false;

/** Checks if any values in an array are exactly true. */
export const any = (...xs: any[]) => xs.some(isTrue);

/** Checks if all values in an array are exactly true. */
export const all = (...xs: any[]) => xs.every(isTrue);

export const equalStrings = (x: string, y: string) => y && (typeof y === 'string') && x && (typeof x === 'string') && x.toUpperCase() === y.toUpperCase();